<template>
  <div class="navItem" :class="{ onscrollItem: onscroll, onscrollItem: logo }">
    <div class="logo">
      <a href="/">
        <img
          :src="
            require('@/assets/icon/' + `${logo ? 'Frame' : 'LOGO'}` + '.svg')
          "
          alt="adpost"
          width="140"
          height="40"
        />
      </a>
    </div>
    <div class="menu">
      <div
        class="item register flex-center"
        @click="openModal('RegisterModal')"
      >
        <span class="txt-shadow">立即體驗</span>
      </div>
    </div>
    <div class="bars" uk-toggle="target: #sideNav">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
  <div id="sideNav" uk-offcanvas=" overlay: true">
    <div class="uk-offcanvas-bar sideBarArea">
      <div class="menu">
        <div
          class="item register flex-center  uk-offcanvas-close"
          @click="openModal('RegisterModal')"
        >
          <span class="txt-shadow">立即體驗</span>
        </div>
      </div>
    </div>
  </div>
  <component
    :is="modal"
    :ref="setRef"
    @open-modal="openModal"
    v-for="modal in theModals"
  ></component>
</template>

<script>
import { inject, ref, computed } from "vue";
import LoginModal from "@/components/modal/LoginModal.vue";
import RegisterModal from "@/components/modal/RegisterModal.vue";
import ForgotModal from "@/components/modal/ForgotModal.vue";

export default {
  name: "Login",
  components: {
    LoginModal,
    RegisterModal,
    ForgotModal
  },
  setup() {
    const theModals = ["LoginModal", "RegisterModal", "ForgotModal"];
    const modals = ref({});
    const onscroll = inject("onscroll");
    const fixed = inject("fixed");
    const openModal = async modalName => {
      modals.value[modalName]?.openModal();
    };
    const setRef = el => {
      if (el) {
        modals.value[el.$.type.name] = el;
      }
    };
    const logo = computed(() => {
      if (fixed.value) {
        if (onscroll.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    return {
      onscroll,
      openModal,
      logo,
      setRef,
      theModals
    };
  }
};
</script>
<style lang="scss" scoped>
.navItem {
  .menu {
    .item {
      &.register {
        padding: 0 20px;
        height: 40px;
        margin: 0 6px;
        color: $white-color;
      }
    }
  }
  &.onscrollItem {
    .menu {
      .item {
        &.register {
          color: $white-color;
          border-radius: 100vh;
          background: linear-gradient(91.61deg, #74dbcf 13.46%, #68c0cc 115.1%),
            linear-gradient(89.74deg, #c8f8ff 0.28%, #50eee5 41.98%);
          box-shadow: 0px 0px 3px 2px rgba(108, 255, 237, 0.45);
        }
      }
    }
  }
}
</style>
