import { createStore } from 'vuex';
import auth from './module/auth';
import project from './module/project';
import video from './module/video';
import editor from './module/editor';
import chat from './module/chat';
import onBoardingStep from './module/onBoardingStep';
import worksUpload from './module/worksUpload';
import notification from './module/notification';
import case_report from './module/case_report';
import brief from './module/brief';
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    project,
    video,
    editor,
    chat,
    onBoardingStep,
    worksUpload,
    notification,
    case_report,
    brief
  }
});
