import axios from 'axios';

export default {
  getOnboardingData(page, pageStatus) {
    return axios
      .get(`/api/onboarding/${page}/${pageStatus}`)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  sendOnboardingData(data = { page: '', status: '', step: '' }) {
    return axios
      .post(`/api/onboarding/update-step`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  }
};
