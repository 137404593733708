<template>
  <div class="replayComment t-shadow_medium">
    <div class="uk-flex uk-flex-middle mb-4">
      <div class="headShot mr-3">
        <Avatar
          class="avatar"
          size="36"
          :img="user?.avatar"
          :name="user?.name ?? user?.company_name ?? user?.email"
        />
      </div>
      <div class="nameArea">
        <div class="user">
          <div class="name">
            {{ user?.name ?? user?.company_name ?? user?.email }}
          </div>
        </div>
      </div>
    </div>
    <Editor
      @setEditorData="setEditorData"
      :editorData="msgData"
      :id="`replayChat`"
      :editable="true"
      placeholder="請留下留言...."
      :defaultFocused="true"
      ref="editor"
    />
    <PhotoArea v-if="photoList.data.length > 0" />

    <ActionButtonGroup
      :loading="loading"
      :isSendMsgDisabled="
        msgData.trim() == '' || msgData == '<p></p>' || editorIsEmpty(msgData)
      "
      @uploadPhoto="uploadPhoto"
      @cancel="clearContent"
      @sendMsg="sendMsg"
    />
  </div>
</template>
<script>
import { ref, reactive, watch, computed, inject, nextTick, provide } from 'vue';
import Avatar from '@/components/tools/Avatar.vue';
import Editor from '@/components/editor/Editor.vue';
import { useThrottleFn } from '@vueuse/core';
import { editorIsEmpty } from '@/lib/editorIsEmpty';
import notification from '@/lib/notification';
import ActionButtonGroup from './ActionButtonGroup.vue';
import PhotoArea from './PhotoArea.vue';
export default {
  name: '',
  components: {
    Editor,
    Avatar,
    ActionButtonGroup,
    PhotoArea
  },
  props: ['msgItemId'],
  setup(props, { emit }) {
    const user = computed(() => JSON.parse(localStorage.getItem('user')));
    const loading = ref(false);
    const msgData = ref('');
    const editor = ref(null);
    const sendMessage = inject('sendMessage');
    const getMessage = inject('getMessage');
    const chatState = inject('chatState');
    const photoList = reactive({
      data: [
        // {
        //   file: 'https://s3-ap-northeast-1.amazonaws.com/private.adpost.com.tw/copy-writing-images/9aab4a70-e596-11ed-97a6-ddcf843be66f_1698744182_ipDcSCqOUI.png'
        // }
      ]
    });
    provide('photoList', photoList);
    //電腦上傳
    const uploadPhoto = async e => {
      let uploadPhotos = e.target.files;
      if ([...uploadPhotos].length + photoList.data.length * 1 > 6) {
        notification.notificationShow('danger', '最多上傳6張照片');
        return false;
      }
      for (let i = 0; i < uploadPhotos.length; i++) {
        let img = uploadPhotos[i];

        const filesize = (img.size / 1024 / 1024).toFixed(4);
        if (filesize > 10) {
          notification.notificationShow(
            'danger',
            '上傳失敗，檔案大小勿超過10MB'
          );
          return false;
        }
        photoList.data.push(img);
      }

      e.target.value = '';
    };
    const setEditorData = editorData => {
      msgData.value = editorData;
    };
    const clearContent = () => {
      editor.value.clearContent();
      emit('closeCommentBox');
    };
    const focusEditor = () => {
      editor.value.focusEditor();
    };

    const sendMsg = useThrottleFn(
      async () => {
        debugger;
        try {
          loading.value = true;
          await nextTick();
          await sendMessage(
            msgData.value,
            props.msgItemId,
            null,
            photoList.data
          );
          await getMessage();
        } catch (error) {
          notification.notificationShow('warning', '送出留言失敗');
        } finally {
          loading.value = false;
          msgData.value = '<p></p>';
          emit('emitSendReplayMsg');
        }
      },
      5000,
      false
    );

    const clearMsgData = () => {
      msgData.value = '';
    };

    return {
      user,
      loading,
      msgData,
      editor,
      editorIsEmpty,
      setEditorData,
      clearContent,
      clearMsgData,
      sendMsg,
      focusEditor,
      photoList,
      uploadPhoto
    };
  }
};
</script>
<style lang="scss" scoped></style>
