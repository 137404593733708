<template>
  <section class="home">
    <!-- 首頁 -->
    <LandingPage />
  </section>
</template>

<script>
// @ is an alias to /src
import LandingPage from '@/components/landing_page/2023/LandingPage.vue';

export default {
  name: 'Home',
  components: {
    LandingPage
  }
};
</script>
<style lang="scss">
.home {
  background: white;
}
</style>
