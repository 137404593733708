<template>
  <figure class="photo tw-relative tw-m-0">
    <div class="photoWrap" @click="$emit('openLightBox', index)">
      <img :src="tmpUrl" alt="" loading="lazy" />
    </div>

    <transition name="fade">
      <div class="progressBar" v-if="progress > 1 && !completed">
        <span
          :style="{ width: `${progress}%` }"
          :class="{ error: error }"
        ></span>
      </div>
    </transition>
    <div
      v-if="isShowEditBox"
      class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-absolute tw--right-5 tw--top-5"
      @click="$emit('deletePhoto', index)"
    >
      <img :src="require('@/assets/icon/close.svg')" width="24" height="24" />
    </div>
  </figure>
</template>
<script>
import { ref, inject, onMounted } from 'vue';
import api from '@/api/messageBoard';
import compressorImage from '@/lib/compressorImage';
import notification from '@/lib/notification';
export default {
  name: 'photo',
  props: ['photo', 'taskUuid', 'index'],
  setup(props, { emit }) {
    const isShowEditBox = inject('isShowEditBox', true);
    const { photo, taskUuid, index } = props;
    const progress = ref(0);
    const tmpUrl = ref(photo?.file);
    const completed = ref(false);
    const error = ref(false);
    const setPreview = () => {
      tmpUrl.value = URL.createObjectURL(photo);
    };

    const postData = async () => {
      let img = await compressorImage.compressorImage(photo);
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progress.value = percentCompleted;
        }
      };
      try {
        const resp = await api.uploadImage(taskUuid, img, config);
        completed.value = true;
        tmpUrl.value = resp.file_path;
        emit('addS3Photo', { url: tmpUrl.value, index });
      } catch (err) {
        // console.log(err);
        error.value = true;
        notification.notificationShow('danger', '上傳失敗');
      }
    };

    const init = () => {
      postData();
    };

    onMounted(() => {
      if (!photo?.hasOwnProperty('file')) {
        init();
      }
    });

    return {
      tmpUrl,
      progress,
      completed,
      error,
      isShowEditBox
    };
  }
};
</script>
<style lang="scss" scoped>
.photoWrap {
  position: relative;
  height: 0;
  padding-top: 75%;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
  }
}
.progressBar {
  background: #ffffff;
  height: 6px;
  border-radius: 4px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  position: absolute;
  display: flex;
  span {
    background: $brand-color;
    height: 100%;
    border-radius: 4px;
  }
  .error {
    background: red;
  }
}
.photo {
  .delete {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -9px;
    right: -9px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    .delete {
      display: block;
    }
  }
  .error {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    font-size: 1rem;
  }
}
.blur {
  filter: blur(1px) grayscale(20%);
  opacity: 0.5;
}
.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
