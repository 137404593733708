<template>
  <button
    type="button"
    class="toTopBtn shadow-light"
    @click="$emit('emitScrollToTop')"
    v-if="showToTopBtn"
  >
    <img
      :src="require(`@/assets/icon/scrolltop.svg`)"
      width="36"
      height="36"
      uk-svg
    />
  </button>

  <button
    type="button"
    class="toBottomBtn shadow-light"
    @click="$emit('emitScrollToBottom')"
    v-if="showToBottomBtn"
  >
    <img
      :src="require(`@/assets/icon/scrolltop.svg`)"
      width="36"
      height="36"
      uk-svg
    />
  </button>
</template>
<script>
import { reactive, ref } from 'vue';

export default {
  name: 'ToBottomBtn',
  props: ['showToBottomBtn', 'showToTopBtn'],
  emits: ['emitScrollToTop', 'emitScrollToBottom'],
  setup(props, { emit }) {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.toBottomBtn {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  transform: rotate(180deg);
  z-index: 99;
}
.toTopBtn {
  position: absolute;
  bottom: 4rem;
  right: 1.5rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 99;
}
</style>
