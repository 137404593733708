import axios from 'axios';

export default {
  namespace: true,
  state: {
    showInvite: false,
    inviteData: []
  },
  getters: {},
  mutations: {},
  actions: {}
};
