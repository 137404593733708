<template>
  <div ref="target" class="uk-display-inline-block">
    <button type="button" @click.stop="changeFilterMenu">
      <img :src="require('@/assets/icon/messageBoard/filter.png')" />
    </button>
    <div class="t-shadow_light filterList" v-if="openFilterMenu">
      <label v-for="item in filterList" :key="item">
        <input
          type="radio"
          name="msg"
          :value="item.value"
          v-model="filterListVal"
          @change="$emit('updateFilterList', $event.target.value)"
        />
        <span class="checkmark"></span>
        <span class="ml-2">{{ item.name }}</span>
      </label>
    </div>
  </div>
</template>
<script>
import { ref, reactive, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default {
  name: 'FilterList',
  props: ['filterListVal'],
  setup(props, { emit }) {
    const target = ref(null);
    const filterListVal = ref(props.filterListVal);
    const filterList = reactive([
      { name: '所有留言', value: 'allProcessed' },
      { name: '已處理', value: 'processed' },
      { name: '未處理', value: 'unProcessed' }
    ]);
    const openFilterMenu = ref(false);
    const changeFilterMenu = () => {
      openFilterMenu.value = !openFilterMenu.value;
    };
    const closeFilterMenu = () => {
      openFilterMenu.value = false;
    };
    watch(
      () => props.filterListVal,
      newVal => {
        filterListVal.value = newVal;
      }
    );
    onClickOutside(target, event => closeFilterMenu());
    return {
      target,
      filterList,
      filterListVal,
      openFilterMenu,
      changeFilterMenu,
      closeFilterMenu
    };
  }
};
</script>
<style lang="scss" scoped>
.filterList {
  position: absolute;
  left: 32px;
  top: 53px;
  background-color: #ffffff;
}

label {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  color: black;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: #616161;
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    & + .checkmark {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  input[type='radio']:checked + .checkmark {
    display: block;
    background: url('~@/assets/icon/messageBoard/hook.svg') no-repeat;
    background-size: contain;
  }
}
</style>
