<template>
  <ul
    ref="ukTab"
    uk-tab="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
    style="margin: 0"
  >
    <li class="uk-active"><a href="#">Step 1</a></li>
    <li :class="{ 'link-disabled': status.step1 == false }">
      <a href="#" id="step2Link">Step 2</a>
    </li>
    <li :class="{ 'link-disabled': status.step2 == false }">
      <a href="#" id="step3Link">Step 3</a>
    </li>
  </ul>

  <ul class="uk-switcher mt-12">
    <step1
      class="uk-active"
      @emitSignUpData="emitSignUpData"
      @emitNextStep="emitNextStep"
      :status="status"
    />

    <step2
      @emitSignUpData="emitSignUpData"
      @emitNextStep="emitNextStep"
      :status="status"
    />

    <step3
      @emitLinkData="emitLinkData"
      @emitRegister="emitRegister"
      :status="status"
      :showLoading="showLoading"
    />
  </ul>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import step1 from '@/components/singUp/step1.vue';
import step2 from '@/components/singUp/step2_creator.vue';
import step3 from '@/components/singUp/step3_creator.vue';

export default {
  name: '',
  props: ['type'],
  components: { step1, step2, step3 },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const ukTab = ref(null);
    const registerData = reactive({
      data: {
        type: props.type
      }
    });

    const linkData = reactive({ data: [] });
    const errorMessage = reactive({
      data: {
        telError: null,
        emailError: null,
        emailRepeatError: null,
        passwordError: null
      }
    });
    const status = reactive({ step1: false, step2: false, step3: false });
    const rulesCheckData = ref(false);
    const openPassword = ref(false);
    //註冊鈕loading
    const showLoading = ref(false);
    //檢查有沒有錯誤訊息 控制button disable
    watch([registerData, errorMessage, rulesCheckData], () => {
      //step1
      if (
        errorMessage.data.emailError !== null ||
        errorMessage.data.emailRepeatError !== null ||
        errorMessage.data.passwordError !== null ||
        rulesCheckData.value === false
      ) {
        status.step1 = false;
        return false;
      } else {
        status.step1 = true;
      }

      //step2
      if (status.step1 == true) {
        if (
          registerData.data.phone == '' ||
          registerData.data.company_name == '' ||
          errorMessage.data.telError !== null
        ) {
          status.step2 = false;
        } else {
          status.step2 = true;
        }
      }
    });

    watch(linkData, () => {
      if (linkData.data.filter(item => item.errorMessage != null).length > 0) {
        status.step3 = false;
      } else {
        status.step3 = true;
      }
    });

    const emitSignUpData = (
      childRegisterData,
      childErrorMessage,
      chldRulesCheckData
    ) => {
      registerData.data = { ...registerData.data, ...childRegisterData };
      errorMessage.data = { ...errorMessage.data, ...childErrorMessage };
      if (chldRulesCheckData != null) {
        rulesCheckData.value = chldRulesCheckData;
      }
    };

    const emitLinkData = (childRegisterData, childLinkData) => {
      registerData.data = { ...registerData.data, ...childRegisterData };
      linkData.data = childLinkData;
    };

    //註冊
    const emitRegister = async () => {
      showLoading.value = true;
      try {
        await store.dispatch('register', registerData.data);
        showLoading.value = false;
        emit('closeModal');
        router.push('/validation');
        if (route.path == '/validation') window.location.reload();
      } catch (err) {
        console.log(err);
      }
    };

    const emitNextStep = i => {
      UIkit.switcher(ukTab.value).show(i);
    };

    return {
      registerData,
      errorMessage,
      rulesCheckData,
      linkData,
      showLoading,
      status,
      ukTab,
      emitRegister,
      emitNextStep,
      emitSignUpData,
      emitLinkData
    };
  }
};
</script>
<style lang="scss" scoped>
.uk-tab > * {
  padding: 0;
  width: calc(100% / 3);
}
</style>
