// 狀態管理
import { reactive, ref, provide, onMounted, inject } from 'vue';
import Path from '@/class/Path';
import { useRouter } from 'vue-router';
import { useWindowFocus } from '@vueuse/core';
export default function useNotification(pusher) {
  const router = useRouter();
  // 判斷從通知進入頁面
  const fromNotification = ref(false);
  //點擊該通知後,直接倒進來
  const notificationState = reactive({
    data: {}
  });
  const setState = message => {
    notificationState.data = message;
  };
  const focused = useWindowFocus();
  const newNotification = message => {
    hasNewNotification.value = true;
    setState(message);
    if (!focused.value) {
      showWebNotification();
    }
  };

  //   訊息中心socket
  const connectionIo = () => {
    const userUuid = JSON.parse(localStorage.getItem('user'))?.uuid || false;
    if (userUuid) {
      // subscribe channel
      let kolChannelConnection = pusher.value.subscribe(`adpost-${userUuid}`);

      //事件綁定
      kolChannelConnection
        .bind('new-notification', function (data) {
          console.log('new-notification', data);
          newNotification(data);
        })
        .bind('pusher:subscription_succeeded', function () {
          console.log('success');
        })
        .bind('pusher:subscription_error', function () {
          console.log('error');
          window.location.reload();
        });
    }
  };
  const audio = ref(null);
  const showWebNotification = () => {
    audio.value.play();
    //瀏覽器訊息 notification api
    const notification = new Notification('AD POST', {
      icon: `${require(`@/assets/image/adpost_logo.png`)}`,
      body: '您有一則新訊息'
    });
    notification.onclick = function (event) {
      event.preventDefault();
      window.focus();
    };
  };
  //瀏覽器會擋自動撥放音頻,要先觸發一次
  const NotificationSound = () => {
    if (audio.value) {
      audio.value.muted = true;
      audio.value.play();
      window.removeEventListener('click', NotificationSound);
      setTimeout(() => {
        audio.value.muted = false;
        audio.value.volume = 0.4;
      }, 1500);
    }
  };
  onMounted(() => {
    window.addEventListener('click', NotificationSound);
    Notification.requestPermission();
  });

  const _pathList = {
    '010': 'project',
    '020': 'briefb',
    '030': 'outline',
    '040': 'draft',
    '050': 'video',
    '060': 'copyWriting',
    '061': 'copyWriting',
    '070': 'worksUpload',
    '080': 'caseReport',
    '090': 'project'
  };
  _pathList['100'] = 'profile';

  const setChatParameter = data => {
    const chatRoomParameter = {
      type: _pathList[data.code.function],
      taskId: data.parameters?.task_uuid,
      platformId: data.parameters?.platform_uuid,
      secondaryId:
        data.parameters?.works_online_uuid ||
        data.parameters?.column_name ||
        data.parameters?.video_uuid,
      groupId: data.parameters?.group_id
    };
    // draft 網址欄位不同
    if (data.code.function == '040') {
      chatRoomParameter.secondaryId = data.parameters?.script_uuid;
      chatRoomParameter.columnName = data.parameters?.column_name;
    }
    return chatRoomParameter;
  };

  const jumpToPath = data => {
    const tmp = new Path({
      type: _pathList[data.code.function],
      projectId: data?.parameters?.project_uuid,
      taskId: data?.parameters?.task_uuid,
      otherUuid:
        data?.parameters?.video_uuid ||
        data?.parameters?.platform_uuid ||
        data?.parameters?.script_uuid ||
        data?.parameters?.case_closed_report_uuid,
      order: data?.parameters?.ordering,
      index: data?.parameters?.task_index,
      taskType: data?.parameters?.task_type
    }).goToPath;
    console.log('tmp:', tmp);
    const regPath = /\/(\w+)./g;
    if (
      router.currentRoute.value.path != '/' &&
      router.currentRoute.value.path.match(regPath)[0] == tmp.match(regPath)[0]
    ) {
      router.push('/blank').then(() => router.push(tmp));
    } else {
      router.push(tmp);
    }
    return new Promise(resolve => resolve());
  };

  const setChatName = (type, data) => {
    console.log(type, data);
    if (type == '020') return '需求單';
    if (type == '030') return data.column_name;
    if (type == '040') return data.column_name;
    if (type == '050')
      return `${data.time.hour} : ${data.time.minute} : ${data.time.second}`;
    if (type == '060' || type == '061') return data.platform;
    if (type == '070') return data.platform;
  };

  const hasNewNotification = ref(false);
  provide('fromNotification', fromNotification);
  provide('notificationState', notificationState);
  provide('setState', setState);
  provide('hasNewNotification', hasNewNotification);
  provide('connectionIo', connectionIo);
  provide('NotificationAudio', audio);
  provide('setChatParameter', setChatParameter);
  provide('jumpToPath', jumpToPath);
  provide('setChatName', setChatName);

  return { fromNotification, setState, notificationState };
}
