// 狀態管理
import { reactive, watch, provide } from 'vue';
import api from '@/api/notification';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default function useNotification() {
  const store = useStore();
  const router = useRouter();
  //所有通知內容
  const notificationList = reactive({
    data: []
  });
  // 相關參數 頁數...
  const notificationMeta = reactive({
    data: {}
  });

  const init = async () => {
    notificationList.data = [];
    const data = await api.getNotifications(1);
    notificationList.data = data.data;

    //取得邀請通知
    const inviteData = await api.getNotifications(1, 99, 1);
    store.state.notification.inviteData = inviteData.data;

    if (store.state.notification.inviteData.length > 0) {
      // notificationList.data = [];
      //開發用
      const tmp = JSON.parse(
        JSON.stringify(store.state.notification.inviteData[0])
      );
      tmp.code.action = 10.5;
      notificationList.data.unshift(tmp);
    }
    console.log('notificationList:', notificationList);

    notificationMeta.data = data.meta;
  };
  const loadMore = async () => {
    let page = notificationMeta.data.current_page + 1;
    let lastPage = notificationMeta.data.last_page;

    if (page <= lastPage) {
      const oldData = JSON.parse(JSON.stringify(notificationList.data));
      const data = await api.getNotifications(page);
      notificationList.data = [...oldData, ...data.data];
      notificationMeta.data = data.meta;
      await delay(1000);
    } else {
      console.log('最後一頁');
    }
  };
  const delay = s => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, s);
    });
  };
  //未讀就先已讀>關訊息>init>跳轉
  const readNotification = async (uuid, closePopup, message) => {
    if (uuid) await api.readNotification(uuid);
    closePopup();
    init();
    return new Promise(resolve => resolve());
  };
  //一次已讀所有
  const readNotifications = async () => {
    await api.readNotifications();
    init();
  };
  watch(
    () => notificationMeta,
    val => {
      //有未讀訊息改變favicon
      changeFavicon(val.data.has_unread);
    },
    { deep: true }
  );
  const changeFavicon = status => {
    if (status) {
      document.getElementById('favicon').href = '/favicon_red.ico';
    } else {
      document.getElementById('favicon').href = `/favicon.ico`;
    }
  };
  provide('notificationList', notificationList);
  provide('readNotification', readNotification);
  provide('readNotifications', readNotifications);
  provide('loadMore', loadMore);
  return { notificationList, loadMore, init, notificationMeta };
}
