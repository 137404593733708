<template>
  <div ref="target">
    <button
      type="button"
      @click.stop="changeEditMenu"
      uk-tooltip="title: 編輯; pos: top"
    >
      <img :src="require('@/assets/icon/messageBoard/more-vertical.png')" />
    </button>
    <ul class="t-shadow_light" v-if="openEditMenu">
      <li>
        <a href="javascript:;" @click.stop="$emit('editMsg')">編輯</a>
      </li>
      <li>
        <a href="javascript:;" @click.stop="$emit('delMsg')">刪除</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default {
  name: 'EditList',
  props: [''],
  emits: ['delMsg', 'editMsg'],
  setup(props, { emit }) {
    const target = ref(null);
    const openEditMenu = ref(false);
    const changeEditMenu = () => {
      openEditMenu.value = !openEditMenu.value;
    };
    const closeEditMenu = () => {
      openEditMenu.value = false;
    };
    onClickOutside(target, event => {
      closeEditMenu();
    });
    return { target, openEditMenu, changeEditMenu };
  }
};
</script>
<style lang="scss" scoped>
uL {
  margin: 0;
  padding: 0;
  position: absolute;
  left: -13px;
  top: 34px;
  background-color: #ffffff;
  width: 67px;

  li {
    padding: 8px 16px;
    a {
      color: black;
      display: block;
      font-size: 16px;
      line-height: 24px;
      &:hover {
        color: black;
      }
    }
    &:hover {
      background-color: #e5e5e5;
    }
  }
}
</style>
