import { getSteps } from '@/shared/onBoarding/getOnBoardingSteps.js';
import onBoardingApi from '@/api/onBoarding.js';

export default {
  state: {
    steps: [], //所有導覽步驟
    goToStep: null, //套件方法
    finish: null, //套件方法
    copywriteKolImages: { data: [] }, //文案撰寫網紅是否有上傳圖片（forYT)
    reviewBtnsStatus: { data: [] }, //作品上線
    videoShowAddBtnLoaded: false, //影片製作
    videoShowAddBtn: null, //影片製作
    onboardingFinish: false,
    onboardingCurrentStep: 0,
    onboardingTotalSteps: 0,
    currentStepPage: null, //目前是哪一頁
    currentStepPageStatus: null //目前是哪一頁的狀態
  },
  mutations: {
    SET_steps(state, payload) {
      state.steps = payload;
    },
    SET_goToStep(state, fn) {
      state.goToStep = fn;
    },
    SET_finish(state, fn) {
      state.finish = fn;
    },
    SET_copywriteKolImages(state, payload) {
      state.copywriteKolImages.data = payload;
    },
    SET_reviewBtnStatus(state, payload) {
      state.reviewBtnsStatus.data.push(payload);
    },
    resetCopywriteKolImages(state) {
      state.copywriteKolImages.data = [];
    },
    resetReviewBtnStatus(state) {
      state.reviewBtnsStatus.data = [];
    },
    SET_videoShowAddBtnLoaded(state, payload) {
      state.videoShowAddBtnLoaded = payload;
    },
    SET_videoShowAddBtn(state, payload) {
      state.videoShowAddBtn = payload;
    },
    SET_onBoardingData(state, payload) {
      state.onboardingFinish = payload?.finish;
      state.onboardingCurrentStep = payload?.currentStep;
      state.onboardingTotalSteps = payload?.totalSteps;
    },
    SET_currentStepPage(state, payload) {
      state.currentStepPage = payload;
    },
    SET_currentStepPageStatus(state, payload) {
      state.currentStepPageStatus = payload;
    }
  },
  actions: {
    updateSteps({ commit }, { page, userRole, pageStatus }) {
      const stepsData = getSteps(page, userRole, pageStatus);
      commit('SET_steps', stepsData);
      commit('SET_currentStepPage', page);
      commit('SET_currentStepPageStatus', pageStatus);
    },
    useGoToStep({ state }, step) {
      if (state.goToStep) {
        state.goToStep(step);
      }
    },
    useFinish({ state }) {
      if (state.finish) {
        state.finish();
      }
    },
    async fetchOnboardingData({ commit, dispatch }, { page, status }) {
      const resp = await onBoardingApi.getOnboardingData(page, status);
      commit('SET_onBoardingData', resp);
    },
    async updateOnboardingData({ commit, dispatch }, data) {
      const resp = await onBoardingApi.sendOnboardingData(data);
    }
  },
  getters: {
    isAnyReviewBtnEnabled: state => {
      return state.reviewBtnsStatus.data.some(item => !item.status);
    }
  }
};
