<template>
  <div class="navItem">
    <div class="logo">
      <a href="/">
        <img
          :src="
            require('@/assets/icon/LOGO.svg')
          "
          alt="adpost"
          width="140"
          height="40"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invitation",
  components: {
  },
  setup() {
  },
};
</script>

<style lang="scss" scoped>
.navItem {
	width: 100%;
	padding-left: 74px;
	background-color: #44C3CE;
}
</style>
