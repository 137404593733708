<template>
  <div
    id="offcanvas-messageBoard"
    uk-offcanvas="flip: true; overlay: true"
    class="uk-offcanvas"
  >
    <div class="uk-offcanvas-bar sideMessageArea">
      <div class="messageArea">
        <div class="backIcon">
          <a @click="closeOffcanvas"
            ><img
              :src="require(`@/assets/icon/arrow_back.svg`)"
              width="24"
              height="24"
          /></a>
          <span>留言</span>
        </div>
        <ScrollToBtn
          :showToBottomBtn="showToBottomBtn"
          :showToTopBtn="showToTopBtn"
          @emitScrollToBottom="scrollToBottom"
          @emitScrollToTop="scrollToTop"
        />
        <div class="messageBox" ref="messageBox" @scroll.passive="onScroll">
          <div class="commentBox" id="messageBoard">
            <CommentBox ref="commentBox" />
          </div>
          <div
            class="filterBox uk-position-relative"
            v-if="chatMessage.message.data?.items.length"
          >
            <FilterList
              :filterListVal="filterListVal"
              @updateFilterList="updateFilterList"
            />
          </div>
          <div class="message" v-if="newFilterList && newFilterList.length">
            <ul>
              <li
                class="messageItem"
                v-for="(item, index) in newFilterList"
                :key="item.message_id"
              >
                <MessageItem :msgItem="item" />
              </li>
            </ul>
          </div>
          <div class="emptyMessage" v-else>
            <img
              :src="require('@/assets/icon/message_disable.svg')"
              width="36"
            />
            <span class="text-s mt-4">目前沒有任何留言</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  nextTick,
  provide,
  onMounted,
  inject,
  computed,
  onUnmounted
} from 'vue';
import FilterList from './message_board/FilterList.vue';
import EditList from './message_board/EditList.vue';
import CommentBox from './message_board/CommentBox.vue';
import MessageItem from './message_board/MessageItem.vue';
import debounce from 'lodash/debounce';
import ScrollToBtn from './message_board/ScrollToBtn.vue';

export default {
  name: 'MessageBoardRoom',
  components: {
    FilterList,
    EditList,
    MessageItem,
    CommentBox,
    ScrollToBtn
  },
  setup() {
    const commentBox = ref(null);
    const messageBox = ref(null);
    const showToTopBtn = ref(false);
    const showToBottomBtn = ref(false);
    let intervalId;
    const closeOffcanvas = () => {
      UIkit.offcanvas('#offcanvas-messageBoard').hide();
    };
    const chatState = inject('chatState');
    const chatMessage = inject('chatMessage');
    const getMessage = inject('getMessage');
    const filterListVal = ref('unProcessed');
    const newFilterList = computed(() => {
      switch (filterListVal.value) {
        case 'allProcessed':
          return chatMessage.message.data?.items;
        case 'unProcessed':
          return chatMessage.message.data?.items.filter(
            item => item.is_processed == 0
          );
        case 'processed':
          return chatMessage.message.data?.items.filter(
            item => item.is_processed == 1
          );
        default:
          return chatMessage.message.data?.items;
      }
    });

    // 聊天室回最底部
    const scrollToBottom = () => {
      messageBox.value.scrollTo({
        top: messageBox.value.scrollHeight,
        behavior: 'smooth'
      });
    };
    provide('scrollToBottom', scrollToBottom);

    const scrollToTop = () => {
      messageBox.value.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    const onScroll = debounce(function () {
      const isNotEndOfScroll =
        Math.ceil(messageBox.value.clientHeight + messageBox.value.scrollTop) -
          messageBox.value.scrollHeight <
        0;

      if (isNotEndOfScroll) {
        // console.log('Scrolling 沒有到最底');
        showToBottomBtn.value = true;
      } else {
        showToBottomBtn.value = false;
      }

      if (messageBox.value.scrollTop > 10) {
        showToTopBtn.value = true;
      } else {
        showToTopBtn.value = false;
      }
    }, 500);

    const updateFilterList = res => {
      filterListVal.value = res;
    };

    onMounted(() => {
      UIkit.util.on('#offcanvas-messageBoard', 'show', function () {
        commentBox.value.clearContent();
        showToBottomBtn.value = false;
        showToTopBtn.value = false;
        filterListVal.value = 'unProcessed';
        clearInterval(intervalId);
        intervalId = setInterval(getMessage, 30000);
      });

      UIkit.util.on('#offcanvas-messageBoard', 'hide', function () {
        chatMessage.message.data = null;
        clearInterval(intervalId);
      });
    });

    onUnmounted(() => clearInterval(intervalId));

    return {
      messageBox,
      scrollToTop,
      scrollToBottom,
      closeOffcanvas,
      showToTopBtn,
      showToBottomBtn,
      commentBox,
      chatMessage,
      chatState,
      onScroll,
      updateFilterList,
      newFilterList,
      filterListVal
    };
  }
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
:deep .uk-offcanvas-bar {
  h1,
  h2,
  h3,
  h4,
  code {
    color: #333333;
    margin: 0;
  }
}
:deep .sideMessageArea {
  text-align: left;
  padding: 0;
  background: #fff;
  color: #333333;
  width: 400px;
  font-size: 14px;
  word-break: break-word;

  .quotation {
    background: #f9f9f9;
    border: 1px solid rgba(182, 182, 182, 0.3);
    border-radius: 4px;
    padding: 8px 16px;
    p {
      margin: 0;
    }
  }
  .headShot {
    .avatar {
      height: 36px;
      width: 36px;
      border-radius: 50%;
    }
  }

  .messageArea {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .backIcon {
      display: flex;
      align-items: center;
      padding: 24px 32px;
      height: 76px;

      background-color: #fff;
      z-index: 1;

      img {
        cursor: pointer;
      }
      span {
        font-size: 1.25rem;
        line-height: 28px;
        letter-spacing: 0.17em;
        margin-left: 10px;
      }
      &.onScroll {
        box-shadow: 3px 4px 10px rgb(0 0 0 / 10%);
      }
    }

    .messageBox {
      flex: 1;
      height: calc(100% - 76px);
      overflow: auto;
      display: flex;
      flex-direction: column;
      .commentBox#messageBoard {
        padding: 16px 0 24px 0;
        margin: 0 auto;
        .comment {
          width: 358px;
          padding: 16px;
          // .ourEditor {
          //   flex: auto;
          //   width: 100%;
          //   > div {
          //     max-height: 40vh;
          //     overflow-y: auto;
          //   }
          // }
        }
      }
      .filterBox {
        padding: 0 21px 0 21px;
      }

      .messageItem {
        padding: 24px 0;
        margin: 0 32px;
        border-bottom: 1px solid #eeeeee;
        .nameArea {
          flex: 1;
        }
        &:last-child {
          border-bottom: none;
        }
        .editorBtnArea {
          display: flex;
          align-items: center;
        }
      }

      .emptyMessage {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }
    .replayCommentBox {
      .replayComment {
        width: 100%;
        padding: 16px;
        // .ourEditor {
        //   flex: auto;
        //   width: 100%;

        //   > div {
        //     max-height: 40vh;
        //     overflow-y: auto;
        //   }
        // }
      }
    }
  }
}

:deep .ourEditor {
  flex: auto;
  width: 100%;

  > div {
    max-height: 25vh;
    overflow-y: auto;
  }
}
</style>
