<template>
  <MessageBox></MessageBox>
</template>
<script>
import MessageBox from './MessageBox.vue';

export default {
  name: 'ReplayItem',
  components: {
    MessageBox
  },
  setup(props, { emit }) {
    return {};
  }
};
</script>
<style lang="scss" scoped></style>
