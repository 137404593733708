import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

export default Image.extend({
  name: 'custom-image',

  addAttributes() {
    return {
      ...Image.config.addAttributes(),

      size: {
        default: 'medium',
        renderHTML: attributes => {
          return {
            class: attributes.size
          };
        },
        parseHTML: element => element.getAttribute('class')
      },
      textAlign: {
        default: 'center',
        renderHTML: attributes => ({
          style: `text-align: ${attributes.textAlign}`
        }),
        parseHTML: element => element.style.textAlign
      }
    };
  },

  addCommands() {
    return {
      setImage:
        options =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name == 'custom-image') {
            return commands.updateAttributes('custom-image', options);
          } else {
            return commands.insertContent({
              type: this.name,
              attrs: options
            });
          }
        }
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    // console.log('HTMLAttributes', HTMLAttributes);
    const { style, ...otherAttributes } = HTMLAttributes;
    return [
      'figure',
      { style },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    ];
  }
});
