<template>
  <label for="upload_img" class="uploadFile">
    <input
      id="upload_img"
      style="position: absolute; width: 0; height: 0"
      type="file"
      @change="addImage"
      accept=".jpg,.png,.jpeg"
    />
    <v-icon icon="mdi-camera-outline" size="small"></v-icon>
    <v-tooltip activator="parent" location="top">插入圖片</v-tooltip>
  </label>
</template>

<script>
import compressorImage from '@/lib/compressorImage';
import { loadImgConfigApi } from '@/store/editor/index.js';

export default {
  components: {},
  props: {
    editor: {
      type: Object,
      default: () => {}
    },
    imageUploadConfig: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { editor, imageUploadConfig } = props;

    let api;
    // 配合不同的Step傳入相對應的api管理js
    const importApi = async () => {
      api = await loadImgConfigApi(imageUploadConfig.lib);
    };
    const addImage = async e => {
      await importApi();
      let img = e.target.files[0];
      img = await compressorImage.compressorImage(img);
      const form = new FormData();
      form.append('task_uuid', imageUploadConfig.task_uuid);
      form.append('file', img);
      try {
        const res = await api.storeImage(form);
        const url = res.filename;
        if (url) {
          editor.chain().focus().setImage({ src: url }).run();
        }
      } catch (e) {
        throw new Error(e);
      }
      //e.target.value = '';
    };
    return {
      addImage
    };
  }
};
</script>
<style lang="scss">
.mdi-camera-outline.mdi.v-icon.notranslate.v-theme--light.v-icon--size-small {
  font-size: 17px;
}
.uploadFile {
  border: 1px solid #44c3ce;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #44c3ce;
  cursor: pointer;
  background-color: #fff;
}

.uploadFile:hover {
  color: #44c3ce;
  border: 1px solid #44c3ce;
  background-color: #f0fafb;
}
.ProseMirror {
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .ProseMirror-selectednode {
    img {
      outline: 3px solid #68cef8;
    }
  }
  .small {
    max-width: 200px;
  }
  .medium {
    max-width: 500px;
  }
  .large {
    max-width: 100%;
  }
}
</style>
