import axios from 'axios';

export default {
  async sendMessage(data, chatroom_uuid) {
    return await axios
      .post(`/api/chatrooms/${chatroom_uuid}/messages`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async getMessageByPage(chatroom_uuid, page) {
    return await axios
      .get(`/api/chatrooms/${chatroom_uuid}/messages?per_page=20&page=${page}`)
      .then(resp => resp)
      .catch(err => err);
  },
  async getMessage(chatroom_uuid) {
    return await axios
      .get(`/api/chatrooms/${chatroom_uuid}/messages?per_page=20&page=1`)
      .then(resp => resp)
      .catch(err => err);
  },
  async getMessageId(user_uuid) {
    if (user_uuid == '') return;
    return await axios
      .get(`/api/contact/${user_uuid}/chatroom/info`)
      .then(resp => resp)
      .catch(err => err);
  },
  async getList(search = '') {
    let url = `/api/chatrooms?search=${search}&per_page=999&page=1`;
    return await axios
      .get(url)
      .then(resp => resp)
      .catch(err => err);
  },
  async makeSee(chatroom_uuid) {
    //console.log('make see');
    return await axios
      .post(`/api/chatrooms/${chatroom_uuid}/seen`)
      .then(resp => resp)
      .catch(err => err);
  }
};
