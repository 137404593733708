export function getSteps(page, userType, pageStatus) {
  return pageSteps[page][userType]?.[pageStatus] || [];
}

const progress = {
  Creator: {
    load: [
      {
        attachTo: {
          element: '.partnerList'
        },
        content: {
          title: '查看不同合作類型',
          description: '可切換此次合作品牌不同類型的案件'
        }
      },
      {
        attachTo: { element: '.partnerProfile' },
        content: {
          title: '查看合作對象',
          description: '合作廠商資訊清楚顯示'
        }
      },
      {
        attachTo: { element: '#guide_toBriefBtn' },
        content: {
          title: '查看需求單',
          description: '查看品牌主的合作需求，協助您快速了解此次案件'
        }
      },
      {
        attachTo: { element: '.stepLink' },
        content: {
          title: '查看各製作環節',
          description: '進入不同環節查看進度'
        }
      },
      {
        attachTo: { element: '.stepStatus' },
        content: {
          title: '最新狀態亮燈提示',
          description: '內容更新、新留言、提醒驗收'
        }
      },
      {
        attachTo: { element: '.stepTimeArea' },
        content: {
          title: '清楚的時程表',
          description: '預計的專案時程'
        }
      },
      {
        attachTo: { element: '#guide_scheduleBtn' },
        content: {
          title: '專案時程管理',
          description: '可修改或確認廠商提供的專案時程'
        }
      }
    ]
  },
  Vendor: {
    load: [
      {
        attachTo: {
          element: '.partnerList'
        },
        content: {
          title: '查看不同合作對象',
          description: '切換不同網紅頭像查看個別進度'
        }
      },
      {
        attachTo: { element: '#guide_toBriefBtn' },
        content: {
          title: '填寫需求單',
          description:
            '針對合作網紅個別提出業配合作需求，協助網紅更快速了解此次合作重點'
        }
      },
      {
        attachTo: { element: '.stepLink' },
        content: {
          title: '查看各製作環節',
          description: '進入不同環節查看進度'
        }
      },
      {
        attachTo: { element: '.stepStatus' },
        content: {
          title: '最新狀態亮燈提示',
          description: '內容更新、新留言、提醒驗收'
        }
      },
      {
        attachTo: { element: '.stepTimeArea' },
        content: {
          title: '清楚的時程表',
          description: '網紅所提供的專案時程進度'
        }
      },
      {
        attachTo: { element: '#guide_scheduleBtn' },
        content: {
          title: '專案時程管理',
          description: '可修改或確認網紅提供的專案時程'
        }
      },
      {
        attachTo: { element: '.guide_inviteButton' },
        content: {
          title: '邀請人員',
          description:
            '邀請協作同仁一起掌握專案進度（廣告代理商，直客，公司同仁）'
        }
      }
    ]
  }
};

const briefb = {
  Creator: {
    create: [
      {
        attachTo: {
          element: '.versionControl'
        },
        content: {
          title: '查看版本紀錄',
          description: '回到先前版本紀錄查看或編輯'
        }
      },
      {
        attachTo: {
          element: '#guide_comparePreviousBtn'
        },
        content: {
          title: '比對前版內容',
          description: '開啟功能比對，了解品牌修改內容'
        }
      },
      {
        attachTo: {
          element: '.messageBtn'
        },
        content: {
          title: '留言給對方',
          description: '能與對方討論內容'
        }
      }
    ]
  },
  Vendor: {
    create: [
      {
        attachTo: {
          element: '.versionControl'
        },
        content: {
          title: '查看版本紀錄',
          description: '回到先前版本紀錄查看或編輯'
        }
      },
      {
        attachTo: {
          element: '#guide_comparePreviousBtn'
        },
        content: {
          title: '比對前版內容',
          description: '開啟功能比對，了解網紅修改內容'
        }
      },
      {
        attachTo: {
          element: '.messageBtn'
        },
        content: {
          title: '留言給對方',
          description: '能與對方討論內容'
        }
      }
    ]
  }
};

const outline = {
  Creator: {
    create: [
      {
        attachTo: {
          element: '.versionControl'
        },
        content: {
          title: '查看版本紀錄',
          description: '回到先前版本紀錄查看或編輯'
        }
      },
      {
        attachTo: {
          element: '#guide_comparePreviousBtn'
        },
        content: {
          title: '比對前版內容',
          description: '開啟功能比對，了解品牌方修改內容'
        }
      },
      {
        attachTo: {
          element: '.exportArea'
        },
        content: {
          title: '匯出 Google 文件',
          description: '可提供文件連結給外部人員查看'
        }
      },
      {
        attachTo: {
          element: '.messageBtn'
        },
        content: {
          title: '留言給對方',
          description: '能與對方討論內容'
        }
      }
    ]
  },
  Vendor: {
    create: [
      {
        attachTo: {
          element: '.versionControl'
        },
        content: {
          title: '查看版本紀錄',
          description: '回到先前版本紀錄查看或編輯'
        }
      },
      {
        attachTo: {
          element: '#guide_comparePreviousBtn'
        },
        content: {
          title: '比對前版內容',
          description: '開啟功能比對，了解網紅修改內容'
        }
      },
      {
        attachTo: {
          element: '.exportArea'
        },
        content: {
          title: '匯出 Google 文件',
          description: '可提供文件連結給外部人員查看'
        }
      },
      {
        attachTo: {
          element: '.messageBtn'
        },
        content: {
          title: '留言給對方',
          description: '能與對方討論內容'
        }
      }
    ]
  }
};

const video = {
  Creator: {
    load: [
      {
        attachTo: {
          element: '.videoContainer'
        },
        content: {
          title: '上傳合作影片',
          description: '將製作好的影片上傳至平台'
        }
      }
    ],
    firstTime: [
      {
        attachTo: {
          element: '.guide_videoContinaer'
        },
        content: {
          title: '修改意見回饋',
          description: '對方對你的影片提出的修改想法'
        }
      },
      {
        attachTo: {
          element: '.openIcon img'
        },
        content: {
          title: '查看修改秒數的畫面',
          description: '切換到影片中對應的秒數快速瀏覽'
        }
      }
    ]
  },
  Vendor: {
    load: [
      {
        attachTo: {
          element: '.firstIcon'
        },
        content: {
          title: '提出修改意見',
          description: '針對網紅提供的影片內容提出反饋'
        }
      }
    ],
    firstTime: [
      {
        attachTo: {
          element: '.openIcon img'
        },
        content: {
          title: '查看秒數',
          description: '切換到影片中對應的秒數快速瀏覽'
        }
      }
    ]
  }
};

const copywriting = {
  Creator: {
    create: [
      {
        attachTo: {
          element: '.plusIcon'
        },
        content: {
          title: '新增其他平台文案',
          description: '針對不同平台分別製作文案'
        }
      }
    ],
    firstTime_text: [
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      }
    ],
    firstTime_text_storyModel: [
      {
        attachTo: {
          element: '#storyModel'
        },
        content: {
          title: '文案呈現模式',
          description: '可依照合作案類型切換適合的呈現模式'
        }
      },
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      }
    ],
    firstTime_img: [
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      },
      {
        attachTo: {
          element: '.uploadPhotos'
        },
        content: {
          title: '上傳照片區',
          description: '供廠商選擇照片與排序'
        }
      }
    ],
    firstTime_img_storyModel: [
      {
        attachTo: {
          element: '#storyModel'
        },
        content: {
          title: '文案呈現模式',
          description: '可依照合作案類型切換適合的呈現模式'
        }
      },
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      },
      {
        attachTo: {
          element: '.uploadPhotos'
        },
        content: {
          title: '上傳照片區',
          description: '供廠商選擇照片與排序'
        }
      }
    ],
    secondTime_img: [
      {
        attachTo: {
          element: '.guide_addMultiPhoto'
        },
        content: {
          title: '新增多輪選圖',
          description: '點擊可開啟新的照片編輯區，以提供多次討論與紀錄'
        }
      }
    ]
  },
  Vendor: {
    create: [],
    firstTime_text: [
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      }
    ],
    firstTime_text_storyModel: [
      {
        attachTo: {
          element: '#storyModel'
        },
        content: {
          title: '文案呈現模式',
          description: '可依照合作案類型切換適合的呈現模式'
        }
      },
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      }
    ],
    firstTime_img: [
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      },
      {
        attachTo: {
          element: '.uploadPhotos'
        },
        content: {
          title: '選擇照片',
          description: '選擇行銷照片並進行排序'
        }
      }
    ],
    firstTime_img_storyModel: [
      {
        attachTo: {
          element: '#storyModel'
        },
        content: {
          title: '文案呈現模式',
          description: '可依照合作案類型切換適合的呈現模式'
        }
      },
      {
        attachTo: {
          element: '.guide_contentCopyWrite'
        },
        content: {
          title: '文案編輯區',
          description: '選取文字可編輯多種樣式'
        }
      },
      {
        attachTo: {
          element: '.guide_expanded .uploadPhotos'
        },
        content: {
          title: '選擇照片',
          description: '選擇行銷照片並進行排序'
        }
      }
    ]
  }
};

const works_upload = {
  Creator: {
    load: [
      {
        attachTo: {
          element: '.guide_createWrok'
        },
        content: {
          title: '上線的作品區',
          description: '將上線的平台連結貼至連結框'
        }
      }
    ],
    create: [
      {
        attachTo: {
          element: '.guide_compareBtn'
        },
        content: {
          title: '文案自動比對',
          description: '一鍵點擊系統將幫您自動比對文案'
        }
      },
      {
        attachTo: {
          element: '.guide_review:not(.disabled)'
        },
        content: {
          title: '讓對方審核',
          description: '提交給廠商進行合作文案審核'
        }
      }
    ]
  },
  Vendor: {
    load: [
      {
        attachTo: {
          element: '.guide_createWrok'
        },
        content: {
          title: '上線的作品區',
          description: '將上線的平台連結貼至連結框'
        }
      }
    ],
    create: [
      {
        attachTo: {
          element: '.guide_compareBtn'
        },
        content: {
          title: '文案自動比對',
          description: '一鍵點擊系統將幫您自動比對文案'
        }
      }
    ],
    review: [
      {
        attachTo: {
          element: '.guide_compareBtn'
        },
        content: {
          title: '文案自動比對',
          description: '一鍵點擊系統將幫您自動比對文案'
        }
      },
      {
        attachTo: {
          element: '.guide_review:not(.disabled)'
        },
        content: {
          title: '審核功能',
          description: '在網紅上線後，可使用審核功能進行回覆'
        }
      }
    ]
  }
};

const draft_onepage = {
  Creator: {
    load: [
      {
        attachTo: {
          element: '#guide_uploadBtn'
        },
        content: {
          title: '上傳腳本',
          description: '將線下檔案輕鬆上傳至平台'
        }
      }
    ]
  },
  Vendor: {}
};

const draft = {
  Creator: {
    create: [
      {
        attachTo: {
          element: '#guide_toSeeDeleteDraftBtn'
        },
        content: {
          title: '已移除的腳本',
          description: '查看已移除的腳本內容，亦可進行資料還原'
        }
      }
    ],
    secondCreate: [
      {
        attachTo: {
          element: '.draftItem'
        },
        content: {
          title: '上下移動腳本順序',
          description: '拖曳卡片可以移動順序'
        }
        // options: {
        //   popper: {
        //     placement: 'bottom',
        //     modifiers: [
        //       {
        //         name: 'offset',
        //         options: {
        //           offset: [0, 20]
        //         }
        //       },
        //       {
        //         name: 'flip',
        //         options: {
        //           fallbackPlacements: ['top', 'bottom']
        //         }
        //       }
        //     ]
        //   }
        // }
      }
    ]
  },
  Vendor: {}
};

const profile = {
  Creator: {
    line_reminder: [
      {
        attachTo: {
          element: '.bindinagArea'
        },
        content: {
          title: '綁定 LINE 接收通知',
          description: '綁定後可即時接收平台通知，掌握專案進度'
        }
      }
    ]
  },
  Vendor: {
    line_reminder: [
      {
        attachTo: {
          element: '.bindinagArea'
        },
        content: {
          title: '綁定 LINE 接收通知',
          description: '綁定後可即時接收平台通知，掌握專案進度'
        }
      }
    ]
  }
};

const pageSteps = {
  works_upload,
  progress,
  draft_onepage,
  draft,
  briefb,
  outline,
  video,
  copywriting,
  profile
};
