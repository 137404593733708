export default {
  state: {
    dragStartEditorObj: null
  },
  mutations: {
    setDragStartEditorObj(state, data) {
      state.dragStartEditorObj = data;
    }
  },
  actions: {},
  modules: {}
};
