import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import notFoundPage from '@/store/notFoundPage/index';
import navbar from '@/lib/navbar';
import store from '@/store/index';

const routes = [
  {
    path: '/', //首頁
    name: 'Home',
    meta: {
      title:
        'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
    },
    component: Home
  },
  {
    path: '/blank', //空白
    name: 'Blank',
    component: () =>
      import(/* webpackChunkName:"Blank" */ '../views/Blank.vue'),
    meta: {
      title:
        'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
    }
  },
  {
    path: '/ladingpage/', //lading page
    name: 'ladingPage',
    component: () =>
      import(/* webpackChunkName:"Index" */ '../views/land-page/Index.vue'),
    meta: {
      title:
        'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
    },
    children: [
      {
        path: 'cyberbiz', //lading page
        name: 'ladingPageCyberbiz',
        component: () =>
          import(
            /* webpackChunkName:"Cyberbiz" */ '../views/land-page/Cyberbiz.vue'
          ),
        meta: {
          title:
            'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效',
          hideNav: true
        }
      }
    ]
  },
  {
    path: '/invitation/:token', // 邀請函註冊
    name: 'Invitation',
    props: true,
    meta: {
      title:
        'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
    },
    component: () =>
      import(/* webpackChunkName:"Invitation" */ '../views/Invitation.vue')
  },
  {
    path: '/account_binding', // 邀請函註冊
    name: 'BindAccount',
    props: true,
    meta: {
      title:
        'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
    },
    component: () =>
      import(/* webpackChunkName:"BindAccount" */ '../views/BindAccount.vue')
  },
  // {
  //     path: '/adpost_vendor', //廣告
  //     name: 'Vendor',

  //     component: () =>
  //         import ( /* webpackChunkName:"Vendor" */ '../views/LandingPage2.vue'),
  //     meta: {
  //         title: 'AD POST 業配溝通平台 - 讓網紅媒合業配變得更簡單、快速｜保證KOL觀看觸及成效'
  //     }
  // },
  {
    path: '/project', //專案列表
    name: 'Project',

    component: () =>
      import(/* webpackChunkName:"Project" */ '../views/Project.vue'),
    meta: {
      title: '專案管理｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true
    }
  },
  {
    path: '/project_progress', //新專案進度
    name: 'Progress',

    component: () =>
      import(
        /* webpackChunkName:"ProjectProgress" */
        '../views/Progress.vue'
      ),
    meta: {
      title: '專案進度｜AD POST 網紅媒合・業配溝通平台',

      requiresAuth: true,
      breadcrumb: [
        {
          name: '專案進度',
          link: '/project'
        },
        {
          name: '專案進度'
        }
      ]
    }
  },
  {
    path: '/project_progress_invite', //新專案進度，自行建立
    name: 'Invite',

    component: () =>
      import(
        /* webpackChunkName:"ProjectProgress" */
        '../views/ProjectInvite.vue'
      ),
    meta: {
      title: '專案進度｜AD POST 網紅媒合・業配溝通平台',

      requiresAuth: true,
      breadcrumb: [
        {
          name: '專案進度',
          link: '/project'
        },
        {
          name: '邀請列表'
        }
      ]
    }
  },
  {
    path: '/create_project', //新專案進度，自行建立
    name: 'CreateProject',

    component: () =>
      import(
        /* webpackChunkName:"ProjectProgress" */
        '../views/CreateProject.vue'
      ),
    meta: {
      title: '專案進度｜AD POST 網紅媒合・業配溝通平台',

      requiresAuth: true,
      breadcrumb: [
        {
          name: '專案進度',
          link: '/project'
        },
        {
          name: '專案進度'
        }
      ]
    }
  },

  {
    path: '/project/schedule-setting', // 個人中心
    name: 'ProjectScheduleSetting',
    props: true,
    meta: {
      title: '個人中心｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName:"ProjectScheduleSetting" */ '../views/ProjectScheduleSetting.vue'
      )
  },
  {
    path: '/project/schedule-history', // 個人中心
    name: 'ProjectScheduleHistory',
    props: true,
    meta: {
      title: '個人中心｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName:"ProjectScheduleHistory" */ '../views/ProjectScheduleHistory.vue'
      )
  },
  {
    path: '/reset_password/:token', //重設密碼
    name: 'ResetPassword',
    props: true,
    meta: {
      title: '重設密碼｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(
        /* webpackChunkName:"ResetPassword" */
        '../views/ResetPassword.vue'
      )
  },
  {
    path: '/auto_login/:token', //自動登入
    name: 'AutoLogin',
    props: true,
    meta: {
      title: '自動登入｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"AutoLogin" */ '../views/AutoLogin.vue')
  },
  {
    path: '/hack-login/:token', //自動登入 後台
    name: 'HackLogin',
    props: true,
    meta: {
      title: '自動登入｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"HackLogin" */ '../views/HackLogin.vue')
  },
  {
    path: '/validation', // 驗證信
    name: 'Validation',
    props: true,
    meta: {
      title: '帳號驗證｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"Validation" */ '../views/Validation.vue')
  },
  {
    path: '/add_project/', //創建專案
    name: 'AddProject',
    props: true,

    meta: {
      title: '創建專案｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName:"AddProject" */ '../views/AddProject.vue')
  },
  {
    path: '/about-us', //關於我們
    name: 'AboutUs',
    meta: {
      title: '關於我們｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/terms-of-use', //服務條款
    name: 'Terms',
    meta: {
      title: '服務條款｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"Terms" */ '../components/Terms.vue')
  },

  {
    path: '/outline/:task_id', //大綱
    name: 'Outline',
    props: true,
    meta: {
      requiresAuth: true,
      title: '創意大綱｜AD POST 網紅媒合・業配溝通平台',
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '創意大綱'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"Outline" */ '../views/Outline.vue')
  },
  {
    path: '/video/:task_id', //影片製作
    name: 'MakeVideo',
    props: true,
    meta: {
      requiresAuth: true,
      title: '影片製作｜AD POST 網紅媒合・業配溝通平台',

      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '影片製作'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"MakeVideo" */ '../views/MakeVideo.vue')
  },
  {
    path: '/copywriting/:task_id', //文案
    name: 'CopyWritingEntry',

    props: true,
    meta: {
      title: '文案撰寫｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true,
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '文案平台'
        }
      ]
    },

    component: () =>
      import(
        /* webpackChunkName:"CopyWriting" */
        '../views/CopyWritingEntry.vue'
      )
  },
  {
    path: '/copywriting/:task_id/:platform_uuid', //文案
    name: 'CopyWriting',
    props: true,
    meta: {
      title: '文案撰寫｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true,
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '文案平台'
        }
      ]
    },

    component: () =>
      import(
        /* webpackChunkName:"CopyWriting" */
        '../views/CopyWriting.vue'
      )
  },
  {
    path: '/works_upload/:task_id', //作品
    name: 'WorksUpload',
    props: true,
    meta: {
      requiresAuth: true,
      title: '作品上線｜AD POST 網紅媒合・業配溝通平台',

      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '作品上線'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"WorksUpload" */ '../views/WorksUpload.vue')
  },
  {
    path: '/case_report/:task_id', //作品
    name: 'CaseReportIndex',
    props: true,
    meta: {
      requiresAuth: true,
      title: '結案報告列表｜AD POST 網紅媒合・業配溝通平台',

      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '結案報告列表'
        }
      ]
    },
    component: () =>
      import(
        /* webpackChunkName:"CaseReportIndex" */
        '../views/CaseReportIndex.vue'
      )
  },
  {
    path: '/case_report/:task_id/:url_uid', //作品
    name: 'CaseReport',
    props: true,
    meta: {
      requiresAuth: true,
      title: '結案報告｜AD POST 網紅媒合・業配溝通平台',

      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '結案報告'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"CaseReport" */ '../views/CaseReport.vue')
  },
  {
    path: '/case_report/edit/:task_id/:url_uid',
    name: 'CaseReportEdit',
    props: true,
    meta: {
      requiresAuth: true,
      title: '結案報告｜AD POST 網紅媒合・業配溝通平台',

      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '結案報告'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"CaseReport" */ '../views/CaseReportEdit.vue')
  },
  {
    path: '/case_report/review/:task_id/:url_uid',
    name: 'CaseReportReview',
    props: true,
    meta: {
      requiresAuth: true,
      title: '結案報告｜AD POST 網紅媒合・業配溝通平台',
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '結案報告'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"CaseReport" */ '../views/CaseReport.vue')
  },
  {
    path: '/draft/:task_id', //腳本
    name: 'Draft',
    props: true,
    meta: {
      requiresAuth: true,
      title: '腳本細節｜AD POST 網紅媒合・業配溝通平台'
    },
    children: [
      {
        path: 'draft-script',
        props: true,

        meta: {
          breadcrumb: [
            {
              name: '我的專案',
              link: '/project'
            },
            {
              name: '專案進度',
              link: ''
            },
            {
              name: '腳本細節'
            }
          ]
        },
        component: () =>
          import(
            /* webpackChunkName:"DraftMutilPage" */
            '../views/DraftMutilPage.vue'
          )
      }
    ],
    component: () => import(/* webpackChunkName:"Draft" */ '../views/Draft.vue')
  },
  {
    path: '/draft-type/:task_id', //選擇腳本種類
    name: 'DraftType',
    props: true,
    meta: {
      requiresAuth: true,
      title: '腳本細節｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(
        /* webpackChunkName:"DraftType" */
        '../views/DraftType.vue'
      )
  },
  {
    path: '/draft-one-page/:task_id',
    name: 'DraftOnePage',
    props: true,
    meta: {
      requiresAuth: true,
      title: '腳本細節｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(
        /* webpackChunkName:"DraftOnePage" */
        '../views/DraftOnePage.vue'
      )
  },
  {
    path: '/briefb/:task_id/:type', // briefb
    name: 'BriefB',
    props: true,

    meta: {
      title: '編輯需求單｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true,
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '需求單'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"BriefB" */ '../views/BriefB.vue')
  },
  {
    path: '/briefa/:project_id/:task_id', // briefa
    name: 'BriefA',

    props: true,
    meta: {
      title: '檢視發案需求｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true,
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '發案需求'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"BriefA" */ '../views/BriefA.vue')
  },
  {
    path: '/accept/:task_id/:type', // 過稿狀態
    name: 'Accept',
    props: true,

    meta: {
      title: '過稿狀態｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true,
      breadcrumb: [
        {
          name: '我的專案',
          link: '/project'
        },
        {
          name: '專案進度',
          link: ''
        },
        {
          name: '過稿紀錄'
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName:"Accept" */ '../views/Accept.vue')
  },
  {
    path: '/profile', // 個人中心
    name: 'Profile',
    props: true,
    meta: {
      title: '個人中心｜AD POST 網紅媒合・業配溝通平台',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName:"Profile" */ '../views/Profile.vue')
  },
  {
    path: '/error/:errorType', // 錯誤頁面
    name: 'Error',
    props: true,
    meta: {
      title: '請求失敗｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () => import(/* webpackChunkName:"Error" */ '../views/Error.vue')
  },

  {
    path: '/:catchAll(.*)',
    name: 'notFoundPage',
    meta: {
      title: '無法取得頁面｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"notFoundPage" */ '../views/notFoundPage.vue')
  },
  {
    path: '/guideline',
    name: 'guideline',
    meta: {
      title: 'guideline'
    },
    component: () =>
      import(/* webpackChunkName:"guideline" */ '../views/Guideline.vue')
  },
  {
    path: '/contact-us', // 個人中心
    name: 'ContactUs',
    meta: {
      title: '聯絡我們｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () =>
      import(/* webpackChunkName:"ContactUs" */ '../views/ContactUs.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '註冊/登入｜AD POST 網紅媒合・業配溝通平台'
    },
    component: () => import('../views/Register/index.vue'),
    children: [
      {
        path: '', // 空路徑表示默認子路由
        name: 'registerStepChecking',
        component: () => import('../views/Register/stepChecking.vue')
      },
      {
        path: 'isForm3rdLogin',
        name: 'isForm3rdLogin',
        component: () => import('../views/Register/isForm3rdLogin.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    };
  },
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('useFinish');
  document.title = to.meta.title;
  navbar.path.value = to.path;
  //viewport
  let windowWidth = window.innerWidth;
  let viewport = document.querySelector('meta[name=viewport]');
  const setView = () => {
    if (
      to.path == '/' ||
      // to.path == '/adpost_vendor' ||
      to.path == '/add_project' ||
      to.path == '/404' ||
      to.path == '/account_binding'
    ) {
      let logout = localStorage.getItem('user');
      if (logout == null) {
        viewport.setAttribute('content', 'width=device-width,initial-scale=1');
      }
    } else {
      //糞！！！！
      if (
        to.path != '/about-us' &&
        to.path != '/validation' &&
        to.path != '/project'
      ) {
        if (windowWidth <= 1440) {
          viewport.setAttribute('content', 'width=1440,initial-scale=1.0');
        }
      } else {
        if (windowWidth <= 1440) {
          viewport.setAttribute(
            'content',
            'width=device-width,initial-scale=1'
          );
        }
      }

      if (['/ladingpage/cyberbiz'].includes(to.path)) {
        //lading page 設定
        viewport.setAttribute('content', 'width=device-width,initial-scale=1');
      }
    }
  };
  // setView();
  //紀錄來源網址
  //從訊息進入錯誤頁面,製作返回功能
  const filterData = ['draftIndex', 'CopyWriting'];
  if (filterData.includes(to.name)) {
    notFoundPage.fromName.value = to.name;
    notFoundPage.fromUrlData.value = { params: to.params, query: to.query };
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    } else {
      localStorage.setItem('goPath', to.fullPath);
    }
    console.log('to:', to);
    if (['/add_project', '/create_project'].includes(to.path)) {
      next('/?login=1');
    } else {
      next('/');
    }
  } else {
    next();
  }
});
export default router;
