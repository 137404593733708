<template>
  <div
    ref="target"
    class="tw-flex tw-items-center tw-p-2 tw-bg-white tw-shadow-[4px_4px_24px_0px_rgba(0,0,0,0.20)] tw-rounded-lg"
  >
    <LinkPreview
      v-if="editView == 'linkPreview'"
      :editor="editor"
      @showLinkEditModel="showLinkEditModel"
    />

    <LinkEdit
      v-else
      :editor="editor"
      :currentUrl="editor.getAttributes('link').href"
      @closeShowEdit="closeShowEdit"
    />
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import LinkEdit from './LinkEdit.vue';
import { onClickOutside } from '@vueuse/core';
import LinkPreview from './LinkPreview.vue';

export default {
  name: 'LinkMenuIndex',
  props: ['editor'],
  components: {
    LinkEdit,
    LinkPreview
  },
  setup(props, { emit }) {
    const target = ref(null);
    const editView = ref('linkPreview');
    const showLinkEditModel = () => {
      editView.value = 'linkEdit';
    };

    const closeShowEdit = () => {
      editView.value = 'linkPreview';
      props.editor.commands.focus();
    };

    onClickOutside(target, () => {
      editView.value = 'linkPreview';
    });
    return { target, editView, showLinkEditModel, closeShowEdit };
  }
};
</script>
<style lang="scss" scoped></style>
