<template>
  <header
    class="top_bar_wrapper tw-text-left md:tw-text-center"
    :class="{ onscroll: onscroll }"
  >
    <div>
      <span class="info_title tw-block md:tw-inline-block"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1071_85687)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.0001 4.74098V7.99998C19.394 7.99998 19.7841 8.07758 20.1481 8.22834C20.5121 8.3791 20.8428 8.60008 21.1214 8.87866C21.4 9.15723 21.6209 9.48795 21.7717 9.85193C21.9225 10.2159 22.0001 10.606 22.0001 11C22.0001 11.3939 21.9225 11.7841 21.7717 12.148C21.6209 12.512 21.4 12.8427 21.1214 13.1213C20.8428 13.3999 20.5121 13.6209 20.1481 13.7716C19.7841 13.9224 19.394 14 19.0001 14V17C19.0001 18.648 17.1191 19.589 15.8001 18.6L13.7401 17.054C12.6389 16.2283 11.3568 15.6771 10.0001 15.446V18.29C10.0002 18.9437 9.76395 19.5755 9.33495 20.0688C8.90596 20.5621 8.3131 20.8837 7.66567 20.9743C7.01824 21.0649 6.35987 20.9184 5.81193 20.5618C5.26399 20.2053 4.86342 19.6626 4.68405 19.034L3.11405 13.538C2.54907 12.8708 2.18139 12.0592 2.05234 11.1945C1.92329 10.3298 2.03799 9.44622 2.38354 8.64312C2.72909 7.84002 3.29179 7.14922 4.0084 6.64837C4.72501 6.14753 5.56711 5.85649 6.44005 5.80798L9.45805 5.63998C10.9347 5.55781 12.3707 5.12631 13.6481 4.38098L15.9921 3.01298C17.3261 2.23598 19.0001 3.19698 19.0001 4.74098ZM5.63405 15.078L6.60705 18.485C6.65388 18.6499 6.75879 18.7923 6.90243 18.8859C7.04606 18.9795 7.2187 19.018 7.38848 18.9942C7.55826 18.9704 7.71371 18.886 7.82612 18.7566C7.93853 18.6272 8.00031 18.4614 8.00005 18.29V15.28L6.44005 15.193C6.16858 15.1779 5.89891 15.1394 5.63405 15.078ZM17.0001 4.74098L14.6551 6.10998C13.2304 6.94042 11.6419 7.45026 10.0001 7.60398V13.423C11.7871 13.669 13.4881 14.366 14.9401 15.454L17.0001 17V4.74098ZM8.00005 7.72398L6.55005 7.80398C5.8755 7.84122 5.23942 8.13 4.76737 8.6133C4.29532 9.0966 4.0216 9.73931 4.00026 10.4146C3.97891 11.0898 4.21148 11.7485 4.65205 12.2607C5.09263 12.7728 5.7092 13.1012 6.38005 13.181L6.55005 13.196L8.00005 13.276V7.72398ZM19.0001 9.99998V12C19.2549 11.9997 19.5001 11.9021 19.6854 11.7271C19.8708 11.5522 19.9823 11.313 19.9972 11.0586C20.0122 10.8041 19.9294 10.5536 19.7658 10.3581C19.6022 10.1627 19.3701 10.0371 19.1171 10.007L19.0001 9.99998Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1071_85687">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span class="tw-pl-1.5">過年期間休假公告：</span>
      </span>
      <span class="info_txt"
        >2/7~2/14 暫停所有客服服務．將於 2/15 陸續回覆</span
      >
      <span class="tw-pl-9 tw-hidden md:tw-inline-block">
        <span class="cycle"> 祝 </span>
        <span class="cycle"> 您 </span>
        <span class="cycle"> 龍 </span>
        <span class="cycle"> 年 </span>
        <span class="cycle"> 行 </span>
        <span class="cycle"> 大 </span>
        <span class="cycle"> 運 </span>
      </span>
    </div>

    <div class="close_btn" @click="closeHandler">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M13.3333 3.74097L12.259 2.66669L8 6.92573L3.74095 2.66669L2.66667 3.74097L6.92571 8.00002L2.66667 12.2591L3.74095 13.3334L8 9.07431L12.259 13.3334L13.3333 12.2591L9.07428 8.00002L13.3333 3.74097Z"
          fill="white"
        />
      </svg>
    </div>
  </header>
</template>

<script>
import { inject, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TopBar',
  components: {},
  emit: ['update'],
  setup(props, { emit }) {
    const route = useRoute();
    const onscroll = ref(false);
    const closeHandler = function () {
      emit('update', false);
    };

    console.log('route:', route.path == '/');

    document.addEventListener('scroll', function (e) {
      if (document.documentElement.scrollTop > 50) {
        if (route.path == '/') {
          onscroll.value = true;
        } else {
          onscroll.value = false;
        }
      } else {
        onscroll.value = false;
      }
    });
    return {
      onscroll,
      closeHandler
    };
  }
};
</script>
<style lang="scss" scoped>
.onscroll {
  position: fixed;
}
.close_btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 6px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.top_bar_wrapper {
  padding: 9px 24px;
  width: 100%;
  background: #ce4444;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}

.info_title {
  font-weight: 300;
  letter-spacing: 2.88px;
}
.info_txt {
  font-weight: 400;
  letter-spacing: 3.04px;
}

.cycle {
  margin: 3px;
  display: inline-block;
  text-align: center;
  width: 26px;
  height: 26px;
  gap: 8px;
  border-radius: 30px;
  background: #e6dba7;

  color: #e40017;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
</style>
