<template>
  <section class="case tw-pt-8 md:tw-pt-14">
    <h2
      class="tw-text-[#161818] tw-text-center tw-text-[32px] tw-font-medium tw-tracking-[1.44px] tw-w-full tw-py-2 tw-mb-0"
    >
      成功合作案例
    </h2>
    <div
      class="uk-position-relative uk-visible-toggle uk-light tw-pb-8 tw-pt-8 md:tw-pb-14"
      tabindex="-1"
      uk-slider="center: true"
    >
      <ul
        class="uk-slider-items uk-grid uk-grid-match tw-m-0"
        uk-height-viewport="offset-top: true; offset-bottom: 30"
      >
        <li
          v-for="(item, index) in cases"
          :key="index"
          class="tw-w-[calc(100%_-_64px)] sm:tw-w-[620px] lg:tw-w-[1000px] tw-bg-white tw-shadow-[4px_4px_20px_0px_rgba(0,0,0,0.12)] tw-rounded-xl tw-p-[32px] sm:tw-p-[48px] tw-mx-[30px] sm:tw-mx-[30px]"
        >
          <div
            class="card_inner tw-flex tw-flex-wrap tw-text-[#161818] tw-w-full tw-text-left"
          >
            <div
              :class="{
                'lg:tw-w-[calc(100%_-_240px)] lg:tw-pr-12': item.pic,
                'tw-w-[100%]': !item.pic
              }"
              class="card_info tw-flex tw-flex-wrap tw-content-start"
            >
              <div class="tw-flex">
                <div class="card_info_logo tw-w-[80px] tw-h-[auto] tw-mr-6">
                  <img :src="item.logo" alt="" loading="lazy" />
                </div>
                <div
                  class="card_info_text tw-w-[calc(100%_-_104px)] tw-h-[auto] tw-items-start tw-flex tw-flex-wrap tw-justify-items-start tw-flex-row sm:tw-flex-col"
                >
                  <div
                    class="card_text_name tw-text-[#161818] tw-w-full tw-text-2xl tw-font-medium tw-leading-9 tw-tracking-[0.96px]"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="card_text_title tw-text-[#2AADBE] tw-text-base tw-not-italic tw-font-normal tw-leading-6 tw-tracking-[1.28px]"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>

              <div
                class="card_contents tw-text-[#161818] tw-w-full tw-text-sm tw-font-normal tw-leading-7 tw-tracking-[1.12px] tw-mt-[32px]"
                v-html="item.contents"
              ></div>
            </div>
            <div
              class="card_pic tw-w-[240px] tw-hidden lg:tw-block"
              v-if="item.pic"
            >
              <img :src="item.pic" alt="" loading="lazy" />
            </div>
            <div
              class="card_img tw-w-full tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-4 md:tw-gap-2 xl:tw-gap-6"
            >
              <div
                class="card_img_inner"
                v-for="(img_item, img_index) in item.imgs"
              >
                <img
                  class="tw-w-full tw-h-[auto]"
                  :key="img_index"
                  :src="img_item"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>

      <a
        class="uk-position-center-left uk-position-small uk-hidden-hover tw-border tw-bg-[rgba(196,196,196,0.70)] tw-rounded-[30px] tw-border-solid tw-border-white"
        href="#"
        uk-slidenav-previous
        uk-slider-item="previous"
      ></a>
      <a
        class="uk-position-center-right uk-position-small uk-hidden-hover tw-border tw-bg-[rgba(196,196,196,0.70)] tw-rounded-[30px] tw-border-solid tw-border-white"
        href="#"
        uk-slidenav-next
        uk-slider-item="next"
      ></a>
    </div>
  </section>
</template>
<script setup>
import { reactive } from 'vue';
const cases = reactive([
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol1/logo.png'),
    name: '潤姬桃子',
    title: '藝人代言 Ｘ 口碑行銷 Ｘ 整合通路導購',
    pic: require('@/assets/landing_page/cyberbiz/case/kol1/pic.png'),
    contents: `<p>人氣話題的潤姬桃子，是來自日本的神經醯胺營養輔助食品，主打可直接食用香甜桃子風味粉末，與AD POST合作希望提升品牌知名度和口碑並創造更多銷售機會。
              </p>
              <p>策略以邵雨薇作為代言人，2021年開始延續到現在的長期合作形式，用短影音和一連串的FB、IG貼文強化代言人對商品本身的信賴支持，同時搭配十多位中小型網紅的IG圖文分享。</p>
              <p>達到破百萬的觸及人數和曝光次數，在官方電商網站和各大藥妝通路引發購買熱潮，也獲得廣泛使用者的好評回饋，成功奠定品牌市場地位。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol1/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol1/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol1/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol1/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol2/logo.png'),
    name: 'ONEBOY',
    title: '品牌曝光 Ｘ 導購轉換 Ｘ 藝人代言',
    contents: `<p>行銷界討論度最高的ONEBOY，成為行銷界爭相複製的成功案例。</p>
              <p>首波操作以品牌知名度為主，與數十位微網紅合作IG圖文進行強力曝光，接著在FB操作導購，同時與Joman、見習網美小吳合作 FB和 YT 影音，並與多位知名藝人代言合作，在信義區中心登上大型戶外廣告看版，整合線上線下行銷資源，從電商快速拓展到數十間實體的旗艦店和百貨櫃位。</p>
              <p>在FB累積超過百萬的按讚數，IG累積數十萬的觸及次數，佔據各大媒體、社群平台等版面，至今已經創下多次紀錄，話題熱度居高不下，締造出難以估計的品牌價值。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol2/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol2/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol2/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol2/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol3/logo.png'),
    name: 'Foodpanda',
    title: '品牌形象 Ｘ 社群擴散',
    contents: `<p>身為外送平台龍頭的Foodpanda，在訂單暴增的 2019年，面臨外送員嚴重短缺，與AD POST合作希望透過社群力克服困境。</p>
              <p>從AI網紅數據資料庫，選擇和親民的上班不要看的蔡哥業配操作，以百萬年薪來重塑外送員的品牌形象，透過「百萬年薪」關鍵字提供 SEO 被搜尋到這支影片的機率，讓大家了解外送員一天在做什麼。除此，每月固定Youtube影音操作，進行一系列主題以「不同外送需求」的情境，逐步推疊宣傳效益。</p>
              <p>單隻影片總共創造126萬以上的觀看次數，高達1.7萬的讚數，達成外送員徵才300%以上的轉換成效，也帶動一波話題討論！</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol3/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol3/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol3/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol3/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol4/logo.png'),
    name: 'LABALLE 拉蓓',
    title: '品牌曝光 Ｘ 直播導購',
    pic: require('@/assets/landing_page/cyberbiz/case/kol4/pic.png'),
    contents: `<p>知名輕養生飲品品牌 LABALLE 拉蓓，由中醫師 77老大以極致．純淨．科學調理精心研發，這次規劃推廣2款新商品閃纖飲和亮妍茶。</p>
              <p>挑選以符合品牌形象的唯真、王柔勻、Pei沛沛和Lauren，推廣年輕女性也要日常輕保養的觀念，在 FB 和 IG 以圖文合作並結合廣告投放。</p>
              <p>累績總曝光超過 3萬次，不重複觸及90%以上，貼文互動率20%，成效表現相當亮眼！</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol4/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol4/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol4/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol4/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol5/logo.png'),
    name: '特力家居',
    title: '檔期曝光 Ｘ 商場介紹',
    pic: require('@/assets/landing_page/cyberbiz/case/kol5/pic.png'),
    contents: `<p>全台最大居家生活購物中心特力家居與AD POST攜手合作，本次為宣傳高雄館的年中慶活動，行銷以曝光行銷活動和介紹商場為主。</p>
              <p>涵蓋HOLA、特力屋、快樂小熊、康樸樂、回家睡覺、知識睡眠等櫃位，以母子和單身女性兩種網紅類型分別在FB和IG合作圖文。</p>
              <p>累積一千七百多次的讚數，近千次的連結點擊次數，藉由線上操作累積不少商場人氣。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol5/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol5/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol5/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol5/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol6/logo.png'),
    name: '王品阪前和牛鐵板燒',
    title: '品牌推廣Ｘ 活動宣傳',
    contents: `<p>阪前和牛鐵板燒為王品集團旗下新品牌，以職人精神呈現頂級的澳日和牛料理。本次合作以推廣母親節活動、春宴新套餐和壽星活動為主。</p>
              <p>以用餐體驗與三位網紅分別合作 IG圖文、限動、Reels、FB圖文、部落格文等形式，從品牌、環境、食材、餐點、服務等整體呈現。</p>
              <p>在 IG 達到3萬多次的曝光數，單篇Reels有8千多觀看，部落格文累積4千多次的瀏覽，成功以母親節議題帶動這波行銷活動，引起一波好評迴響。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol6/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol6/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol6/img3.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol7/logo.png'),
    name: 'momo購物網',
    title: '社群擴散 Ｘ 品牌曝光',
    contents: `<p>全台最大的零售電商平台 momo購物網與AD POST攜手合作，宣傳 FUN4購物節活動，行銷以曝光行銷活動並促進銷售為主。</p>
              <p>宣傳涵蓋食品、保健、居家清潔、大小生活家電等生活用品，搭配藝人夏和熙和 5位符合商品屬性中小型網紅在Instagram規劃直播、限時動態的圖文和 Reels、貼文等多種形式，以一日生活、出國旅行等主題包裝互動。</p>
              <p>這波宣傳成效亮眼，累績按讚數近萬次，曝光次數高達13萬次，短短4天的檔期活動在社群上快速發酵，引起一波話題討論，成功為品牌提升行銷成效。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol7/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol7/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol7/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol7/img4.png')
    ]
  },
  {
    logo: require('@/assets/landing_page/cyberbiz/case/kol8/logo.png'),
    name: 'CYRANO',
    title: '社群擴散 Ｘ 品牌曝光',
    pic: require('@/assets/landing_page/cyberbiz/case/kol8/pic.png'),
    contents: `<p>CYRANO為香氛體驗與保養美學品牌，希望將精選的3款國際香氛名品CREED、AMOUAGE和LABORATORIO OLFATTIVO 更進一步推廣給愛好者。</p>
              <p>從AD POST網紅資料庫中搜尋符合的中大型網紅和藝人，同時在 FB及IG雙平台露出。知名藝人王思佳和其他 7位獨具個人風格的網紅，以情感訴求與專屬的香水連結，同時呈現出一系列時尚雜誌般的拍攝，讓不同款香氛想傳達的訊息深深植入消費者意識。</p>
              <p>本次的形象推廣非常成功！總曝光高達 80萬次，80%以上的不重複觸及，且超過 14%的貼文互動率，得到許多正面的迴響，也吸引更多的潛在族群。</p>`,
    imgs: [
      require('@/assets/landing_page/cyberbiz/case/kol8/img1.png'),
      require('@/assets/landing_page/cyberbiz/case/kol8/img2.png'),
      require('@/assets/landing_page/cyberbiz/case/kol8/img3.png'),
      require('@/assets/landing_page/cyberbiz/case/kol8/img4.png')
    ]
  }
]);
</script>
<style lang="scss" scoped>
:deep .card_contents p,
.card_text_name,
.card_text_title {
  font-family: PingFangBold2, sans-serif !important;
}
</style>
