<template>
  <form
    @submit.prevent
    autocomplete="on"
    class="uk-position-relative accountForm"
  >
    <div class="mb-6">
      <div class="uk-form-controls uk-position-relative">
        <input
          class="uk-input uk-border-rounded"
          :class="[
            errorMessage.emailError != null ||
            errorMessage.emailRepeatError != null
              ? 'uk-form-danger'
              : ''
          ]"
          type="mail"
          @input="emailValidate"
          id="registerEmail"
          placeholder=""
          required="required"
          v-model.trim="registerData.email"
        />
        <label class="uk-form-label uk-position-absolute" for="registerEmail"
          >帳號：E-mail信箱</label
        >
      </div>
      <small
        class="uk-text-danger uk-float-right"
        v-if="errorMessage.emailError != null"
        >{{ errorMessage.emailError }}</small
      >
      <small
        class="uk-text-danger uk-float-right"
        v-if="
          errorMessage.emailError == null &&
          errorMessage.emailRepeatError != null
        "
        >{{ errorMessage.emailRepeatError }}</small
      >
    </div>
    <div class="mb-6">
      <div class="uk-form-controls uk-inline uk-width-1 uk-position-relative">
        <input
          class="uk-input uk-border-rounded"
          :class="[errorMessage.passwordError != null ? 'uk-form-danger' : '']"
          type="password"
          id="registerPassword"
          placeholder=""
          @keyup="passwordValidate"
          required="required"
          v-model.trim="registerData.password"
        />
        <label
          class="uk-form-label uk-position-absolute passwordLabel"
          for="registerPassword"
          >密碼</label
        >
        <a class="uk-form-icon uk-form-icon-flip" @click="viewPassword">
          <img
            :src="
              openPassword
                ? require('@/assets/icon/eye.svg')
                : require('@/assets/icon/eye_none.svg')
            "
            width="24"
            height="24"
            alt="檢視密碼"
        /></a>
      </div>
      <small
        class="uk-text-danger uk-float-right"
        v-if="errorMessage.passwordError != null"
        >{{ errorMessage.passwordError }}</small
      >
    </div>
    <div class="mb-6">
      <div class="uk-form-controls uk-flex">
        <label class="uk-flex uk-flex-middle" style="top: 0; transform: none">
          <input
            type="checkbox"
            class="uk-checkbox rulesCheck"
            style="margin-right: 6px"
            v-model="rulesCheckData"
          />
          <small>
            我同意<a href="/terms-of-use" target="_blank" style="color: #73d1dd"
              >AD POST條款與規範</a
            >
          </small>
        </label>
      </div>
    </div>
    <div class="mt-12">
      <div class="uk-form-controls uk-flex uk-flex-center">
        <button
          v-if="status.step1 === false"
          class="uk-button confirmBtn"
          disabled
        >
          下一步
        </button>
        <div
          v-if="status.step1 === true"
          class="nextBtn m-btn-rounded_brand_white"
          @click="setNextStep(1)"
        >
          下一步
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import VALIDATE from '@/lib/validate';

export default {
  name: '',
  props: ['status'],
  emits: ['emitSignUpData', 'emitNextStep'],
  setup(props, { emit }) {
    const registerData = reactive({
      email: '',
      password: ''
    });

    const errorMessage = reactive({
      emailError: null,
      emailRepeatError: null,
      passwordError: null
    });

    const openPassword = ref(false);
    const rulesCheckData = ref(false);

    // 確認信箱是否重複
    const emailValidate = async event => {
      const email = event.target.value;
      const emailData = { email: email };
      if (!email) {
        errorMessage.emailError = '記得填寫喔';
        return;
      }
      errorMessage.emailError = VALIDATE.emailValidate(event);
      if (errorMessage.emailError) return;
      try {
        const res = await VALIDATE.emailRepeatValidate(emailData);
        if (res.status == '0101') {
          errorMessage.emailRepeatError = '信箱已存在';
        } else {
          errorMessage.emailRepeatError = null;
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    // 密碼驗證
    const passwordValidate = event => {
      errorMessage.passwordError = VALIDATE.passwordValidate(event);
    };
    // 看密碼
    const viewPassword = () => {
      let passwordInput = document.getElementById('registerPassword');
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        openPassword.value = true;
      } else {
        passwordInput.type = 'password';
        openPassword.value = false;
      }
    };

    const setNextStep = num => {
      emit('emitNextStep', num);
    };

    watch([registerData, errorMessage, rulesCheckData], () => {
      emit('emitSignUpData', registerData, errorMessage, rulesCheckData.value);
    });

    return {
      registerData,
      errorMessage,
      openPassword,
      rulesCheckData,
      viewPassword,
      emailValidate,
      passwordValidate,
      setNextStep
    };
  }
};
</script>

<style lang="scss" scoped></style>
