<template>
  <footer>
    <div>
      <div class="infoImg info">
        <div class="logo">
          <img
            :src="require('@/assets/icon/LOGO.svg')"
            alt="adpost"
            width="208"
            height="44"
          />
        </div>
      </div>
      <div class="infoConnection info">
        <div class="text">
          <span>客服專線｜02-2345-1199 </span>
          <span>客服信箱｜service@adpost.com.tw</span>
          <span>公司名稱｜瑪樂愛迪科技股份有限公司</span>
          <span>統一編號｜66657111</span>
        </div>
      </div>

      <div class="infoAbout info">
        <div class="linkArea">
          <router-link to="/about-us">關於我們</router-link>
          <a
            href="https://blog.adpost.com.tw"
            target="_blank"
            rel="noreferrer noopener"
            >部落格</a
          >
          <router-link to="/terms-of-use">服務條款</router-link>
        </div>
        <div class="buttonArea">
          <div class="item">
            <a
              href="https://www.facebook.com/ADPOSTTW"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                :src="require('@/assets/icon/FBfooter.svg')"
                alt="FB"
                width="48"
                loading="lazy"
                height="48"
              />
            </a>
          </div>
          <div class="item">
            <a
              href="https://www.instagram.com/adpost_taiwan/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                :src="require('@/assets/icon/IGfooter.svg')"
                alt="IG"
                width="48"
                loading="lazy"
                height="48"
              />
            </a>
          </div>
          <div class="item">
            <a
              href="http://line.me/ti/p/@633twzkq"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                :src="require('@/assets/icon/LINEfooter.svg')"
                alt="LINE"
                width="48"
                loading="lazy"
                height="48"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <span> COPYRIGHT © 2023 AD POST All rights reserved. </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {}
};
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  background: #292929;
  max-width: 1920px;
  margin: 0 auto;
  > div {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 1136px;

    .info {
      display: flex;
      align-items: flex-start;
      padding-top: 72px;
    }

    .infoConnection {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      padding-left: 60px;
      .text {
        display: flex;
        flex-direction: column;
        span {
          text-align: left;
          color: white;
          font-weight: normal;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.18em;
        }
      }
    }
    .infoAbout {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .linkArea {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 46px;

        a {
          color: white;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.155em;
          &:hover {
            color: rgb(179, 179, 179);
          }
        }
      }

      .buttonArea {
        display: flex;
        justify-content: space-around;
        .item {
          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
    }
  }
  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    span {
      font-size: 12px;
      letter-spacing: 0.2em;

      color: #929292;
    }
  }

  @media (max-width: 1024px) {
    > div {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: 75% 25%;
      grid-template-rows: 100px 100px;
      padding: 0 50px;
      .info {
        padding-top: 18px;
        display: flex;
        justify-content: flex-start;
      }
      .infoImg {
        width: 90%;
        margin: 0;

        grid-area: 1 / 1 / 1/ 1;
        .logo {
          margin: 0;
          display: flex;
          justify-content: flex-start;
        }
      }
      .infoConnection {
        padding-left: 0;
        width: 90%;
        flex: unset;
        grid-area: 2 / 1 / 2/ 1;
        .text {
          width: 100%;
          span {
            text-align: left;
          }
        }
      }
      .infoAbout {
        margin: 0;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        .linkArea {
          justify-content: space-between;

          margin-bottom: 0;
          a {
            font-size: 18px;
          }
        }
        .buttonArea {
          margin-bottom: 30px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    > div {
      grid-template-columns: 75% 30%;
      padding: 50px 70px;
      .infoImg {
        .logo {
          display: flex;
          justify-content: flex-start;
        }
      }
      .infoAbout {
        justify-content: space-between;
      }
      .infoConnection .text span {
        letter-spacing: 0.12em;
      }
    }
    .copyright {
      padding: 20px;
      span {
        font-size: 10px;
      }
    }
  }
  @media (max-width: 767px) {
    > div {
      display: flex;
      grid-template-columns: 75% 30%;
      padding: 50px 70px;
      .infoAbout {
        width: 40%;
        .linkArea {
          order: 2;
        }
        .buttonArea {
          width: 100%;
          justify-content: space-between;
        }
      }
      .infoConnection {
        margin-bottom: 50px;
        .txt {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    > div {
      padding: 20px 0;
      .infoAbout {
        width: 40%;
        .linkArea {
          order: 2;
        }
        .buttonArea {
          width: 100%;
          justify-content: space-between;
        }
      }
      .infoConnection {
        margin-bottom: 50px;
        .text {
          span {
            font-size: 16px;
          }
        }
      }
    }
    .copyright {
      padding: 10px;
      span {
        font-size: 10px;
      }
    }
  }
  @media (max-width: 414px) {
    > div {
      padding: 0 30px;

      .infoConnection {
        .text {
          span {
            font-size: 12px;
          }
        }
      }
      .infoAbout {
        width: 75%;
        .linkArea {
          a {
            font-size: 16px;
          }
        }
      }
    }
    .copyright {
      margin-top: 10px;
      span {
        letter-spacing: 0.01rem;
      }
    }
  }
  @media (max-width: 375px) {
    > div {
      padding: 0 10px;

      .infoConnection {
        .text {
          span {
            font-size: 12px;
          }
        }
      }
      .infoAbout {
        width: 75%;
        .linkArea {
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media (max-width: 320px) {
    > div {
      padding: 0 5px;
      .infoImg {
        justify-content: center;
        .logo {
          width: 60%;
        }
      }
      .infoConnection {
        .text {
          span {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
