<template>
  <div class="ourEditor" ref="target">
    <div v-if="editable">
      <bubble-menu
        class="bubble-menu t-shadow_heavy"
        :editor="editor"
        :tippy-options="{ animation: false }"
        v-if="editor"
      >
        <div
          class="uk-flex"
          v-show="useImage && editor.isActive('custom-image')"
        >
          <ImageSize
            v-for="size in imgSize"
            :key="size"
            :size="size"
            :editor="editor"
          />

          <ImageAlign
            v-for="align in imgAlign"
            :key="align"
            :align="align"
            :editor="editor"
          />
        </div>

        <div
          class="uk-flex"
          v-show="!editor.isActive('custom-image') && !editor.isActive('table')"
        >
          <Heading
            v-for="heading in headings"
            :key="heading.level"
            :heading="heading"
            :editor="editor"
          />

          <Link :editor="editor" @handleLink="handleLink" />

          <FontColor
            v-for="color in fontColors"
            :key="color.color"
            :color="color"
            :editor="editor"
          />

          <BgColor
            v-for="color in bgColors"
            :key="color.color"
            :color="color"
            :editor="editor"
          />

          <RemoveMark :editor="editor" />
          <Quote
            :editor="editor"
            @quoteSelectedText="getSelectedText"
            v-show="useQuote"
          />
        </div>

        <div class="uk-flex table" v-if="editor.isActive('table')">
          <div v-if="useTable" class="px-4">
            <v-list>
              <v-list-item class="py-3">
                <v-btn
                  variant="plain"
                  @click="editor.chain().focus().addColumnAfter().run()"
                  :disabled="!editor.can().addColumnAfter()"
                >
                  <v-icon
                    icon="mdi-table-column-plus-after"
                    class="mr-2"
                  ></v-icon>

                  向右插入一欄</v-btn
                >
              </v-list-item>
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().addColumnBefore().run()"
                  :disabled="!editor.can().addColumnBefore()"
                >
                  <v-icon
                    icon="mdi-table-column-plus-before"
                    class="mr-2"
                  ></v-icon>

                  向左插入一欄
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().addRowBefore().run()"
                  :disabled="!editor.can().addRowBefore()"
                >
                  <v-icon
                    icon="mdi-table-row-plus-before"
                    class="mr-2"
                  ></v-icon>
                  向上插入一列
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().addRowAfter().run()"
                  :disabled="!editor.can().addRowAfter()"
                >
                  <v-icon icon="mdi-table-row-plus-after" class="mr-2"></v-icon>
                  向下插入一列
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().mergeOrSplit().run()"
                  :disabled="!editor.can().mergeOrSplit()"
                >
                  <v-icon icon="mdi-table-merge-cells" class="mr-2"></v-icon>
                  合併/分割儲存格
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().deleteRow().run()"
                  :disabled="!editor.can().deleteRow()"
                >
                  <v-icon icon="mdi-table-row-remove" class="mr-2"></v-icon>
                  刪除列
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().deleteColumn().run()"
                  :disabled="!editor.can().deleteColumn()"
                >
                  <v-icon icon="mdi-table-column-remove" class="mr-2"></v-icon>
                  刪除欄
                </v-btn></v-list-item
              >
              <v-list-item class="py-3"
                ><v-btn
                  variant="plain"
                  @click="editor.chain().focus().deleteTable().run()"
                  :disabled="!editor.can().deleteTable()"
                >
                  <v-icon icon="mdi-table-remove" class="mr-2"></v-icon>
                  刪除表格
                </v-btn></v-list-item
              >
            </v-list>
          </div>
        </div>
      </bubble-menu>
    </div>
    <!-- 插入圖片改為浮動打字時出現至旁 -->

    <div v-if="editor">
      <floating-menu
        class="floating-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
      >
        <div v-if="!useTable">
          <ImageComponent
            :editor="editor"
            :imageUploadConfig="imageUploadConfig"
            v-if="editor && editable && useImage && showImageIcon"
          />
        </div>

        <v-menu
          location="end"
          v-if="useTable && editor && editable && useImage"
        >
          <template v-slot:activator="{ props, isActive }">
            <v-btn
              size="x-small"
              color="#616161"
              variant="outlined"
              class="neutral cycle"
              v-bind="props"
              :icon="isActive ? 'mdi-close' : 'mdi-plus'"
              ><v-icon
                :icon="isActive ? 'mdi-close' : 'mdi-plus'"
                size="large"
              ></v-icon
            ></v-btn>
          </template>

          <div class="ml-5" style="display: flex">
            <ImageComponent
              class="mr-2"
              :editor="editor"
              :imageUploadConfig="imageUploadConfig"
            />

            <v-btn
              size="x-small"
              variant="outlined"
              icon="mdi-table"
              outlined
              color="#44c3ce"
              @click="insertTable"
            >
              <v-icon icon="mdi-table" size="large"></v-icon>
              <v-tooltip activator="parent" location="top">插入表格</v-tooltip>
            </v-btn>
          </div>
        </v-menu>
      </floating-menu>
    </div>
    <editor-content
      :editor="editor"
      :id="`${id}Editor`"
      class="editorContent"
    />
    <div v-if="editable">
      <bubble-menu
        class="bubble-menu tw-p-0"
        :editor="editor"
        :tippy-options="{
          placement: 'bottom'
        }"
        :should-show="() => editor.isActive('link')"
        v-if="editor"
      >
        <LinkMenu
          :editor="editor"
          v-show="editor.isActive('link') && !shouldShowLinkEditMenu"
        ></LinkMenu>
      </bubble-menu>
      <LinkEditMenu
        :editor="editor"
        v-if="editor && shouldShowLinkEditMenu"
        :editBoxPosition="editBoxPosition"
        @closeShowEdit="closeShowEdit"
      />
    </div>
  </div>
</template>

<script>
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  FloatingMenu
} from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import { Color } from '@tiptap/extension-color';
import Image from '@/lib/editor/Image';
import ImageComponent from '@/components/editor/Image.vue';
import FontColor from '@/components/editor/FontColor.vue';
import BgColor from '@/components/editor/BgColor.vue';
import Heading from '@/components/editor/Heading.vue';
import ImageSize from '@/components/editor/ImageSize.vue';
import compressorImage from '@/lib/compressorImage';
import { useRoute } from 'vue-router';
import { loadImgConfigApi } from '@/store/editor/index';
import { watch, onBeforeUnmount, onMounted, computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import ImageAlign from '@/components/editor/ImageAlign.vue';
import Quote from './Quote.vue';
import GetSelectedText from '@/lib/editor/GetSelectedText';
import { ExtendedLink } from '@/lib/editor/Link';
import Link from '@/components/editor/Link.vue';
import RemoveMark from './RemoveMark.vue';
import LinkMenu from './LinkMenu/index.vue';
import LinkEditMenu from './LinkMenu/LinkEditMenu.vue';
import { useStore } from 'vuex';
import notification from '@/lib/notification';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

export default {
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    Heading,
    ImageComponent,
    FontColor,
    BgColor,
    ImageSize,
    ImageAlign,
    Quote,
    Link,
    LinkMenu,
    RemoveMark,
    LinkEditMenu
  },
  props: {
    id: String,
    item: Object,
    editorData: [Object, String],
    placeholder: String,
    editable: Boolean,
    useImage: Boolean,
    imageUploadConfig: Object,
    defaultFocused: Boolean,
    useQuote: {
      type: Boolean,
      default: false
    },
    useTable: Boolean
  },
  emits: ['setEditorData', 'setFocus', 'setQuoteSelectedText'],
  setup(props, context) {
    const isActivePlaceholder = ref(props.placeholder);
    const id = props.id || '';
    const item = props.item || '';
    const store = useStore();
    const shouldShowLinkEditMenu = ref(false);
    const editable = computed(() => {
      if (props.editable != null || props.editable != undefined) {
        return props.editable;
      } else {
        return true;
      }
    });
    const useImage = computed(() => {
      if (props.useImage != null || props.useImage != undefined) {
        return props.useImage;
      } else {
        return false;
      }
    });
    const editorData = computed(() => props.editorData);
    const route = useRoute();
    const taskId = route.params.task_uuid || props.imageUploadConfig?.task_uuid;
    const showImageIcon = ref(false);
    const urlToObject = async (image, changeStatus) => {
      let response, blob, file;
      if (changeStatus) {
        response = await fetch(image);
        blob = await response.blob();
        file = new File([blob], 'image.jpg', { type: blob.type });
      } else {
        file = image;
      }
      let img = await compressorImage.compressorImage(file);
      const form = new FormData();
      form.append('task_uuid', taskId);
      form.append('file', img);
      try {
        const api = await loadImgConfigApi(props.imageUploadConfig?.lib);
        const res = await api.storeImage(form);
        return res.filename;
      } catch (e) {
        console.log('創意', e);
      }
    };
    const str = ref('');
    const startPos = ref();
    const editor = useEditor({
      editorProps: {
        handleDOMEvents: {
          async paste(v, e) {
            // image
            if (useImage.value && e.clipboardData.files.length) {
              e.preventDefault();
              let url = await urlToObject(e.clipboardData.files[0], false);
              editor.value.commands.insertContent(`<img src='${url}' />`);
              return true;
            }
            //text
            let text = e.clipboardData.getData('text/plain');
            let text2 = e.clipboardData.getData('text/html');
            if (text2.includes('fbcdn.net') || text2.includes('-yt')) {
              console.log('可能來自FB、YT');
              let output = text.replace(/(<\/?(?:p)[^>]*>)|<[^>]+>/g, '$1');
              output.split('\n').forEach(i => {
                str.value += `<p>${i}</p>`;
              });
              editor.value.commands.insertContent(str.value);
              str.value = '';
              e.preventDefault();
              return false;
            } else {
              if (text2.includes('base64')) {
                e.preventDefault();
                return false;
              }
              console.log('不來自FB、YT');
              return false;
            }
          },
          dragstart(view, e, slice, moved) {
            e.dataTransfer.setData('text/html', e.target.innerHTML);
            editor.value.commands.focus();
            //將原始拖拉的editor記錄起來
            store.commit('setDragStartEditorObj', editor.value);
          }
        },
        handleDrop: async function (view, e, slice, moved) {
          e.preventDefault();
          const { dataTransfer } = e;
          if (!useImage.value) {
            notification.notificationShow('danger', '不支援拖曳功能');
            return false;
          }
          if (
            !moved &&
            e.dataTransfer &&
            e.dataTransfer.files &&
            e.dataTransfer.files[0]
          ) {
            handleFileDrop(view, dataTransfer.files, e);
          } else {
            handleHTMLDrop(view, e);
          }
        }
      },

      extensions: [
        StarterKit,
        Color,
        TextStyle,
        Placeholder.configure({
          placeholder: isActivePlaceholder.value || ''
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image']
        }),
        Highlight.configure({ multicolor: true }),
        Image,
        GetSelectedText,
        ExtendedLink,
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell
      ],
      content: editable.value,
      editable: editable.value,
      onUpdate: () => {
        let content = editor.value.getHTML();
        context.emit('setEditorData', content, props.id, props.item);
      },
      onFocus() {
        showImageIcon.value = true;
        context.emit('setFocus');
      },
      onSelectionUpdate({ editor }) {}
    });
    const target = ref(null);
    onClickOutside(target, () => (showImageIcon.value = false));

    const fontColors = [
      { color: '#E64C4C', text: '紅色', img: 'text-color-red' },
      { color: 'rgb(77, 153, 230)', text: '藍色', img: 'text-color-blue' },
      { color: 'rgb(76, 230, 76)', text: '綠色', img: 'text-color-green' }
    ];
    const bgColors = [
      { color: '#FFF6A9', text: '黃色', img: 'highlighter_yellow' }
    ];
    const headings = [
      { level: '2', text: '1', img: 'text-H1' },
      { level: '3', text: '2', img: 'text-H2' },
      { level: '4', text: '3', img: 'text-H3' }
    ];
    const imgSize = [
      { size: 'large', text: '大', img: 'size-large' },
      { size: 'medium', text: '中', img: 'size-medium' },
      { size: 'small', text: '小', img: 'size-small' }
    ];
    const imgAlign = [
      { align: 'left', text: '置左', img: 'align-left' },
      { align: 'center', text: '置中', img: 'align-center' },
      { align: 'right', text: '置右', img: 'align-right' }
    ];
    watch(editorData, val => {
      const isSame = editor.value.getHTML() === val;
      if (isSame) return;
      editor.value.commands.setContent(val, false);
    });
    onMounted(function () {
      editor.value.commands.setContent(editorData.value, false);
    });
    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    //focus 輸入
    const focusEditor = () => {
      if (props.defaultFocused) {
        editor.value.commands.focus();
      }
    };
    const focusEditorEnd = () => {
      editor.value.commands.focus('end');
    };
    //清除
    const clearContent = () => {
      editor.value.commands.clearContent(true);
    };
    // 在這裡處理框選的文字
    const getSelectedText = () => {
      editor.value.chain().focus();
      context.emit(
        'setQuoteSelectedText',
        editor.value.commands.getSelectedText()
      );
    };

    const editBoxPosition = ref({ top: 0, left: 0 });
    const handleLink = position => {
      editBoxPosition.value = position;
      shouldShowLinkEditMenu.value = true;
    };
    const closeShowEdit = () => {
      shouldShowLinkEditMenu.value = false;
      editor.value.commands.blur();
    };

    async function handleFileDrop(view, files, e) {
      if (files.length > 10) {
        notification.notificationShow(
          'danger',
          '拖曳失敗，請勿一次上傳10張以上'
        );
        return false;
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const filesize = (file.size / 1024 / 1024).toFixed(4);

        if (
          (file.type === 'image/jpeg' || file.type === 'image/png') &&
          filesize < 10
        ) {
          const url = await urlToObject(file, false);

          if (url) {
            const { schema } = view.state;
            const coordinates = view.posAtCoords({
              left: e.clientX,
              top: e.clientY
            });
            const node = schema.nodes['custom-image'].create({ src: url });
            const transaction = view.state.tr.insert(coordinates.pos, node);
            view.dispatch(transaction);
          }
        } else {
          notification.notificationShow(
            'danger',
            '拖曳失敗，請確認是否為圖片格式（jpg/png)，及檔案大小勿超過10MB'
          );
        }
      }
    }

    function handleHTMLDrop(view, e) {
      const draggedData = e.dataTransfer?.getData('text/html');
      console.log('draggedData', draggedData);
      if (!draggedData) {
        notification.notificationShow('danger', '拖曳失敗');
      } else if (draggedData.includes('base64')) {
        notification.notificationShow(
          'danger',
          '拖曳失敗，圖片格式不能為base64'
        );
      } else {
        const pos = view?.posAtCoords({
          top: e?.clientY,
          left: e?.clientX
        });

        if (pos) {
          //先刪除節點再重新加入
          store.state.editor.dragStartEditorObj?.commands.deleteSelection();
          editor.value?.commands?.insertContentAt(pos?.pos, `${draggedData}`);
          store.commit('setDragStartEditorObj', null);
        }
      }
    }

    const insertTable = function () {
      editor.value
        .chain()
        .focus()
        .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
        .run();
    };

    return {
      insertTable,
      editor,
      fontColors,
      bgColors,
      imgSize,
      imgAlign,
      showImageIcon,
      target,
      headings,
      focusEditor,
      focusEditorEnd,
      clearContent,
      getSelectedText,
      handleLink,
      editBoxPosition,
      closeShowEdit,
      shouldShowLinkEditMenu
    };
  }
};
</script>
<style lang="scss">
.v-btn.v-btn--icon.v-theme--light.v-btn--density-default.v-btn--size-x-small.v-btn--variant-outlined {
  background-color: #fff;
}
.outline:hover {
  background-color: #f1f1f1 !important;
}
.bubble-menu {
  .table {
    button {
      opacity: 1 !important;
    }
  }
}
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.ProseMirror ol {
  margin-bottom: 0rem;
  list-style-position: outside;
  list-style-type: decimal;
  padding: 0.125rem;
}

.ProseMirror ol li {
  margin-left: 1.25rem;
}

.ProseMirror li {
  list-style: inherit;
}
.ourEditor {
  width: 100%;
  height: 100%;
  position: relative;
}
.tippy-box {
  max-width: unset !important;
}
.bubble-menu {
  display: flex;
  background: #ffffff;
  padding: 0.2rem;
  border-radius: 8px;

  button {
    border: none;
    background: none;
    color: $gray-color;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
.ProseMirror {
  /* white-space: pre-wrap !important; */
  white-space: normal !important;
  &:focus,
  &:active {
    outline: none;
  }
  > * + * {
    margin-top: 0.75em;
  }
  mark {
    color: inherit;
  }
  p {
    margin: 0;
  }
  a {
    color: #44c3ce;
    text-decoration: underline;
    &:hover {
      color: #68cef8;
      text-decoration: underline;
    }
  }
}
.editorSideBar {
  height: inherit;
  min-height: 25rem;
  left: -52px;

  position: absolute;
  .uploadPhotoIcon {
    cursor: pointer;
    position: sticky;
    top: 50px;
    z-index: 999;
  }
}

.ProseMirror p.is-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}

.floating-menu {
  margin-left: -65px;
}
</style>
