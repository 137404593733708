import axios from 'axios';

export default {
  userRegister(userData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/auth/register`, userData)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  userLogin(userData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/auth/login`, userData)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  bindAccount(userData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/auth/line/login`, userData)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  unbindAccount() {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/auth/line/unbind`)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  checkEmailRepeat(email) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/auth/check_email_repeat`, email)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  verifyEmail(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/auth/verify_email/${token}`)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  superLogin(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/super_login/${token}`)
        .then(resp => resolve(resp.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  async readCompanyID(id) {
    return await axios
      .get(`/api/company/${id}`)
      .then(resp => resp.data)
      .catch(err => console.log(err));
  },
  async checkToken(data) {
    return await axios
      .post(`/api/check-token`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  },
  async getGoogleLoginSDK(roleType) {
    return await axios
      .get(
        `/api/login/google?role=${roleType}&url=${process.env.VUE_APP_REDIRECT_DEFAULT_URL}/register/isForm3rdLogin`
      )
      .then(resp => resp.data)
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  }
};
