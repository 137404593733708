export default {
  object2FormData(object) {
    var form_data = new FormData();

    for (var key in object) {
      form_data.append(key, object[key]);
    }
    return form_data;
  },
  isSameWeek(inDate) {
    // inDate 是一个date对象
    let inDateStr = inDate.toLocaleDateString(); // 获取如YYYY/MM/DD的日期
    let nowDate = new Date();
    let nowTime = nowDate.getTime();
    let nowDay = nowDate.getDay();
    for (let i = 0; i < 7; i++) {
      if (
        inDateStr ==
        new Date(nowTime + (i - nowDay) * 24 * 3600 * 1000).toLocaleDateString()
      )
        return true;
    }
    return false;
  },
  formatDate(inputDate, showToday, type) {
    //type: "date" , "time"
    const now = new Date();
    const date = new Date(inputDate);

    // 检查是否是今天
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      // 今天只显示时间
      if (showToday) {
        return '今天';
      } else {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
    }

    // 检查是否是昨天
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return '昨天';
    }

    // 检查是否是本星期
    if (this.isSameWeek(date)) {
      const daysOfWeek = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ];
      return daysOfWeek[date.getDay()];
    }

    // 其他情况，显示完整日期
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    if (type == 'date') {
      return `${year}-${month}-${day} `;
    } else {
      return `${hours}:${seconds}`;
    }
  },
  formatDateWithZeroPadding(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
  /*
   *originalTime:'1:20:10'
   *offsetSeconds:1
   */
  // addSecondsToTime(originalTime, offsetSeconds) {
  //   // 解析時間字串
  //   let [hours, minutes, seconds] = originalTime.split(':').map(Number);

  //   // 計算新的時間
  //   seconds += offsetSeconds;

  //   // 處理進位
  //   if (seconds >= 60) {
  //     minutes += Math.floor(seconds / 60);
  //     seconds %= 60;
  //   }

  //   // 處理進位
  //   if (minutes >= 60) {
  //     hours += Math.floor(minutes / 60);
  //     minutes %= 60;
  //   }

  //   // 格式化新的時間
  //   let newTime = `${hours}:${String(minutes).padStart(2, '0')}:${String(
  //     seconds
  //   ).padStart(2, '0')}`;

  //   // 印出結果
  //   console.log('新的影片時間:', newTime);

  //   return { hours, minutes, seconds };
  // },
  addSecondsToTime(originalTime, offsetSeconds) {
    // 解析時間字串
    let [hours, minutes, seconds] = originalTime.split(':').map(Number);

    // 計算新的時間
    seconds += offsetSeconds;

    // 處理進位和借位
    if (seconds >= 60) {
      minutes += Math.floor(seconds / 60);
      seconds %= 60;
    } else if (seconds < 0) {
      let minutesBorrow = Math.ceil(-seconds / 60);
      minutes -= minutesBorrow;
      seconds += minutesBorrow * 60;
    }

    // 處理進位和借位
    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes %= 60;
    } else if (minutes < 0) {
      let hoursBorrow = Math.ceil(-minutes / 60);
      hours -= hoursBorrow;
      minutes += hoursBorrow * 60;
    }
    console.log('hours:', hours);
    // 處理進位和借位
    if (hours < 0) {
      hours = 0; // 避免負數時間
      minutes = 0;
      seconds = 0;
    }

    return { hours, minutes, seconds };
  },
  /*
   *originalTime:'02:50:10'
   *offsetSeconds:'01:10:25'
   */
  timeDifferenceInSeconds(time1, time2) {
    // 將時間轉換為秒
    var time1Array = time1.split(':');
    var time2Array = time2.split(':');

    console.log(time1Array, time2Array);
    var seconds1 =
      parseInt(time1Array[0]) * 3600 +
      parseInt(time1Array[1]) * 60 +
      parseInt(time1Array[2]);
    var seconds2 =
      parseInt(time2Array[0]) * 3600 +
      parseInt(time2Array[1]) * 60 +
      parseInt(time2Array[2]);

    // 計算時間差異（秒）
    console.log('計算時間差異（秒）:', seconds2 - seconds1);
    return seconds2 - seconds1;
  }
};
