<template>
  <section class="userArea tw-py-8 md:tw-pb-14 md:tw-pt-14 lg:tw-pt-0">
    <div class="tw-homeContainer">
      <div class="wrap">
        <div class="card" v-for="item in userData" :key="item">
          <div class="tw-flex tw-justify-center tw-items-center tw-gap-[6px]">
            <p
              class="tw-m-0 tw-text-[#161818] tw-text-[40px] tw-not-italic tw-leading-[normal] tw-tracking-[1.6px]"
            >
              {{ item.number }}
            </p>
            <div v-if="item.imgs" class="tw-flex">
              <div class="" v-for="(img, index) in item.imgs">
                <img
                  class="tw-w-[22px] tw-h-[auto]"
                  :key="index"
                  :src="img"
                  alt=""
                />
              </div>
            </div>
          </div>
          <p
            class="tw-m-0 tw-text-[rgba(22,24,24,0.90)] tw-text-center tw-text-base tw-not-italic tw-leading-6 tw-tracking-[1.28px]"
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue';
const userData = reactive([
  {
    number: '20萬+',
    title: '網紅資料庫'
  },
  {
    number: '1萬+',
    title: '品牌用戶'
  },
  {
    number: '5',
    title: '社群平台',
    imgs: [
      require('@/assets/icon/FB.svg'),
      require('@/assets/icon/IG.svg'),
      require('@/assets/icon/YT.svg'),
      require('@/assets/icon/tiktok.svg'),
      require('@/assets/icon/pixnet.svg')
    ]
  }
]);
</script>

<style lang="scss" scoped>
.wrap {
  @apply tw-flex tw-flex-col md:tw-flex-row  tw-gap-4 md:tw-gap-8 tw-justify-center tw-items-center;
  .card {
    @apply tw-bg-[linear-gradient(272deg,_#F9F9F9_-7.54%,_#F0FCFF_102.62%)] tw-rounded-full tw-w-[200px] tw-h-[200px]  tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-[4px];
  }
}
</style>
