import axios from 'axios';
import draftApi from '@/api/draft';
import utils from '@/class/Utils.js';
export default {
  namespace: true,
  state: {
    uuid: '',
    schedule: {
      build_mode: 1, //1: 業務媒合  2: 客戶自行邀約建立
      loading: false,
      diff: [],
      note: null,
      feebackNote: null,
      index: 0,
      status: 1, //0:待KOL提交時程  1:可編輯  2:只能顯示 3:雙方確認此時程
      isConfirm: 1, //0:修改 1:確認
      task_uuid: null,
      projectName: '',
      projectStatus: 0, //專案進度 0:創建 1:審核 2:進行 3:結案
      isEdit: false,
      hasNotification: false,
      canRecall: false,
      isShowDemoAlert: false,
      isShowWarmDemoAlert: false,
      freeLeft: 0,
      isFree: true,
      //創意大綱
      outline: {
        except_date: null,
        except_deadline: null
      },
      //腳本細節
      draft: {
        except_date: null,
        except_deadline: null
      },
      //影片製作
      video: {
        shoot_date: null,
        except_a_copy_date: null,
        review_a_copy_date: null,
        except_b_copy_date: null,
        review_b_copy_date: null
      },
      //文案撰寫
      copywriting: {
        except_date: null,
        except_deadline: null
      },
      //作品上線
      works_upload: {
        except_date: null
      }
    },
    draftScript: {
      scriptType: 0, //無樣式、1 一頁式、2 分頁式
      script: { items: [] },
      deleteScript: { items: [] },
      scriptDetail: {},
      updated_at: null,
      mutilPageData: { scripts: [], is_draft: 0 },
      mutilPageVersionList: [],
      onePageData: {
        task_uuid: '',
        is_draft: 0,
        description: '',
        compare_content: ''
      },
      onePageVersionList: []
    },
    taskList: [],
    showDeleteDialog: false,
    removeProjectTaskid: ''
  },
  getters: {},
  mutations: {
    setDrafts(state, data) {
      state.draftScript.script_type = data.script_type;
      state.draftScript.message_count = data.message_count[1];
      state.draftScript.updated_at = data.updated_at;

      state.draftScript.script.items = [];
      for (const key in data.items) {
        state.draftScript.script.items.push(data.items[key]);
      }
    },
    setDraftsDetail(state, data) {
      state.draftScript.scriptDetail = data;
    },
    setDeleteDrafts(state, data) {
      state.draftScript.deleteScript.items = [];
      for (const key in data.items) {
        state.draftScript.deleteScript.items.push(data.items[key]);
      }
    }
  },
  actions: {
    async getDraftsVerstionList({ commit, state }, task_id) {
      let resDraft = await draftApi.getDraftsVersionList(task_id);
      console.log('state:', state);

      return new Promise((resolve, reject) => {
        try {
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
    async getDrafts({ commit }, task_id) {
      let resDraft = await draftApi.getDrafts(task_id);
      commit('setDrafts', resDraft);
      let resDeleteDraft = await draftApi.getDeleteDrafts(task_id);
      commit('setDeleteDrafts', resDeleteDraft);
      return new Promise((resolve, reject) => {
        try {
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },

    async getVersionDetail({ commit }, { task_id, script_uuid }) {
      try {
        let resDraft = await draftApi.getVersionDetail(task_id, script_uuid);
        commit('setDraftsDetail', resDraft);
      } catch (error) {
        console.log(error);
      }
    },
    async saveDraftScript({ commit }, data) {
      return new Promise((resolve, reject) => {
        draftApi
          .updateDraftDetail(
            data.task_uuid,
            data.script_uuid,
            utils.object2FormData(data.data)
          )
          .then(res => {
            resolve(res);
            console.log(res);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            console.log('saveDraftScript api completed');
          });
      });
    },
    async saveScript({ commit }, data) {
      return new Promise((resolve, reject) => {
        draftApi
          .storeDraft(utils.object2FormData(data))
          .then(res => {
            resolve(res);
            console.log(res);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            console.log('saveScript api completed');
          });
      });
    },
    async deleteAllScript({ commit }, data) {
      let promise = [];

      for (let index = 0; index < data.script_id.length; index++) {
        promise.push(
          draftApi.deleteScript({
            task_uuid: data.task_id,
            delete_script_uuid: data.script_id[index]
          })
        );
      }

      return new Promise((resolve, reject) => {
        Promise.all(promise)
          .then(res => {
            resolve(res);
            console.log(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
