<template>
  <button
    @click="editor.chain().focus().setImage({ size: size.size }).run()"
    :class="{
      'is-active': editor.isActive('custom-image', {
        size: size.size
      })
    }"
  >
    <img
      :src="require('@/assets/icon/editor/' + size.img + '.png')"
      width="24"
      height="24"
    />
  </button>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default: () => {}
    },
    size: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup(props, { emit }) {
    const { editor, size } = props;
    return {
      size,
      editor
    };
  }
};
</script>
<style lang="scss"></style>
