import axios from 'axios';

export default {
  getNotifications(page, perPage = 10, invitation) {
    return axios
      .get(
        `/api/notifications?page=${page}&per_page=${perPage}&invitation=${
          invitation ? invitation : 0
        }`
      )
      .then(resp => resp.data)
      .catch(err => console.log(err));
  },
  rejectInvite(project_build_invitation_uuid, notification_uuid) {
    let form = new FormData();
    form.append('notification_uuid', notification_uuid);
    return axios
      .post(
        `/api/project/build-invitation/${project_build_invitation_uuid}/reject`,
        form
      )
      .then(resp => resp.data)
      .catch(err => console.log(err));
  },

  readNotification(notificationUuid) {
    return axios
      .patch(`/api/notifications/${notificationUuid}`)
      .then(resp => resp)
      .catch(err => err);
  },
  readNotifications() {
    return axios
      .patch(`/api/notifications`)
      .then(resp => resp)
      .catch(err => err);
  }
};
