<template>
  <section class="partnerArea tw-py-8 md:tw-py-14">
    <h2
      class="tw-text-[#161818] tw-text-[32px] tw-leading-[normal] tw-tracking-[1.28px] tw-mb-8 tw-py-2"
    >
      成功品牌客戶
    </h2>

    <swiper
      :slides-per-view="'auto'"
      :space-between="22"
      :centered-slides="true"
      :speed="3000"
      :autoplay="{ delay: 0 }"
      :loop="true"
      :modules="modules"
      :disableOnInteraction="true"
      :allowTouchMove="false"
    >
      <swiper-slide
        v-for="i in 29"
        :key="i"
        :class="{
          'land-bg-pink': i == 25,
          'land-bg-orange': i == 3
        }"
        ><img
          :src="require(`@/assets/landing_page/logo/2023/band_${i}.png`)"
          alt="logo"
      /></swiper-slide>
    </swiper>
  </section>
</template>
<script setup>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
const modules = [Autoplay];
</script>
<style lang="scss" scoped>
:deep .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper-slide {
  width: 176px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #e9e9e9;
}

.land-bg-orange {
  background-color: #ec6c00;
}

.land-bg-pink {
  background-color: #c7a3a5;
}
</style>
