import utils from '@/class/Utils.js';
import axios from 'axios';

export default {
  namespace: true,
  state: {
    upload_status: 1,
    reSend: false, //api是否使用 patch /video-feedback
    addingVideoStatus: false, //選取檔案後為 true 使用上傳檔案方式
    urlStatus: false, //填寫 youtube 連結方式
    videoUrlReback: '', //取消時回到youtube的url
    file: null,
    showSend: false, //是否顯示送出按鈕
    isUpload: false, //是否顯示上傳中..... 上傳檔案時使用
    isMaking: false, //檔案後端上傳youtube中 一開始的狀態
    uploadTime: 1, //上傳檔案預估時間
    loadPercentage: 0, //上傳檔案百分比
    uploadCancel: false, //api 中斷
    isLoadDetail: false, //是否載入detail資料
    isDeleteDisable: true,
    currentIndex: 0
  },
  getters: {},
  mutations: {},
  actions: {
    async chunkUploadVideo({ commit, rootState }, data) {
      console.log('dispatch/chunkUploadVideo');
      console.log('rootState:', rootState);
      console.log('file:', data.file);
      const chunkSize = 1024 * 1024 * 5; //5MB
      const totalChunks = Math.ceil(data.file.size / chunkSize);

      const uploadChunk = async function (chunkIndex) {
        return new Promise((resolve, reject) => {
          if (rootState.video.uploadCancel) {
            reject();
            return;
          }
          const start = chunkIndex * chunkSize;
          const end = Math.min(start + chunkSize, data.file.size);
          const chunk = data.file.slice(start, end);

          const formData = new FormData();

          if (data.file != null) {
            formData.append('task_uuid', data.data.task_uuid);
            formData.append('chunk', chunkIndex);
            formData.append('chunks', totalChunks);
            formData.append('file', new File([chunk], data.file.name));
            formData.append('feedback', JSON.stringify(data.data.feedback));
          } else {
            formData.append('task_uuid', data.data.task_uuid);
            formData.append('url', data.data.url);
            formData.append('feedback', data.data.feedback);
          }

          // for (const value of formData.values()) {
          //   console.log(value);
          // }

          axios
            .post('/api/video-feedback', formData)
            .then(function (data) {
              if (chunkIndex + 1 == totalChunks) {
                console.log('done:', data);
                rootState.video.loadPercentage = 100;
                rootState.video.addingVideoStatus = false;
                rootState.video.file = null;
                rootState.video.showSend = true;
                rootState.video.urlStatus = true;
                rootState.video.isUpload = false;
                rootState.video.isMaking = true;
                rootState.video.loadPercentage = 0;
              } else {
                console.log(data.data.done, '%');
                rootState.video.loadPercentage = data.data.done;
              }
              console.log('data:', data);

              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        });
      };
      let isError = false;

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        try {
          await uploadChunk(chunkIndex);
        } catch (error) {
          console.log('error:', error);
          isError = true;
        }
      }

      return new Promise((resolve, reject) => {
        if (isError) {
          reject();
        } else {
          resolve();
        }
      });
    },
    async reChunkUploadVideo({ commit, rootState }, data) {
      console.log('dispatch/chunkUploadVideo');
      console.log('rootState:', rootState);
      console.log('file:', data.file);
      const chunkSize = 1024 * 1024 * 5; //5MB
      const totalChunks = Math.ceil(data.file.size / chunkSize);

      const uploadChunk = async function (chunkIndex) {
        return new Promise((resolve, reject) => {
          if (rootState.video.uploadCancel) {
            reject();
            return;
          }
          const start = chunkIndex * chunkSize;
          const end = Math.min(start + chunkSize, data.file.size);
          const chunk = data.file.slice(start, end);

          const formData = new FormData();
          formData.append('_method', 'PATCH');
          console.log('data.file:', data.file);
          if (data.file != null) {
            formData.append('task_uuid', data.data.task_uuid);
            formData.append('video_uuid', data.data.video_uuid);
            formData.append('chunk', chunkIndex);
            formData.append('chunks', totalChunks);
            formData.append('file', new File([chunk], data.file.name));
          } else {
            formData.append('task_uuid', data.data.task_uuid);
            formData.append('url', data.data.url);
            formData.append('feedback', data.data.feedback);
          }

          axios
            .post('/api/video-feedback', formData)
            .then(function (data) {
              if (chunkIndex + 1 == totalChunks) {
                console.log('done:', data);
                rootState.video.loadPercentage = 100;
                rootState.video.addingVideoStatus = false;
                rootState.video.file = null;
                rootState.video.showSend = true;
                rootState.video.urlStatus = true;
                rootState.video.isUpload = false;
                rootState.video.isMaking = true;
                rootState.video.loadPercentage = 0;
              } else {
                console.log(data.data.done, '%');
                rootState.video.loadPercentage = data.data.done;
              }
              console.log('data:', data);

              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        });
      };
      let isError = false;

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        try {
          await uploadChunk(chunkIndex);
        } catch (error) {
          console.log('error:', error);
          isError = true;
        }
      }

      return new Promise((resolve, reject) => {
        if (isError) {
          reject();
        } else {
          resolve();
        }
      });
    }
  }
};
