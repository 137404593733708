<template>
  <button uk-tooltip="title: 移除; pos: top" @click="removeMark">
    <img
      :src="require('@/assets/icon/editor/eraser.svg')"
      width="24"
      height="24"
    />
  </button>
</template>
<script>
export default {
  name: 'RemoveMark',
  props: ['editor'],
  setup(props, { emit }) {
    const removeMark = () => {
      props.editor
        .chain()
        .focus()
        .unsetAllMarks() //清樣式
        .clearNodes() //清tag 標題等
        .run();

      props.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    };
    return { removeMark };
  }
};
</script>
<style lang="scss" scoped></style>
