<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" :options="options">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <div
          ref="VOnboardingStepwrapper"
          class="tw-bg-white tw-rounded-xl tw-relative tw-w-[100%] sm:tw-w-[350px]"
        >
          <div class="tw-px-[30px] tw-pt-16 tw-pb-6">
            <p
              class="tw-text-gray-600 tw-text-xs tw-absolute tw-top-6 tw-inset-x-0"
            >
              {{ `${index + 1}/${steps.length}` }}
            </p>
            <div v-if="step.content">
              <p
                v-if="step.content.title"
                class="tw-text-2xl tw-text-[#616161] tw-tracking-[2.88px]"
              >
                {{ step.content.title }}
              </p>
              <div
                v-if="step.content.description"
                class="tw-mt-4 tw-text-[#616161] tw-text-base tw-leading-6 tw-tracking-[2.48px]"
              >
                <p>{{ step.content.description }}</p>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between">
              <template v-if="!isFirst">
                <button
                  @click="
                    previous();
                    trackStep('previous', index);
                  "
                  type="button"
                  class="tw-text-[#44C3CE] tw-text-sm"
                >
                  上一步
                </button>
              </template>
              <button
                @click="
                  next();
                  trackStep('next', index);
                "
                type="button"
                class="tw-text-[#44C3CE] tw-text-sm tw-ml-auto"
              >
                {{ isLast ? '完成' : '繼續' }}
              </button>
            </div>
            <button
              type="button"
              @click="
                finish();
                trackStep('', steps.length);
              "
              class="tw-absolute tw-right-4 tw-top-4"
            >
              <span
                class="icon-error tw-text-[#cacaca] tw-opacity-60 tw-text-[30px]"
              ></span>
            </button>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script>
import { ref, onUnmounted, watch } from 'vue';
import {
  VOnboardingWrapper,
  VOnboardingStep,
  useVOnboarding
} from 'v-onboarding';
import 'v-onboarding/dist/style.css';
import { useIntersectionObserver } from '@vueuse/core';
import { useStore } from 'vuex';
export default {
  components: {
    VOnboardingWrapper,
    VOnboardingStep
  },
  props: ['steps'],
  setup(props, { emit }) {
    const store = useStore();
    const wrapper = ref(null);
    const VOnboardingStepwrapper = ref(null);
    const { start, goToStep, finish } = useVOnboarding(wrapper);
    store.commit('SET_goToStep', goToStep);
    store.commit('SET_finish', finish);
    const options = {
      popper: {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom', 'right', 'left']
            }
          },
          {
            name: 'offset',
            options: {
              offset: [0, 20]
            }
          }
        ]
      }
    };

    const trackStep = (method, index) => {
      let currentStep;
      if (method === 'next') {
        currentStep = Number(index + 1);
      } else if (method === 'previous') {
        currentStep = Number(index - 1);
      } else {
        currentStep = Number(index);
      }

      emit('trackStep', currentStep);
    };

    const stepwrapperVisible = ref(false);
    const { stop } = useIntersectionObserver(
      VOnboardingStepwrapper,
      ([{ isIntersecting }], observerElement) => {
        stepwrapperVisible.value = isIntersecting;
      },
      {
        threshold: 1
      }
    );

    watch(
      [VOnboardingStepwrapper, stepwrapperVisible],
      ([newTarget, newVisible], [oldTarget, oldVisible]) => {
        try {
          if (newTarget && stepwrapperVisible.value) {
            setTimeout(() => {
              document.querySelector('html').style.overflowY = 'hidden';
              document.querySelector('html').style.paddingRight = '12px';
            }, 0);
          } else {
            setTimeout(() => {
              document.querySelector('html').removeAttribute('style');
            }, 0);
          }
        } catch (error) {
          console.error(error);
        }
      }
    );

    onUnmounted(() => {
      stop();
    });

    return {
      wrapper,
      VOnboardingStepwrapper,
      finish,
      goToStep,
      options,
      trackStep,
      stepwrapperVisible
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-error {
  font-family: 'icomoon' !important;
}
</style>
<style>
[data-v-onboarding-wrapper]
  [data-popper-placement^='left']
  > [data-popper-arrow] {
  right: 5px;
}

[data-v-onboarding-wrapper] svg {
  pointer-events: auto !important;
}
</style>
