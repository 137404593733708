<template>
  <form @submit.prevent autocomplete="on" class="uk-position-relative infoForm">
    <div class="mb-6" v-for="(item, index) in linkData" :key="item.id">
      <div class="uk-form-controls uk-position-relative">
        <input
          class="uk-input uk-border-rounded"
          type="text"
          :class="[item.errorMessage != null ? 'uk-form-danger' : '']"
          :id="`${item.id}`"
          placeholder="&nbsp;"
          v-model.trim="registerData[item.name]"
          @keyup="urlValidate($event, index)"
        />
        <label class="uk-form-label uk-position-absolute" :for="`${item.id}`">{{
          item.titleName
        }}</label>
        <small
          class="uk-text-danger uk-float-right"
          v-if="item.errorMessage != null"
          >{{ item.errorMessage }}</small
        >
      </div>
    </div>

    <div class="mb-6">
      <div class="uk-form-controls uk-position-relative">
        <TextareaAutosize
          class="uk-input uk-border-rounded"
          id="cooperation_fee"
          :min-height="48"
          placeholder="&ensp;"
          :value="registerData.cooperation_fee"
          v-model="registerData.cooperation_fee"
        >
        </TextareaAutosize>

        <label class="uk-form-label uk-position-absolute" for="cooperation_fee"
          >合作費用</label
        >
      </div>
    </div>

    <div class="mt-12">
      <div class="uk-form-controls uk-flex uk-flex-center">
        <button
          v-if="status.step3 === false"
          class="uk-button confirmBtn"
          disabled
        >
          註冊
        </button>
        <div
          v-if="status.step3 === true"
          id="register"
          class="registerBtn m-btn-rounded_brand_white"
          :class="{ disable: showLoading }"
          @click.once="register"
          :data-role="creator"
        >
          <span v-if="showLoading" uk-spinner="ratio: 1"></span>

          <span v-if="!showLoading">註冊</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import VALIDATE from '@/lib/validate';
import TextareaAutosize from '@/components/TextareaAutosize.vue';
export default {
  name: '',
  props: ['status', 'showLoading'],
  emits: ['emitLinkData', 'emitRegister'],
  components: { TextareaAutosize },
  setup(props, { emit }) {
    const registerData = reactive({
      youtube: '',
      facebook: '',
      instagram: '',
      other_link: '',
      cooperation_fee: ''
    });

    const linkData = reactive([
      {
        titleName: 'Facebook平台',
        id: 'facebookLink',
        name: 'facebook',
        errorMessage: null
      },
      {
        titleName: 'Instagram平台',
        id: 'instagramLink',
        name: 'instagram',
        errorMessage: null
      },
      {
        titleName: 'Youtube平台',
        id: 'youtubeLink',
        name: 'youtube',
        errorMessage: null
      },
      {
        titleName: '其他平台',
        id: 'otherLink',
        name: 'other_link',
        errorMessage: null
      }
    ]);

    //url驗證
    function urlValidate(event, index) {
      linkData[index].errorMessage = VALIDATE.checkCorrectUrl(event);
    }

    watch(
      [registerData, linkData],
      () => {
        emit('emitLinkData', registerData, linkData);
      },
      {
        immediate: true
      }
    );

    const register = async () => {
      emit('emitRegister', registerData);
    };

    return {
      registerData,
      linkData,
      urlValidate,
      register
    };
  }
};
</script>

<style lang="scss" scoped></style>
