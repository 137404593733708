<template>
  <div class="tw-flex tw-justify-between tw-items-center mt-4">
    <UploadImg @uploadPhoto="emitUploadPhoto" />
    <div class="tw-flex">
      <button
        type="button"
        class="m-btn-comment_white mr-2"
        @click="emitCancel"
      >
        取消
      </button>
      <SendMsgBtn
        :loading="loading"
        @emitSendMsg="emitSendMsg"
        :disabled="isSendMsgDisabled"
      >
        <slot></slot
      ></SendMsgBtn>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import SendMsgBtn from './SendMsgBtn.vue';
import UploadImg from './UploadImg.vue';

export default {
  name: 'ActionButtonGroup',
  components: {
    SendMsgBtn,
    UploadImg
  },
  props: ['loading', 'isSendMsgDisabled'],
  emits: ['cancel', 'sendMsg', 'uploadPhoto'],
  setup(props, { emit }) {
    const emitCancel = () => {
      emit('cancel');
    };

    const emitSendMsg = () => {
      emit('sendMsg');
    };

    const emitUploadPhoto = e => {
      emit('uploadPhoto', e);
    };

    return { emitCancel, emitSendMsg, emitUploadPhoto };
  }
};
</script>
<style lang="scss" scoped></style>
