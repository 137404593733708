<template>
  <div
    class="ctaBtn"
    @click="isLoggedIn ? toProject() : openModal('RegisterModal')"
  >
    立即體驗
    <img
      :src="require(`@/assets/icon/arrow-right.svg`)"
      alt="立即體驗"
      width="24"
      height="24"
    />
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, inject } from 'vue';

export default {
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const openModal = inject('openModal');
    const toProject = inject('toProject');
    return { isLoggedIn, openModal, toProject };
  }
};
</script>
<style lang="scss" scoped>
.ctaBtn {
  // width: 200px;
  // height: 66px;
  padding: 16px 36px;
  background: rgba(249, 249, 249, 0.3);
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.12em;
  cursor: pointer;
  position: relative;
  animation: scaleSmall 2s infinite ease;
  color: white;

  @media (min-width: 576px) {
    width: 205px;
  }

  img {
    margin-left: 0.25rem;
  }
  &:hover {
    background: rgba(249, 249, 249, 0.5);
  }
  &::after {
    content: '';
    border: solid 1px rgba(249, 249, 249, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 44px;
    position: absolute;
    left: 0;
    animation: scaleBig 2s infinite ease;
  }
  @keyframes scaleBig {
    from {
      opacity: 1;

      transform: scale(1);
    }
    to {
      opacity: 0;

      transform: scale(1.3);
    }
  }
  @keyframes scaleSmall {
    0% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.95);
    }
  }
}
</style>
