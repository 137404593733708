<template>
  <section class="quickRegisterArea">
    <div class="tw-homeContainer">
      <div class="wrap">
        <div class="content">
          <p
            class="tw-text-[#161818] tw-text-[32px] tw-leading-[normal] tw-tracking-[1.28px] tw-text-left tw-mb-8"
          >
            立即使用網紅行銷界的Trello<br />開啟更好的合作！
          </p>
          <div class="tw-flex tw-flex-col md:tw-flex-row">
            <button
              type="button"
              class="adp-button adp-button-outline adp-button-round tw-mb-4 md:tw-mr-8 md:tw-mb-0"
              @click="toContactUs"
            >
              專人諮詢
            </button>

            <button
              type="button"
              class="adp-button adp-button-round"
              @click="isLoggedIn ? toProject() : openModal('RegisterModal')"
            >
              立即體驗
            </button>
          </div>
        </div>

        <div class="img">
          <img
            loading="lazy"
            :src="require(`@/assets/landing_page/quick/2023/quickGroup.png`)"
            alt="品牌主"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, inject } from 'vue';
import CtaButton from '@/components/landing_page/CtaButton.vue';
import { useRouter } from 'vue-router';
const store = useStore();
const router = useRouter();
const toContactUs = () => {
  router.push(`/contact-us`);
};
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const openModal = inject('openModal');
const toProject = inject('toProject');
</script>
<style lang="scss" scoped>
.quickRegisterArea {
  @apply tw-pt-4 tw-pb-12 md:tw-pt-8 md:tw-pb-16;
}
.wrap {
  @apply tw-shadow-[4px_4px_20px_0px_rgba(0,0,0,0.12)] tw-rounded-[20px] tw-bg-white tw-flex tw-flex-col md:tw-flex-row tw-py-10 lg:tw-py-0 tw-px-12 tw-justify-between tw-items-center tw-gap-4 tw-flex-[1_0_0] tw-text-left;
}
</style>
