import axios from 'axios';
import authApi from '@/api/auth';
export default {
  namespace: true,
  state: {
    loading: false,
    status: false,
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || '',
    socket: localStorage.getItem('socketConnect') || true,
    openSignStep: false,
    roleType: 'vendor',
    FB: null
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userData: state => state.user,
    isLoading: state => state.loading,
    isFraud: state => state.fraud,
    socketStatus: state => state.socket
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, data) {
      state.status = 'success';
      state.token = data.token;
      state.user = data.user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
    loading_status(state, status) {
      state.loading = status;
    },
    socketConnect(state, status) {
      state.socket = status;
      localStorage.setItem('socketConnect', status);
    },
    setOpenSignStep(state, status) {
      state.openSignStep = status;
    },
    setRoleType(state, status) {
      state.roleType = status;
    },
    updateUserData(state, newData) {
      state.user = newData;
      localStorage.setItem('user', JSON.stringify(newData));
    },
    setFB(state, payload) {
      state.FB = payload;
    },
    selectedRoleType(state, type) {
      state.roleType = type;
      state.openSignStep = true;
    }
  },
  actions: {
    // 註冊
    async register({ commit, dispatch }, user) {
      try {
        commit('auth_request');
        const resp = await authApi.userRegister(user);
        commit('updateUserData', user);
        if (resp.data?.token) {
          await dispatch('setUserInfo', resp.data);
        }
        return resp;
      } catch (err) {
        const error = new Error();
        error.response = err.response;
        throw error;
      }
    },
    // 設定登入者資訊
    setUserInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        try {
          commit('socketConnect', true);
          const { token, user } = data;
          localStorage.setItem('token', token);
          commit('updateUserData', user);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          commit('auth_success', data);
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
    // 登入
    async login({ commit, dispatch }, user) {
      try {
        commit('auth_request');
        const resp = await authApi.userLogin(user);
        await dispatch('setUserInfo', resp.data);
      } catch (err) {
        dispatch('loginFail');
        throw {
          status: err.response.status,
          code: err.response.data.status,
          data: err.response.data.data
        };
      }
    },

    // 驗證成功自動登入
    async autoLogin({ commit, dispatch }, token) {
      try {
        commit('auth_request');
        const res = await authApi.verifyEmail(token);
        await dispatch('setUserInfo', res.data);
      } catch (err) {
        dispatch('loginFail', err);
      }
    },
    //後門登入
    async hackLogin({ commit, dispatch }, token) {
      try {
        commit('auth_request');
        const res = await authApi.superLogin(token);
        await dispatch('logout');
        await dispatch('setUserInfo', res.data);
      } catch (err) {
        dispatch('loginFail', err);
      }
    },
    // 登入失敗處理
    loginFail({ commit }, err) {
      commit('auth_error');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      if (err) throw new Error(err);
    },
    // 登出
    logout({ commit }) {
      return new Promise(resolve => {
        commit('logout');
        commit('socketConnect', false);
        const firstEntry = localStorage.getItem('firstEntry');
        const socketConnect = localStorage.getItem('socketConnect');
        localStorage.clear();
        localStorage.setItem('isFraud', true);
        if (firstEntry) localStorage.setItem('firstEntry', true);
        localStorage.setItem('socketConnect', socketConnect);
        sessionStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    //set loading
    loading({ commit }, status) {
      commit('loading_status', status);
    }
  }
};
