import axios from 'axios';

export default {
  /*取得創意大綱所有留言*/
  getMessagesFromOutline(taskId) {
    return axios
      .get(`/api/outline_message/${taskId}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*建立創意大綱留言*/
  sendMessageFromOutline(data) {
    return axios
      .post('/api/outline_message', data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*更新創意大綱留言處理狀態*/
  updateMessagesProcessedFromOutline(task_uuid, message_id, status) {
    return axios
      .patch(
        `/api/outline_message/${task_uuid}/${message_id}/processed/${status}`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯創意大綱留言*/
  editMessageFromOutline(task_uuid, message_id, data) {
    return axios
      .patch(`/api/outline_message/${task_uuid}/${message_id}/edit`, data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*刪除創意大綱留言*/
  delMessageFromOutline(task_uuid, message_id) {
    return axios
      .delete(`/api/outline_message/${task_uuid}/${message_id}/delete`)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*取得創意大綱留言回覆*/
  getResponseFromOutline(task_uuid, message_id) {
    return axios
      .get(`/api/outline_message/${task_uuid}/${message_id}/getResponse`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*取得文案撰寫所有留言*/
  getMessagesFromCopyWriting(task_uuid, platformId) {
    return axios
      .get(`/api/copy-writing-message/${task_uuid}/${platformId}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*建立文案撰寫留言*/
  sendMessageFromCopyWriting(data) {
    return axios
      .post('/api/copy-writing-message', data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*更新文案撰寫留言處理狀態*/
  updateMessagesProcessedFromCopyWriting(
    task_uuid,
    platformId,
    message_id,
    status
  ) {
    return axios
      .patch(
        `/api/copy-writing-message/${task_uuid}/${platformId}/${message_id}/processed/${status}`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯文案撰寫留言*/
  editMessageFromCopyWriting(task_uuid, platformId, message_id, data) {
    return axios
      .patch(
        `/api/copy-writing-message/${task_uuid}/${platformId}/${message_id}/edit`,
        data
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*刪除文案撰寫留言*/
  delMessageFromCopyWriting(task_uuid, platformId, message_id) {
    return axios
      .delete(
        `/api/copy-writing-message/${task_uuid}/${platformId}/${message_id}/delete`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*取得文案撰寫留言回覆*/
  getResponseFromCopyWriting(task_uuid, platformId, message_id) {
    return axios
      .get(
        `/api/copy-writing-message/${task_uuid}/${platformId}/${message_id}/getResponse`
      )
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*取得腳本細節所有留言*/
  getMessagesFromDraftDetails(task_uuid, scriptId, name) {
    ///api/draft-script-messages/{{task_uuid}}?parent_id=790
    return axios
      .get(`/api/draft-script/get-script-messages/${task_uuid}/${scriptId}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*建立腳本細節留言*/
  sendMessageFromDraftDetails(data) {
    return axios
      .post('/api/draft-script/message/store', data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*更新腳本細節留言處理狀態*/
  updateMessagesProcessedFromDraftDetails(
    task_uuid,
    script_uuid,
    message_id,
    status
  ) {
    return axios
      .patch(
        `/api/draft-script/get-script-messages/${task_uuid}/${script_uuid}/${message_id}/processed/${status}`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯腳本細節留言*/
  editMessageFromDraftDetails(task_uuid, script_uuid, message_id, data) {
    return axios
      .patch(
        `/api/draft-script/get-script-messages/${task_uuid}/${script_uuid}/${message_id}/edit`,
        data
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*刪除腳本細節留言*/
  delMessageFromDraftDetails(task_uuid, script_uuid, message_id) {
    return axios
      .delete(
        `/api/draft-script/get-script-messages/${task_uuid}/${script_uuid}/${message_id}/delete`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },

  /*取得腳本細節留言回覆*/
  getResponseFromDraftDetails(task_uuid, script_uuid, message_id) {
    return axios
      .get(
        `/api/draft-script/get-script-messages/${task_uuid}/${script_uuid}/${message_id}/getResponse`
      )
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*取得需求單所有留言*/
  getMessagesFromBriefB(task_uuid) {
    return axios
      .get(`/api/brief-message/${task_uuid}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },

  /*------------------------------------------------------------------------------
  // 腳本v3
  -------------------------------------------------------------------------------*/

  /*取得腳本細節留言*/
  getMessagesFromDraft(taskId) {
    return axios
      .get(`/api/draft-script-messages/${taskId}`)
      .then(resp => resp)
      .catch(err => err);
  },

  /*建立腳本細節留言*/
  sendMessageFromDraft(data) {
    return axios
      .post('/api/draft-script-messages', data)
      .then(resp => resp)
      .catch(err => err);
  },
  /*更新腳本細節留言處理狀態*/
  updateMessagesProcessedFromDraft(task_uuid, message_id) {
    return axios
      .patch(
        `/api/draft-script-messages/${task_uuid}/${message_id}/process-toggle`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯腳本細節留言*/
  editMessageFromDraft(task_uuid, message_id, data) {
    return axios
      .patch(`/api/draft-script-messages/${task_uuid}/${message_id}`, data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },

  /*刪除腳本細節留言*/
  delMessageFromDraft(task_uuid, message_id) {
    return axios
      .delete(`/api/draft-script-messages/${task_uuid}/${message_id}`)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*取得腳本細節留言回覆*/
  getResponseFromDraft(task_uuid, message_id) {
    return axios
      .get(`/api/draft-script-messages/${task_uuid}?parent_id=${message_id}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },

  /*------------------------------------------------------------------------------
  //
  -------------------------------------------------------------------------------*/

  /*建立需求單留言*/
  sendMessageFromBriefB(data) {
    return axios
      .post('/api/brief-message', data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*更新需求單留言處理狀態*/
  updateMessagesProcessedFromBriefB(task_uuid, message_id, status) {
    return axios
      .patch(
        `/api/brief-message/${task_uuid}/${message_id}/processed/${status}`
      )
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯需求單留言*/
  editMessageFromBriefB(task_uuid, message_id, data) {
    return axios
      .patch(`/api/brief-message/${task_uuid}/${message_id}/edit`, data)
      .then(resp => resp)
      .catch(err => {
        console.log(err.response);
        throw err;
      });
  },
  /*刪除需求單留言*/
  delMessageFromBriefB(task_uuid, message_id) {
    return axios
      .delete(`/api/brief-message/${task_uuid}/${message_id}/delete`)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*取得需求單留言回覆*/
  getResponseFromBriefB(task_uuid, message_id) {
    return axios
      .get(`/api/brief-message/${task_uuid}/${message_id}/getResponse`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*取得影片製作所有留言*/
  getMessagesFromVideo(taskId) {
    return axios
      .get(`/api/video-messages/${taskId}`)
      .then(resp => resp)
      .catch(err => err);
  },
  /*建立影片製作留言*/
  sendMessageFromVideo(data) {
    return axios
      .post('/api/video-messages', data)
      .then(resp => resp)
      .catch(err => err);
  },
  /*更新影片製作留言處理狀態*/
  updateMessagesProcessedFromVideo(task_uuid, message_id) {
    return axios
      .patch(`/api/video-messages/${task_uuid}/${message_id}/process-toggle`)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*編輯影片製作留言*/
  editMessageFromVideo(task_uuid, message_id, data) {
    return axios
      .patch(`/api/video-messages/${task_uuid}/${message_id}`, data)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*刪除影片製作留言回覆*/
  delMessageFromVideo(task_uuid, message_id) {
    return axios
      .delete(`/api/video-messages/${task_uuid}/${message_id}`)
      .then(resp => resp)
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  /*取得影片製作留言回覆*/
  getResponseFromVideo(task_uuid, message_id) {
    return axios
      .get(`/api/video-messages/${task_uuid}?parent_id=${message_id}`)
      .then(resp => resp)
      .catch(err => console.log(err));
  },
  /*留言取得圖片連結*/
  uploadImage(taskId, file, config) {
    let form = new FormData();
    form.append('task_uuid', taskId);
    form.append('file', file);

    return axios
      .post('/api/message_boards/image', form, config)
      .then(resp => resp.data.data)
      .catch(err => {
        throw new Error(err);
      });
  }
};
