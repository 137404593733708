<template>
  <div :class="isShowEditBox ? 't-shadow_medium p-4' : ''">
    <div class="uk-flex uk-flex-top mb-4">
      <div class="headShot mr-3">
        <Avatar
          class="avatar"
          size="36"
          :img="
            msgItem.avatar
              ? `${baseUrl}/api/editor/image/${msgItem.avatar}`
              : null
          "
          :name="msgItem?.company_name ?? msgItem.created_by"
        />
      </div>
      <div class="nameArea">
        <div class="user">
          <span class="name">{{
            msgItem?.company_name ?? msgItem.created_by
          }}</span>
        </div>
        <div class="dateItem">
          <span class="text-hint">
            {{ dateTransferLocalTimeZone(msgItem.created_at) }}</span
          >
          <span class="text-hint" v-if="msgItem.is_updated"> 已更新</span>
        </div>
      </div>

      <div
        class="editorBtnArea uk-position-relative"
        v-if="msgItem.poster_uuid == user.uuid && !isShowEditBox"
      >
        <EditList
          @delMsg="delMsg(msgItem.message_id)"
          @editMsg="editMsg(msgItem.message)"
        />
        <slot name="checkbtn"></slot>
      </div>
    </div>
    <div class="contentArea">
      <div
        class="quotation tw-cursor-pointer"
        v-if="msgItem?.quote && msgItem?.quote !== JSON.stringify('')"
        @click.stop="scrollAnchorPosition(msgItem.quote)"
      >
        <p>
          {{ JSON.parse(msgItem.quote).selectedText }}
        </p>
      </div>

      <div class="content mt-4">
        <Editor
          v-if="isShowEditBox"
          @setEditorData="setEditorData"
          :editorData="cacheMessage"
          :id="`msgChat`"
          :editable="true"
          placeholder="請留下留言...."
          :defaultFocused="true"
          ref="editor"
        />

        <vue-ellipsis-3
          v-else
          :text="msgItem.message"
          :visible-line="4"
          useInnerHtml
          :ellipsis="ellipsis"
        >
          <template v-slot:ellipsisNode>
            <button
              type="button"
              class="text-color_primary mt-2 text-xs"
              @click="expand()"
            >
              ...顯示更多
            </button>
          </template>
        </vue-ellipsis-3>
        <!-- <p v-html="msgItem.message"></p> -->
      </div>
    </div>
    <PhotoArea v-if="photoList.data?.length > 0" />
    <ActionButtonGroup
      v-if="isShowEditBox"
      :loading="loading"
      :isSendMsgDisabled="
        cacheMessage.trim() == '' ||
        cacheMessage == '<p></p>' ||
        (cacheMessage == msgItem.message &&
          JSON.stringify(photoList.data) === JSON.stringify(msgItem.images)) ||
        editorIsEmpty(cacheMessage)
      "
      @uploadPhoto="uploadPhoto"
      @cancel="closeEditBox"
      @sendMsg="sendMsg"
    >
      儲存
    </ActionButtonGroup>

    <div
      v-else
      class="replyBtnArea uk-flex mt-6"
      :class="[
        msgItem?.response_count > 0 ? 'uk-flex-between' : 'uk-flex-right'
      ]"
    >
      <slot name="responseCount"></slot>
      <button
        type="button"
        class="text-color_primary text-s"
        @click="emitshowCommentBox"
      >
        回覆
      </button>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  inject,
  onMounted,
  watch,
  computed,
  nextTick,
  provide
} from 'vue';
import Avatar from '@/components/tools/Avatar.vue';
import EditList from './EditList.vue';
import { onClickOutside } from '@vueuse/core';
import { dateTransferLocalTimeZone } from '@/lib/time.js';
import { VueEllipsis3 } from 'vue-ellipsis-3';
import notification from '@/lib/notification';
import Editor from '@/components/editor/Editor.vue';
import { useThrottleFn } from '@vueuse/core';
import { editorIsEmpty } from '@/lib/editorIsEmpty';
import { scrollAnchorPosition } from './anchor';
import ActionButtonGroup from './ActionButtonGroup.vue';
import PhotoArea from './PhotoArea.vue';
export default {
  name: 'MessageBox',
  components: {
    Avatar,
    EditList,
    VueEllipsis3,
    Editor,
    ActionButtonGroup,
    PhotoArea
  },
  props: ['msgItem', 'isShowCommentBox'],
  emits: ['showCommentBox'],
  setup(props, { emit }) {
    const user = computed(() => JSON.parse(localStorage.getItem('user')));
    const loading = ref(false);
    const baseUrl = inject('baseUrl');
    const editor = ref(null);
    const delMessage = inject('delMessage');
    const ellipsis = ref(true);
    const isShowCommentBoxVal = ref(props.isShowCommentBox);
    const isShowEditBox = ref(false);
    provide('isShowEditBox', isShowEditBox);
    const cacheMessage = ref(null);
    const editMessage = inject('editMessage');
    const getMessage = inject('getMessage');
    const getResponseData = inject('getResponseData');
    const chatState = inject('chatState');
    const expand = () => {
      ellipsis.value = false;
    };
    const photoList = reactive({
      data: [...props.msgItem.images]
    });

    provide('photoList', photoList);
    const uploadPhoto = async e => {
      let uploadPhotos = e.target.files;
      if ([...uploadPhotos].length + photoList.data.length * 1 > 6) {
        notification.notificationShow('danger', '最多上傳6張照片');
        return false;
      }
      for (let i = 0; i < uploadPhotos.length; i++) {
        let img = uploadPhotos[i];

        const filesize = (img.size / 1024 / 1024).toFixed(4);
        if (filesize > 10) {
          notification.notificationShow(
            'danger',
            '上傳失敗，檔案大小勿超過10MB'
          );
          return false;
        }
        photoList.data.push(img);
      }

      e.target.value = '';
    };
    watch(
      () => props.isShowCommentBox,
      newVal => {
        isShowCommentBoxVal.value = newVal;
      }
    );
    const emitshowCommentBox = () => {
      isShowCommentBoxVal.value = !isShowCommentBoxVal.value;
      emit('showCommentBox', isShowCommentBoxVal.value);
    };
    const focusEditorEnd = () => {
      editor.value.focusEditorEnd();
    };

    const delMsg = useThrottleFn(
      async messageId => {
        try {
          await delMessage(messageId);
          await Promise.all([getMessage(), getResponseData()]).then(() => {
            notification.notificationShow('success', '刪除留言成功');
          });
        } catch (error) {
          notification.notificationShow('warning', '刪除留言失敗');
        }
      },
      5000,
      false
    );
    const editMsg = item => {
      isShowEditBox.value = true;
      cacheMessage.value = item;
      nextTick(() => {
        focusEditorEnd();
      });
    };
    const closeEditBox = () => {
      isShowEditBox.value = false;
      ellipsis.value = true;
      photoList.data = props.msgItem.images;
    };
    const sendMsg = useThrottleFn(
      async () => {
        try {
          loading.value = true;
          await nextTick();
          await editMessage(
            cacheMessage.value,
            props.msgItem.message_id,
            photoList.data
          );
          await Promise.all([getMessage(), getResponseData()]).then(() => {
            notification.notificationShow('success', '儲存留言成功');
          });
        } catch (error) {
          notification.notificationShow('warning', '儲存留言失敗');
        } finally {
          loading.value = false;
          isShowEditBox.value = false;
          ellipsis.value = true;
        }
      },
      5000,
      false
    );

    const setEditorData = editorData => {
      cacheMessage.value = editorData;
    };

    return {
      user,
      loading,
      editor,
      dateTransferLocalTimeZone,
      baseUrl,
      ellipsis,
      expand,
      isShowCommentBoxVal,
      isShowEditBox,
      emitshowCommentBox,
      delMsg,
      editMsg,
      sendMsg,
      closeEditBox,
      cacheMessage,
      setEditorData,
      editorIsEmpty,
      scrollAnchorPosition,
      photoList,
      uploadPhoto
    };
  }
};
</script>

<style lang="scss">
.vue-ellipsis p {
  margin: 0;
}
.vue-ellipsis-js-content {
  line-height: 21px;
}
</style>
<style lang="scss" scoped></style>
