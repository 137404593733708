import { Extension } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { mergeAttributes } from '@tiptap/core';

export const ExtendedLink = Link.extend({
  addAttributes() {
    this.options.openOnClick = false;
    return {
      href: {
        default: null
      },
      target: {
        default: this.options.HTMLAttributes.target
      },
      class: {
        default: 'tippyLink'
      },
      'data-href': {
        default: null,
        parseHTML: element => element.getAttribute('href')
      }
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ['a', mergeAttributes(HTMLAttributes, { rel: this.options.rel }), 0];
  }
});
