import axios from 'axios';
import notification from '@/lib/notification';

export default {
  //取得分頁式版本列表
  async getMutilDraftsVersionList(task_id) {
    return await axios
      .get('/api/draft-script-separate/get-scripts/' + task_id)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //取得分頁式詳細內容
  async getMutilDraftsDetail(data) {
    let url = '';
    if (data.is_draft) {
      url = `/api/draft-script-separate/get-script/${data.task_uuid}?is_draft=1`;
    } else {
      url = `/api/draft-script-separate/get-script/${data.task_uuid}?version=${data.version}`;
    }
    return await axios
      .get(url)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //創建分頁式內容
  async createMutilDrafts(formData) {
    return await axios
      .post(`/api/draft-script-separate/store`, formData)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //更新分頁式內容
  async updateMutilDrafts(taskId, data) {
    //
    return await axios
      .post(`/api/draft-script-separate/${taskId}/draft-update`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //刪除分頁式內容
  async deleteMutilDrafts(taskId) {
    return await axios
      .delete(`/api/draft-script-separate/${taskId}/delete`)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },

  //取得單頁式版本列表
  async getOnePageDraftsVersionList(task_id) {
    return await axios
      .get('/api/draft-script-one/get-scripts/' + task_id)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //取得單頁式詳細內容
  async getOnePageDraftsDetail(task_uuid, script_uuid) {
    return await axios
      .get(`/api/draft-script-one/get-script/${task_uuid}/${script_uuid}`)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //創建單頁式內容
  async createOnePageDrafts(formData) {
    return await axios
      .post(`/api/draft-script-one/store`, formData)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //更新單頁式內容
  async updateOnePageDrafts(taskId, script_uuid, data) {
    return await axios
      .post(`/api/draft-script-one/${taskId}/${script_uuid}/draft-update`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  //刪除單頁式內容
  async deleteOnePageDrafts(taskId) {
    return await axios
      .delete(`/api/draft-script-one/${taskId}/delete`)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },

  async getDrafts(task_id) {
    return await axios
      .get('/api/draft-script/get-scripts/' + task_id)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async getVersionDetail(task_id, script_uuid) {
    return await axios
      .get('/api/draft-script/get-script/' + task_id + '/' + script_uuid)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async orderDraft(data) {
    return await axios
      .post('/api/draft-script/ordering-draft-scripts', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async storeDraft(data) {
    return await axios
      .post('/api/draft-script/store', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  },
  async deleteScript(data) {
    return await axios
      .post('/api/draft-script/delete', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async recoveryDraft(task_id, script_id) {
    let data = {};
    data.task_uuid = task_id;
    data.script_uuid = [];
    data.script_uuid.push(script_id);
    return await axios
      .post('/api/draft-script/recovery-draft-scripts', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async createMessage(data) {
    return await axios
      .post('/api/draft-script/message/store', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async getDeleteDrafts(task_id) {
    return await axios
      .get('/api/draft-script/get-deleted-scripts/' + task_id)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  },
  async updateDraftDetail(task_uuid, script_uuid, data) {
    return await axios
      .post(`/api/draft-script/${task_uuid}/${script_uuid}`, data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  },
  async sendWordFile(data) {
    var bodyFormData = new FormData();
    bodyFormData.append('file', data);
    return await axios
      .post('/api/draft-script/send-word-file', bodyFormData)
      .then(resp => resp.data.data)
      .catch(function (err) {
        console.log(err);
        notification.notificationShow('danger', err, 9000);
      });
  },
  async storeImage(data) {
    return await axios
      .post('/api/draft-script/image-file', data)
      .then(resp => resp.data.data)
      .catch(err => {
        console.log(err);
      });
  }
};
