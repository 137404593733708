export function handlePublishTimeDesc(post_modified) {
  // 拿到當前時間戳和釋出時的時間戳，然後得出時間戳差
  var curTime = new Date();
  var postTime = new Date(post_modified);
  var timeDiff = curTime.getTime() - postTime.getTime();

  // 單位換算
  var min = 60 * 1000;
  var hour = min * 60;
  var day = hour * 24;
  var week = day * 7;

  // 計算髮布時間距離當前時間的周、天、時、分
  var exceedWeek = Math.floor(timeDiff / week);
  var exceedDay = Math.floor(timeDiff / day);
  var exceedHour = Math.floor(timeDiff / hour);
  var exceedMin = Math.floor(timeDiff / min);

  // 最後判斷時間差到底是屬於哪個區間，然後return
  if (exceedWeek > 0) {
    return new Date(post_modified).toLocaleString('sv-SE');
  } else {
    if (exceedDay < 7 && exceedDay > 0) {
      return exceedDay + '天前';
    } else {
      if (exceedHour < 24 && exceedHour > 0) {
        return exceedHour + '小時前';
      } else {
        return exceedMin < 1 ? '剛剛' : `${exceedMin}分鐘前`;
      }
    }
  }
}

export function dateTransferLocalTimeZone(date) {
  let time = new Date(date);
  return `${time
    .toLocaleDateString()
    .replace(/\//g, '-')} ${time.toLocaleTimeString('en-GB')}`;
}
