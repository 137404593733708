<template>
  <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-6">
    <Photo
      v-for="(photo, index) in photoList.data"
      :key="photo"
      :photo="photo"
      :index="index"
      :taskUuid="chatState.data.taskId"
      @deletePhoto="deletePhoto"
      @addS3Photo="addS3Photo"
      @openLightBox="openLightBox"
    >
    </Photo>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="imgsRef"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<script setup>
import { reactive, ref, inject, computed } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import Photo from './Photo.vue';
const photoList = inject('photoList');
const chatState = inject('chatState');
const visibleRef = ref(false);
const indexRef = ref(0);
const imgsRef = computed(() => photoList.data.map(i => i.file));
const onShow = () => {
  visibleRef.value = true;
};
const openLightBox = index => {
  indexRef.value = index;
  onShow();
};

const deletePhoto = index => {
  photoList.data.splice(index, 1);
};
const addS3Photo = ({ url, index }) => {
  photoList.data[index] = {
    file: url
  };
};
const onHide = () => (visibleRef.value = false);
</script>
<style lang="scss" scoped></style>
