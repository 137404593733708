export default {
  state: {
    isShow: false,
    contentCurrentPage: 1, //1:網紅廠商內容 2:平台內容
    currentPage: 1, //內頁頁數
    taskId: '',
    scores: [],
    userSpuerLogin: false,
    isShowDemoAlert: false
  },
  mutations: {
    setScoreVendorData(state, data) {
      state.scores.push({
        type: 'copartner',
        index: 1,
        contents: [
          {
            page: 1,
            title: '為合作的廠商評分',
            qa: [
              {
                key: 'creative-freedom',
                type: 'choice',
                question: '創作自由度',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              },
              {
                key: 'communication',
                type: 'choice',
                question: '溝通方式和效率',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              },
              {
                key: 'modify-opinion',
                type: 'choice',
                question: '修改意見專業度',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              }
            ]
          },
          {
            page: 2,
            title: '為合作的廠商評分',
            qa: [
              {
                key: 'cooperate-again',
                type: 'choice',
                question: '未來是否願意再次合作',
                anser: [
                  { text: '願意', value: 1 },
                  { text: '不願意', value: 2 },
                  { text: '再考慮', value: 3 }
                ],
                user: ''
              },
              {
                key: 'comment',
                type: 'answer',
                question: '根據此次的合作，您還有什麼想和我們分享的嗎？',
                user: ''
              }
            ]
          }
        ]
      });
      state.scores.forEach((element, index) => {
        element.index = index + 1;
      });
    },
    setScoreKolData(state, data) {
      state.scores.push({
        type: 'copartner',
        index: 1,
        contents: [
          {
            page: 1,
            title: '為合作的網紅評分',
            qa: [
              {
                key: 'achievement',
                type: 'choice',
                question: '作品成果',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              },
              {
                key: 'communication',
                type: 'choice',
                question: '溝通方式和效率',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              },
              {
                key: 'cooperation',
                type: 'choice',
                question: '配合度',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              },
              {
                key: 'cooperation-fee',
                type: 'choice',
                question: '合作費用',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ],
                user: ''
              }
            ]
          },
          {
            page: 2,
            title: '為合作的網紅評分',
            qa: [
              {
                key: 'cooperate-again',
                type: 'choice',
                question: '未來是否願意再次合作',
                anser: [
                  { text: '願意', value: 1 },
                  { text: '不願意', value: 2 },
                  { text: '再考慮', value: 3 }
                ],
                user: ''
              },
              {
                key: 'comment',
                type: 'answer',
                question: '根據此次的合作，您還有什麼想和我們分享的嗎？',
                user: ''
              }
            ]
          }
        ]
      });
      state.scores.forEach((element, index) => {
        element.index = index + 1;
      });
    },
    setScorePlatformData(state, data) {
      state.scores.push({
        type: 'website',
        index: 2,
        contents: [
          {
            page: 1,
            title: '您的回饋幫助我們成長',
            qa: [
              {
                key: 'experience',
                type: 'choice',
                question: '平台使用體驗',
                anser: [
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 }
                ]
              },
              {
                key: 'cooperate-again',
                type: 'choice',
                question: '未來是否願意再次使用平台',
                anser: [
                  { text: '願意', value: 1 },
                  { text: '不願意', value: 2 },
                  { text: '再考慮', value: 3 }
                ]
              }
            ]
          },
          {
            page: 2,
            title: '您的回饋幫助我們成長',
            qa: [
              {
                key: 'comment',
                type: 'answer',
                question: '您寶貴的意見，將幫助我們優化平台，提供更好的服務。'
              }
            ]
          }
        ]
      });
      state.scores.forEach((element, index) => {
        element.index = index + 1;
      });
    }
  },
  actions: {},
  modules: {}
};
