<template>
  <button
    class="link"
    :uk-tooltip="`title:插入連結;pos:top;`"
    @click="handleLink"
    :class="{
      'is-active': editor.isActive('link')
    }"
  >
    <img
      :src="require('@/assets/icon/editor/link-chain.svg')"
      width="24"
      height="24"
    />
  </button>
</template>
<script>
import { reactive, ref, watch } from 'vue';
import notification from '@/lib/notification';
import { onClickOutside } from '@vueuse/core';
import LinkEdit from './LinkMenu/LinkEdit.vue';

export default {
  name: '',
  props: ['editor'],
  emits: ['handleLink'],
  components: {},
  setup(props, { emit }) {
    const handleLink = () => {
      //避免重新計算位置
      if (window.getSelection().toString() == '') {
        props.editor.chain().focus();
        return;
      }
      const currentUrl = props.editor.getAttributes('link').href;
      const rect = window.getSelection().getRangeAt(0).getBoundingClientRect();
      const editBoxPosition = {
        top: rect.bottom + window.scrollY + 'px',
        left: rect.left + window.scrollX + rect.width / 2 + 'px'
      };
      emit('handleLink', editBoxPosition);
    };
    const closeShowEdit = () => {
      showEdit.value = false;
    };

    return {
      handleLink
    };
  }
};
</script>

<style lang="scss" scoped>
.link {
  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #000;
    opacity: 0.6;
  }
  &::before {
    margin: 0 6px 0 0px;
  }
  &::after {
    margin: 0 0px 0 6px;
  }
}
</style>
