<template>
  <div
    class="notificationListItem"
    :class="{
      kol: message.code.identity == 2,
      vendor: message.code.identity == 1
    }"
    :key="message"
  >
    <div class="avatar">
      <Avatar
        size="36"
        :img="message.avatar"
        :name="message.parameters?.name"
      />
    </div>
    <div class="message">
      <NotificationListItemContent
        :name="{
          name: message.parameters?.name,
          company: message.parameters?.company_name
        }"
        :code="{
          identity: message.code.identity,
          function: message.code.function,
          action: message.code.action
        }"
        :parameters="message.parameters"
      />

      <div class="projectInfo" v-if="message.code.identity == 1">
        <span
          class="name"
          :uk-tooltip="
            'title: ' +
            message.parameters.company_name +
            '; pos: bottom;offset: 6'
          "
          >{{ message.parameters.company_name }}</span
        >
        <span
          class="type"
          v-if="message.parameters.task_type"
          :uk-tooltip="
            'title:' + message.parameters.task_type + '; pos: bottom;offset: 6'
          "
          >{{ getType(message.parameters.task_type) }}</span
        >
      </div>

      <div class="date">
        <span>{{ handlePublishTimeDesc(message.created_at) }}</span>
      </div>
      <div class="tw-w-full tw-pt-2">
        <div
          class="adp-button adp-button-small tw-mr-[8px]"
          style="min-width: 140px"
          @click="onClickJointInviteHandler"
        >
          {{ message.code.identity == 1 ? '加入專案' : '前往開案' }}
        </div>
        <div
          class="adp-button adp-button-outline adp-button-small"
          style="min-width: 140px"
          @click="onClickRejectInviteHandler"
        >
          拒絕
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationListItemContent from '@/components/navbar/notification/NotificationListItemContent';
import { handlePublishTimeDesc } from '@/lib/time';
import { toRef, inject, ref } from 'vue';
import Avatar from '@/components/tools/Avatar.vue';
import api from '@/api/notification';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  props: ['messageData'],
  components: { NotificationListItemContent, Avatar },
  setup(props) {
    const store = useStore();
    const message = toRef(props, 'messageData');
    const readNotification = inject('readNotification');
    const closePopup = inject('closePopup');
    const openChatService = inject('openChatService');
    const jumpToPath = inject('jumpToPath');
    const setChatParameter = inject('setChatParameter');
    const setChatName = inject('setChatName');
    const triggerNotification = async isUuid => {
      await readNotification(isUuid, closePopup, message.value);
      if (message.value.code.action != '03') await jumpToPath(message.value);
      // 例外處理 -> 是否顯示對話框
      if (_isChatUpdate(message.value.code.action))
        openChatService({
          data: setChatParameter(message.value),
          name: setChatName(
            message.value.code.function,
            message.value.parameters
          )
        });
    };
    const gotoNextHandler = function () {
      store.state.notification.showInvite = true;
      api.readNotification(message.value.uuid);
    };

    const _isChatUpdate = code => code == '04';
    const getType = function (array) {
      if (typeof array == 'string') return typeList[array];
      return array.join('/');
    };
    const functionList = {
      '010': '合作案',
      '020': '需求單',
      '030': '創意大綱',
      '040': '腳本細節',
      '050': '影片製作',
      '060': '文案撰寫',
      '061': '文案撰寫照片',
      '070': '作品上線',
      '080': '結案報告',
      '090': '專案'
    };
    const actionList = {
      '01': '新增',
      '02': '更新',
      '03': '刪除',
      '04': '留下了新留言',
      '05': '過稿',
      '06': '時程',
      '07': '開放'
    };
    const typeList = {
      video: '影音',
      essay: '圖文',
      live: '直播',
      clip_video: '短影音',
      story: '限時動態'
    };
    const userRole = JSON.parse(localStorage.getItem('user')).role;
    const router = useRouter();

    const closeNotification = inject('closeNotification');
    const init = inject('init');
    const onClickJointInviteHandler = function () {
      console.log('props.messageData:', props.messageData);
      const userRole = JSON.parse(localStorage.getItem('user')).role;
      if (userRole === 'Creator') {
        //廠商邀網紅
        //http://localhost:8080/create_project?project_build_invitation_uuid=81df46a0-7a1c-11ee-8ac0-0daf999ddf60&project_uuid=6c8259a0-7a1c-11ee-97c7-2367731e4f12
        router.push({
          path: `/create_project`,
          query: {
            project_build_invitation_uuid:
              props.messageData.parameters.project_build_invitation_uuid,
            project_uuid: props.messageData.parameters.project_uuid,
            uuid: props.messageData.uuid
          }
        });
      } else {
        //網紅邀廠商
        //http://localhost:8080/add_project?create=kol&kol=01bd9e10-7471-11ee-aae1-cf1f8db5088a&name=%E7%B6%B2%E7%B4%85-Rose&avatar=https://alpha.admin.adpost.com.tw/api/editor/image/KPmTTvFp5phjRUj52whPfgbolxS2JATBKXhPLyl5.jpg&project_build_invitation_uuid=5927c870-7a25-11ee-8a30-d5b81e62e8e2
        router.push({
          path: `/add_project`,
          query: {
            create: 'kol',
            kol: props.messageData.parameters.kol_uuid,
            name: props.messageData.parameters.kol_name,
            avatar: props.messageData.avatar,
            project_build_invitation_uuid:
              props.messageData.parameters.project_build_invitation_uuid,
            uuid: props.messageData.uuid
          }
        });
      }

      closeNotification();
    };

    const onClickRejectInviteHandler = function () {
      api
        .rejectInvite(
          props.messageData.parameters.project_build_invitation_uuid,
          props.messageData.uuid
        )
        .then(function () {
          init();
        });
    };
    return {
      store,
      triggerNotification,
      handlePublishTimeDesc,
      functionList,
      actionList,
      typeList,
      message,
      readNotification,
      closePopup,
      getType,
      gotoNextHandler,
      onClickJointInviteHandler,
      onClickRejectInviteHandler
    };
  }
};
</script>
<style lang="scss" scoped>
.notificationListItem {
  display: flex;
  padding: 1rem 2rem 1rem 1rem;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    height: 1px;
    background: #f5f5f5;
    position: absolute;
    bottom: 0;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover,
  &.read:hover {
    background: #f4feff;
  }

  .avatar {
    flex: 0 0 auto;
    width: 2.25rem;
    height: 2.25rem;
  }
  .message {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 1rem;
    flex: 1;
    .content {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.155em;
      color: #000000;
      text-align: left;
      p {
        margin: 0;
        padding: 0;
      }
      span {
        font-weight: bold;
      }
    }
    .projectInfo {
      display: flex;
      margin: 0.5rem 0;
      span {
        padding: 0.05rem 0.25rem;
        margin-right: 0.25rem;
        border-radius: 8px;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.05em;
        &.name {
          background: #dcf2ff;
          color: #233f87;
          max-width: 7rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.partner {
          background: #f3f3f3;
          color: #535353;
          max-width: 6rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.type {
          background: #f3f3f3;
          color: #696969;
        }
      }
    }
    .date {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $gray-color;
    }
  }
  .read {
    .dot {
      width: 8px;
      height: 8px;
      background: $brand-color;
      border-radius: 100%;
    }
  }
  &.read {
    .message {
      .content {
        color: #8f8f8f;
      }
      .projectInfo {
        span {
          &.name {
            background: #dcf2ff;
            color: #233f87;
          }
          &.partner {
            background: #e8f7ff;
            color: #5f78b9;
          }
          &.type {
            background: #f4f4f4;
            color: #8a8a8a;
          }
        }
      }
      .date {
        color: #d6d6d6;
      }
    }
    .read {
      .dot {
        visibility: hidden;
      }
    }
  }
}
</style>
