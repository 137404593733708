<template>
  <section
    class="bannerSection tw-pt-[calc(50px+48px)] lg:tw-pt-[150px] tw-pb-12 md:tw-pb-20 lg:tw-pb-[calc(80px+56px)]"
  >
    <div class="tw-homeContainer">
      <div class="info md:tw-flex">
        <div
          class="content tw-text-left md:tw-w-[55%] tw-mb-10 sm:tw-mb-16 md:tw-mb-0"
        >
          <h3>網紅行銷界的 Trello</h3>
          <h1>AD POST 網紅專案管理平台</h1>
          <p
            class="tw-text-white tw-text-base tw-not-italic tw-leading-6 tw-tracking-[1.28px] tw-mt-0 tw-mb-8 lg:tw-mb-12"
          >
            AD POST
            致力於協助網紅與品牌主執行業配合作！一站式整合業配過程中，用科技解決網紅行銷的所有專案流程，彙整所有訊息的渠道，自動生成結案分析報告，讓您節省大量的人力，給您最流暢的業配體驗！讓網紅行銷執行更簡單更方便。
          </p>

          <CtaButton />
        </div>
        <div class="panel md:tw-w-[45%]">
          <img
            :src="require(`@/assets/landing_page/banner/2023/banner.svg`)"
            alt="ad post"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CtaButton from '@/components/landing_page/2023/CtaButton.vue';
export default {
  name: 'BannerArea',
  components: { CtaButton },
  setup() {}
};
</script>

<style lang="scss" scoped>
.bannerSection {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @apply tw-bg-no-repeat tw-bg-[url('~@/assets/landing_page/banner/2023/bannerBg_1024.svg')] lg:tw-bg-[url('~@/assets/landing_page/banner/2023/bannerBg_1440.svg')] xl:tw-bg-[url('~@/assets/landing_page/banner/2023/bannerBg.svg')] tw-bg-[length:auto_765px] md:tw-bg-[length:cover]  tw-bg-[center_0] xl:tw-bg-[bottom];
  // tw-bg-[center_0]
  // background-size: cover;
  // background-position: bottom;
}
h3 {
  @apply tw-text-2xl tw-not-italic tw-leading-9 tw-tracking-[0.96px] tw-text-white tw-mt-0 tw-mb-4;
}
h1 {
  @apply tw-text-[40px] tw-not-italic tw-leading-[normal] tw-tracking-[1.6px] tw-text-white tw-mt-0 tw-mb-6;
}

.panel {
  img {
    @apply md:tw-absolute md:tw-left-[58%] xl:tw-left-[55%] xl:tw-top-[70px] md:tw-max-w-[622px] xl:tw-max-w-none;
  }
}
</style>
