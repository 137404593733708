<template>
  <button
    :uk-tooltip="'title:螢光筆;pos:top;'"
    @click="
      editor.chain().focus().toggleHighlight({ color: color.color }).run()
    "
    :class="{
      'is-active': editor.isActive('highlight', { color: color.color })
    }"
  >
    <img
      :src="require('@/assets/icon/editor/' + color.img + '.svg')"
      width="24"
      height="24"
    />
  </button>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default: () => {}
    },
    color: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup() {
    return {};
  }
};
</script>
<style lang="scss"></style>
