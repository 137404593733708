<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container "
    content-class="modal-content"
    id="loginModal"
    v-if="showModal"
  >
    <div
      class="modal__content uk-modal-body uk-width-1-1 uk-card uk-card-body uk-background-default uk-border-rounded"
      uk-animation-fade
    >
      <button
        class="uk-modal-close-default"
        @click="showModal = false"
        type="button"
        uk-close
      ></button>
      <h3
        class="uk-card-title uk-text-bold uk-text-uppercase uk-flex uk-flex-center"
        style="color: #73d1dd"
      >
        登入
      </h3>
      <div
        class="uk-alert-danger uk-flex uk-flex-center errorItem"
        uk-alert
        v-if="loginFail"
        style="width: 480px; height: 30px; padding: 0; margin: 0 0 12px 0"
      >
        <p>{{ loginFail }}</p>
      </div>
      <form @submit.prevent autocomplete="on">
        <div class="uk-margin" style="margin-bottom: 36px">
          <div class="uk-form-controls uk-position-relative">
            <input
              class="uk-input uk-border-rounded"
              name="loginEmail"
              :class="[errorMessage.emailError != null ? 'uk-form-danger' : '']"
              type="mail"
              id="loginEmail"
              @change="emailValidate"
              v-model="user.email"
              required="required"
              @keydown.enter.exact="login"
            />
            <label class="uk-form-label uk-position-absolute" for="loginEmail"
              >帳號：E-mail信箱</label
            >
          </div>
          <small
            class="uk-text-danger uk-float-right"
            v-if="errorMessage.emailError != null"
            >{{ errorMessage.emailError }}</small
          >
        </div>
        <div
          class="uk-margin-top passwordBox"
          style="width: 480px; margin: 36px 0 16px 0"
        >
          <div
            class="uk-form-controls uk-inline uk-width-1 uk-position-relative"
          >
            <input
              class="uk-input uk-border-rounded"
              :class="[
                errorMessage.passwordError != null ? 'uk-form-danger' : ''
              ]"
              name="password"
              type="password"
              id="loginPassword"
              @keyup="passwordValidate"
              v-model="user.password"
              required="required"
              @keydown.enter.exact="login"
            />
            <label
              class="uk-form-label uk-position-absolute"
              for="loginPassword"
              >密碼</label
            >
            <a @click="viewPassword" class="uk-form-icon uk-form-icon-flip">
              <img
                :src="
                  openPassword
                    ? require('@/assets/icon/eye.svg')
                    : require('@/assets/icon/eye_none.svg')
                "
                width="24"
                height="24"
                alt="檢視密碼"
            /></a>
          </div>
          <small
            class="uk-text-danger uk-float-right"
            v-if="errorMessage.passwordError != null"
            >{{ errorMessage.passwordError }}</small
          >
        </div>
        <div class="uk-margin-small-bottom">
          <div class="uk-form-controls uk-flex uk-flex-left">
            <small
              ><span
                @click="openOtherModal('ForgotModal')"
                style="color: #73d1dd"
                >忘記密碼了?</span
              ></small
            >
          </div>
        </div>
        <div class="">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <div class="loginBtn m-btn-rounded_brand_white" @click="login">
              <span>立即登入</span>
            </div>
          </div>
        </div>
        <div class="">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <small
              >沒有帳號嗎?<span
                style="color: #73d1dd"
                @click="openOtherModal('RegisterModal')"
                >立即註冊</span
              ></small
            >
          </div>
        </div>
      </form>
    </div>
  </vue-final-modal>
</template>

<script>
import { reactive, ref, watch, onMounted, toRaw } from 'vue';
import { useStore } from 'vuex';
import VALIDATE from '@/lib/validate';
import { VueFinalModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'LoginModal',
  components: { VueFinalModal },
  emits: ['openModal', 'open-modal'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const user = reactive({
      email: '',
      password: ''
    });
    const errorMessage = reactive({
      emailError: null,
      passwordError: null
    });
    const openPassword = ref(false);
    const loginFail = ref('');
    //檢查輸入有沒有錯 控制button
    watch([user, errorMessage], () => {
      let loginBtn = document.querySelector('.loginBtn');

      for (let key in user) {
        if (
          user[key] == '' ||
          errorMessage.emailError != null ||
          errorMessage.passwordError != null
        ) {
          loginBtn.classList.add('disabled');
          return false;
        }
      }
      loginBtn.classList.remove('disabled');
    });

    //email檢查
    function emailValidate(event) {
      errorMessage.emailError = VALIDATE.emailValidate(event);
    }
    // password檢查
    function passwordValidate(event) {
      errorMessage.passwordError = VALIDATE.passwordValidate(event);
    }

    onMounted(() => {
      setTimeout(function () {
        if (route.query.login) showModal.value = true;
      }, 100);
    });
    // 登入
    const login = async () => {
      loginFail.value = '';
      try {
        await store.dispatch('login', user);
        showModal.value = false;
        if (localStorage.getItem('goPath')) {
          router.push(localStorage.getItem('goPath'));
        } else {
          router.push('/project');
        }
      } catch (err) {
        if (err.status == 403) {
          switch (err.code) {
            case '0102':
              loginFail.value = '帳號或密碼錯誤';
              break;
            case '0103':
              loginFail.value = '帳號權限錯誤';
              break;
            case '0104': {
              const tmpEmail = err.data;
              loginFail.value = '帳號尚未驗證';
              showModal.value = false;
              localStorage.setItem('user', JSON.stringify(tmpEmail));
              router.push('/validation');
              break;
            }
            default:
              loginFail.value = '無法登入,請洽客服人員';
          }
        }
      }
    };
    // 顯示密碼
    const viewPassword = () => {
      let passswordInput = document.getElementById('loginPassword');
      if (passswordInput.type === 'password') {
        passswordInput.type = 'text';
        openPassword.value = true;
      } else {
        passswordInput.type = 'password';
        openPassword.value = false;
      }
    };

    const openOtherModal = async modalName => {
      showModal.value = false;
      setTimeout(() => {
        emit('open-modal', modalName);
      }, 500);
    };
    const openModal = () => {
      showModal.value = true;
    };
    const showModal = ref(false);
    return {
      user,
      login,
      emailValidate,
      passwordValidate,
      errorMessage,
      viewPassword,
      loginFail,
      openPassword,
      openModal,
      openOtherModal,
      showModal
    };
  }
};
</script>
<style lang="scss" scoped>
input {
  &:focus + label {
    transform: translate(-0.5rem, -50%);
    background: #fff;
    top: 0;
  }
  &:valid + label {
    transform: translate(-0.5rem, -50%);
    background: #fff;
    top: 0;
  }
}
label {
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  transition: transform 0.5s ease-in-out;
  padding: 0 2px;
}

#loginModal .modal__content {
  width: 680px;
  height: 480px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 95vw;
  transition: 0.3s linear;
  input {
    height: 40px;
    width: 480px;
    border-radius: 4px;
  }
  h3 {
    margin-top: 56px;
    margin-bottom: 20px;
    font-size: 32px;
  }
  form div {
    &:nth-child(2) {
      margin-bottom: 16px;
    }
    &:nth-child(4) {
      margin-top: 54px;
      margin-bottom: 12px;
    }
  }
  .loginBtn {
    &.disabled {
      pointer-events: none;
      background: #ffffff;
      box-shadow: 2px 2px 12px rgb(0 0 0 / 6%);
      color: #cacaca;
      border: none;
    }
  }
  small {
    a {
      font-size: 12px;
    }
    span {
      cursor: pointer;
    }
  }
}
@media (max-width: 560px) {
  #loginModal > div {
    width: 95vw;
    .errorItem {
      width: 90% !important;
    }
    input {
      width: 100%;
    }
    form {
      width: 90%;
    }
    .passwordBox {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss">
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
