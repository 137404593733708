<template>
  <section class="featureArea tw-py-8 md:tw-py-14 tw-overflow-hidden">
    <div class="tw-homeContainer">
      <h2
        class="tw-text-[#161818] tw-text-center tw-text-[32px] tw-font-medium tw-tracking-[1.28px] tw-w-full tw-py-2 tw-mt-0 tw-mb-8"
      >
        五大特色<br />給你最順暢的業配體驗！
      </h2>
    </div>
    <div class="featureWrap">
      <div
        class="item"
        v-for="(item, index) in data"
        :key="index"
        :data-aos="`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`"
        data-aos-delay="500"
        data-aos-offset="200"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div class="tw-homeContainer">
          <div
            class="wrap tw-flex tw-flex-col lg:tw-flex-row lg:tw-px-10 lg:tw-gap-6 tw-justify-center tw-items-center"
          >
            <lazy-content :once="true">
              <img
                v-if="width >= 1200"
                :src="
                  require(`@/assets/landing_page/feature/2023/${item.img}_550.svg`)
                "
                :alt="item.title"
              />
              <img
                v-else-if="width >= 992"
                :src="
                  require(`@/assets/landing_page/feature/2023/${item.img}_440.svg`)
                "
                :alt="item.title"
              />
              <img
                v-else
                :src="
                  require(`@/assets/landing_page/feature/2023/${item.img}_550.svg`)
                "
                :alt="item.title"
              />
            </lazy-content>
            <div class="info tw-px-11 tw-text-left lg:tw-flex-[1_0_0]">
              <p
                class="tw-text-[#44C3CE] tw-text-xl tw-leading-[35px] tw-tracking-[0.8px] tw-mt-0 tw-mb-3 tw-hidden lg:tw-flex lg:tw-items-center"
              >
                <span>特色</span>
                <img
                  :src="
                    require(`@/assets/landing_page/feature/2023/${
                      index + 1
                    }.png`)
                  "
                  alt=""
                />
              </p>
              <h3
                class="tw-mt-0 tw-mb-4 tw-text-[#161818] tw-text-2xl tw-leading-9 tw-tracking-[0.96px]"
              >
                {{ item.title }}
              </h3>
              <p
                v-if="item.subTitle"
                class="tw-m-0 tw-text-[#161818] tw-text-base tw-leading-6 tw-tracking-[1.28px]"
              >
                {{ item.subTitle }}
              </p>
              <ul class="tw-pb-4 tw-m-0 tw-pl-0">
                <li
                  v-for="(info, index) in item.info"
                  :key="index"
                  class="tw-list-disc tw-list-inside tw-text-[#161818] tw-text-base tw-leading-6 tw-tracking-[1.28px] tw-mt-3"
                >
                  {{ info }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue';
import { useWindowSize } from '@vueuse/core';
const { width } = useWindowSize();
const data = reactive([
  {
    title: '網紅 + 藝人明星全方位合作人選',
    info: [
      '20萬筆網紅資料庫，標籤篩選類型',
      '韓國明星獨家接洽，引爆話題潮流',
      'AI 精準媒合，找出最佳網紅人選'
    ],
    img: 'image_feature01'
  },
  {
    title: '科技化業配專案合作流程',
    info: [
      '專案排程完整掌控進度',
      '模組化表單套用，快速有效傳達',
      '群發百位網紅也輕鬆管理',
      '線上多人協作，或匯出線下共有',
      'LINE通知/ 聊天室即時溝通'
    ],
    img: 'image_feature02'
  },
  {
    title: '獨家智能專案輔助工具',
    info: [
      '影片快速校稿，一秒到達指定秒數',
      'AI 文案自動比對，省去費時的人工校稿',
      'AI 文字雲、自動留言分析比對'
    ],
    img: 'image_feature03'
  },
  {
    title: '彈性多社群平台合作，完整服務',
    info: [
      '影音/ 圖文/ 部落格業配',
      '團購/ 直播/ 代言合作',
      'Podcast/ TikTok/ 小紅書皆可操作',
      '專案團隊協助完整流程'
    ],
    img: 'image_feature04'
  },
  {
    title: '保證網紅行銷成效',
    subTitle:
      'AD POST 提供完整的業配成效分析，並保證業配成效，讓每一分花費都值得。',
    info: ['影音觀看成效保證', '自動生成結案分析報告', '數位廣告諮詢協助'],
    img: 'image_feature05'
  }
]);
</script>

<style lang="scss" scoped>
.featureWrap {
  .item {
    @apply tw-py-6;

    &:nth-of-type(1) {
      @apply tw-bg-no-repeat tw-bg-[length:81%_100%] tw-bg-[url('~@/assets/landing_page/feature/2023/bg01_576.png')] md:tw-bg-[url('~@/assets/landing_page/feature/2023/bg01_768.png')] lg:tw-bg-[url('~@/assets/landing_page/feature/2023/bg01.png')];
    }

    &:nth-of-type(3) {
      @apply tw-bg-no-repeat tw-bg-right tw-bg-[length:95%_100%] tw-bg-[url('~@/assets/landing_page/feature/2023/bg03_576.png')] md:tw-bg-[url('~@/assets/landing_page/feature/2023/bg03_768.png')] lg:tw-bg-[url('~@/assets/landing_page/feature/2023/bg03.png')];
    }

    &:nth-of-type(5) {
      @apply tw-bg-no-repeat tw-bg-[length:78%_100%] tw-bg-[url('~@/assets/landing_page/feature/2023/bg05_576.png')] md:tw-bg-[url('~@/assets/landing_page/feature/2023/bg05_768.png')] lg:tw-bg-[url('~@/assets/landing_page/feature/2023/bg05.png')];
    }

    &:nth-child(odd) {
      .wrap {
        @apply lg:tw-flex-row-reverse;
      }
    }
  }
}
</style>
