export function getQuoteRange() {
  const selection = window.getSelection();
  let savedRange = null;
  if (selection.rangeCount > 0) {
    savedRange = selection.getRangeAt(0);
    const serializedRange = serializeRange(savedRange);
    return serializedRange;
  }
}

export function scrollAnchorPosition(serializedRange) {
  try {
    if (!JSON.parse(serializedRange).startContainerPath) {
      return false;
    }
    const range = deserializeRange(JSON.parse(serializedRange));
    if (!range) {
      return false;
    }
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    // 將選區所在的段落滾動到可視區域的中心
    const paragraph = range.startContainer.parentNode;
    paragraph.scrollIntoView({ block: 'center' });
  } catch (error) {
    console.log(error);
  }
}

// 將範圍序列化為對象
function serializeRange(range) {
  return {
    // selectedText: range.toString(),
    startContainerPath: getNodePath(range.startContainer),
    startOffset: range.startOffset,
    endContainerPath: getNodePath(range.endContainer),
    endOffset: range.endOffset
  };
}

// 從對像中反序列化範圍
function deserializeRange(serializedRange) {
  const startContainer = getNodeByPath(serializedRange.startContainerPath);
  const endContainer = getNodeByPath(serializedRange.endContainerPath);
  if (startContainer && endContainer) {
    const range = document.createRange();
    range.setStart(startContainer, serializedRange.startOffset);
    range.setEnd(endContainer, serializedRange.endOffset);
    // 檢查選區的文本是否匹配框選的文本
    const rangeText = range.toString();
    if (rangeText === serializedRange.selectedText) {
      return range;
    }
  }
  return null;
}

// 獲取節點的路徑
function getNodePath(node) {
  const path = [];
  let current = node;
  while (current !== document) {
    const index = Array.from(current.parentNode.childNodes).indexOf(current);
    path.unshift(index);
    current = current.parentNode;
  }
  return path;
}

// 根據路徑獲取節點
function getNodeByPath(path) {
  let current = document;
  for (const index of path) {
    current = current.childNodes[index];
    if (!current) {
      return null;
    }
  }
  return current;
}
