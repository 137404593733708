<template>
  <label uk-tooltip="title: 解決; pos: top">
    <input
      type="checkbox"
      :checked="checked"
      @change="$emit('changeProcessed', $event.target.checked)"
    />
    <span class="checkmark"></span>
  </label>
</template>
<script>
import { reactive, ref, computed } from 'vue';

export default {
  name: '',
  props: ['processed'],
  setup(props, { emit }) {
    const checked = computed(() => {
      return props.processed == 1 ? true : false;
    });
    return { checked };
  }
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .checkmark {
    display: block;
    height: 22px;
    width: 22px;
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px solid #cacaca;
    border-radius: 30px;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      display: none;
      width: 9px;
      height: 6px;
      border: solid white;
      border-width: 0px 0px 2px 2px;
      left: 0;
      right: 0;
      top: 25%;
      bottom: 25%;
      margin: 0 auto;
      transform: rotate(-45deg);
    }
  }
  &:hover input ~ .checkmark {
    background-color: #44c3ce;
  }
  input:checked ~ .checkmark {
    background-color: #44c3ce;
    border: none;
    &::after {
      display: block;
    }
  }
}
</style>
