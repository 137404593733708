import merge from 'lodash/merge';
export default {
  state: {
    postCaseReportData: {
      //送出結案報告需要的格式
      report_type: '', //youtube.facebook.instgram.other目前僅有四種
      yt_final_total_views: '',
      yt_first_week_mission_views: '',
      yt_final_total_likes: '',
      yt_final_total_comments: '',
      yt_final_interactions_rate: '',
      yt_final_subscribers: '',
      yt_first_week_impressions: '',
      yt_first_week_impressions_click_through_rate: '',
      yt_first_week_views: '',
      yt_first_week_view_rate: '',
      yt_first_week_unique_views: '',
      yt_female_percent: '',
      yt_male_percent: '',
      yt_highest_age_group: '',
      yt_second_highest_age_group: '',
      yt_add_subscribers: '',
      fb_views: '',
      fb_shares: '',
      fb_comments: '',
      fb_link_clicks: '',
      fb_other_clicks: '',
      fb_post_engagements: '',
      fb_post_reach: '',
      fb_reactions: '',
      ig_video_views: '',
      ig_reactions: '',
      ig_shares: '',
      ig_comments: '',
      ig_saves: '',
      ig_post_reach: '',
      other_info: [], //其他數據
      curve_perday: [], //每日數據
      report_banner_url: '', //封面照片
      other_img: [] //上傳附件
    },
    getCaseReportData: {
      //預覽結案報告需要的格式
      recordSection: {
        other: []
      }
    }
  },
  mutations: {
    SET_postCaseReportData(state, payload) {
      state.postCaseReportData = { ...state.postCaseReportData, ...payload };
    },
    SET_getCaseReportData(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key === 'recordSection') {
          // 如果是 recordSection 且包含 other，直接替換 recordSection.other
          state.getCaseReportData[key] = merge(
            {},
            state.getCaseReportData[key],
            payload[key]
          );
          state.getCaseReportData.recordSection.other =
            payload.recordSection.other;
        } else if (key === 'commentSection') {
          state.getCaseReportData[key] = merge(
            {},
            state.getCaseReportData[key],
            payload[key]
          );
          state.getCaseReportData.commentSection.imageUrl =
            payload.commentSection.imageUrl;
        } else if (typeof payload[key] === 'Object') {
          state.getCaseReportData[key] = merge(
            {},
            state.getCaseReportData[key],
            payload[key]
          );
        } else {
          state.getCaseReportData[key] = payload[key];
        }
      });

      localStorage.setItem(
        'caseReportForm',
        JSON.stringify(state.getCaseReportData)
      );
    },
    RESET_getCaseReportData(state) {
      state.getCaseReportData = {};
      localStorage.removeItem('caseReportForm');
    }
  },
  actions: {},
  getters: {}
};
