<template>
  <div><span uk-spinner="ratio: 1"></span></div>
</template>

<script>
export default {
  name: "Loading",
  components: {},
  setup() {},
};
</script>
<style lang="scss" scoped>
div {
  position: fixed;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.568);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 9999999;
  left: 0;
}
</style>
