<template>
  <MessageBox
    :msgItem="msgItem"
    :isShowCommentBox="isShowCommentBox"
    @showCommentBox="showCommentBox"
  >
    <template v-slot:checkbtn>
      <CheckBtn
        class="ml-2"
        :processed="msgItem.is_processed"
        @changeProcessed="changeProcessed"
      />
    </template>
    <template v-slot:responseCount>
      <ResponseCountBox
        :msgItem="msgItem"
        :isShowReplayItem="isShowReplayItem"
        @showReplayItem="showReplayItem"
      />
    </template>
  </MessageBox>

  <ul
    ref="replayItemWrapDom"
    style="
      border-left: 2px solid rgba(68, 195, 206, 0.3);
      padding-left: 30px;
      margin-left: 18px;
    "
  >
    <li
      class="replayItem mt-8"
      v-for="item in replayMessage.data.items"
      :key="item.message_id"
      v-if="replayMessage.data?.items && isShowReplayItem"
    >
      <MessageBox
        :msgItem="item"
        :isShowCommentBox="isShowCommentBox"
        @showCommentBox="showCommentBox"
      >
      </MessageBox>
    </li>
    <li
      class="replayCommentBox mt-8"
      v-if="isShowCommentBox"
      ref="replayCommentBoxDom"
    >
      <ReplayCommentBox
        ref="replayCommentDom"
        :msgItemId="msgItem.message_id"
        @closeCommentBox="closeCommentBox"
        @emitSendReplayMsg="sendReplayMsg"
      />
    </li>
  </ul>
</template>

<script>
import {
  ref,
  reactive,
  inject,
  nextTick,
  computed,
  provide,
  onUnmounted
} from 'vue';
import CheckBtn from './CheckBtn.vue';
import MessageBox from './MessageBox.vue';
import ReplayItem from './ReplayItem.vue';
import ResponseCountBox from './ResponseCountBox.vue';
import ReplayCommentBox from './ReplayCommentBox.vue';
import notification from '@/lib/notification';
export default {
  name: 'MessageItem',
  components: {
    MessageBox,
    CheckBtn,
    ResponseCountBox,
    ReplayItem,
    ReplayCommentBox
  },
  props: ['msgItem'],
  setup(props) {
    const user = computed(() => JSON.parse(localStorage.getItem('user')));
    const replayItemWrapDom = ref(null);
    const replayCommentBoxDom = ref(null);
    const replayCommentDom = ref(null);
    const getMessage = inject('getMessage');
    const getResponse = inject('getResponse');
    const updateMessagesProcessed = inject('updateMessagesProcessed');
    const scrollToBottom = inject('scrollToBottom');
    const replayMessage = reactive({
      data: null
    });
    const isShowReplayItem = ref(false);
    const isShowCommentBox = ref(false);
    let intervalId;

    const showReplayItem = async (messageId, isShow) => {
      isShowReplayItem.value = isShow;
      if (isShow) {
        replayMessage.data = await getResponse(messageId);

        intervalId = setInterval(async () => {
          if (!isShowReplayItem.value) {
            clearInterval(intervalId);
          } else {
            replayMessage.data = await getResponse(messageId);
          }
        }, 30000);
      }
    };
    const showCommentBox = isShow => {
      isShowCommentBox.value = isShow;
      if (isShow) {
        nextTick(() => {
          replayCommentBoxDom.value?.scrollIntoView(false);
          setTimeout(() => {
            replayCommentDom.value?.focusEditor();
          }, 800);
        });
      }
    };
    const closeCommentBox = () => {
      isShowCommentBox.value = false;
    };
    const sendReplayMsg = async () => {
      closeCommentBox();
      await showReplayItem(props.msgItem.message_id, true);
      nextTick(() => {
        replayItemWrapDom.value.scrollIntoView(false);
      });
    };
    const changeProcessed = async status => {
      try {
        await updateMessagesProcessed(props.msgItem.message_id, status);
        await getMessage();
        notification.notificationShow('success', '儲存處理狀態成功');
      } catch (err) {
        notification.notificationShow('warning', '儲存處理狀態失敗');
      }
    };
    const getResponseData = async () => {
      replayMessage.data = await getResponse(props.msgItem.message_id);
    };
    provide('getResponseData', getResponseData);

    onUnmounted(() => clearInterval(intervalId));

    return {
      user,
      replayMessage,
      isShowReplayItem,
      isShowCommentBox,
      showReplayItem,
      showCommentBox,
      closeCommentBox,
      sendReplayMsg,
      replayItemWrapDom,
      replayCommentBoxDom,
      replayCommentDom,
      changeProcessed,
      getResponseData
    };
  }
};
</script>

<style lang="scss" scoped>
.responseCountArea {
  color: #616161;
}
</style>
