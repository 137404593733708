<template>
  <div class="notification" ref="target">
    <div class="svg">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="icon"
        @click="openNotification"
      >
        <path
          d="M12.5096 3V5.04808M7.04808 12.5577C7.04808 9.14423 7.73077 5.04808 12.5096 5.04808C17.2885 5.04808 17.9712 9.14423 17.9712 12.5577C17.9712 15.9712 20.0192 18.0192 20.0192 18.0192H5C5 18.0192 7.04808 15.9712 7.04808 12.5577ZM15.2404 18.0192C15.2404 18.0192 15.2404 20.75 12.5096 20.75C9.77885 20.75 9.77885 18.0192 9.77885 18.0192H15.2404Z"
          stroke="#E96666"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="18.5"
          cy="5.5"
          r="4.5"
          fill="#E96666"
          v-if="notificationMeta.data.has_unread"
        />
      </svg>
    </div>
    <NotificationList ref="notification" />
    <audio
      id="notificationSound"
      :src="require('@/assets/sound/notification.mp3')"
    ></audio>
  </div>
</template>
<script>
import NotificationList from '@/components/navbar/notification/NotificationList';
import { ref, inject, watch, onMounted, provide } from 'vue';
import { onClickOutside } from '@vueuse/core';
import useNotification from '@/components/navbar/stateManagement/notification.js';
import { useStore } from 'vuex';

export default {
  components: { NotificationList },
  setup() {
    const store = useStore();
    const { init, notificationMeta } = useNotification();
    init();
    const hasNewNotification = inject('hasNewNotification');
    const audio = inject('NotificationAudio');
    onMounted(() => {
      audio.value = document.getElementById('notificationSound');
    });

    watch(hasNewNotification, val => {
      if (!val) return;
      init();
      hasNewNotification.value = false;
    });
    const openNotification = () => {
      notification.value.togglePopup();
      init();

      store.commit('showNotificationList', true);
    };
    const notification = ref();
    const target = ref();

    onClickOutside(target, function () {
      notification.value.closePopup();
      if (store.state.showNotification) {
        store.commit('showNotificationList', false);
      }
    });

    const closeNotification = function () {
      notification.value.closePopup();
      if (store.state.showNotification) {
        store.commit('showNotificationList', false);
      }
    };

    provide('closeNotification', closeNotification);
    provide('init', init);

    return { openNotification, notification, target, notificationMeta };
  }
};
</script>
<style lang="scss" scoped>
.notification {
  position: relative;
  width: 24px;
  height: 24px;
  .svg {
    .icon {
      cursor: pointer;
      path {
        stroke: #ffffff;
      }
    }
  }
  .notificationList {
    display: flex;
    position: absolute;
    background: red;
    top: 2rem;
    width: 25rem;
    right: 0;
    height: 80vh;
    z-index: 99;
  }
}
.onscrollItem {
  .notification {
    .svg {
      .icon {
        path {
          stroke: $brand-color;
        }
      }
    }
  }
}
#notificationSound {
  display: none;
}
</style>
