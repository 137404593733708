import Compressor from 'compressorjs';

export default {
  compressorImage(file) {
    return new Promise(resolve => {
      new Compressor(file, {
        quality: 1,
        maxWidth: 2000,
        maxHeight: 2000,
        success(result) {
          const img = new window.File([result], result.name, {
            type: result.type
          });
          resolve(img);
        },
        error(err) {
          console.log(err.message);
        }
      });
    });
  }
};
