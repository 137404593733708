<template>
  <div class="comment t-shadow_medium">
    <div class="uk-flex uk-flex-middle mb-4" v-show="isOpenCommentDOM">
      <div class="headShot mr-3">
        <Avatar
          class="avatar"
          size="36"
          :img="user?.avatar"
          :name="user?.company_name ?? user?.contact_person ?? user?.email"
        />
      </div>
      <div class="nameArea">
        <div class="user">
          <div class="name">
            {{ user?.company_name ?? user?.contact_person ?? user?.email }}
          </div>
        </div>
      </div>
    </div>
    <div class="quotation" v-show="isOpenCommentDOM && quotedValue">
      <p>{{ quotedValue?.['selectedText'] }}</p>
    </div>
    <Editor
      @setEditorData="setEditorData"
      :editorData="msgData"
      :id="`chat`"
      :editable="true"
      placeholder="請留下留言...."
      :defaultFocused="true"
      ref="editor"
      @setFocus="setFocus"
    />

    <PhotoArea v-if="photoList.data.length > 0" />

    <ActionButtonGroup
      v-show="isOpenCommentDOM"
      :loading="loading"
      :isSendMsgDisabled="
        msgData.trim() == '' || msgData == '<p></p>' || editorIsEmpty(msgData)
      "
      @uploadPhoto="uploadPhoto"
      @cancel="clearContent"
      @sendMsg="sendMsg"
    />
  </div>
</template>
<script>
import { ref, reactive, watch, computed, inject, nextTick, provide } from 'vue';
import Avatar from '@/components/tools/Avatar.vue';
import Editor from '@/components/editor/Editor.vue';
import SendMsgBtn from './SendMsgBtn.vue';
import { useThrottleFn } from '@vueuse/core';
import { editorIsEmpty } from '@/lib/editorIsEmpty';
import notification from '@/lib/notification';
import ActionButtonGroup from './ActionButtonGroup.vue';
import PhotoArea from './PhotoArea.vue';
export default {
  name: 'CommentBox',
  components: {
    Editor,
    Avatar,
    ActionButtonGroup,
    PhotoArea
  },
  emits: [''],
  setup(props, { emit }) {
    const quotedValue = inject('quotedValue');
    const isOpenCommentDOM = inject('isOpenCommentDOM');
    const user = computed(() => JSON.parse(localStorage.getItem('user')));
    const loading = ref(false);
    const msgData = ref('');
    const editor = ref(null);
    const sendMessage = inject('sendMessage');
    const getMessage = inject('getMessage');
    const scrollToBottom = inject('scrollToBottom');
    const chatState = inject('chatState');
    const photoList = reactive({
      data: [
        // {
        //   file: 'https://s3-ap-northeast-1.amazonaws.com/private.adpost.com.tw/copy-writing-images/9aab4a70-e596-11ed-97a6-ddcf843be66f_1698744182_ipDcSCqOUI.png'
        // }
      ]
    });
    provide('photoList', photoList);
    //電腦上傳
    const uploadPhoto = async e => {
      let uploadPhotos = e.target.files;
      if ([...uploadPhotos].length + photoList.data.length * 1 > 6) {
        notification.notificationShow('danger', '最多上傳6張照片');
        return false;
      }
      for (let i = 0; i < uploadPhotos.length; i++) {
        let img = uploadPhotos[i];

        const filesize = (img.size / 1024 / 1024).toFixed(4);
        if (filesize > 10) {
          notification.notificationShow(
            'danger',
            '上傳失敗，檔案大小勿超過10MB'
          );
          return false;
        }
        photoList.data.push(img);
      }

      e.target.value = '';
    };

    // const deletePhoto = index => {
    //   photoList.data.splice(index, 1);
    // };
    // const addS3Photo = ({ url, index }) => {
    //   photoList.data[index] = {
    //     file: url
    //   };
    // };
    //編輯器
    const setEditorData = editorData => {
      msgData.value = editorData;
    };

    const setFocus = () => {
      isOpenCommentDOM.value = true;
    };

    const clearContent = () => {
      msgData.value = '';
      isOpenCommentDOM.value = false;
      quotedValue.value = '';
      photoList.data = [];
    };

    // watch(msgData, val => {
    //   if (val == '<p></p>' || val == '') {
    //     isOpenCommentDOM.value = false;
    //   } else {
    //     isOpenCommentDOM.value = true;
    //   }
    // });

    const sendMsg = useThrottleFn(
      async () => {
        try {
          loading.value = true;
          await nextTick();
          await sendMessage(
            msgData.value,
            null,
            JSON.stringify(quotedValue.value),
            photoList.data
          );
          await getMessage();
        } catch (error) {
          notification.notificationShow('warning', '送出留言失敗');
        } finally {
          loading.value = false;
          quotedValue.value = '';
          msgData.value = '<p></p>';
          photoList.data = [];
          isOpenCommentDOM.value = false;
          scrollToBottom();
        }
      },
      5000,
      false
    );

    return {
      user,
      loading,
      msgData,
      editor,
      isOpenCommentDOM,
      quotedValue,
      editorIsEmpty,
      setEditorData,
      setFocus,
      clearContent,
      sendMsg,
      photoList,
      uploadPhoto,
      chatState
    };
  }
};
</script>
<style lang="scss" scoped>
figure {
  // margin: 0;
}
.photoWrap {
  position: relative;
  height: 0;
  padding-top: 75%;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
  }
}
</style>
