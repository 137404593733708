<template>
  <div
    class="responseCountArea uk-flex uk-flex-center"
    v-if="msgItem.response_count > 0"
    @click.stop="emitshowReplayItem"
  >
    <img :src="require('@/assets/icon/message.svg')" width="22" />
    <button type="button" class="text-s ml-2" v-if="!isShowReplayItemVal">
      {{ msgItem.response_count }}回覆
    </button>
    <button type="button" class="text-s ml-2" v-else>隱藏回覆</button>
  </div>
</template>
<script>
import { reactive, ref, watch } from 'vue';

export default {
  name: 'responseCountBox',
  props: ['msgItem', 'isShowReplayItem'],
  emits: ['showReplayItem'],
  setup(props, { emit }) {
    const isShowReplayItemVal = ref(props.isShowReplayItem);

    watch(
      () => props.isShowReplayItem,
      newVal => {
        isShowReplayItemVal.value = newVal;
      }
    );

    const emitshowReplayItem = () => {
      isShowReplayItemVal.value = !isShowReplayItemVal.value;
      emit(
        'showReplayItem',
        props.msgItem.message_id,
        isShowReplayItemVal.value
      );
    };
    return { isShowReplayItemVal, emitshowReplayItem };
  }
};
</script>
<style lang="scss" scoped>
.responseCountArea {
  button {
    color: #616161;
  }
}
</style>
