import { computed } from 'vue';
import { useStore } from 'vuex';

export function useOnboarding() {
  const store = useStore();

  const steps = computed(() => store.state.onBoardingStep.steps);

  const currentStepPage = computed(
    () => store.state.onBoardingStep.currentStepPage
  );

  const currentStepPageStatus = computed(
    () => store.state.onBoardingStep.currentStepPageStatus
  );

  const onboardingFinish = computed(
    () => store.state.onBoardingStep.onboardingFinish
  );
  const onboardingCurrentStep = computed(
    () => store.state.onBoardingStep.onboardingCurrentStep
  );
  const onboardingTotalSteps = computed(
    () => store.state.onBoardingStep.onboardingTotalSteps
  );

  return {
    steps,
    currentStepPage,
    currentStepPageStatus,
    onboardingFinish,
    onboardingCurrentStep,
    onboardingTotalSteps
  };
}
