<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    id="registerModal"
    v-if="showModal"
    @click-outside="clickOutside"
  >
    <div
      class="modal__content uk-width-1-1 uk-card uk-card-body uk-background-default uk-border-rounded"
      uk-animation-fade
    >
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
        @click="closeBtn"
      ></button>
      <h3
        class="uk-card-title uk-text-bold uk-text-uppercase uk-flex uk-flex-center"
      >
        註冊
      </h3>

      <div class="uk-position-relative character" v-if="!openSignStep">
        <label
          class="uk-form-label uk-flex uk-flex-center uk-width-1-1"
          for="form-stacked-text"
          >請選擇身份?</label
        >
        <div class="uk-flex uk-flex-around" style="height: 230px">
          <div class="uk-form-controls radioInput">
            <input
              type="radio"
              value="default"
              v-model.trim="selectedType"
              style="display: none"
            />
            <input
              type="radio"
              id="vendor"
              class="typeInput"
              value="vendor"
              v-model.trim="selectedType"
            />
            <label for="vendor" style="transform: none">
              <div class="uk-text-center img" id="vendorBtn">
                <div
                  class="uk-inline-clip uk-transition-toggle uk-light"
                  tabindex="0"
                >
                  <img :src="require('@/assets/image/vendor.png')" alt="" />
                  <div class="uk-overlay uk-overlay-default uk-position-bottom">
                    <p>廠商</p>
                  </div>
                </div>
              </div>
            </label>
          </div>
          <div class="uk-form-controls radioInput">
            <input
              type="radio"
              id="creator"
              class="typeInput"
              value="creator"
              v-model.trim="selectedType"
            />
            <label for="creator" style="transform: none">
              <div class="uk-text-center img" id="creatorBtn">
                <div
                  class="uk-inline-clip uk-transition-toggle uk-light un-inline"
                  tabindex="0"
                >
                  <div class="uk-overlay uk-overlay-default uk-position-bottom">
                    <p>網紅</p>
                  </div>
                  <img :src="require('@/assets/image/creator.png')" alt="" />
                </div>
              </div>
            </label>
          </div>
        </div>
        <div class="mt-12">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <button
              class="uk-button confirmBtn"
              :disabled="selectedType == null"
              @click="openSignStep = true"
            >
              下一步
            </button>
          </div>
        </div>
        <div class="mt-6">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <small class="loginNow" @click="openOtherModal('LoginModal')"
              >已經有帳號嗎? <span style="color: #73d1dd">立即登入</span></small
            >
          </div>
        </div>
      </div>

      <component
        :is="`${selectedType}Form`"
        v-if="openSignStep"
        :type="selectedType"
        @closeModal="closeModal"
      ></component>
    </div>
  </vue-final-modal>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import VALIDATE from '@/lib/validate';
import { VueFinalModal } from 'vue-final-modal';
import vendorForm from '../singUp/vendorForm.vue';
import creatorForm from '../singUp/creatorForm.vue';
export default {
  name: 'RegisterModal',
  components: { VueFinalModal, vendorForm, creatorForm },
  emits: ['openModal', 'open-modal'],
  setup(props, { emit }) {
    const selectedType = ref(null);
    const openSignStep = ref(false);
    const showModal = ref(false);
    // const resetForm = () => {
    //   registerData.email = '';
    //   registerData.password = '';
    //   registerData.type = 'default';
    //   registerData.phone = '';
    //   registerData.contact_person = '';
    //   registerData.company_name = '';
    //   errorMessage.telError = null;
    //   errorMessage.emailError = null;
    //   errorMessage.emailRepeatError = null;
    //   errorMessage.passwordError = null;
    //   rulesCheckData.value = false;
    //   openPassword.value = false;
    //   status.step1 = false;
    //   status.step2 = false;
    //   status.step3 = false;
    // };
    const openOtherModal = async modalName => {
      showModal.value = false;
      setTimeout(() => {
        emit('open-modal', modalName);
      }, 500);
    };
    const openModal = () => {
      showModal.value = true;
    };

    const closeBtn = () => {
      showModal.value = false;
      openSignStep.value = false;
    };

    const closeModal = () => {
      closeBtn();
    };

    const clickOutside = () => {
      closeBtn();
    };

    return {
      selectedType,
      openSignStep,
      showModal,
      openModal,
      openOtherModal,
      closeBtn,
      closeModal,
      clickOutside
    };
  }
};
</script>
<style lang="scss" scoped>
.typeInput {
  visibility: hidden;
}
input[type='radio'] + label .img {
  border: solid 5px white !important;
  border-radius: 25px;
}
input[type='radio']:checked + label .img {
  border: solid 5px #68c0cc !important;
  border-radius: 25px;
}
#registerModal:deep .modal__content {
  width: 680px;
  height: 720px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 95vw;
  transition: 0.3s linear;
  overflow-y: auto;
  max-height: 90vh;

  .uk-active {
    a {
      border-radius: 0;
    }
  }
  input {
    &:focus + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
    &:required:valid + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
  }

  textarea {
    border: 1px solid rgb(229, 229, 229);
    min-height: 48px;

    &:focus + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
    &:required:valid + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
  }
  #cooperation_fee {
    height: auto;
  }

  .infoForm {
    input:not(:placeholder-shown) + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
    textarea:not(:placeholder-shown) + label {
      transform: translate(-0.5rem, -50%);
      background: #fff;
      top: 0;
    }
  }

  label {
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    transition: transform 0.5s ease-in-out;
    padding: 0 2px;
  }

  ul {
    width: 488px;
  }
  input[type='mail'],
  input[type='tel'],
  input[type='text'],
  input[type='password'] {
    height: 48px;
    width: 100%;
    border-radius: 4px;
  }
  input[type='checkbox'] {
    margin-right: 6px;
    margin-top: 0;
  }

  h3 {
    margin-top: 48px;
    margin-bottom: 48px;
    font-size: 32px;
    color: #73d1dd;
  }
  button {
    &:last-child {
      width: 168px;
      height: 48px;
      border-radius: 35px;
    }
  }
  .registerBtn.disable {
    pointer-events: none;
  }

  small {
    a {
      font-size: 12px;
    }
  }
  .character {
    label {
      top: 0;
      transform: unset;
      font-size: 20px;
      margin: 20px 0 0 0;
    }
    .img {
      width: 220px;
      height: 190px;
      & > div {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }
  }
  .uk-overlay {
    padding: 0;
    p {
      background: #68c0cc;
      color: white !important;
    }
  }
  .radioInput {
    margin-top: 20px;
    display: flex;
    input,
    label {
      height: 0;
      margin: 0;
    }
  }
  .loginNow {
    cursor: pointer;
  }

  .rulesCheck:checked {
    background-color: #73d1dd;
  }

  .link-disabled {
    pointer-events: none;
  }
  .disabled {
    pointer-events: none;
  }
}
@media (max-width: 560px) {
  #registerModal:deep .modal__content {
    width: 95vw;
    ul {
      width: 100%;
    }
    input[type='mail'] {
      width: 100%;
    }
    form {
      width: 90%;
      margin: 0 auto;
    }
    .character .img {
      width: 90%;
      height: auto;
    }
    .character .uk-form-controls {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
