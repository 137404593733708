<template>
  <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar sideMessageArea">
      <div class="messageArea">
        <div class="backIcon t-shadow_light">
          <img
            :src="require(`@/assets/icon/arrow_back.svg`)"
            width="24"
            height="24"
            @click="closeOffcanvas"
          />
          <span>{{ chatState.other.name }}</span>
        </div>
        <div
          class="toBottomBtn shadow-light"
          @click="toBottom"
          v-if="showToBottom"
        >
          <img
            :src="require(`@/assets/icon/arrow_back.svg`)"
            width="24"
            height="24"
          />
        </div>
        <div class="messageBox">
          <div v-if="chatMessage.messages?.data">
            <div
              class="message"
              v-if="Object.keys(chatMessage.messages?.data).length"
            >
              <div
                class="dateArea"
                v-for="(item, date, index) in chatMessage.messages.data"
                :key="index"
              >
                <div class="dateItem">
                  <span
                    v-if="
                      new Date().getTime() - new Date(date).getTime() < 86400000
                    "
                  >
                    今天
                  </span>
                  <span v-else>
                    {{ date }}
                  </span>
                </div>
                <div
                  class="messageItem"
                  v-for="(item2, index2) in item"
                  :key="index2"
                >
                  <div class="headShot">
                    <Avatar
                      class="avatar"
                      size="28"
                      :img="
                        item2.avatar
                          ? `${baseUrl}/api/editor/image/${item2.avatar}`
                          : null
                      "
                      :name="item2.created_by"
                    />
                  </div>
                  <div class="contentArea">
                    <div class="nameArea">
                      <div class="user">
                        <div class="name">
                          {{ item2.created_by }}
                        </div>
                        <div class="company" v-if="item2.company_name">
                          {{ item2.company_name }}
                        </div>
                      </div>

                      <div class="date">
                        {{
                          new Date(item2.created_at)
                            .toLocaleTimeString('en-GB')
                            .substr(0, item2.time.length - 3)
                        }}
                      </div>
                    </div>
                    <div class="content txtS">
                      <p v-html="item2.message"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="emptyMessage" v-else>
              <img :src="require('@/assets/icon/emptyMessage.svg')" />
            </div>
          </div>
        </div>

        <div
          class="commentBox uk-position-relative"
          id="chatRoom"
          v-if="chatState.other.showInput"
        >
          <div class="blurBg" v-if="loading">
            <div uk-spinner v-if="loading"></div>
          </div>
          <div class="comment">
            <Editor
              @setEditorData="setEditorData"
              :editorData="msgData"
              :id="`chat`"
              :editable="true"
              placeholder="留下訊息"
              :defaultFocused="true"
              ref="editor"
            />
            <label class="sendIcon"
              ><button
                @click="sendMessage"
                uk-tooltip="title: 按 Enter 即可傳送; pos: top"
              >
                <img
                  :src="require('@/assets/icon/send.svg')"
                  width="24"
                  height="24"
                  uk-svg
                /></button
            ></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, nextTick, provide, onMounted, inject } from 'vue';
import Editor from '@/components/editor/Editor.vue';
import Avatar from '@/components/tools/Avatar.vue';

export default {
  name: 'ChatRoom',
  components: {
    Editor,
    Avatar
  },
  setup() {
    const baseUrl = inject('baseUrl');
    const loading = ref(false);
    const chatState = inject('chatState');
    const chatMessage = inject('chatMessage');
    const msgData = ref('');
    const editor = ref(null);
    const getMessage = inject('getMessage');
    const sendMsg = inject('sendMessage');
    const closeOffcanvas = () => {
      UIkit.offcanvas('#offcanvas-flip').hide();
    };
    watch(
      () => chatMessage?.message?.data,
      () => {
        console.log('Change');
        nextTick(() => {
          const messageBox = document.querySelector('.messageBox > div');
          if (messageBox) {
            messageBox.scrollTop = messageBox.scrollHeight;
          }
          editor.value.focusEditor();
        });
      }
    );
    const sendMessage = () => {
      loading.value = true;
      sendMsg(msgData.value).then(() => {
        setTimeout(() => {
          getMessage();
          console.log(chatMessage);
          loading.value = false;
        }, 1000);
      });
      msgData.value = '';
    };

    provide('msgData', msgData);
    provide('sendMessage', sendMessage);
    onMounted(() => {
      UIkit.util.on('#offcanvas-flip', 'show', function () {
        setTimeout(() => {
          checkMessagePosition();
        }, 500);
      });
      UIkit.util.on('#offcanvas-flip', 'hide', function () {
        chatMessage.messages.data = null;
      });

      const editor = document.getElementById('chatEditor');
      //按下enter才送出
      if (editor) {
        editor.addEventListener('keydown', e => {
          let key = e.keyCode || e.which;
          if (key == 13 && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          }
        });
      }
    });
    //編輯器
    const setEditorData = editorData => {
      msgData.value = editorData;
    };
    // 聊天室回最底部
    const toBottom = () => {
      const messageBox = document.querySelector('.messageBox > div');
      messageBox.scrollTo({
        top: messageBox.scrollHeight,
        behavior: 'smooth'
      });
    };
    //顯示置底按鈕
    const showToBottom = ref(false);
    const checkMessagePosition = () => {
      const messageBox = document.querySelector('.messageBox > div');
      messageBox.addEventListener('scroll', function () {
        if (
          messageBox.clientHeight +
            messageBox.scrollTop -
            messageBox.scrollHeight !=
          0
        ) {
          showToBottom.value = true;
        } else {
          showToBottom.value = false;
        }
      });
    };
    return {
      baseUrl,
      chatState,
      msgData,
      getMessage,
      sendMessage,
      chatMessage,
      loading,
      editor,
      toBottom,
      closeOffcanvas,
      setEditorData,
      showToBottom
    };
  }
};
</script>
<style lang="scss">
.commentBox#chatRoom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .blurBg {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(1px);
    .uk-spinner {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      :deep svg {
        circle {
          stroke: currentColor !important;
        }
      }
    }
  }
  .comment {
    height: auto;
    width: 95%;
    border-radius: 4px;
    border: 0.5px solid #616161;
    padding: 8px;
    resize: none;
    font-size: 16px;
    text-align: left;
    display: flex;
    :deep .ourEditor {
      flex: auto;
      width: 85%;
      > div {
        max-height: 100px;
        overflow-y: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.sideMessageArea {
  padding: 0;
  background: #f9f9f9;
  color: #616161;
  width: 450px;
  .messageArea {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .backIcon {
      display: flex;
      justify-content: flex-start;
      padding: 1rem;
      position: relative;
      img {
        position: absolute;
        cursor: pointer;
      }
      span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
      }
      &.onScroll {
        box-shadow: 3px 4px 10px rgb(0 0 0 / 10%);
      }
    }
    .toBottomBtn {
      position: absolute;
      bottom: 4rem;
      right: 1.5rem;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: grid;
      place-items: center;
      background: $color-white;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      img {
        transform: rotate(90deg);
      }
    }
    .messageBox {
      height: calc(100% - 130px);
      flex: 1;
      > div {
        height: 100%;
        scroll-behavior: auto;
        overflow-y: auto;
        .message {
          .dateArea {
            .dateItem {
              margin: 1rem 0;
              span {
                min-width: 100px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.06em;
                padding: 4px 12px;
                box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.06);
                border-radius: 6px;
                color: rgba(0, 0, 0, 0.32);
              }
            }
            .messageItem {
              padding: 0 16px;
              display: flex;
              margin-bottom: 1.5rem;
              .headShot {
                .avatar {
                  height: 28px;
                  width: 28px;
                  overflow: hidden;
                  background-size: cover;
                  background-position: 50% 50%;
                  border-radius: 50%;
                }
                margin-right: 12px;
              }
              .contentArea {
                flex-grow: 1;
                .nameArea {
                  display: flex;
                  // margin-bottom: 0.2rem;
                  .user {
                    display: flex;
                    flex-direction: column;
                    flex: auto;
                    .name,
                    .company {
                      margin-right: 8px;
                      font-weight: bolder;
                      font-size: 13px;
                      letter-spacing: 0.155em;
                      color: #000000;
                      text-align: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      white-space: normal;
                      line-height: 12px;
                    }
                    .company {
                      font-size: 12px;
                      color: $disable-gray-color;
                      margin: 4px 0 8px 0;
                      font-size: 10px;
                    }
                  }
                  .date {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.06em;
                    color: rgba(0, 0, 0, 0.32);
                    display: flex;
                    align-items: flex-start;
                  }
                }
                .content {
                  text-align: left;
                  max-width: 90%;
                  width: fit-content;
                  background: #f3f3f3;
                  padding: 0.5rem;
                  border-radius: 6px;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .emptyMessage {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
    .commentBox {
      bottom: 0;
      width: 100%;
      padding: 12px 0;
      .sendIcon {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        button {
          background: transparent;
          border: unset;
          outline: unset;
          cursor: pointer;
          :deep svg {
            path {
              fill: $color-light-green !important;
            }
          }
        }
      }
    }
  }
}
</style>
