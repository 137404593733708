<template>
  <label
    uk-tooltip="title: 圖片上傳; pos: top"
    class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center"
  >
    <input
      type="file"
      accept=".jpg,.png,.jpeg"
      style="display: none"
      @change="$emit('uploadPhoto', $event)"
      multiple
    />
    <img :src="require('@/assets/icon/camera.svg')" width="24" height="24" />
  </label>
</template>
<script>
import { reactive, ref } from 'vue';

export default {
  name: '',
  props: [],
  emits: ['uploadPhoto'],
  setup(props, { emit }) {
    return {};
  }
};
</script>
<style lang="scss" scoped></style>
