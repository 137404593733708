<template>
  <span @click="moveUp" v-if="toTop" class="toTop">
    <img
      :src="require('@/assets/icon/scrolltop.svg')"
      width="36"
      height="36"
      uk-svg
    />
  </span>
</template>
<script>
import { onMounted, ref } from 'vue';
export default {
  setup() {
    const moveUp = () => window.scrollTo(0, 0);
    const toTop = ref(false);
    onMounted(() => {
      // toTopBtn
      document.addEventListener('scroll', function () {
        if (
          document.documentElement.scrollTop > 100 &&
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight -
            document.documentElement.scrollTop >
            268
        ) {
          toTop.value = true;
        } else {
          toTop.value = false;
        }
      });
    });
    return { moveUp, toTop };
  }
};
</script>
<style lang="scss" scoped>
.toTop {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: pointer;
}
</style>
