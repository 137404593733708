<template>
  <div class="content">
    <div>
      <!-- 01新增 -->
      <article v-if="code.action == '01'">
        <p v-if="code.function == '050'">
          <span>{{ formattedName }}</span
          >已新增<span>第{{ parameters.version }}版</span>影片。
        </p>
        <p v-if="code.function == '010'">
          您的專案<span>{{ parameters.project_name }}</span
          >已與<span>{{ name.name }}</span
          >建立合作案。
        </p>
        <p v-if="code.function == '020'">
          <span>{{ formattedName }}</span
          >已提供<span>{{ functionList[code.function] }}</span
          >。
        </p>
        <p v-if="code.function == '030' || code.function == '040'">
          <span>{{ formattedName }}</span
          >已新增<span>{{ functionList[code.function] }}</span
          >。
        </p>
        <p v-if="code.function == '060'">
          <span>{{ formattedName }}</span
          >已新增<span>{{ parameters.platform }}</span
          >的文案。
        </p>

        <p v-if="code.function == '090'">
          <span>{{ formattedName }}</span
          >提供了<span>{{ functionList[code.function] }}</span
          >。
        </p>
        <p v-if="code.function == '070'">
          <span>{{ formattedName }}</span
          >已新增<span>{{ parameters.platform }}</span
          >的{{ functionList[code.function] }}。
        </p>

        <p v-if="code.function == '100'">
          <span>感謝您使用我們的平台！</span>
          為確保您不會錯過任何重要訊息和即時通知，請至<span>個人中心</span>完成
          LINE 通知的綁定
        </p>
      </article>
      <!-- 02更新 -->
      <article v-if="code.action == '02'">
        <p
          v-if="
            code.function == '020' ||
            code.function == '030' ||
            code.function == '040'
          "
        >
          <span>{{ formattedName }}</span
          >已更新<span>{{ functionList[code.function] }}</span
          >。
        </p>
        <p v-if="code.function == '050' && code.identity == 1">
          <span>{{ formattedName }}</span
          >更新了<span>第{{ parameters.version }}版</span>影片意見。
        </p>
        <p v-if="code.function == '050' && code.identity == 2">
          <span>{{ formattedName }}</span
          >更新了<span>第{{ parameters.version }}版</span>影片連結。
        </p>
        <p v-if="code.function == '060'">
          <span>{{ formattedName }}</span
          >已更新<span>{{ parameters.platform }}</span
          >的文案。
        </p>

        <!--文案撰寫 廠商 更新 完成選圖-->
        <p v-if="code.function == '061' && code.identity == 1">
          <span>{{ formattedName }}</span
          >已完成<span>{{ parameters.platform }}</span
          >的照片選圖
        </p>
        <!--文案撰寫 網紅 更新 上傳照片-->
        <p v-if="code.function == '061' && code.identity == 2">
          <span>{{ parameters.kol_name }}</span> 已上傳照片至<span
            >{{ parameters.platform }}的{{
              functionList[code.function]
            }}。</span
          >
        </p>

        <p v-if="code.function == '070'">
          <span>{{ formattedName }}</span
          >已更新<span>{{ parameters.platform }}</span
          ><span>{{ functionList[code.function] }}</span
          >。
        </p>

        <p v-if="code.function == '090'">
          <span>{{ formattedName }}</span
          >更新了{{ functionList[code.function] }}。
        </p>
      </article>
      <!-- 03封存 -->
      <article v-if="code.action == '03'">
        <p>
          <span>{{ formattedName }}</span
          >已封存<span v-if="code.function == '040'"
            >{{ `第${parameters.order}幕`
            }}{{ functionList[code.function] }}</span
          ><span v-if="code.function == '060'"
            >{{ `${parameters.platform}`
            }}{{ functionList[code.function] }}</span
          >。
        </p>
      </article>
      <!-- 04留言 -->
      <article v-if="code.action == '04'">
        <p>
          <span>{{ formattedName }}</span
          >在<span>{{ functionList[code.function] }}</span
          ><span v-if="code.function == '030'">{{
            outlineColumn[parameters.column_name]
          }}</span
          ><span v-if="code.function == '040'"
            >第{{ parameters.order }}幕{{
              draftColumn[parameters.column_name]
            }}</span
          ><span v-if="code.function == '050'"
            >第{{ parameters.version }}版{{
              `${parameters.time.hour}:${parameters.time.minute}:${parameters.time.second}`
            }}</span
          ><span v-if="code.function == '060'">{{ parameters.platform }}</span
          >留下了新留言。
        </p>
      </article>
      <!-- 05過稿 -->
      <article v-if="code.action == '05'">
        <p v-if="code.identity == '1'">
          <span>{{ formattedName }}</span
          >在<span
            >{{ functionList[code.function] }}的過稿{{
              parameters.status == 'success' ? '已通過' : '未通過'
            }}</span
          >。
        </p>
        <p v-if="code.identity == '2'">
          <span>{{ formattedName }}</span
          >在<span>{{ functionList[code.function] }}申請過稿。</span>
        </p>
      </article>
      <!-- 06時程 -->
      <article v-if="code.action == '06'">
        <!-- <p>
         <span>{{formattedName}}</span>在<span>{{ functionList[code.function] }}</span
          >設定了<span>{{ taskTimeColumn[parameters.column_name] }}</span
          >。
        </p> -->
        <p v-if="code.function == '090'">
          <span>{{ formattedName }}</span
          >確認了{{ functionList[code.function] }}。
        </p>
      </article>
      <!-- 07結案報告 -->
      <article v-if="code.action == '07'">
        <p>
          您與<span>{{ name.name }}</span
          >的{{ parameters.platform }}合作案已產生結案報告。
        </p>
      </article>
      <!-- 08回覆留言 -->
      <article v-if="code.action == '08'">
        <p v-if="code.function == '040'">
          <span>{{ formattedName }}</span
          >在{{ functionList[code.function]
          }}{{ `第${parameters.order}幕` }}回覆了留言。
        </p>
        <p v-else-if="code.function == '060'">
          <span>{{ formattedName }}</span
          >在{{ functionList[code.function]
          }}{{ parameters.platform }}回覆了留言。
        </p>
        <p v-else>
          <span>{{ formattedName }}</span
          >在{{ functionList[code.function] }}回覆了留言。
        </p>
      </article>
      <!-- 09文案比對 -->
      <article v-if="code.action == '09'">
        <p>
          <span>{{ formattedName }}</span
          >已完成{{ parameters.platform
          }}{{ functionList[code.function] }}的文案比對。
        </p>
      </article>

      <!-- 10邀約-邀請-->
      <article v-if="code.action == '10'">
        <p v-if="code.identity == 1">
          {{ parameters.company_name }}_{{ parameters.name }}
        </p>
        <p v-if="code.identity == 1">邀請您加入{{ parameters.project_name }}</p>
        <p v-if="code.identity == 2">
          {{ parameters.kol_name }}
        </p>
        <p v-if="code.identity == 2">邀請您一同合作</p>

        <!-- <p>{{ parameters.company_name }}</p> -->
      </article>

      <!-- 11邀約-通知 -->
      <article v-if="code.action == '11'">
        <!-- 廠商收到網紅 -->
        <p v-if="code.identity == 1">
          {{ parameters.kol_name }}
        </p>
        <p v-if="code.identity == 1">加入了{{ parameters.project_name }}</p>

        <!-- 網紅收到廠商 -->
        <p v-if="code.identity == 2">
          {{ parameters.company_name }}-{{ parameters.name }}
        </p>
        <p v-if="code.identity == 2">創建了{{ parameters.project_name }}</p>
      </article>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  props: ['name', 'code', 'parameters'],
  /*
  identity:
    1:廠商 2:網紅 3:adpost
  function:
    "010": "合作案",
    "020": "需求單",
    "030": "創意大綱",
    "040": "腳本細節",
    "050": "影片製作",
    "060": "文案撰寫",
    "061": "文案撰寫照片",    
    "070": "作品上線",
    "080": "結案報告",
    '090': '專案時程'
    '100': '第三方功能-line綁定'
  action:
    01新增
    02更新
    03封存
    04留言
    05過稿
    06時程
    07結案報告
    08回覆留言
    09文案比對
    10邀約-邀請
    11邀約-通知
  */

  setup(props) {
    const functionList = {
      '010': '合作案',
      '020': '需求單',
      '030': '創意大綱',
      '040': '腳本細節',
      '050': '影片製作',
      '060': '文案撰寫',
      '061': '文案撰寫照片',
      '070': '作品上線',
      '080': '結案報告',
      '090': '專案時程'
    };
    const outlineColumn = {
      pre_order_resources: '預約資源',
      video_lasts: '影片長度',
      notion: '拍攝理念',
      video_style: '影片風格',
      outline: '內容大綱',
      example_url: '參考範例',
      memo: '備註說明'
    };
    const draftColumn = {
      situation: '情境畫面',
      subtitle: '字幕與旁白',
      actor: '演員',
      motion: '動作或情感',
      location: '地點'
    };
    const taskTimeColumn = {
      except_date: '預計提交時程',
      except_deadline: '最晚回覆時間',
      shoot_date: '預計拍攝時程',
      except_a_copy_date: '預計提交第一版',
      review_a_copy_date: '最晚回覆第一版',
      except_b_copy_date: '預計提交第二版',
      review_b_copy_date: '最晚回覆第二版'
    };

    const formattedName = computed(() => {
      console.log('props:', props);
      return props.code.identity === '1'
        ? `${props.name.company}${
            props.name.company && props.name.name ? '_' : ''
          }${props.name.name} `
        : `${props.parameters.kol_name} `;
    });
    return {
      functionList,
      outlineColumn,
      draftColumn,
      taskTimeColumn,
      formattedName
    };
  }
};
</script>
<style lang="scss" scoped>
.content {
  div {
    p {
      margin: 0;
      padding: 0;
      /* span {
        color: black;
      } */
    }
  }
}
</style>
