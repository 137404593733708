<template>
  <div class="modal" id="modal">
    <div class="text-s content">
      <span class="close" @click="closeModal">&times;</span>

      <div class="noticeIcon">
        <img :src="require('@/assets/image/notice.svg')" alt="" />
      </div>
      <span class="text">
        <p class="title">
          關心您，AD POST不會主動要求您操作 ATM 匯款至指定帳戶
        </p>
        <p class="title">
          若有接到疑似詐騙資訊，請聯絡客服或撥打 165 反詐騙專線。
        </p>
      </span>
      <p class="mobile-title">
        關心您，AD POST不會主動要求您操作 ATM
        匯款至指定帳戶，若有接到疑似詐騙資訊，請聯絡客服或撥打 165 反詐騙專線。
      </p>
      <div class="adpostName">AD POST團隊</div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    localStorage.setItem('isFraud', true);
    function closeModal() {
      const modal = document.getElementById('modal');
      modal.style.display = 'none';
    }
    return {
      closeModal
    };
  }
};
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 99999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.6);
}
.content {
  position: relative;
  background-color: #fefefe;
  padding: 40px;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -25%);
  border-radius: 3px;
  width: 50%;
  max-width: 600px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .noticeIcon {
    img {
      width: 40%;
      min-width: 145px;
    }
  }
  .text {
    margin: 40px 0 62px 0;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      justify-content: center;
      text-align: left;
      margin: 0;
      color: #616161;
      font-size: 1rem;
      font-weight: 900;
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
  .mobile-title {
    display: none;
  }
  .adpostName {
    color: #616161;
    text-align: right;
    font-size: 1rem;
  }
}
.close {
  position: absolute;
  top: 0;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .content {
    width: 70%;
  }
}
@media (max-width: 425px) {
  .content {
    width: 95%;
    padding: 50px;
    .text {
      display: none;
    }
    .mobile-title {
      display: block;
      text-align: left;
      font-size: 14px;
      color: #616161;
      letter-spacing: 0.15em;
      line-height: 1.5rem;
    }
  }
}
</style>
