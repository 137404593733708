<template>
  <button
    class="quote"
    :uk-tooltip="`title:引文;pos:top;`"
    @click="handleQuote"
  >
    <img
      :src="require('@/assets/icon/editor/chat-quote.png')"
      width="24"
      height="24"
    />
  </button>
</template>
<script>
import { reactive, ref } from 'vue';

export default {
  name: '',
  setup(props, { emit }) {
    const handleQuote = () => {
      emit('quoteSelectedText');
    };

    return {
      handleQuote
    };
  }
};
</script>

<style lang="scss" scoped>
.quote {
  &::before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #000;
    opacity: 0.6;
    margin: 0 6px 0 0px;
  }
}
</style>
