<template>
  <div class="navItem" :class="{ onscrollItem: onscroll, onscrollItem: logo }">
    <div class="logo">
      <a href="/">
        <img
          :src="
            require('@/assets/icon/' + `${logo ? 'Frame' : 'LOGO'}` + '.svg')
          "
          alt="adpost"
          width="140"
          height="40"
        />
      </a>
    </div>
    <div class="menu login">
      <div class="item shadow">
        <a
          href="https://blog.adpost.com.tw"
          target="_blank"
          rel="noreferrer noopener"
          >部落格</a
        >
      </div>
      <div class="item project">
        <router-link to="/project">專案管理</router-link>
      </div>
      <div class="items">
        <Chat />
      </div>
      <div class="items notification">
        <Notification />
      </div>
      <div class="item userInfoArea" @click.stop="openMenu = !openMenu">
        <div class="svg">
          <Avatar
            size="28"
            :img="user?.avatar"
            :name="user?.name ?? user?.company_name ?? user?.email"
            class="avatar"
          />
        </div>
        <ul class="userInfoList" v-if="openMenu">
          <li>
            <router-link class="txt-shadow" to="/profile">個人中心</router-link>
          </li>
          <li>
            <a class="txt-shadow" @click="logout">登出</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="bars tw-ml-[16px]" uk-toggle="target: #sideNav">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
  <div id="sideNav" uk-offcanvas=" overlay: true">
    <div class="uk-offcanvas-bar sideBarArea">
      <div class="menu">
        <div class="item uk-offcanvas-close">
          <router-link class="txt-shadow" to="/profile">個人中心</router-link>
        </div>
        <div class="item uk-offcanvas-close">
          <router-link class="txt-shadow" to="/project">專案管理</router-link>
        </div>
        <div class="item">
          <a
            href="https://blog.adpost.com.tw"
            target="_blank"
            class="txt-shadow"
            rel="noreferrer noopener"
            >部落格</a
          >
        </div>
        <div
          class="item uk-offcanvas-close"
          @click="closeSideNav(sideNav, logout)"
        >
          <span class="txt-shadow">登出</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Notification from '@/components/navbar/notification/index';
import Chat from '@/components/navbar/Chat';

import Avatar from '@/components/tools/Avatar.vue';

export default {
  name: 'LoggedIn',
  components: { Notification, Avatar, Chat },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onscroll = inject('onscroll');
    const closeSideNav = inject('closeSideNav');
    const user = computed(() => store.getters.userData);

    const fixed = inject('fixed');
    const logo = computed(() => {
      if (fixed.value) {
        if (onscroll.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    document.addEventListener('click', e => {
      if (e) {
        openMenu.value = false;
      }
    });
    const logout = () => {
      //離線通知
      setTimeout(function () {
        store.dispatch('logout').then(() => {
          router.push('/');
          store.commit('showChat', false);
        });
      }, 500);
    };
    const openMenu = ref(false);
    const sideNav = ref(null);
    const connectionIo = inject('connectionIo');

    onMounted(() => {
      sideNav.value = document.getElementById('sideNav');
      connectionIo();
    });

    return {
      onscroll,
      logo,
      openMenu,
      logout,
      closeSideNav,
      sideNav,
      user
    };
  }
};
</script>
<style lang="scss" scoped>
.userInfoArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .userInfoList {
    position: absolute;
    background: white;
    display: flex;
    border-radius: 6px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.06);
    flex-direction: column;
    width: 150px;
    padding: 0;
    top: 40px;
    right: 5px;
    z-index: 999;
    &::before {
      border-width: 0 8px 22px 8px;
      border-color: transparent transparent white transparent;
      border-style: solid solid solid solid;
      top: -17px;
      content: '';
      z-index: 2;
      height: 0px;
      right: 10px;
      position: absolute;
      width: 0px;
    }
    li {
      padding: 10px 1rem;
      &:first-child {
        padding-top: 6px;
      }
      &:last-child {
        padding-bottom: 6px;
      }
      span,
      a {
        padding: 8px 4px;
        display: flex;
        color: #616161;
        justify-content: center;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        &:hover {
          color: black;
        }
      }
    }
  }
  .avatar {
    width: 28px;
    height: 28px;
    letter-spacing: normal;
  }
}

@media (max-width: 768px) {
  .menu.login {
    margin-left: auto;
    .item.shadow {
      display: none;
    }
    .item.project {
      display: none;
    }
    .items.notification {
      display: none;
    }
    .item.userInfoArea {
      display: none;
    }
  }
}
</style>
