<template>
  <Teleport to="body">
    <div
      ref="target"
      class="tw-flex tw-items-center tw-p-2 tw-absolute tw-bg-white tw-shadow-[4px_4px_24px_0px_rgba(0,0,0,0.20)] tw-rounded-lg tw-translate-x-[0%] md:tw-translate-x-[-50%] tw-translate-y-[10px]"
      :style="editBoxPosition"
    >
      <LinkEdit :editor="editor" @closeShowEdit="closeShowEdit" />
    </div>
  </Teleport>
</template>
<script>
import { reactive, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import LinkEdit from './LinkEdit.vue';
export default {
  name: '',
  props: ['editor', 'editBoxPosition'],
  emits: ['closeShowEdit'],
  components: {
    LinkEdit
  },
  setup(props, { emit }) {
    const target = ref(null);
    const closeShowEdit = () => {
      emit('closeShowEdit');
    };
    onClickOutside(target, () => {
      emit('closeShowEdit');
    });
    return { target, closeShowEdit };
  }
};
</script>
<style lang="scss" scoped></style>
