<template>
  <main class="landingPage">
    <BannerArea />
    <UserArea />
    <ServiceArea />
    <PartnerArea />
    <FeatureArea />
    <CustomerSayArea />
    <CaseArea />
    <QuickRegister />
    <component :is="modal" ref="theModal" @open-modal="openModal"></component>
  </main>
</template>

<script>
import BannerArea from '@/components/landing_page/2023/BannerArea.vue';
import UserArea from '@/components/landing_page/2023/UserArea.vue';
import ServiceArea from '@/components/landing_page/2023/ServiceArea.vue';
import PartnerArea from '@/components/landing_page/2023/PartnerArea.vue';
import FeatureArea from '@/components/landing_page/2023/FeatureArea.vue';
import CustomerSayArea from '@/components/landing_page/2023/CustomerSayArea.vue';
import CaseArea from '@/components/landing_page/2023/CaseArea.vue';
import QuickRegister from '@/components/landing_page/2023/QuickRegister.vue';
import RegisterModal from '@/components/modal/RegisterModal.vue';
import LoginModal from '@/components/modal/LoginModal.vue';
import ForgotModal from '@/components/modal/ForgotModal.vue';

import { onMounted, ref, provide } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'LandingPage',
  components: {
    BannerArea,
    FeatureArea,
    UserArea,
    ServiceArea,
    PartnerArea,
    CustomerSayArea,
    CaseArea,
    QuickRegister,
    RegisterModal,
    LoginModal,
    ForgotModal
  },

  setup() {
    onMounted(() => {
      AOS.init();
    });
    const router = useRouter();
    const modal = ref(null);
    const theModal = ref(null);

    const setModal = modalName => {
      return new Promise(resolve => {
        modal.value = modalName;
        resolve();
      });
    };

    const openModal = async modalName => {
      await setModal(modalName);
      theModal.value?.openModal();
    };
    const toProject = () => {
      router.push('/project');
    };
    provide('openModal', openModal);
    provide('toProject', toProject);
    return { modal, theModal, openModal };
  }
};
</script>

<style lang="scss"></style>
