<template>
  <button
    :uk-tooltip="'title:' + color.text + ';pos:top;'"
    @click="editor.chain().focus().setColor(color.color).run()"
    :class="{
      'is-active': editor.isActive('textStyle', { color: `${color.color}` })
    }"
  >
    <img
      loading="lazy"
      :src="require('@/assets/icon/editor/' + color.img + '.svg')"
      width="24"
      height="24"
    />
  </button>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default: () => {}
    },
    color: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup() {
    return {};
  }
};
</script>
<style lang="scss"></style>
