<template>
  <div class="message" :class="{ fiexd: width <= 768 }" ref="target">
    <div class="svg" @click="store.commit('showChatList', true)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8.625 12H8.25M12.375 12H12M16.125 12H15.75M8.625 12C8.625 12.0995 8.58549 12.1948 8.51517 12.2652C8.44484 12.3355 8.34946 12.375 8.25 12.375C8.15054 12.375 8.05516 12.3355 7.98484 12.2652C7.91451 12.1948 7.875 12.0995 7.875 12C7.875 11.9005 7.91451 11.8052 7.98484 11.7348C8.05516 11.6645 8.15054 11.625 8.25 11.625C8.34946 11.625 8.44484 11.6645 8.51517 11.7348C8.58549 11.8052 8.625 11.9005 8.625 12V12ZM12.375 12C12.375 12.0995 12.3355 12.1948 12.2652 12.2652C12.1948 12.3355 12.0995 12.375 12 12.375C11.9005 12.375 11.8052 12.3355 11.7348 12.2652C11.6645 12.1948 11.625 12.0995 11.625 12C11.625 11.9005 11.6645 11.8052 11.7348 11.7348C11.8052 11.6645 11.9005 11.625 12 11.625C12.0995 11.625 12.1948 11.6645 12.2652 11.7348C12.3355 11.8052 12.375 11.9005 12.375 12V12ZM16.125 12C16.125 12.0995 16.0855 12.1948 16.0152 12.2652C15.9448 12.3355 15.8495 12.375 15.75 12.375C15.6505 12.375 15.5552 12.3355 15.4848 12.2652C15.4145 12.1948 15.375 12.0995 15.375 12C15.375 11.9005 15.4145 11.8052 15.4848 11.7348C15.5552 11.6645 15.6505 11.625 15.75 11.625C15.8495 11.625 15.9448 11.6645 16.0152 11.7348C16.0855 11.8052 16.125 11.9005 16.125 12V12ZM21 12C21 16.556 16.97 20.25 12 20.25C11.1372 20.251 10.278 20.1377 9.445 19.913C8.27076 20.7389 6.83833 21.1141 5.41 20.97C5.25119 20.9547 5.09307 20.933 4.936 20.905C5.42887 20.3241 5.76547 19.6272 5.914 18.88C6.004 18.423 5.781 17.979 5.447 17.654C3.93 16.178 3 14.189 3 12C3 7.444 7.03 3.75 12 3.75C16.97 3.75 21 7.444 21 12Z"
          stroke="#44C3CE"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="18.5"
          cy="5.5"
          r="4.5"
          fill="#E96666"
          v-if="store.state.chat.hasUnread"
        />
      </svg>
    </div>

    <div
      class="messagePopup"
      :class="{ fiexd: width <= 768 }"
      v-if="store.state.chat.showChatRoomList"
    >
      <Lists :showFooter="width > 768" />
    </div>

    <audio
      id="messageSound"
      :src="require('@/assets/sound/notification.mp3')"
    ></audio>
  </div>
</template>
<script>
import { ref, inject, watch, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useStore } from 'vuex';
import Lists from '@/components/chat_room/Lists.vue';
import { useWindowSize } from '@vueuse/core';
export default {
  components: { Lists },
  setup() {
    const audio = inject('NotificationAudio');
    onMounted(() => {
      audio.value = document.getElementById('notificationSound');
    });

    const { width } = useWindowSize();
    const store = useStore();
    const target = ref();

    onClickOutside(target, function () {
      if (store.state.chat.showChatRoom || store.state.chat.showChatRoomList) {
        store.commit('showChatList', false);
      }
    });

    return { store, target, width };
  }
};
</script>
<style lang="scss" scoped>
.message.fiexd {
  position: unset;
}
.messagePopup {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: $white-color;
  top: 2rem;
  width: 25rem;
  right: 0;
  height: 80vh;
  z-index: 1000;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.06);
}

.messagePopup.fiexd {
  top: 55px;
  left: 0px;
  width: 100%;
  height: 100vh;
  border-radius: 0px;
}
.message {
  position: relative;
  width: 24px;
  height: 24px;
  .svg {
    svg {
      cursor: pointer;
      path {
        stroke: #ffffff;
      }
    }
  }
}
.onscrollItem {
  .message {
    .svg {
      svg {
        path {
          stroke: $brand-color;
        }
      }
    }
  }
}
#messageSound {
  display: none;
}
</style>
