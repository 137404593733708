export default {
    defaultAvatar(value) {
        const tranColor = (name) => {
            let str = "";
            for (let i = 0; i < name.length; i++) {
                str += parseInt(name[i].charCodeAt(0), 10).toString(16);
            }
            if (str.length > 4) {
                return "#" + str.slice(1, 4);
            } else {
                str = `${str.slice(1, 4)}777777`;
                return "#" + str.slice(1, 4);
            }
        };
        return tranColor(value);
    },
};