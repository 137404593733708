<template>
  <div
    class="chatWrapper"
    :class="{
      popup: store.state.chat.chatModel == 0,
      full: store.state.chat.chatModel == 1,
      avatar: store.state.chat.chatModel == -1
    }"
    v-if="store.state.chat.showChatRoom"
  >
    <Lists v-if="store.state.chat.chatModel == 1" style="width: 400px" />
    <Message
      v-if="store.state.chat.chatModel == 1 || store.state.chat.chatModel == 0"
    />

    <div v-if="store.state.chat.chatModel == -1">
      <div class="avatar__wrapper">
        <div class="avatar__pic" @click="onPopupHandler">
          <img
            :src="store.state.chat.chatTo.avatar"
            :class="{ w: store.state.chat.chatTo.imgRatio > 1 }"
            alt=""
          />
        </div>
        <div class="avatar__online" v-if="store.state.chat.chatTo.online">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <circle
              cx="8"
              cy="8"
              r="7"
              fill="#57F094"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      <div class="avatar__unread-wrapper">
        <div class="avatar__unread" v-if="store.state.chat.unseenCount != 0">
          {{
            store.state.chat.unseenCount > 99
              ? '99+'
              : store.state.chat.unseenCount
          }}
        </div>
      </div>
      <div class="avatar__close" @click="store.commit('showChat', false)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <circle cx="12.5" cy="12" r="10" fill="#616161" fill-opacity="0.6" />
          <path
            d="M18.2879 7.4207C18.405 7.30355 18.405 7.1136 18.2879 6.99644L17.5036 6.21213C17.3864 6.09497 17.1965 6.09497 17.0793 6.21213L12.7121 10.5793C12.595 10.6965 12.405 10.6965 12.2879 10.5793L7.9207 6.21213C7.80355 6.09497 7.6136 6.09497 7.49644 6.21213L6.71213 6.99644C6.59497 7.1136 6.59497 7.30355 6.71213 7.4207L11.0793 11.7879C11.1965 11.905 11.1965 12.095 11.0793 12.2121L6.71213 16.5793C6.59497 16.6965 6.59497 16.8864 6.71213 17.0036L7.49644 17.7879C7.6136 17.905 7.80355 17.905 7.9207 17.7879L12.2879 13.4207C12.405 13.3035 12.595 13.3035 12.7121 13.4207L17.0793 17.7879C17.1965 17.905 17.3864 17.905 17.5036 17.7879L18.2879 17.0036C18.405 16.8864 18.405 16.6965 18.2879 16.5793L13.9207 12.2121C13.8035 12.095 13.8035 11.905 13.9207 11.7879L18.2879 7.4207Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, nextTick, provide, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import Lists from '@/components/chat_room/Lists.vue';
import Message from '@/components/chat_room/Message.vue';

export default {
  name: 'ChatRoom',
  components: {
    Lists,
    Message
  },
  setup() {
    const store = useStore();

    onMounted(() => {});
    const onPopupHandler = function () {
      store.commit('setCahtModel', 0);
      store.dispatch('getMessage', {
        form: store.state.auth.user.uuid,
        to: store.state.chat.projcetChatId
      });
    };
    return { store, onPopupHandler };
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.chatWrapper {
  z-index: 99;
  display: flex;
}

.chatWrapper.full {
  border-top: 1px solid #eaeaea;
  top: 70px;
  width: 100%;
  left: 0;
  height: 100vh;
  position: absolute;
  background: #fff;
}

.chatWrapper.popup {
  position: fixed;
  width: 420px;
  right: 40px;
  bottom: 0px;
  height: 540px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: #fff;
}

.chatWrapper.avatar {
  position: fixed;
  width: 72px;
  height: 72px;
  right: 60px;
  bottom: 40px;
}

.avatar__wrapper {
  width: 72px;
  height: 72px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}
.avatar__pic {
  cursor: pointer;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.avatar__online {
  position: absolute;
  right: -1px;
  bottom: -3px;
}

.avatar__unread-wrapper {
  position: absolute;
  top: -3px;
  left: -5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40px;
}
.avatar__unread {
  border-radius: 30px;
  background: #44c3ce;
  padding: 6px 8px;
  color: #fff;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.6px;
  height: 24px;
}

.avatar__close {
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .chatWrapper.popup {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0px;
    height: calc(100% - 55px);
    border-radius: 0px;
  }
}
</style>
