<template>
  <form @submit.prevent autocomplete="on" class="uk-position-relative infoForm">
    <div class="mb-6">
      <div class="uk-form-controls uk-position-relative">
        <input
          class="uk-input uk-border-rounded"
          type="text"
          id="registerCompanyName"
          placeholder="&nbsp;"
          required="required"
          v-model.trim="registerData.company_name"
        />
        <label
          class="uk-form-label uk-position-absolute"
          for="registerCompanyName"
          >網紅名稱</label
        >
      </div>
    </div>
    <div class="mb-6">
      <div class="uk-form-controls uk-position-relative">
        <input
          class="uk-input uk-border-rounded"
          :class="[errorMessage.telError != null ? 'uk-form-danger' : '']"
          type="tel"
          id="registerPhone"
          placeholder="&nbsp;"
          @keyup="telValidate"
          v-model.trim="registerData.phone"
        />
        <label class="uk-form-label uk-position-absolute" for="registerPhone"
          >聯絡電話</label
        >
        <small
          class="uk-text-danger uk-float-right"
          v-if="errorMessage.telError != null"
          >{{ errorMessage.telError }}</small
        >
      </div>
    </div>
    <div class="mt-12">
      <div class="uk-form-controls uk-flex uk-flex-center">
        <button
          v-if="status.step2 === false"
          class="uk-button confirmBtn"
          disabled
        >
          下一步
        </button>
        <div
          v-if="status.step2 === true"
          class="nextBtn m-btn-rounded_brand_white"
          @click="setNextStep(2)"
        >
          下一步
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import VALIDATE from '@/lib/validate';
export default {
  name: '',
  props: ['status'],
  emits: ['emitSignUpData', 'emitNextStep'],
  setup(props, { emit }) {
    const registerData = reactive({
      phone: '',
      company_name: ''
    });

    const errorMessage = reactive({
      telError: null
    });

    function telValidate(event) {
      errorMessage.telError = VALIDATE.phoneValidate(event);
    }

    watch([registerData, errorMessage], () => {
      emit('emitSignUpData', registerData, errorMessage);
    });

    const setNextStep = num => {
      emit('emitNextStep', num);
    };
    return {
      registerData,
      errorMessage,
      telValidate,
      setNextStep
    };
  }
};
</script>

<style lang="scss" scoped></style>
