<template>
  <img
    :src="img"
    loading="lazy"
    alt="avatar"
    :style="`width: ${size}px; height: ${size}px;`"
    v-if="img"
  />

  <div
    v-else
    :style="{
      'background-color': avatarLib.defaultAvatar(name || 'User'),
      width: `${size}px`,
      height: `${size}px`,
      'font-size': `${(size * 50) / 100}px`
    }"
  >
    {{
      name
        ? name.substring(1, 0).toUpperCase()
        : 'User'.substring(1, 0).toUpperCase()
    }}
  </div>
</template>
<script>
import avatarLib from '@/lib/avatar';
export default {
  props: {
    size: { type: String, default: '36' },
    name: { type: String },
    img: { type: String, default: null }
  },
  setup() {
    return { avatarLib };
  }
};
</script>
<style lang="scss" scoped>
img {
  // height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  color: $color-white;
}
</style>
