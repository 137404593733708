export default {
  state: {
    isShowSample: false,
    isShowAddSample: false,
    list: []
  },
  mutations: {},
  actions: {},
  modules: {}
};
