<template>
  <div class="message__to_me">
    <div class="message__to_me__inner">
      <div class="message__to_me__main-avatar">
        <img
          :src="store.state.chat.chatTo.avatar"
          :class="{ w: store.state.chat.chatTo.imgRatio > 1 }"
          alt=""
        />
      </div>
      <div
        v-if="showImg"
        class="message__to_me__img"
        :class="{ h: imgRatio < 1 }"
        @contextmenu="onContextMenuHandler($event)"
      >
        <a :href="image" target="_blank">
          <img :src="image" alt="" />
        </a>
      </div>
      <div
        v-else
        class="message__to_me__text"
        @contextmenu="onContextMenuHandler($event)"
      >
        <div class="message__to_me__text-header" v-if="showHeader">
          <!-- 回復的那條訊息  -->
          <div class="message__to_me__reply-wrapper" v-if="replyText">
            <div class="message__to_me__avatar">
              <img
                :src="replyAvater"
                :class="{ w: replyAvaterImgRatio > 1 }"
                alt=""
              />
            </div>
            <div class="message__to_me__reply-info" v-if="replyText">
              <div class="message__to_me__reply-name">{{ replyName }}</div>
              <div class="message__to_me__reply-text">{{ replyText }}</div>
            </div>
          </div>

          <!-- 回復的那條圖檔 -->
          <div class="message__to_me__reply-wrapper img" v-if="replyImg">
            <div class="message__to_me__avatar">
              <img
                :src="replyAvater"
                :class="{ w: replyAvaterImgRatio > 1 }"
                alt=""
              />
            </div>
            <div class="message__to_me__reply-info img">
              <div class="message__to_me__reply-name img">{{ replyName }}</div>
              <div class="message__to_me__reply-img img">
                <img :src="replyImg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="message__to_me__text-line" v-if="showHeader"></div>
        <div class="message__to_me__text-body" v-if="!showfile">
          <p
            class="message__to_me__text-p"
            style="word-break: break-all"
            v-html="formatMessage(text)"
          ></p>
        </div>
        <div
          class="message__to_me__text-body"
          style="cursor: pointer"
          v-if="showfile"
          @click="onDownloadFileHandler"
        >
          <div class="message__to_me__text-file">
            <div class="message__to_me__text-file-pic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
              >
                <path
                  d="M11.4999 7.14995V2.19995H6.5499C6.1123 2.19995 5.69261 2.37379 5.38318 2.68323C5.07374 2.99266 4.8999 3.41234 4.8999 3.84995V18.15C4.8999 18.5876 5.07374 19.0072 5.38318 19.3167C5.69261 19.6261 6.1123 19.8 6.5499 19.8H16.4499C16.8875 19.8 17.3072 19.6261 17.6166 19.3167C17.9261 19.0072 18.0999 18.5876 18.0999 18.15V8.79995H13.1499C12.7123 8.79995 12.2926 8.62611 11.9832 8.31668C11.6737 8.00724 11.4999 7.58756 11.4999 7.14995ZM7.6499 11H15.3499C15.4958 11 15.6357 11.0579 15.7388 11.161C15.842 11.2642 15.8999 11.4041 15.8999 11.55C15.8999 11.6958 15.842 11.8357 15.7388 11.9389C15.6357 12.042 15.4958 12.1 15.3499 12.1H7.6499C7.50403 12.1 7.36414 12.042 7.26099 11.9389C7.15785 11.8357 7.0999 11.6958 7.0999 11.55C7.0999 11.4041 7.15785 11.2642 7.26099 11.161C7.36414 11.0579 7.50403 11 7.6499 11ZM7.6499 13.2H15.3499C15.4958 13.2 15.6357 13.2579 15.7388 13.361C15.842 13.4642 15.8999 13.6041 15.8999 13.75C15.8999 13.8958 15.842 14.0357 15.7388 14.1389C15.6357 14.242 15.4958 14.3 15.3499 14.3H7.6499C7.50403 14.3 7.36414 14.242 7.26099 14.1389C7.15785 14.0357 7.0999 13.8958 7.0999 13.75C7.0999 13.6041 7.15785 13.4642 7.26099 13.361C7.36414 13.2579 7.50403 13.2 7.6499 13.2ZM7.6499 15.4H15.3499C15.4958 15.4 15.6357 15.4579 15.7388 15.561C15.842 15.6642 15.8999 15.8041 15.8999 15.95C15.8999 16.0958 15.842 16.2357 15.7388 16.3389C15.6357 16.442 15.4958 16.5 15.3499 16.5H7.6499C7.50403 16.5 7.36414 16.442 7.26099 16.3389C7.15785 16.2357 7.0999 16.0958 7.0999 15.95C7.0999 15.8041 7.15785 15.6642 7.26099 15.561C7.36414 15.4579 7.50403 15.4 7.6499 15.4ZM12.5999 7.14995V2.47495L17.8249 7.69995H13.1499C13.004 7.69995 12.8641 7.642 12.761 7.53886C12.6578 7.43571 12.5999 7.29582 12.5999 7.14995Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div class="message__to_me__text-file-info">
            <div class="message__to_me__text-file-name">
              {{ fileName }}
            </div>
            <div class="message__to_me__text-file-size">{{ fileSize }}</div>
          </div>
        </div>
      </div>

      <div class="message__to_me__info">
        <!-- <div class="message__to_me__read" v-if="read == 'true' || read">
          已讀
        </div> -->
        <div class="message__to_me__date-time">
          {{ utils.formatDate(date) }}
        </div>
      </div>
    </div>
    <!-- <div class="message__to_me__append">
      <div class="file-upload" v-if="showfile">
        <div class="file-uploading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="5"
            viewBox="0 0 100 5"
            fill="none"
          >
            <rect y="0.526367" width="100" height="4" rx="2" fill="#E5E5E5" />
            <rect
              y="0.526367"
              :width="filePercentage"
              height="4"
              rx="2"
              fill="#00CA69"
            />
          </svg>
          <span>{{ filePercentage }}%</span>
          <svg
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            @click="onFileCancleHandler"
          >
            <circle
              cx="8.00016"
              cy="8.52653"
              r="6.66667"
              fill="#616161"
              fill-opacity="0.6"
            />
            <path
              d="M11.7879 5.54421C11.905 5.42706 11.905 5.23711 11.7879 5.11995L11.4064 4.7385C11.2893 4.62134 11.0993 4.62134 10.9822 4.7385L8.21213 7.50852C8.09498 7.62568 7.90503 7.62568 7.78787 7.50852L5.01785 4.7385C4.90069 4.62134 4.71074 4.62134 4.59358 4.7385L4.21213 5.11995C4.09497 5.23711 4.09497 5.42706 4.21213 5.54421L6.98215 8.31423C7.09931 8.43139 7.09931 8.62134 6.98215 8.7385L4.21213 11.5085C4.09498 11.6257 4.09497 11.8156 4.21213 11.9328L4.59358 12.3142C4.71074 12.4314 4.90069 12.4314 5.01785 12.3142L7.78787 9.54421C7.90503 9.42706 8.09497 9.42706 8.21213 9.54421L10.9822 12.3142C11.0993 12.4314 11.2893 12.4314 11.4064 12.3142L11.7879 11.9328C11.905 11.8156 11.905 11.6257 11.7879 11.5085L9.01785 8.7385C8.90069 8.62134 8.90069 8.43139 9.01785 8.31424L11.7879 5.54421Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="file-uploaded">
          <span @click="onFileRelHandler">重傳</span>｜<span
            @click="onFileDetelHandler"
            >刪除</span
          >
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { ref, watch, nextTick, provide, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import ContextMenu from '@imengyu/vue3-context-menu';
import utils from '@/class/Utils.js';

export default {
  name: 'ChatRoomMessageToMe',
  props: {
    messageUuid: { type: String, default: '' },

    text: {
      type: String,
      default: ''
    },
    read: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    },
    //回復內容------------------------------------------
    replyAvater: {
      type: String,
      default: ''
    },
    replyName: {
      type: String,
      default: ''
    },
    replyText: {
      type: String,
      default: ''
    },
    //回復圖檔------------------------------------------
    replyImg: {
      type: String,
      default: ''
    },
    //上傳檔案------------------------------------------
    fileName: {
      type: String,
      default: ''
    },
    fileSize: {
      type: String,
      default: ''
    },
    //上傳圖檔------------------------------------------
    image: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    const showHeader = ref(false);
    const showfile = ref(false);
    const showImg = ref(false);
    const filePercentage = ref(60);
    const imgRatio = ref(0);
    const replyAvaterImgRatio = ref(0);

    if (
      props.replyAvater ||
      props.replyName ||
      props.replyText ||
      props.replyImg
    ) {
      showHeader.value = true;
    }
    const getFileExtension = function (filename) {
      return filename.split('.').pop().toLowerCase();
    };

    if (
      ['jpg', 'jpeg', 'png', 'bmp'].includes(getFileExtension(props.fileName))
    ) {
      showImg.value = true;
    }

    if (
      ['zip', 'rar', 'xlsx', 'docx', 'txt', 'mp3'].includes(
        getFileExtension(props.fileName)
      )
    ) {
      showfile.value = true;
    }

    if (props.image != '') {
      const img = new Image();
      img.onload = function () {
        imgRatio.value = this.width / this.height;
      };
      img.src = props.image;
    }

    if (props.replyAvater != '') {
      const img = new Image();
      img.onload = function () {
        replyAvaterImgRatio.value = this.width / this.height;
      };
      img.src = props.replyAvater;
    }

    onMounted(() => {
      //console.log('props', props);
    });
    const formatMessage = function (text) {
      if (!text) return '';
      return text.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a style="color:#435BDA;word-break: break-all" href="$1" target="_blank">$1</a>'
      );
    };
    const onFileCancleHandler = function () {};
    const onFileDetelHandler = function () {};
    const onFileRelHandler = function () {};

    const onDownloadFileHandler = function (e) {
      var link = document.createElement('a');
      link.download = props.fileName;
      link.href = props.image;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const onContextMenuHandler = function (e) {
      //prevent the browser's default menu
      e.preventDefault();
      //console.log('e:', e);
      //show our menu
      ContextMenu.showContextMenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: '回覆',
            onClick: () => {
              store.state.chat.reply = true;
              store.state.chat.replyTo.replyReplyUuid = props.messageUuid;
              store.state.chat.replyTo.name = store.state.chat.chatTo.name;
              store.state.chat.replyTo.avatar = store.state.chat.chatTo.avatar;

              store.state.chat.replyTo.imgRatio =
                store.state.chat.chatTo.imgRatio;
              store.commit('setMessageHeight');
              if (props.image != '') {
                store.state.chat.replyTo.img = props.image;
              } else {
                if (props.fileName != '') {
                  store.state.chat.replyTo.text = props.fileName;
                } else {
                  store.state.chat.replyTo.text = props.text;
                }
              }

              document.getElementById('message__input').focus();
            }
          }
        ]
      });
    };
    return {
      store,
      utils,
      showHeader,
      showfile,
      showImg,
      filePercentage,
      imgRatio,
      replyAvaterImgRatio,
      formatMessage,
      onFileCancleHandler,
      onFileDetelHandler,
      onFileRelHandler,
      onContextMenuHandler,
      onDownloadFileHandler
    };
  }
};
</script>
<style lang="scss">
.mx-menu-ghost-host {
  .mx-context-menu {
    z-index: 9999;
  }
}
</style>
<style lang="scss" scoped>
.message__to_me {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 7px;
  padding-bottom: 7px;
}

.message__to_me__append {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  padding-top: 6px;
}
.file-uploading {
  display: flex;
  justify-content: flex-end;
  span {
    color: var(--b32, rgba(0, 0, 0, 0.32));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
.file-uploaded {
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
}
.message__to_me__inner {
  display: flex;
  padding-right: 18px;
  width: 100%;
  justify-content: flex-start;
}

.message__i_say__text-p {
  word-break: break-all;
}

.message__to_me__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 12px;
}

.message__to_me__read {
  color: rgba(0, 0, 0, 0.32);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__to_me__date-time {
  color: rgba(0, 0, 0, 0.32);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__to_me__main-avatar {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-right: 0px;
  overflow: hidden;
  margin-left: 16px;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}
.message__to_me__text {
  margin-left: 12px;
  max-width: 60%;
  border-radius: 2px 10px 10px 10px;

  border: 1px solid rgba(68, 195, 206, 0.6);
  text-align: left;
}

.message__to_me__img {
  margin-left: 10px;
  max-width: 280px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  width: 60%;
  img {
    width: 100%;
    height: auto;
  }
}

.message__to_me__img.h {
  max-width: 180px;
}

.message__to_me__text-header {
}
.message__to_me__text-line {
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.1);
}
.message__to_me__text-body {
  display: flex;
  padding: 8px 12px;
  p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 2.17px;
    margin: 0px;
  }
}

.message__to_me__text-p {
  ::v-deep .message__to_me__text-link {
    color: red;
  }
}

.message__to_me__text-file-pic {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 100px;
  background-color: rgba(68, 195, 206, 0.3);
  margin-right: 10px;
}

.message__to_me__text-file-info {
}

.message__to_me__text-file-name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2.17px;
  word-break: break-all;
}

.message__to_me__text-file-size {
  color: #616161;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__to_me__reply-wrapper {
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.message__to_me__reply-wrapper.img {
  align-items: center;
}
.message__to_me__avatar {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.message__to_me__reply-info {
  //max-width: 176px;
  max-width: calc(100% - 38px);
}

.message__to_me__reply-info.img {
  display: flex;
  align-items: center;
  width: 100%;
}

.message__to_me__reply-name.img {
  padding-right: 5px;
  width: calc(100% - 45px);
}
.message__to_me__reply-img.img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}
.message__to_me__reply-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  //max-width: 138px;
  width: 100%;
}
.message__to_me__reply-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.45);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  //max-width: 138px;
  width: 100%;
}
</style>
