<template>
  <div id="alertModal" uk-modal container="false">
    <div
      class="uk-modal-body uk-margin-auto uk-modal-dialog uk-width-1-1 uk-width-2-3@s uk-width-1-2@m uk-card uk-card-body uk-background-default uk-border-rounded"
      uk-animation-fade
    >
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h1
        class="uk-card-title uk-text-bold uk-text-uppercase uk-text-primary uk-flex uk-flex-center"
      >
        {{ alertMessage.title }}
      </h1>
      <small class="uk-flex uk-flex-center txtS">{{
        alertMessage.message
      }}</small>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "AlertModal",

  components: {},
  setup() {
    const alertMessage = inject("alertMessage");
    return { alertMessage };
  },
};
</script>
<style lang="scss" scoped>
.uk-card {
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  h1 {
    color: $color-light-green !important;
  }
  .txtS {
    width: 170px;
  }
}
</style>
