<template>
  <TopBar v-if="showInfoBar" @update="closeInfoBarHandler"> </TopBar>
  <v-app>
    <div
      v-if="
        route.path === '/register' || route.path === '/register/isForm3rdLogin'
      "
    >
      <router-view :key="route.path" />
    </div>
    <div v-else>
      <FraudModal v-if="!(isFraud == 'true')"></FraudModal>
      <Navbar v-if="!router.currentRoute.value.meta.hideNav" />
      <main class="main">
        <router-view :key="route.path" />
        <ToTop />
        <!-- <ChatComponent v-if="isLoggedIn" /> -->
        <MessageBoardRoom v-if="isLoggedIn" />
      </main>
      <Footer />
      <Loading v-if="isLoading" />
      <Notification />
      <ModalsContainer />
      <ChatRoom />
      <Onboarding :steps="steps" @trackStep="trackStep" />
      <vue-progress-bar />
    </div>
  </v-app>
</template>
<script>
// @ is an alias to /src

import TopBar from '@/components/navbar/TopBar.vue';
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/Footer.vue';
import ToTop from '@/components/tools/ToTop.vue';
import Loading from '@/components/Loading.vue';
import FraudModal from '@/components/FraudModal.vue';
import Notification from '@/components/notification/index.vue';
import { reactive, ref, computed, provide, watchEffect } from 'vue';
import { useStore } from 'vuex';
import useNotification from '@/store/notification/index';
import ChatComponent from '@/components/ChatRoom.vue';
import MessageBoardRoom from '@/components/MessageBoardRoom.vue';
import useMessageBoard from '@/store/messageBoard/index.js';
import { useRouter, useRoute } from 'vue-router';
import Pusher from 'pusher-js';
import { ModalsContainer } from 'vue-final-modal';
import ChatRoom from '@/components/chat_room/Index.vue';
import Onboarding from './components/Onboarding.vue';
import { useOnboarding } from '@/shared/onBoarding/useOnboarding.js';

import { VApp } from 'vuetify/components';
export default {
  components: {
    VApp,
    TopBar,
    Navbar,
    Footer,
    Loading,
    FraudModal,
    ChatComponent,
    Notification,
    ToTop,
    MessageBoardRoom,
    ModalsContainer,
    ChatRoom,
    Onboarding
  },
  setup() {
    const showInfoBar = ref(process.env.VUE_APP_SHOW_INFO_BAR === 'true');
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const alertMessage = reactive({
      title: '',
      message: ''
    });

    const isFraud = computed(() => localStorage.isFraud);
    const isLoading = computed(() => {
      return store.getters.isLoading;
    });
    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn;
    });

    const { steps, currentStepPage, currentStepPageStatus } = useOnboarding();

    const trackStep = async step => {
      await store.dispatch('updateOnboardingData', {
        page: currentStepPage.value,
        status: currentStepPageStatus.value,
        step: step
      });
    };

    //socket
    const pusher = ref();
    watchEffect(() => {
      if (store.getters.socketStatus) {
        pusher.value = new Pusher(process.env.VUE_APP_SOCKET_API_KEY, {
          encrypted: true,
          cluster: process.env.VUE_APP_SOCKET_CLUSTER,
          authEndpoint:
            process.env.VUE_APP_AXIOS_DEFAULT_URL + '/broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + store.state.auth.token
            }
          }
        });

        Pusher.log = msg => {
          //console.log('■', msg);
        };

        store.commit('setPusher', {
          pusher: pusher.value,
          user: store.state.auth.user,
          auth: store.state.auth
        });
      } else {
        if (pusher.value?.key) {
          pusher.value.disconnect();
        }
      }
    });

    //通知中心
    useNotification(pusher);
    //聊天系統
    // useChat();
    useMessageBoard();
    provide('alertMessage', alertMessage);
    provide('pusher', pusher);
    const closeInfoBarHandler = function (value) {
      showInfoBar.value = value;
    };
    return {
      showInfoBar,
      isFraud,
      isLoading,
      isLoggedIn,
      route,
      router,
      steps,
      closeInfoBarHandler,
      trackStep
    };
  }
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  padding: 0px;
  background: #f9f9f9;
  margin: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  border: hidden;
}

.main {
  min-height: 100vh;
  > :not(.home) *,
  > .uk-offcanvas {
    font-family: PingFangBold2, sans-serif !important;
  }
}
nav {
  #registerModal *,
  #loginModal *,
  #forgotPasswordModal *,
  .notification * {
    font-family: PingFangBold2, sans-serif !important;
  }
}

#registerPage * {
  font-family: PingFangTC, sans-serif !important;
}
</style>
