<template>
  <div class="chatListWraper" :class="{ show__footer: showFooter }">
    <div class="chatList__header">
      <p class="chatList__title">聊天室</p>
      <div class="chatList__search" v-if="!showFooter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.27 17.5392C11.7648 17.5389 13.2196 17.0752 14.436 16.2165C14.6011 16.1 14.8276 16.1128 14.9705 16.2557L18.4322 19.7172C18.5884 19.8734 18.8417 19.8734 18.9979 19.7172L19.7171 18.9979C19.8734 18.8417 19.8734 18.5885 19.7171 18.4322L16.2556 14.9709C16.1126 14.8279 16.0998 14.6014 16.2165 14.4363C17.0756 13.2198 17.5397 11.7647 17.54 10.2696C17.54 6.26133 14.2785 3 10.27 3C6.26151 3 3 6.26133 3 10.2696C3 14.2779 6.26151 17.5392 10.27 17.5392ZM10.27 4.8174C13.2771 4.8174 15.7225 7.26272 15.7225 10.2696C15.7225 13.2765 13.2771 15.7218 10.27 15.7218C7.26295 15.7218 4.8175 13.2765 4.8175 10.2696C4.8175 7.26272 7.26295 4.8174 10.27 4.8174Z"
            fill="#616161"
          />
        </svg>
        <input
          v-model="searchTxt"
          class="chatList__input"
          type="text"
          placeholder="搜尋"
          :disabled="searchFilter().length == 0 && searchTxt == ''"
        />
        <div
          @click="onClearHandler"
          class="chatList__clear"
          v-if="searchTxt != ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <circle
              cx="8.33354"
              cy="8.00016"
              r="6.66667"
              fill="#616161"
              fill-opacity="0.6"
            />
            <path
              d="M12.1214 5.01785C12.2385 4.90069 12.2385 4.71074 12.1214 4.59358L11.7399 4.21213C11.6228 4.09497 11.4328 4.09497 11.3156 4.21213L8.54563 6.98215C8.42847 7.09931 8.23852 7.09931 8.12136 6.98215L5.35134 4.21213C5.23419 4.09498 5.04424 4.09497 4.92708 4.21213L4.54563 4.59358C4.42847 4.71074 4.42847 4.90069 4.54563 5.01785L7.31565 7.78787C7.43281 7.90503 7.43281 8.09497 7.31565 8.21213L4.54563 10.9822C4.42847 11.0993 4.42847 11.2893 4.54563 11.4064L4.92708 11.7879C5.04424 11.905 5.23419 11.905 5.35134 11.7879L8.12136 9.01785C8.23852 8.90069 8.42847 8.90069 8.54563 9.01785L11.3156 11.7879C11.4328 11.905 11.6228 11.905 11.7399 11.7879L12.1214 11.4064C12.2385 11.2893 12.2385 11.0993 12.1214 10.9822L9.35134 8.21213C9.23419 8.09498 9.23419 7.90503 9.35134 7.78787L12.1214 5.01785Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="chatList__body-loading"
      v-if="store.state.chat.isLoadList"
      :class="{ show__footer: showFooter }"
    >
      loading...
    </div>
    <div v-else class="chatList__body" :class="{ show__footer: showFooter }">
      <div class="chatList__empty" v-if="searchFilter().length == 0">
        {{ emptyTxt }}
      </div>
      <div
        class="chatList__item"
        v-for="(item, index) in searchFilter()"
        :key="index"
        :class="{ active: item.active && store.state.chat.showChatRoom }"
        @click="onItemClickHandler(item)"
      >
        <div class="chatList__avatar">
          <div class="chatList__pic" :class="{ h: getImgSize(item) < 1 }">
            <img
              :src="item.contact.avatar"
              :class="{ w: item.imgRatio > 1 }"
              alt=""
            />
          </div>
          <div class="chatList__online" v-if="item.contact.online">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle
                cx="8"
                cy="8"
                r="7"
                fill="#57F094"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div class="chatList__info">
          <div class="chatList__name">{{ item.contact.name }}</div>
          <div class="chatList__last-message">
            {{ item.text }}
          </div>
          <div class="chatList__date">
            {{ utils.formatDate(item.created_at) }}
          </div>
        </div>
        <div class="chatList__unread-wrapper">
          <div class="chatList__unread" v-if="item.unseen_count != 0">
            {{ item.unseen_count > 99 ? '99+' : item.unseen_count }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="chatList__footer"
      :class="{ show__footer: showFooter }"
      v-if="showFooter && !store.state.chat.lists.length == 0"
    >
      <div class="chatList__showall" @click="onFooterClickHandler">
        前往聊天室查看全部
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, nextTick, provide, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useWindowSize } from '@vueuse/core';
import utils from '@/class/Utils.js';

export default {
  name: 'ChatRoomList',
  components: {},
  props: {
    showFooter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { width } = useWindowSize();
    const store = useStore();
    const searchTxt = ref('');
    const emptyTxt = ref('');
    const searchFilter = function () {
      let data;
      if (searchTxt.value == '') {
        data = store.state.chat.lists;
        emptyTxt.value = '目前沒有任何聊天訊息';
      } else {
        data = store.state.chat.lists.filter(function (el) {
          //console.log('el.contact.name:', el.contact.name);
          emptyTxt.value = '找不到符合的資料';
          return el.contact.name.includes(searchTxt.value);
        });
      }

      return data;
    };

    const getImgSize = async function (item) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          resolve(this.width / this.height);
          item.imgRatio = this.width / this.height;
        };
        img.src = item.contact.avatar;
      });
    };

    onMounted(() => {
      store.dispatch('getList', null);
    });

    const onClearHandler = function () {
      searchTxt.value = '';
    };

    const onItemClickHandler = function (item) {
      if (store.state.chat.isLoadMessage) return;
      if (
        store.state.chat.projcetChatId == item.chatroom_uuid &&
        store.state.chat.showChatRoom &&
        !store.state.chat.chatModel == -1
      )
        return;
      store.commit('clearMessage');
      store.state.chat.projcetChatId = item.chatroom_uuid;
      if (props.showFooter || width.value <= 768) {
        //列表頁模式
        store.commit('setCahtModel', 0);
        store.commit('showChatList', false);
        store.commit('showChat', true);
      }

      store
        .dispatch('getMessage', {
          form: store.state.auth.user.uuid,
          to: item.chatroom_uuid
        })
        .then(function (e) {
          //console.log('e:', e);
        });
    };

    const onFooterClickHandler = function () {
      if (store.state.chat.isLoadList) return;
      if (searchFilter().length > 0) {
        searchFilter()[0].active = true;
        store.commit('setChatTo', searchFilter()[0]);
        store.dispatch('getMessage', {
          form: store.state.auth.user.uuid,
          to: store.state.chat.lists[0].chatroom_uuid,
          uuid: store.state.auth.user.uuid
        });
      }

      store.commit('setCahtModel', 1);
      store.commit('showChatList', false);
      store.commit('showChat', true);
    };

    return {
      store,
      utils,
      emptyTxt,
      searchTxt,
      getImgSize,

      searchFilter,
      onClearHandler,
      onItemClickHandler,
      onFooterClickHandler
    };
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.chatListWraper {
  width: 100%;
  position: relative;
  height: calc(100vh - 70px);
  border-right: 1px solid #eaeaea;
}

.chatListWraper.show__footer {
  height: 100%;
}
.chatList__header {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid #f9f9f9;
  background-color: #fff;
}

.chatList__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2.48px;
  margin: 0px;

  color: #616161;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2.48px;
}

.chatList__search {
  position: relative;
  border-radius: 30px;
  background-color: #f1f1f1;
  padding: 8px 14px 8px 20px;
  width: 100%;
  display: flex;
}

.chatList__input {
  appearance: none;
  border: 0px;
  outline: none;
  background-color: #f1f1f1;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.17px;
  color: #000;
  width: 90%;
}

@media (max-width: 768px) {
  .chatList__input {
    font-size: 16px;
  }
}

.chatList__clear {
  position: absolute;
  top: 0px;
  right: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.chatList__body {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 110px);
}

@media (max-width: 768px) {
  .chatList__body {
    height: calc(100% - 94px);
  }
}

.chatList__body-loading {
  color: #cacaca;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 2.48px;

  display: flex;

  justify-content: center;
  height: calc(100% - 110px);

  flex-direction: column;
}

.chatList__body.show__footer {
  height: calc(100% - 108px);
}

.chatList__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chatList__item {
  display: flex;
  padding: 16px 20px;
  border-bottom: 1px solid #f9f9f9;
  cursor: pointer;
}
.chatList__item.active {
  background-color: #f4feff;
}

.chatList__avatar {
  width: 56px;
  height: 56px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}
.chatList__pic {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.chatList__online {
  position: absolute;
  right: -1px;
  bottom: -3px;
}

.chatList__info {
  padding-left: 18px;
  text-align: left;
  width: calc(100% - 126px);
}

.chatList__name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2.17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chatList__last-message {
  overflow: hidden;
  color: #616161;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  letter-spacing: 2.17px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chatList__date {
  color: #b6b6b6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.chatList__unread-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 40px;
  margin-left: 30px;
  padding-bottom: 10px;
}
.chatList__unread {
  border-radius: 30px;
  background: #44c3ce;
  padding: 6px 8px;
  color: #fff;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.6px;
  height: 24px;
}
.chatList__footer {
  width: 100%;
  border-top: 1px solid #eaeaea;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.chatList__footer.show__footer {
  position: relative;
}

.chatList__showall {
  width: 100%;
  height: 50px;
  padding: 12px 16px;
  color: #44c3ce;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 2.17px;
  cursor: pointer;
}
</style>
