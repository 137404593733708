import authApi from '@/api/auth';

export default {
  // 信箱
  emailValidate(event) {
    let value = event.target.value;

    if (!value) {
      return '記得填寫喔';
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return '電子郵件不正確';
    }
    if (/[\s\\^\uff00-\uffff]/.test(value)) {
      return '電子郵件不正確';
    }
    return null;
  },
  emailValidate_v(value) {
    if (!value) {
      return '請填寫Email';
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return '請填寫正確的Email格式';
    }
    if (/[\s\\^\uff00-\uffff]/.test(value)) {
      return '請填寫正確的Email格式';
    }
    return true;
  },
  // 密碼
  passwordValidate(event) {
    let value = event.target.value;
    if (!value) {
      return '記得填寫喔';
    }
    if (/[*+?^${}()|/'"\s[\]\\^\uff00-\uffff]/.test(value)) {
      return '注意格式是否錯誤';
    }
    return null;
  },
  passwordValidate_v(value) {
    if (!value) {
      return '請填寫密碼';
    }
    if (/[*+?^${}()|/'"\s[\]\\^\uff00-\uffff]/.test(value)) {
      return '注意格式是否錯誤';
    }
    return true;
  },
  //電話
  phoneValidate(event) {
    let value = event.target.value;
    if (!value) {
      return null;
    }
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)) {
      return '注意格式是否錯誤';
    }
    if (value.length < 7) {
      return '無效的電話號碼';
    }
    return null;
  },
  phoneValidate_v(value) {
    if (!value) {
      return '請填寫此欄位';
    }
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)) {
      return '注意格式是否錯誤';
    }
    if (value.length < 7) {
      return '無效的電話號碼';
    }
    return true;
  },
  //email是否註冊過
  async emailRepeatValidate(emailData) {
    try {
      const res = await authApi.checkEmailRepeat(emailData);
      return res;
    } catch (err) {
      throw new Error(err);
    }
  },
  checkCorrectUrl(event) {
    let url = event.target.value;
    const reg = /^(?:https?:\/\/)(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/g;
    var compareRes = reg.test(url);
    if (!url) {
      return null;
    }

    if (!compareRes) {
      return '注意格式是否錯誤';
    }
    return null;
  },
  checkCorrectUrl_v(url) {
    const reg = /^(?:https?:\/\/)(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/g;
    var compareRes = reg.test(url);
    if (!url) {
      return true;
    }
    if (!compareRes) {
      return '注意格式是否錯誤';
    }
    return true;
  },
  async readCompanyID(id) {
    try {
      const res = await authApi.readCompanyID(id);
      return res;
    } catch (err) {
      throw new Error(err);
    }
  }
};
