<template>
  <div
    class="message__wrapper"
    :class="{
      popup: store.state.chat.chatModel == 0,
      full: store.state.chat.chatModel == 1
    }"
  >
    <div class="message__header">
      <div class="message__header-left">
        <div class="message__back" @click="onMessageBackHandler">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.6223 4.08273C17.7397 3.96534 17.7394 3.77493 17.6217 3.65787L16.2673 2.31105C16.15 2.19441 15.9604 2.19472 15.8435 2.31175L6.37696 11.7879C6.2599 11.905 6.25995 12.0949 6.37706 12.212L15.8529 21.6879C15.9701 21.805 16.16 21.805 16.2772 21.6879L17.6229 20.3421C17.7401 20.225 17.7401 20.035 17.6229 19.9179L9.91717 12.2121C9.80001 12.095 9.80001 11.905 9.91717 11.7879L17.6223 4.08273Z"
              fill="black"
              fill-opacity="0.54"
            />
          </svg>
        </div>
        <div class="message__avatar">
          <div class="message__pic">
            <img
              :src="store.state.chat.chatTo.avatar"
              :class="{ w: store.state.chat.chatTo.imgRatio > 1 }"
              alt=""
            />
          </div>
          <div class="message__online" v-if="store.state.chat.chatTo.online">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle
                cx="8"
                cy="8"
                r="7"
                fill="#57F094"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div class="message__info">
          <div class="message__name">{{ store.state.chat.chatTo.name }}</div>
          <div class="message__online-text">
            {{ store.state.chat.chatTo.online ? '目前在線上' : '未在線上' }}
          </div>
        </div>
      </div>
      <div class="message__header-right">
        <div
          class="message__control__full"
          v-if="store.state.chat.chatModel == 1"
        >
          <div class="message__scale tw-mr-[12px]" @click="onScaleHandler">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.9999 8.99994H16.4199L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L14.9999 7.56994V4.99994C14.9999 4.73472 14.8946 4.48037 14.707 4.29283C14.5195 4.1053 14.2652 3.99994 13.9999 3.99994C13.7347 3.99994 13.4804 4.1053 13.2928 4.29283C13.1053 4.48037 12.9999 4.73472 12.9999 4.99994V9.99994C12.9999 10.2652 13.1053 10.5195 13.2928 10.707C13.4804 10.8946 13.7347 10.9999 13.9999 10.9999H18.9999C19.2652 10.9999 19.5195 10.8946 19.707 10.707C19.8946 10.5195 19.9999 10.2652 19.9999 9.99994C19.9999 9.73472 19.8946 9.48037 19.707 9.29283C19.5195 9.1053 19.2652 8.99994 18.9999 8.99994ZM9.99994 12.9999H4.99994C4.73472 12.9999 4.48037 13.1053 4.29283 13.2928C4.1053 13.4804 3.99994 13.7347 3.99994 13.9999C3.99994 14.2652 4.1053 14.5195 4.29283 14.707C4.48037 14.8946 4.73472 14.9999 4.99994 14.9999H7.56994L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L8.99994 16.4199V18.9999C8.99994 19.2652 9.1053 19.5195 9.29283 19.707C9.48037 19.8946 9.73472 19.9999 9.99994 19.9999C10.2652 19.9999 10.5195 19.8946 10.707 19.707C10.8946 19.5195 10.9999 19.2652 10.9999 18.9999V13.9999C10.9999 13.7347 10.8946 13.4804 10.707 13.2928C10.5195 13.1053 10.2652 12.9999 9.99994 12.9999Z"
                fill="#616161"
              />
            </svg>
          </div>
          <div class="message__control-close" @click="onCloseHandler">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
                fill="#616161"
              />
            </svg>
          </div>
        </div>

        <div
          class="message__control__popup"
          v-if="store.state.chat.chatModel == 0"
        >
          <div
            class="message__control-full tw-mr-[12px]"
            @click="onFullHandler"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H14C13.7348 4 13.4804 4.10536 13.2929 4.29289C13.1054 4.48043 13 4.73478 13 5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6H16.57L13.29 9.29C13.1963 9.38296 13.1219 9.49356 13.0711 9.61542C13.0203 9.73728 12.9942 9.86799 12.9942 10C12.9942 10.132 13.0203 10.2627 13.0711 10.3846C13.1219 10.5064 13.1963 10.617 13.29 10.71C13.383 10.8037 13.4936 10.8781 13.6154 10.9289C13.7373 10.9797 13.868 11.0058 14 11.0058C14.132 11.0058 14.2627 10.9797 14.3846 10.9289C14.5064 10.8781 14.617 10.8037 14.71 10.71L18 7.42V10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10V5ZM10.71 13.29C10.617 13.1963 10.5064 13.1219 10.3846 13.0711C10.2627 13.0203 10.132 12.9942 10 12.9942C9.86799 12.9942 9.73728 13.0203 9.61542 13.0711C9.49356 13.1219 9.38296 13.1963 9.29 13.29L6 16.57V14C6 13.7348 5.89464 13.4804 5.70711 13.2929C5.51957 13.1054 5.26522 13 5 13C4.73478 13 4.48043 13.1054 4.29289 13.2929C4.10536 13.4804 4 13.7348 4 14V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H10C10.2652 20 10.5196 19.8946 10.7071 19.7071C10.8946 19.5196 11 19.2652 11 19C11 18.7348 10.8946 18.4804 10.7071 18.2929C10.5196 18.1054 10.2652 18 10 18H7.42L10.71 14.71C10.8037 14.617 10.8781 14.5064 10.9289 14.3846C10.9797 14.2627 11.0058 14.132 11.0058 14C11.0058 13.868 10.9797 13.7373 10.9289 13.6154C10.8781 13.4936 10.8037 13.383 10.71 13.29Z"
                fill="#616161"
              />
            </svg>
          </div>
          <div
            class="message__control-avatar tw-mr-[12px]"
            @click="onAvaterHandler"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                x="5"
                y="11"
                width="14"
                height="2"
                rx="0.3"
                fill="#616161"
              />
            </svg>
          </div>
          <div class="message__control-close" @click="onCloseHandler">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
                fill="#616161"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <transition>
      <div class="message__body-loading" v-if="store.state.chat.isLoadMessage">
        <div class="message__body-loading-text">載入訊息中</div>
      </div>
    </transition>
    <div
      id="message__body"
      class="message__body"
      ref="chatContainer"
      :class="{
        reply: store.state.chat.reply,
        popup: store.state.chat.chatModel == 0,
        full: store.state.chat.chatModel == 1
      }"
      @scroll="onScrollHandler"
    >
      <div id="message__body__inner" class="message__body__inner">
        <div
          class="message__body__date-group"
          v-for="(group, key) in store.state.chat.chatList"
        >
          <div class="message__body__date">
            <span>{{ utils.formatDate(group.date, true, 'date') }}</span>
          </div>
          <div
            v-for="(item, index) in group.message"
            :key="'chat_' + item.message_uuid"
            :id="item.message_uuid"
          >
            <ISay
              v-if="item.is_sender"
              :messageUuid="item.message_uuid"
              :text="item.text"
              :read="item.is_seen"
              :date="item.created_at"
              :replyAvater="item.reply?.avator"
              :replyName="item.reply?.name"
              :replyText="item.reply.text"
              :replyImg="item.reply?.file?.path"
              :replyImgName="item.reply?.file?.name"
              :image="item.file?.path"
              :fileName="item.file?.name"
              :fileSize="item.fileSize"
              :data="item"
            />
            <ToMe
              v-else
              :messageUuid="item.message_uuid"
              :text="item.text"
              :read="item.is_seen"
              :date="item.created_at"
              :replyAvater="item.reply?.avator"
              :replyName="item.reply?.name"
              :replyText="item.reply?.text"
              :replyImg="item.reply?.file?.path"
              :replyImgName="item.reply?.file?.name"
              :image="item.file?.path"
              :fileName="item.file?.name"
              :fileSize="item.fileSize"
              :data="item"
            />
          </div>
        </div>
      </div>
      <div class="message__body__file-loading" v-if="filePercentage != 0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="5"
          viewBox="0 0 100 5"
          fill="none"
        >
          <rect y="0.526367" width="100" height="4" rx="2" fill="#E5E5E5" />
          <rect
            y="0.526367"
            :width="filePercentage"
            height="4"
            rx="2"
            fill="#00CA69"
          />
        </svg>
        <span>{{ filePercentage }}%</span>
      </div>
    </div>
    <div
      id="message__footer"
      class="message__footer"
      :class="{ reply: store.state.chat.reply }"
    >
      <div class="message__reply" v-if="store.state.chat.reply">
        <div class="message__reply__avatar">
          <div class="message__reply__pic">
            <img
              :src="store.state.chat.replyTo.avatar"
              alt=""
              :class="{ w: store.state.chat.chatTo.imgRatio > 1 }"
            />
          </div>
        </div>
        <div class="message__reply__info" v-if="store.state.chat.replyTo.text">
          <div class="message__reply__name">
            {{ store.state.chat.replyTo.name }}
          </div>
          <div class="message__reply__message">
            {{ store.state.chat.replyTo.text }}
          </div>
        </div>
        <div
          class="message__reply__info img"
          v-if="store.state.chat.replyTo.img"
        >
          <div class="message__reply__name">
            {{ store.state.chat.replyTo.name }}
          </div>
          <div class="message__reply__img">
            <img :src="store.state.chat.replyTo.img" alt="" />
          </div>
        </div>

        <div class="message__reply__cancle" @click="onReplyCancleHandler">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M14.3324 4.27044L13.2582 3.19581L8.99785 7.45446L4.7392 3.19407L3.66458 4.26825L7.92323 8.52865L3.66283 12.7873L4.73702 13.8619L8.99741 9.60327L13.2561 13.8637L14.3307 12.7895L10.072 8.52909L14.3324 4.27044Z"
              fill="#616161"
            />
          </svg>
        </div>
      </div>
      <div class="message__input">
        <label for="fileUpload">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <path
              d="M28.2287 17.8191L19.2287 26.8191C13.2287 32.8191 4.22869 23.8191 10.2287 17.8191L19.2287 8.81913C23.2287 4.81913 29.2287 10.8191 25.2287 14.8191L16.2287 23.8191C14.2287 25.8191 11.2287 22.8191 13.2287 20.8191L22.2287 11.8191"
              stroke="#44C3CE"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>
        </label>
        <input
          type="file"
          id="fileUpload"
          @change="onFileChangeHandler"
          :disabled="store.state.chat.isLoadMessage"
        />
        <div class="message__text">
          <textarea
            :disabled="store.state.chat.isLoadMessage"
            v-model="messageText"
            id="message__input"
            ref="messageInput"
            type="text"
            placeholder="訊息"
            @keypress.enter.exact.prevent="onSendMessageHandler"
            @keydown.enter.shift.exact.prevent="messageText += '\n'"
            @input="onMessageInputHandler"
          />
        </div>

        <div
          class="message__send"
          @click="onSendMessageHandler"
          v-if="!store.state.chat.isLoadMessage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3.79432 3.05733L20.693 11.4996C20.7851 11.5457 20.8627 11.617 20.917 11.7054C20.9712 11.7938 21 11.8959 21 12.0001C21 12.1042 20.9712 12.2063 20.917 12.2948C20.8627 12.3832 20.7851 12.4545 20.693 12.5005L3.79322 20.9428C3.69854 20.99 3.59249 21.0086 3.4877 20.9963C3.38291 20.9841 3.28379 20.9416 3.20215 20.8739C3.12051 20.8062 3.05979 20.7161 3.02722 20.6144C2.99465 20.5126 2.99161 20.4036 3.01845 20.3001L4.69235 13.8897C4.71949 13.786 4.77555 13.6924 4.85383 13.6202C4.9321 13.5479 5.02926 13.5001 5.13367 13.4825L12.7064 12.2026C12.7515 12.195 12.7941 12.1762 12.8302 12.1477C12.8663 12.1193 12.8949 12.0821 12.9133 12.0397L12.9331 11.9727C12.9434 11.9107 12.9327 11.8469 12.9027 11.7918C12.8728 11.7367 12.8253 11.6935 12.768 11.6692L12.7064 11.6514L5.09185 10.3648C4.98764 10.347 4.89072 10.2991 4.81265 10.2269C4.73459 10.1546 4.67869 10.0611 4.65163 9.95757L3.01845 3.70116C2.99133 3.59765 2.99416 3.48842 3.02661 3.38651C3.05906 3.28459 3.11976 3.1943 3.20145 3.12641C3.28315 3.05853 3.38239 3.01591 3.48732 3.00366C3.59225 2.99141 3.69845 3.01004 3.79322 3.05733H3.79432Z"
              fill="#44C3CE"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  nextTick,
  provide,
  onMounted,
  inject,
  onUpdated
} from 'vue';
import { useStore } from 'vuex';
import utils from '@/class/Utils.js';
import ISay from '@/components/chat_room/MessageISay.vue';
import ToMe from '@/components/chat_room/MessageToMe.vue';
import notification from '@/lib/notification';
export default {
  name: 'ChatRoomMessage',
  components: { ISay, ToMe },
  setup() {
    const chatContainer = ref(null);
    const messageInput = ref(null);
    const store = useStore();
    const messageText = ref('');
    const filePercentage = ref(0);

    const getCurrentDateTime = function () {
      var now = new Date();

      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var h = now.getHours();
      var m = now.getMinutes();

      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      if (h < 10) {
        h = '0' + h;
      }
      if (m < 10) {
        m = '0' + m;
      }

      return year + '-' + month + '-' + day + ' ' + h + ':' + m;
    };

    const escapeHtml = unsafe => {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
    };

    const textToHtml = function (text) {
      return text.replace(/\n/g, '<br>');
    };

    const formatBytes = function (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    let intervalId;
    const startfileUpload = function () {
      filePercentage.value = 0;

      function handleDynamicRequest() {
        if (filePercentage.value < 99) {
          filePercentage.value++;
        } else {
          clearInterval(intervalId);
        }
      }

      intervalId = setInterval(handleDynamicRequest, 10);
    };
    const stopfileUpload = function () {
      clearInterval(intervalId); // 清除 setInterval
    };

    const getMessageByPage = function () {
      if (store.state.chat.chatCurrentPage >= store.state.chat.chatLastPage)
        return;
      if (store.state.chat.isLoadMessage) return;
      store.state.chat.chatCurrentPage++;

      store.dispatch('getMessageByPage', {
        form: store.state.auth.user.uuid,
        to: store.state.chat.projcetChatId,
        page: store.state.chat.chatCurrentPage
      });
    };

    onMounted(() => {});

    onUpdated(() => {});
    const onReplyCancleHandler = function () {
      store.commit('reSetReply');
      store.commit('setMessageHeight');
    };

    const onScaleHandler = function () {
      store.commit('setCahtModel', 0);
      store.commit('checkChatFixed');
      store.commit('setMessageHeight');
    };

    const onFullHandler = function () {
      store.commit('setCahtModel', 1);
      store.commit('checkChatFixed');
      store.commit('setMessageHeight');
    };
    const onAvaterHandler = function () {
      store.commit('setCahtModel', -1);
      store.commit('checkChatFixed');
    };
    const onCloseHandler = function () {
      store.commit('showChat', false);
    };

    const onMessageBackHandler = function () {
      store.commit('showChatList', true);
      store.commit('showChat', false);
      store.commit('setMessageHeight');
    };

    const onSendMessageHandler = function () {
      if (messageText.value.length == 0) return;
      const user = store.state.auth.user;
      //console.log('onSendMessageHandler:', user);

      const data = {
        form: user.uuid,
        to: store.state.chat.projcetChatId,
        text: textToHtml(escapeHtml(messageText.value)),
        is_seen: false,
        date: getCurrentDateTime(),
        reply_uuid: ''
      };

      if (store.state.chat.reply) {
        data.replyAvater = store.state.chat.replyTo.avatar;
        data.replyName = store.state.chat.replyTo.name;
        data.replyText = store.state.chat.replyTo.text;
        data.replyImg = store.state.chat.replyTo.img;
        data.reply_uuid = store.state.chat.replyTo.replyReplyUuid;
      }

      //console.log('data:', data);

      store.dispatch('sendMessage', data).then(function () {
        messageText.value = '';
        messageInput.value.style.height = '26px';
      });
    };

    const onFileChangeHandler = function (e) {
      //console.log('e:', e);
      let file = e.target.files[0];

      const { name: fileName, size: fileSize } = file;
      const fileExtension = fileName.split('.').pop();
      if (
        ![
          'jpg',
          'png',
          'jpeg',
          'gif',
          'zip',
          'rar',
          'xlsx',
          'docx',
          'txt',
          'mp3'
        ].includes(fileExtension.toString().toLowerCase())
      ) {
        notification.notificationShow('warning', '檔案不允許');
        return false;
      }

      //150MB
      if (fileSize > 10000 * 10000) {
        notification.notificationShow(
          'warning',
          '檔案' + formatBytes(fileSize) + ',超過150MB'
        );
        return false;
      }

      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.addEventListener('loadstart', e => {});
      reader.addEventListener('load', e => {
        if (!file.type.match('image.*')) {
        } else {
        }
      });

      const user = store.state.auth.user;
      const data = {
        form: user.uuid,
        to: store.state.chat.projcetChatId,
        fileName: file.name,
        fileSize: formatBytes(fileSize),
        file: file,
        read: false,
        date: getCurrentDateTime()
      };

      startfileUpload();
      filePercentage.value = 1;
      store.commit('messageScrollDown');
      store.dispatch('sendFile', data).then(function () {
        messageText.value = '';
        stopfileUpload();
        filePercentage.value = 100;
        setTimeout(function () {
          filePercentage.value = 0;
        }, 300);
      });
    };

    const onScrollHandler = function () {
      if (chatContainer.value.scrollTop === 0 && !store.state.chat.loadFreeze) {
        getMessageByPage();
      }
    };

    const onMessageInputHandler = function () {
      store.commit('setMessageHeight');
    };
    return {
      store,
      messageText,
      utils,
      filePercentage,
      chatContainer,
      messageInput,
      onReplyCancleHandler,
      onScaleHandler,
      onFullHandler,
      onAvaterHandler,
      onCloseHandler,
      onMessageBackHandler,
      onSendMessageHandler,
      onFileChangeHandler,
      onScrollHandler,
      onMessageInputHandler
    };
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.message__wrapper {
  position: relative;
}
.message__body__file-loading {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  span {
    color: rgba(0, 0, 0, 0.32);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
.message__body-loading {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: 9;
  top: 80px;
  .message__body-loading-text {
    position: absolute;
    top: 0;
    left: 50%;

    background-color: #44c3ce;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    margin: 10px;
    margin-left: -50px;
  }
}
.message__body__date {
  z-index: 5;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  span {
    border-radius: 12px;
    background-color: #b6b6b6;
    padding: 3px 10px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
  }
}
.message__wrapper.full {
  width: calc(100% - 400px);
}

.message__wrapper.popup {
  width: 100%;
}
.message__header {
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
}

.message__header-left {
  display: flex;
  min-width: 160px;
  min-height: 48px;
}

.message__back {
  padding-right: 24px;
  cursor: pointer;
  display: none;
}

.message__avatar {
  position: relative;
  width: 48px;
  height: 48px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}
.message__pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #44c3ce;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.message__online {
  position: absolute;
  right: -1px;
  bottom: -3px;
}

.message__info {
  padding-left: 10px;
  text-align: left;
  width: 100%;
}
.message__online-text {
  overflow: hidden;
  color: #616161;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 2.17px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.message__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 24px;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 2.48px;
}
.message__scale {
  cursor: pointer;
}

.message__header-right {
  display: flex;
}

.message__control__full {
  display: flex;
}
.message__control__popup {
  display: flex;
}

.message__control-full,
.message__control-avatar,
.message__control-close {
  cursor: pointer;
}
.message__body {
  width: 100%;
  height: calc(100% - 70px - 76px - 80px);
  overflow-y: auto;
  position: relative;
}
.message__body.popup {
  height: calc(100% - 76px - 80px);
}

.message__body.reply.popup {
  height: calc(100% - 224px);
}
.message__body.reply.full {
  height: calc(100% - 294px);
}
.message__body__inner {
}

.message__footer {
  width: 100%;
  height: 80px;
  background-color: #fff;
}

.message__footer.reply {
  height: 148px;
}

.message__reply {
  display: flex;
  padding: 12px 16px;
  position: relative;
}

.message__reply__avatar {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 8px 0px;
}
.message__reply__pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.message__reply__info {
  margin-left: 10px;
  text-align: left;
  width: calc(100% - 28px);
}

.message__reply__info.img {
  display: flex;
  align-items: center;
}
.message__reply__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #616161;

  color: #000;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__reply__message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #616161;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 2.17px;
  width: calc(100% - 40px);
}
.message__reply__img {
  margin-left: 6px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.message__reply__cancle {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px 16px;
  cursor: pointer;
}
.message__input {
  display: flex;
  padding: 16px;
  align-items: flex-end;
  label {
    display: flex;
    width: 36px;
    height: 48px;
    margin-right: 8px;
    cursor: pointer;
  }
  input[type='file'] {
    display: none;
  }
  position: relative;
}

.message__text {
  text-align: left;
  padding: 12px 14px 0px 20px;
  border-radius: 30px;
  background: #f1f1f1;
  width: calc(100% - 44px);
  textarea {
    max-height: 200px;
    resize: none;
    width: calc(100% - 40px);
    appearance: none;
    border: 0px;
    outline: none;
    background: transparent;

    color: #000;
    font-family: PingFang TC;
    font-size: 14px;
    height: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.17px;
    ::placeholder {
      color: rgba(0, 0, 0, 0.3);
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    ::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  textarea::-webkit-scrollbar {
    width: 0;
  }
}
.message__send {
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 60px;
  height: 80px;
  padding-right: 14px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .message__back {
    display: block;
  }

  .message__control-full {
    display: none;
  }

  #message__input {
    font-size: 16px;
  }
}
</style>
