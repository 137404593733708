<template>
  <button
    :uk-tooltip="`title:標題${heading.text};pos:top;`"
    @click="
      editor
        .chain()
        .focus()
        .toggleHeading({ level: parseInt(heading.level) })
        .run()
    "
    :class="{
      'is-active': editor.isActive('heading', {
        level: parseInt(heading.level)
      })
    }"
  >
    <img
      :src="require('@/assets/icon/editor/' + heading.img + '.svg')"
      width="24"
      height="24"
    />
  </button>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default: () => {}
    },
    heading: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup() {
    return {};
  }
};
</script>
<style lang="scss"></style>
