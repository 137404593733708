<template>
  <nav
    :class="{
      fixed: fixed,
      onscroll: onscroll,
      invitation: navbarItem == 'Invitation'
    }"
  >
    <component :is="navbarItem"></component>
  </nav>
</template>

<script>
import { computed, ref, watch, provide } from 'vue';
import Login from '@/components/navbar/Login.vue';
import LoggedIn from '@/components/navbar/LoggedIn.vue';
import Experience from '@/components/navbar/Experience.vue';
import Invitation from '@/components/navbar/Invitation.vue';
import { useStore } from 'vuex';
import navbar from '@/lib/navbar';

export default {
  name: 'Navbar',
  components: {
    Login,
    LoggedIn,
    Experience,
    Invitation
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const fixed = ref(true);
    //有些頁面navbar要fixed
    const fixedPages = ['/adpost_vendor', '/'];
    //navbar改為體驗版
    const experiencePages = ['/adpost_vendor'];
    const navbarItem = ref(null);
    const invitationPages = ['/invitation'];
    const experiencePage = path => {
      return new Promise(resolve => {
        resolve(experiencePages.includes(path));
      });
    };
    const fixedPage = path => {
      return new Promise(resolve => {
        resolve(fixedPages.includes(path));
      });
    };
    const invitationPage = path => {
      return Promise.resolve(path && path.includes(invitationPages));
    };

    const checkNavbar = async path => {
      if (await fixedPage(path)) {
        fixed.value = true;
      } else {
        fixed.value = false;
      }
      if (await experiencePage(path)) {
        return (navbarItem.value = 'Experience');
      }

      if (await invitationPage(path)) {
        return (navbarItem.value = 'Invitation');
      }

      if (isLoggedIn.value) {
        navbarItem.value = 'LoggedIn';
      } else {
        navbarItem.value = 'Login';
      }
    };
    watch(
      isLoggedIn,
      () => {
        checkNavbar(navbar.path.value);
      },
      {
        immediate: true
      }
    );

    watch(navbar.path, value => {
      checkNavbar(value);
    });
    //滾動特效
    const onscroll = ref(false);
    document.addEventListener('scroll', function (e) {
      if (document.documentElement.scrollTop > 50) {
        onscroll.value = true;
      } else {
        onscroll.value = false;
      }
    });
    const closeSideNav = (element, callback) => {
      UIkit.offcanvas(element).hide();
      UIkit.util.on(element, 'hidden', function () {
        callback();
      });
    };
    provide('onscroll', onscroll);
    provide('fixed', fixed);
    provide('closeSideNav', closeSideNav);

    return { fixed, onscroll, navbarItem };
  }
};
</script>

<style lang="scss">
nav {
  position: static;
  width: 100%;
  padding: 12px 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  background: $color-white;
  display: flex;
  justify-content: space-between;
  height: 70px;
  &.fixed {
    position: fixed;
    background: transparent;
    padding: 20px 0;

    &.onscroll {
      background: $white-color;
      box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
      padding: 12px 0;
    }
  }
  &.invitation {
    padding: 0;
    height: 96px;
  }
  .navItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 1200px;
    margin: 0 auto;
    padding: 0 24px;

    .menu {
      display: flex;
      .item {
        padding: 10px;
        cursor: pointer;
        letter-spacing: 0.15em;
        font-weight: 400;
        font-size: 1.1rem;
        color: $white-color;

        &:hover {
          color: $white-color;
        }
      }
      .items {
        padding: 10px;
      }
      .item,
      .items {
        .svg:hover,
        .svg.hover {
          position: relative;
          transition: all 0.8s ease-in-out;
          z-index: 1;
          &:after {
            content: '';
            position: absolute;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            background: rgba(241, 241, 241, 0.322);

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 6px;
            z-index: -1;
          }
        }
      }
      .item {
        .svg {
          &:hover {
            z-index: unset;
          }
        }
      }
    }
    &.onscrollItem {
      .menu {
        .item,
        .items {
          color: #44c3ce;
          &:hover {
            color: #44c3ce;
            z-index: 1000;
          }
          .svg:hover,
          .svg.hover {
            &:after {
              background: rgba(241, 241, 241, 0.795);
            }
          }
        }
        .item {
          .svg {
            &:hover {
              z-index: unset;
            }
          }
        }
        .item.project,
        .item.shadow {
          &:hover {
            text-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
          }
        }
      }
      .bars {
        .bar {
          background: #5a5a5a;
        }
      }
    }
    .bars {
      display: none;
      height: 1.5rem;
      width: 24px;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;
      cursor: pointer;
      .bar {
        height: 3px;
        background: #ffffff;
        border-radius: 1px;
        width: 100%;

        &:nth-child(1) {
          width: 70%;
        }

        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  nav {
    .navItem {
      // padding: 0;
      // width: 90%;
    }
  }
}
@media (max-width: 768px) {
  nav {
    height: 55px;
    .navItem {
      // padding: 0 1rem;
      // width: 95%;
      // .menu {
      //   margin-left: auto;
      //   .item.shadow {
      //     display: none;
      //   }
      //   .item.project {
      //     display: none;
      //   }
      //   .items.notification {
      //     display: none;
      //   }
      //   .item.userInfoArea {
      //     display: none;
      //   }
      // }
      .bars {
        display: flex;
      }
    }
  }
}
.sideBarArea {
  padding: 0;
  background: #f9f9f9;
  color: #616161;
  .menu {
    display: block;
    height: 100%;
    margin: 0;
    padding: 96px 64px 64px 64px;
    .item {
      margin-bottom: 36px;
      a,
      span {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15em;
        color: $color-deep-gray;
        cursor: pointer;
      }
    }
    .register {
      width: 140px;
      height: 48px;
    }
    .login span {
      color: $color-light-green;
    }
    .uk-offcanvas-close {
      position: static;
    }
    .item.register {
      background: linear-gradient(91.61deg, #74dbcf 13.46%, #68c0cc 115.1%),
        linear-gradient(89.74deg, #c8f8ff 0.28%, #50eee5 41.98%);
      box-shadow: 0px 0px 3px 2px rgba(108, 255, 237, 0.45);
      border-radius: 37px;
      cursor: pointer;
      a,
      span {
        color: white;
      }
    }
  }
}
</style>
