<template>
  <div class="message__i_say">
    <div class="message__i_say__inner">
      <div class="message__i_say__info">
        <div class="message__i_say__read" v-if="read == 'true' || read">
          已讀
        </div>
        <div class="message__i_say__date-time">
          {{ utils.formatDate(date) }}
        </div>
      </div>
      <div
        v-if="showImg"
        class="message__i_say__img"
        :class="{ h: imgRatio < 1 }"
      >
        <a :href="image" target="_blank">
          <img :src="image" alt="" />
        </a>
      </div>
      <div v-else class="message__i_say__text">
        <div class="message__i_say__text-header" v-if="showHeader">
          <!-- 回復的那條訊息  -->
          <div class="message__i_say__reply-wrapper" v-if="replyText">
            <div class="message__i_say__avatar">
              <img
                :src="replyAvater"
                alt=""
                :class="{ w: replyAvaterImgRatio > 1 }"
              />
            </div>
            <div class="message__i_say__reply-info" v-if="replyText">
              <div class="message__i_say__reply-name">{{ replyName }}</div>
              <div class="message__i_say__reply-text">{{ replyText }}</div>
            </div>
          </div>

          <!-- 回復的那條圖檔 -->
          <div class="message__i_say__reply-wrapper img" v-if="replyImg">
            <div class="message__i_say__avatar">
              <img
                :src="replyAvater"
                :class="{ w: replyAvaterImgRatio > 1 }"
                alt=""
              />
            </div>
            <div class="message__i_say__reply-info img">
              <div class="message__i_say__reply-name img">
                {{ replyImgName }}
              </div>
              <div class="message__i_say__reply-img img">
                <img :src="replyImg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="message__i_say__text-line" v-if="showHeader"></div>
        <div class="message__i_say__text-body" v-if="!showfile">
          <p class="message__i_say__text-p" v-html="formatMessage(text)"></p>
        </div>
        <div class="message__i_say__text-body" v-if="showfile">
          <div class="message__i_say__text-file">
            <div class="message__i_say__text-file-pic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              >
                <path
                  d="M11.3334 7.67656V2.72656H6.3834C5.94579 2.72656 5.52611 2.9004 5.21667 3.20984C4.90724 3.51927 4.7334 3.93896 4.7334 4.37656V18.6766C4.7334 19.1142 4.90724 19.5339 5.21667 19.8433C5.52611 20.1527 5.94579 20.3266 6.3834 20.3266H16.2834C16.721 20.3266 17.1407 20.1527 17.4501 19.8433C17.7596 19.5339 17.9334 19.1142 17.9334 18.6766V9.32656H12.9834C12.5458 9.32656 12.1261 9.15272 11.8167 8.84329C11.5072 8.53385 11.3334 8.11417 11.3334 7.67656ZM7.4834 11.5266H15.1834C15.3293 11.5266 15.4692 11.5845 15.5723 11.6877C15.6755 11.7908 15.7334 11.9307 15.7334 12.0766C15.7334 12.2224 15.6755 12.3623 15.5723 12.4655C15.4692 12.5686 15.3293 12.6266 15.1834 12.6266H7.4834C7.33753 12.6266 7.19763 12.5686 7.09449 12.4655C6.99134 12.3623 6.9334 12.2224 6.9334 12.0766C6.9334 11.9307 6.99134 11.7908 7.09449 11.6877C7.19763 11.5845 7.33753 11.5266 7.4834 11.5266ZM7.4834 13.7266H15.1834C15.3293 13.7266 15.4692 13.7845 15.5723 13.8877C15.6755 13.9908 15.7334 14.1307 15.7334 14.2766C15.7334 14.4224 15.6755 14.5623 15.5723 14.6655C15.4692 14.7686 15.3293 14.8266 15.1834 14.8266H7.4834C7.33753 14.8266 7.19763 14.7686 7.09449 14.6655C6.99134 14.5623 6.9334 14.4224 6.9334 14.2766C6.9334 14.1307 6.99134 13.9908 7.09449 13.8877C7.19763 13.7845 7.33753 13.7266 7.4834 13.7266ZM7.4834 15.9266H15.1834C15.3293 15.9266 15.4692 15.9845 15.5723 16.0877C15.6755 16.1908 15.7334 16.3307 15.7334 16.4766C15.7334 16.6224 15.6755 16.7623 15.5723 16.8655C15.4692 16.9686 15.3293 17.0266 15.1834 17.0266H7.4834C7.33753 17.0266 7.19763 16.9686 7.09449 16.8655C6.99134 16.7623 6.9334 16.6224 6.9334 16.4766C6.9334 16.3307 6.99134 16.1908 7.09449 16.0877C7.19763 15.9845 7.33753 15.9266 7.4834 15.9266ZM12.4334 7.67656V3.00156L17.6584 8.22656H12.9834C12.8375 8.22656 12.6976 8.16862 12.5945 8.06547C12.4913 7.96233 12.4334 7.82243 12.4334 7.67656Z"
                  fill="#616161"
                />
              </svg>
            </div>
          </div>
          <div class="message__i_say__text-file-info">
            <div class="message__i_say__text-file-name">
              {{ fileName }}
            </div>
            <div class="message__i_say__text-file-size">{{ fileSize }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="message__i_say__append">
      <div class="file-upload" v-if="showfile">
        <!-- <div class="file-uploading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="5"
            viewBox="0 0 100 5"
            fill="none"
          >
            <rect y="0.526367" width="100" height="4" rx="2" fill="#E5E5E5" />
            <rect
              y="0.526367"
              :width="filePercentage"
              height="4"
              rx="2"
              fill="#00CA69"
            />
          </svg>
          <span>{{ filePercentage }}%</span>
          <svg
            style="cursor: pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            @click="onFileCancleHandler"
          >
            <circle
              cx="8.00016"
              cy="8.52653"
              r="6.66667"
              fill="#616161"
              fill-opacity="0.6"
            />
            <path
              d="M11.7879 5.54421C11.905 5.42706 11.905 5.23711 11.7879 5.11995L11.4064 4.7385C11.2893 4.62134 11.0993 4.62134 10.9822 4.7385L8.21213 7.50852C8.09498 7.62568 7.90503 7.62568 7.78787 7.50852L5.01785 4.7385C4.90069 4.62134 4.71074 4.62134 4.59358 4.7385L4.21213 5.11995C4.09497 5.23711 4.09497 5.42706 4.21213 5.54421L6.98215 8.31423C7.09931 8.43139 7.09931 8.62134 6.98215 8.7385L4.21213 11.5085C4.09498 11.6257 4.09497 11.8156 4.21213 11.9328L4.59358 12.3142C4.71074 12.4314 4.90069 12.4314 5.01785 12.3142L7.78787 9.54421C7.90503 9.42706 8.09497 9.42706 8.21213 9.54421L10.9822 12.3142C11.0993 12.4314 11.2893 12.4314 11.4064 12.3142L11.7879 11.9328C11.905 11.8156 11.905 11.6257 11.7879 11.5085L9.01785 8.7385C8.90069 8.62134 8.90069 8.43139 9.01785 8.31424L11.7879 5.54421Z"
              fill="white"
            />
          </svg>
        </div> -->
        <div class="file-uploaded">
          <!-- <span @click="onFileRelHandler">重傳</span>｜ -->
          <!-- <span @click="onFileDetelHandler">刪除</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, nextTick, provide, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import utils from '@/class/Utils.js';

export default {
  name: 'ChatRoomMessageISay',
  props: {
    data: { type: Object, default: {} },
    text: {
      type: String,
      default: ''
    },
    read: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    },
    //回復內容------------------------------------------
    replyAvater: {
      type: String,
      default: ''
    },
    replyName: {
      type: String,
      default: ''
    },
    replyText: {
      type: String,
      default: ''
    },
    //回復圖檔------------------------------------------
    replyImg: {
      type: String,
      default: ''
    },
    replyImgName: {
      type: String,
      default: ''
    },
    //上傳檔案------------------------------------------
    fileName: {
      type: String,
      default: ''
    },
    fileSize: {
      type: String,
      default: ''
    },
    //上傳圖檔------------------------------------------
    image: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    const showHeader = ref(false);
    const showfile = ref(false);
    const showImg = ref(false);

    const filePercentage = ref(60);
    const imgRatio = ref(0);
    const replyAvaterImgRatio = ref(0);
    if (
      props.replyAvater ||
      props.replyName ||
      props.replyText ||
      props.replyImg
    ) {
      showHeader.value = true;
    }

    const getFileExtension = function (filename) {
      return filename.split('.').pop().toLowerCase();
    };

    if (props.replyAvater != '') {
      const img = new Image();
      img.onload = function () {
        replyAvaterImgRatio.value = this.width / this.height;
      };
      img.src = props.replyAvater;
    }

    // if (props.fileName || props.fileSize || props.image) {
    //   showfile.value = true;
    // }

    if (
      ['jpg', 'jpeg', 'png', 'bmp'].includes(getFileExtension(props.fileName))
    ) {
      showImg.value = true;
    }

    if (
      ['zip', 'rar', 'xlsx', 'docx', 'txt', 'mp3'].includes(
        getFileExtension(props.fileName)
      )
    ) {
      showfile.value = true;
    }

    if (props.image != '') {
      const img = new Image();
      img.onload = function () {
        imgRatio.value = this.width / this.height;
      };
      img.src = props.image;
    }

    onMounted(() => {});

    const formatMessage = function (text) {
      if (!text) return '';
      return text.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a style="color:#435BDA;word-break: break-all" href="$1" target="_blank">$1</a>'
      );
    };
    const onFileCancleHandler = function () {};
    const onFileDetelHandler = function () {};
    const onFileRelHandler = function () {};
    return {
      showHeader,
      utils,
      showfile,
      showImg,
      filePercentage,
      imgRatio,
      replyAvaterImgRatio,
      formatMessage,
      onFileCancleHandler,
      onFileDetelHandler,
      onFileRelHandler
    };
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.message__i_say {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 7px;
  padding-bottom: 7px;
}

.message__i_say__append {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  padding-top: 6px;
}
.file-uploading {
  display: flex;
  justify-content: flex-end;
  span {
    color: var(--b32, rgba(0, 0, 0, 0.32));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
.file-uploaded {
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
}
.message__i_say__inner {
  display: flex;
  padding-right: 18px;
  width: 100%;
  justify-content: flex-end;
}

.message__i_say__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message__i_say__read {
  color: rgba(0, 0, 0, 0.32);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__i_say__date-time {
  color: rgba(0, 0, 0, 0.32);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}
.message__i_say__text {
  margin-left: 10px;
  max-width: 60%;
  border-radius: 10px 2px 10px 10px;
  background-color: #edf9fb;

  text-align: left;
}

.message__i_say__img {
  margin-left: 10px;
  max-width: 280px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  width: 60%;
  img {
    width: 100%;
    height: auto;
  }
}

.message__i_say__img.h {
  max-width: 180px;
}

.message__i_say__text-header {
}
.message__i_say__text-line {
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.1);
}
.message__i_say__text-body {
  display: flex;
  padding: 8px 12px;
  p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 2.17px;
    margin: 0px;
  }
}

.message__i_say__text-p {
  word-break: break-all;
}

.message__i_say__text-file-pic {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 100px;
  background-color: #fff;
  margin-right: 10px;
}

.message__i_say__text-file-info {
}

.message__i_say__text-file-name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2.17px;
  word-break: break-all;
}

.message__i_say__text-file-size {
  color: #616161;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.message__i_say__reply-wrapper {
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.message__i_say__reply-wrapper.img {
  align-items: center;
}
.message__i_say__avatar {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
  img.w {
    width: auto;
    height: 100%;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }
}

.message__i_say__reply-info {
  //max-width: 176px;
  max-width: calc(100% - 38px);
}

.message__i_say__reply-info.img {
  display: flex;
  align-items: center;
  width: 100%;
}

.message__i_say__reply-name.img {
  padding-right: 5px;
  width: calc(100% - 45px);
}
.message__i_say__reply-img.img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}
.message__i_say__reply-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  //max-width: 138px;
  width: 100%;
}
.message__i_say__reply-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.45);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  //max-width: 138px;
  width: 100%;
}
</style>
