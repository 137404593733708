import 'vuetify/styles';
import '/node_modules/material-design-icons/iconfont/material-icons.css';
import { createVuetify } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
/*
因為使用component(:is="layout")動態組件套件以下用法失效
import * as components from 'vuetify/components'
https://stackoverflow.com/questions/61437186/vuetify-doesnt-activate-its-components
只能一個一個加入
*/
//import * as components from 'vuetify/components';
import {
  VAlert,
  VApp,
  VAppBar,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBtn,
  VBtnGroup,
  VBtnToggle,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VChip,
  VChipGroup,
  VCode,
  VCol,
  VColorPicker,
  VCombobox,
  VCounter,
  VContainer,
  VDataIterator,
  VDataTable,
  VDatePicker,
  VDefaultsProvider,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanels,
  VField,
  VFileInput,
  VFooter,
  VForm,
  //VGrid,
  VHover,
  VIcon,
  VImg,
  VInfiniteScroll,
  VInput,
  VItemGroup,
  VKbd,
  VLabel,
  VLayout,
  VLazy,
  VList,
  VListItem,
  VListItemTitle,
  VLocaleProvider,
  VMain,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VNoSsr,
  VOtpInput,
  //VOverflowBtn,
  VOverlay,
  VPagination,
  VParallax,
  VProgressCircular,
  VProgressLinear,
  VRow,
  VRadio,
  VRadioGroup,
  //VRangeSilder,
  VRating,
  VResponsive,
  VSelect,
  VSelectionControl,
  VSelectionControlGroup,
  VSheet,
  VSkeletonLoader,
  VSlideGroup,
  VSlider,
  VSnackbar,
  //VSparkline,
  //VSpeedDial,
  VStepper,
  VSwitch,
  VSystemBar,
  VSpacer,
  VTable,
  VTabs,
  VTab,
  VTextarea,
  VTextField,
  VThemeProvider,
  VTimeline,
  //VTimePicker,
  VToolbar,
  VTooltip,
  //VTreeview,
  VValidation,
  VVirtualScroll,
  VWindow,
  VWindowItem
} from 'vuetify/components';
import * as directives from 'vuetify/directives';
const vuetify = createVuetify({
  display: {
    thresholds: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1440
    }
  },
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBanner,
    VBottomNavigation,
    VBottomSheet,
    VBreadcrumbs,
    VBtn,
    VBtnGroup,
    VBtnToggle,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VChip,
    VChipGroup,
    VCode,
    VCol,
    VColorPicker,
    VCombobox,
    VCounter,
    VContainer,
    VDataIterator,
    VDataTable,
    VDatePicker,
    VDefaultsProvider,
    VDialog,
    VDivider,
    VExpansionPanel,
    VExpansionPanels,
    VField,
    VFileInput,
    VFooter,
    VForm,
    //VGrid,
    VHover,
    VIcon,
    VImg,
    VInfiniteScroll,
    VInput,
    VItemGroup,
    VKbd,
    VLabel,
    VLayout,
    VLazy,
    VList,
    VListItem,
    VListItemTitle,
    VLocaleProvider,
    VMain,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VNoSsr,
    VOtpInput,
    //VOverflowBtn,
    VOverlay,
    VPagination,
    VParallax,
    VProgressCircular,
    VProgressLinear,
    VRow,
    VRadio,
    VRadioGroup,
    //VRangeSilder,
    VRating,
    VResponsive,
    VSelect,
    VSelectionControl,
    VSelectionControlGroup,
    VSheet,
    VSkeletonLoader,
    VSlideGroup,
    VSlider,
    VSnackbar,
    //VSparkline,
    //VSpeedDial,
    VStepper,
    VSwitch,
    VSystemBar,
    VSpacer,
    VTable,
    VTabs,
    VTab,
    VTextarea,
    VTextField,
    VThemeProvider,
    VTimeline,
    //VTimePicker,
    VToolbar,
    VTooltip,
    //VTreeview,
    VValidation,
    VVirtualScroll,
    VWindow,
    VWindowItem,
    directives
  }

  // components: {
  //   components,
  //   directives
  // }
});

export default vuetify;
