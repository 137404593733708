<template>
  <teleport to="body">
    <transition-group name="list-complete" tag="div" class="notificationGroup">
      <div
        class="notificationItem list-complete-item"
        v-for="(message, i) in notificationData.data"
        :key="message.random"
        @click="triggerNotification(message), closeNotification(i)"
      >
        <div class="avatar">
          <Avatar
            size="36"
            :img="message.avatar"
            :name="message.parameters?.name"
          />
        </div>
        <div class="message">
          <NotificationListItemContent
            :name="{
              name: message.parameters?.name,
              company: message.parameters?.company_name
            }"
            :code="{
              identity: message.code.identity,
              function: message.code.function,
              action: message.code.action
            }"
            :parameters="message.parameters"
          />

          <div class="projectInfo" v-if="message.code.action == 11">
            <span
              class="name"
              v-if="message.parameters.project_name"
              :uk-tooltip="`title: ${message.parameters.project_name}; pos: bottom;offset: 6`"
              >{{ message.parameters.project_name }}</span
            >
            <span
              class="partner"
              v-if="message.parameters.name"
              :uk-tooltip="`title: ${message.parameters.name}; pos: bottom;offset: 6`"
              >{{ message.parameters.name }}</span
            >
            <span
              class="type"
              v-if="message.parameters.task_type"
              :uk-tooltip="`title: ${getType(
                message.parameters.task_type
              )}; pos: bottom;offset: 6`"
              >{{ getType(message.parameters.task_type) }}</span
            >
          </div>

          <div class="projectInfo" v-else>
            <span
              class="name"
              v-if="message.parameters.project_name"
              :uk-tooltip="`title: ${message.parameters.project_name}; pos: bottom;offset: 6`"
              >{{ message.parameters.project_name }}</span
            >
            <span
              class="partner"
              v-if="message.parameters.name"
              :uk-tooltip="`title: ${message.parameters.name}; pos: bottom;offset: 6`"
              >{{ message.parameters.name }}</span
            >
            <span
              class="type"
              v-if="message.parameters.task_type"
              :uk-tooltip="`title: ${
                typeList[message.parameters.task_type]
              }; pos: bottom;offset: 6`"
              >{{ typeList[message.parameters.task_type] }}</span
            >
          </div>

          <div class="date">
            <span>現在</span>
          </div>
        </div>
        <div class="close" @click.stop="closeNotification(i)">
          <img
            v-bind:src="require('@/assets/icon/close.svg')"
            width="24"
            height="24"
          />
        </div>
      </div>
    </transition-group>
  </teleport>
</template>
<script>
import { reactive, watch, inject } from 'vue';
import NotificationListItemContent from '@/components/navbar/notification/NotificationListItemContent';
import Avatar from '@/components/tools/Avatar.vue';

export default {
  components: { NotificationListItemContent, Avatar },
  setup() {
    const notificationData = reactive({ data: [] });
    const notificationState = inject('notificationState');
    const openChatService = inject('openChatService');
    const jumpToPath = inject('jumpToPath');
    const setChatParameter = inject('setChatParameter');
    const setChatName = inject('setChatName');
    const triggerNotification = async message => {
      if (message.code.action != '03') await jumpToPath(message);
      // 例外處理 -> 是否顯示對話框
      if (_isChatUpdate(message.code.action))
        openChatService({
          data: setChatParameter(message),
          name: setChatName(message.code.function, message.parameters)
        });
    };

    const _isChatUpdate = code => code == '04';

    watch(
      () => notificationState.data,
      () => {
        notificationData.data.push({
          ...notificationState.data,
          random: uuidv4()
        });
      },
      { deep: true }
    );
    watch(
      () => notificationData.data,
      () => {
        if (notificationData.data.length > 0) {
          setTimeout(() => {
            notificationData.data.shift();
          }, 6000);
        }
      },
      { deep: true }
    );
    const closeNotification = i => {
      notificationData.data.splice(i, 1);
    };

    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }
    const getType = function (array) {
      return array.join('/');
    };

    const functionList = {
      '010': '合作案',
      '020': '需求單',
      '030': '創意大綱',
      '040': '腳本細節',
      '050': '影片製作',
      '060': '文案撰寫',
      '061': '文案撰寫照片',
      '070': '作品上線',
      '080': '結案報告'
    };
    const actionList = {
      '01': '新增',
      '02': '更新',
      '03': '刪除',
      '04': '留下了新留言',
      '05': '過稿',
      '06': '時程',
      '07': '開放'
    };
    const typeList = {
      video: '影音',
      essay: '圖文',
      live: '直播',
      short_video: '短影音',
      story: '限時動態'
    };
    return {
      notificationData,
      closeNotification,
      functionList,
      actionList,
      typeList,
      getType,
      triggerNotification
    };
  }
};
</script>
<style lang="scss" scoped>
.list-complete-item {
  transition: all 0.8s ease;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.list-complete-leave-active {
  position: absolute;
}
.notificationGroup {
  position: fixed;
  top: 80px;
  z-index: 1040;
  box-sizing: border-box;
  left: auto;
  right: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  .notificationItem {
    background: $color-white;
    margin-top: 1rem;
    width: 100%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
    display: flex;
    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    position: relative;
    .avatar {
      flex: 0 0 auto;
      width: 2.25rem;
      height: 2.25rem;
    }
    .message {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin: 0 2rem 0 1rem;
      flex: 1;
      .content {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.155em;
        color: #000000;
        text-align: left;
        p {
          margin: 0;
          padding: 0;
        }
        span {
          font-weight: bold;
        }
      }
      .projectInfo {
        display: flex;
        margin: 0.5rem 0 0 0;
        span {
          padding: 0.05rem 0.25rem;
          margin-right: 0.25rem;
          border-radius: 8px;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.05em;
          &.name {
            background: #d8fffd;
            color: #007d88;
            max-width: 7rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.partner {
            background: #dcf2ff;
            color: #244088;
            max-width: 6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.type {
            background: #f3f3f3;
            color: #696969;
          }
        }
      }
      .date {
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: $gray-color;
      }
      :global(.uk-tooltip) {
        z-index: 1041;
      }
    }
    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      top: -8px;
      left: -8px;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.1s ease-in-out;
      img {
        filter: opacity(0.5);
      }
      &:hover {
        img {
          filter: drop-shadow(1px 3px 3px rgb(255, 255, 255));
        }
      }
    }
    &:hover {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.404);
      .close {
        opacity: 1;
      }
    }
  }
}
@media (max-width: 500px) {
  .notificationGroup {
    right: 50%;
    transform: translateX(50%);
    max-width: 360px;
    width: 90%;
    .notificationItem .message .projectInfo {
      span {
        &.name,
        &.partner {
          max-width: 5rem;
        }
      }
    }
  }
}
</style>
