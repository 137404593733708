export default {
  notificationShow(status, message, timeout) {
    let icon = '';
    if (status == 'success') {
      icon = `<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4027 15.9204C10.4027 15.9209 10.402 15.9212 10.4016 15.9208L6.75749 12.2759C6.64036 12.1587 6.64037 11.9688 6.75752 11.8516L7.61877 10.9904C7.73591 10.8732 7.92583 10.8732 8.043 10.9903L10.1905 13.1371C10.3077 13.2542 10.4976 13.2542 10.6148 13.137L15.3324 8.41862C15.4495 8.30145 15.6395 8.30144 15.7567 8.4186L16.6187 9.28067C16.7359 9.39783 16.7359 9.58779 16.6187 9.70495L10.4029 15.9199C10.4028 15.92 10.4027 15.9202 10.4027 15.9204Z" fill="#ffffff"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47727 6.47727 2 12 2C17.5227 2 22 6.47727 22 12C22 17.5227 17.5227 22 12 22C6.47727 22 2 17.5227 2 12ZM12 20.1818C10.9255 20.1818 9.86162 19.9702 8.86895 19.559C7.87629 19.1478 6.97433 18.5452 6.21458 17.7854C5.45483 17.0257 4.85216 16.1237 4.44099 15.131C4.02981 14.1384 3.81818 13.0745 3.81818 12C3.81818 10.9255 4.02981 9.86162 4.44099 8.86895C4.85216 7.87629 5.45483 6.97433 6.21458 6.21458C6.97433 5.45483 7.87629 4.85216 8.86895 4.44099C9.86162 4.02981 10.9255 3.81818 12 3.81818C14.17 3.81818 16.251 4.68019 17.7854 6.21458C19.3198 7.74897 20.1818 9.83005 20.1818 12C20.1818 14.17 19.3198 16.251 17.7854 17.7854C16.251 19.3198 14.17 20.1818 12 20.1818Z" fill="#ffffff"/>
            </svg>`;
    } else {
      icon = `<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47727 6.47727 2 12 2C17.5227 2 22 6.47727 22 12C22 17.5227 17.5227 22 12 22C6.47727 22 2 17.5227 2 12ZM12 20.1818C10.9255 20.1818 9.86162 19.9702 8.86895 19.559C7.87629 19.1478 6.97433 18.5452 6.21458 17.7854C5.45483 17.0257 4.85216 16.1237 4.44099 15.131C4.02981 14.1384 3.81818 13.0745 3.81818 12C3.81818 10.9255 4.02981 9.86162 4.44099 8.86895C4.85216 7.87629 5.45483 6.97433 6.21458 6.21458C6.97433 5.45483 7.87629 4.85216 8.86895 4.44099C9.86162 4.02981 10.9255 3.81818 12 3.81818C14.17 3.81818 16.251 4.68019 17.7854 6.21458C19.3198 7.74897 20.1818 9.83005 20.1818 12C20.1818 14.17 19.3198 16.251 17.7854 17.7854C16.251 19.3198 14.17 20.1818 12 20.1818Z" fill="#ffffff"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2299 11.9999L16.4242 8.80566L15.1942 7.57568L11.9999 10.77L8.80566 7.57568L7.57568 8.80566L10.77 11.9999L7.57568 15.1942L8.80566 16.4242L11.9999 13.2299L15.1942 16.4242L16.4242 15.1942L13.2299 11.9999ZM11.9999 11.1942L15.1942 7.99995L15.1943 8L12 11.1943L11.9999 11.1942ZM8.80566 7.99995L8.80571 8L8 8.80571L7.99995 8.80566L8.80566 7.99995ZM7.99995 15.1942L11.1942 11.9999L11.1943 12L8 15.1943L7.99995 15.1942Z" fill="#ffffff"/>
            </svg>
            `;
    }
    UIkit.notification({
      message: `<div class='notificationAlert'><p><span>${icon}
            </span>${message}</p></div>`,
      status: status,
      pos: 'top-center',
      timeout: timeout || '1500'
    });
  },
  notificationShows(status, message) {
    UIkit.notification({
      message: `<div class='notificationAlert'><p>${message}</p></div>`,
      status: status,
      pos: 'bottom-right',
      timeout: '1500'
    });
  }
};
