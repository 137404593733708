import { Extension } from '@tiptap/core';
const GetSelectedText = Extension.create({
  name: 'myGetSelectedText',
  addCommands: () => {
    return {
      getSelectedText:
        () =>
        ({ editor }) => {
          const { from, to, empty } = editor.state.selection;

          if (empty) {
            return null;
          }

          return editor.state.doc.textBetween(from, to, ' ');
        }
    };
  }
});

export default GetSelectedText;
