import { useWindowSize } from '@vueuse/core';
import chatApi from '@/api/chat';
import utils from '@/class/Utils.js';

import { ref, toRefs, toRaw, reactive } from 'vue';

export default {
  namespace: true,
  state: {
    isLoadMessage: false, //是在讀取訊息嗎
    isLoadList: false, //是在讀取聊天列表嗎
    loadFreeze: false, //防止切換模式時scroll問題
    chatModel: 0, //切換模式  -1: 大頭模式、0:彈窗模式、1:全滿模式
    pusher: null, //共用的pusher
    onlineChannels: [], //pusher 偵聽是否在線
    clientListenChannel: null, //pusher 偵聽收到新訊息
    showChatRoom: false, //顯示聊天內容
    showChatRoomList: false, //是否顯示聊天人物列表
    showNotification: false, //是否顯示通知列表
    hasUnread: false, //是否有新訊息
    unseenCount: 0, //未讀訊息數
    isMobile: false, //是否為手機
    //傳訊息給那位
    chatTo: {
      chatroomUuid: '',
      uuid: '',
      name: '',
      avatar: '',
      online: false,
      imgRatio: 0
    },
    reply: false, //回覆
    replyTo: {
      name: '',
      avatar: '',
      text: '',
      img: ''
    },
    lists: [], //聊天人物列表資料
    onlineLists: [], //在線資料
    chatCurrentPage: 1, //訊息目前頁數
    chatLastPage: 1, //訊息最後一頁
    chatTotal: 0, //訊息總共頁數
    chatLatId: null, //目前的聊天訊息id
    chatPreviousId: null, //上一筆聊天訊息id
    chatList: [], //訊息資料
    projcetChatId: '', //
    currentProjcetChatId: '',
    currentMessageBodyInnerHeight: 0,
    previousMessageBodyInnerHeight: 0
  },
  getters: {},
  mutations: {
    reSetReply(state) {
      state.replyTo = {
        form: '',
        to: '',
        name: '',
        avatar: '',
        text: '',
        img: ''
      };
      state.reply = false;
    },
    setPusher(state, payload) {
      const _self = this;
      state.pusher = payload.pusher;

      // _self.dispatch('getList', null).then(function (data) {
      //   console.log('get list data:', data);
      // });
      state.clientListenChannel = state.pusher.subscribe(
        `private-chatrooms.${payload.user.uuid}`
      );

      state.clientListenChannel.bind('new-message', function (data) {
        if (
          state.projcetChatId == data.chatroom_uuid &&
          state.showChatRoom &&
          (state.chatModel == 0 || state.chatModel == 1)
        ) {
          _self.dispatch('getMessage', {
            to: data.chatroom_uuid
          });
        }

        _self.dispatch('getList', null);
      });

      if (payload.auth.token == '') return;

      chatApi.getList().then(res => {
        state.onlineChannels = [];

        res.data.data.chatrooms.forEach(item => {
          const channel = state.pusher.subscribe(
            `presence-chatrooms.presence.${item.chatroom_uuid}`
          );
          channel.bind('client-seen', function (e) {
            if (
              state.projcetChatId == item.chatroom_uuid &&
              state.showChatRoom &&
              (state.chatModel == 0 || state.chatModel == 1)
            ) {
              _self.commit('messageSeen');
            }
          });

          channel.bind('pusher:subscription_succeeded', function (e) {
            //console.log('subscription_succeeded:', e);
            const thisChannel = channel;
            Object.keys(thisChannel.members.members).forEach(key => {
              state.onlineLists.push(thisChannel.members.members[key]);
            });

            state.onlineLists = state.onlineLists.filter(
              (obj, index, self) =>
                index === self.findIndex(o => o.uuid === obj.uuid)
            );
            _self.commit('setOnlineStatus');
          });
          channel.bind('pusher:member_added', function (e) {
            state.onlineLists.push({ uuid: e.info.uuid });
            _self.commit('setOnlineStatus');
          });

          channel.bind('pusher:member_removed', function (e) {
            state.onlineLists = state.onlineLists.filter(
              item => item.uuid !== e.info.uuid
            );
            _self.commit('setOnlineStatus');
          });
          state.onlineChannels.push(channel);
        });
      });
    },
    setOnlineStatus(state) {
      let object = state.onlineLists;

      state.chatTo.online = false;
      state.lists.forEach(element => {
        element.contact.online = false;
        Object.keys(object).forEach(key => {
          if (element.contact.uuid == object[key].uuid) {
            element.contact.online = true;
          }
        });
      });

      Object.keys(object).forEach(key => {
        if (object[key].uuid == state.chatTo.uuid) {
          state.chatTo.online = true;
        }
      });
    },
    setChatTo(state, chatTo) {
      const _self = this;
      state.chatTo.chatroomUuid = chatTo.chatroomUuid;
      state.chatTo.name = chatTo.name;
      state.chatTo.avatar = chatTo.avatar;
      state.chatTo.uuid = chatTo.uuid;
      const img = new Image();
      img.onload = function () {
        state.chatTo.imgRatio = this.width / this.height;
      };

      img.src = state.chatTo.avatar;

      //列表 active
      this.commit('setList');
    },
    //顯示聊天室
    showChat(state, boolen) {
      const _slef = this;
      state.showChatRoom = boolen;
      this.commit('checkChatFixed');
    },
    //顯示聊天列表
    showChatList(state, boolen) {
      state.showChatRoomList = boolen;
      this.commit('checkChatFixed');
    },
    //顯示通知列表
    showNotificationList(state, boolen) {
      state.showNotification = boolen;
      this.commit('checkChatFixed');
    },
    checkChatFixed(state) {
      const { width } = useWindowSize();
      if (state.showChatRoom || state.showChatRoomList) {
        if (state.showChatRoom && state.chatModel == 1) {
          window.scrollTo({
            top: 0,
            behavior: 'instant'
          });
        }

        if (width.value <= 768) {
          window.scrollTo({
            top: 0,
            behavior: 'instant'
          });
        }

        setTimeout(function () {
          document.querySelector('html').style.overflowY = 'hidden';
          document.querySelector('html').style.overflow = 'hidden';
          document.querySelector('html').style.paddingRight = '12px';
          if (
            [-1, 0].includes(state.chatModel) &&
            state.showChatRoom &&
            !state.showChatRoomList &&
            !state.showNotification &&
            width.value > 768
          ) {
            document.querySelector('html').removeAttribute('style');
          }

          if ([-1].includes(state.chatModel) && width.value <= 768) {
            document.querySelector('html').removeAttribute('style');
          }
        }, 50);
      } else {
        document.querySelector('html').removeAttribute('style');
      }
    },
    setCahtModel(state, modelType) {
      state.chatModel = modelType;

      this.commit('messageScrollDown');
      if (modelType == 1) {
        state.loadFreeze = true;
        setTimeout(function () {
          state.loadFreeze = false;
        }, 500);
      }

      if (document.getElementById('message__input'))
        document.getElementById('message__input').style.height = '26px';

      this.commit('checkChatFixed');
    },
    setCahtList(state, data) {
      const groupedData = {};
      state.chatLastPage = data.last_page;
      state.chatTotal = data.total;
      state.chatCurrentPage = 1;

      data.messages.forEach((item, index) => {
        const date = item.created_at;

        const dateOnly = date.split('T')[0]; // 截取日期部分
        if (!groupedData[dateOnly]) {
          groupedData[dateOnly] = [];
        }
        groupedData[dateOnly].push(item);
        if (index == 0) state.chatLatId = item.message_uuid;
      });

      const tem = [];
      Object.keys(groupedData).forEach(key => {
        tem.push({ date: key, message: groupedData[key] });
      });

      state.chatList = tem;

      this.commit('setChatTo', {
        uuid: data.contact.uuid,
        name: data.contact.name,
        avatar: data.contact.avatar
      });

      this.commit('setOnlineStatus');
    },
    unshiftCahtList(state, data) {
      const tmp = state.chatList;
      const groupedData = {};
      state.chatLastPage = data.last_page;
      state.chatTotal = data.total;
      state.chatPreviousId = state.chatLatId;

      data.messages.forEach(item => {
        const date = item.created_at;
        //console.log('date:', date);
        const dateOnly = date.split('T')[0]; // 截取日期部分
        if (!groupedData[dateOnly]) {
          groupedData[dateOnly] = [];
        }
        groupedData[dateOnly].push(item);
      });

      tmp.forEach(item => {
        Object.keys(groupedData).forEach(groupedkey => {
          if (item.date === groupedkey) {
            const gtmp = groupedData[groupedkey].reverse();

            gtmp.forEach((gitem, index) => {
              item.message.unshift(gitem);
              if (index == gtmp.length - 1) {
                state.chatLatId = gitem.message_uuid;
              }
            });
            delete groupedData[item.date];
          }
        });
      });

      Object.keys(groupedData).forEach(key => {
        tmp.unshift({ date: key, message: groupedData[key] });
        state.chatLatId = groupedData[key][0].message_uuid;
      });
    },
    setList(state, data) {
      const _self = this;
      if (data) {
        state.lists = data;
      }
      //console.log('state.lists:', state.lists);
      state.hasUnread = false;
      state.lists.forEach(element => {
        element.active = false;
        if (element.unseen_count > 0) state.hasUnread = true;
      });
      const filter = state.lists.filter(item => {
        return item.chatroom_uuid === state.projcetChatId;
      });
      if (filter.length > 0) {
        filter[0].active = true;
        state.unseenCount = filter[0].unseen_count;
        if (
          state.showChatRoom &&
          (state.chatModel == 0 || state.chatModel == 1) &&
          !state.showChatRoomList
        ) {
          filter[0].unseen_count = 0;
          state.unseenCount = 0;
        }
      }

      state.hasUnread = false;
      state.lists.forEach(element => {
        if (element.unseen_count > 0) state.hasUnread = true;
      });
      _self.commit('setOnlineStatus');
    },
    setLoadMessage(state, value) {
      state.isLoadMessage = value;
    },
    setLoadList(state, value) {
      if (state.lists.length > 0 && value == true) return;
      state.isLoadList = value;
    },
    setMessageHeight(state) {
      //訊息柵位依文字換行變高及限制最多三行
      const messageInput = document.getElementById('message__input');
      const messageBody = document.getElementById('message__body');
      const messageFooter = document.getElementById('message__footer');
      messageInput.style.height = '26px';
      messageInput.style.height =
        Math.min(messageInput.scrollHeight, 54) + 'px';
      let offset = Math.min(messageInput.scrollHeight - 26, 34);
      let footerHeight = 80 + offset;
      if (state.reply) {
        footerHeight = 148 + offset;
      }
      messageFooter.style.height = footerHeight + 'px';
      //-1: 大頭模式、0:彈窗模式、1:全滿模式
      switch (state.chatModel) {
        case -1:
          break;
        case 0:
          messageBody.style.height =
            'calc(100% - 76px - ' + footerHeight + 'px)';

          break;
        case 1:
          messageBody.style.height =
            'calc(100% - 70px - 76px - ' + footerHeight + 'px)';

          break;
        default:
          break;
      }
    },
    pushMessage(state, message) {
      //console.log('pushMessage:', message);
      //新增訊息到自已的聊天室
      const date = message.created_at;
      const dateOnly = date.split('T')[0];

      let filter = state.chatList.filter(item => {
        //console.log('filter:', item.date, dateOnly);
        return item.date === dateOnly;
      });

      if (filter.length == 0) {
        state.chatList.push({ date: dateOnly, message: [] });
      }

      filter = state.chatList.filter(item => {
        return item.date === dateOnly;
      });

      filter[0].message.push(message);
    },

    clearMessage(state) {
      state.chatList = {};
    },
    messageScrollElement(state) {
      //載入上一頁訊息後，scroll 到上一頁最後一筆訊息位置
      document.getElementById('message__body').scroll({
        top:
          state.currentMessageBodyInnerHeight -
          state.previousMessageBodyInnerHeight +
          30,
        behavior: 'instant'
      });
      setTimeout(function () {
        document.getElementById('message__body').scroll({
          top:
            state.currentMessageBodyInnerHeight -
            state.previousMessageBodyInnerHeight +
            30,
          behavior: 'instant'
        });
      }, 100);
    },
    messageScrollDown() {
      //scroll 到最新(最下方)訊息位置
      setTimeout(function () {
        const div = document.getElementById('message__body');
        if (div) {
          document
            .getElementById('message__body')
            .scroll({ top: div.scrollHeight, behavior: 'instant' });
        }
      }, 60);
      setTimeout(function () {
        const div = document.getElementById('message__body');
        if (div) {
          document
            .getElementById('message__body')
            .scroll({ top: div.scrollHeight, behavior: 'instant' });
        }
      }, 200);
    },
    setProjectChatId(state, data) {
      state.currentProjcetChatId = data.chatroomUuid;
      state.projcetChatId = data.chatroomUuid;
      state.chatTo.chatroomUuid = data.chatroomUuid;
      state.chatTo.uuid = data.userUuid;
    },
    traggerSeeChannel(state, chatroom_uuid) {
      state.onlineChannels.forEach(element => {
        if (element.name == 'presence-chatrooms.presence.' + chatroom_uuid) {
          element.trigger('client-seen', { status: 'ok' });
        }
      });
    },
    messageSeen(state) {
      state.chatList;
      //console.log('state.chatList:', state.chatList);
      state.chatList.forEach(element => {
        //console.log('element:', element);

        element.message.forEach(subelement => {
          //console.log('subelement:', subelement);
          subelement.is_seen = true;
        });
      });
    }
  },
  actions: {
    sendMessage({ commit }, message) {
      //送出訊息
      return new Promise((resolve, reject) => {
        const data = {
          text: message.text,
          file: '',
          reply_uuid: message.reply_uuid ? message.reply_uuid : null
        };
        chatApi
          .sendMessage(utils.object2FormData(message), message.to)
          .then(res => {
            resolve(res);
            //console.log('sendMessage res:', message);
            const callBackMessage = res;
            callBackMessage.chatroom_uuid = message.to;
            callBackMessage.is_seen = false;
            commit('pushMessage', callBackMessage);
            commit('reSetReply');
            commit('messageScrollDown');
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('sendMessage api completed');
          });
      });
    },
    sendFile({ commit }, file) {
      //送出檔案
      return new Promise((resolve, reject) => {
        //console.log('sendMessage:', file);
        const data = { file: file.file, reply_uuid: '' };
        chatApi
          .sendMessage(utils.object2FormData(data), file.to)
          .then(res => {
            resolve(res);
            //console.log('sendFile res:', res);
            const message = res;
            message.is_seen = false;

            commit('pushMessage', message);
            commit('setOnlineStatus');
            commit('reSetReply');
            commit('messageScrollDown');
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('sendFile api completed');
          });
      });
    },
    getMessage({ commit, rootState }, data) {
      //取得訊息資料
      //console.log('getMessage');
      const _self = this;
      commit('setLoadMessage', true);

      return new Promise((resolve, reject) => {
        chatApi
          .getMessage(data.to)
          .then(res => {
            resolve(res);
            //console.log('getMessage:', res);

            commit('setLoadMessage', false);
            commit('setCahtList', res.data.data);
            commit('messageScrollDown');

            _self.dispatch('makeSee', data.to);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('getMessage api completed');
          });
      });
    },
    getMessageByPage({ commit, rootState }, data) {
      //取得分頁訊息資料
      //console.log('getMessageByPage');
      commit('setLoadMessage', true);
      return new Promise((resolve, reject) => {
        chatApi
          .getMessageByPage(data.to, data.page)
          .then(res => {
            resolve(res);
            rootState.chat.previousMessageBodyInnerHeight =
              document.getElementById('message__body__inner').clientHeight;

            //console.log('res.data.data:', res.data.data);
            commit('setLoadMessage', false);
            commit('unshiftCahtList', res.data.data);
            setTimeout(function () {
              rootState.chat.currentMessageBodyInnerHeight =
                document.getElementById('message__body__inner').clientHeight;
              commit('messageScrollElement');
            }, 100);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('getMessage api completed');
          });
      });
    },
    getMessageId({ commit }, uuid) {
      //取得聊天室id
      return new Promise((resolve, reject) => {
        chatApi
          .getMessageId(uuid)
          .then(res => {
            resolve(res);
            commit('setProjectChatId', {
              chatroomUuid: res.data.data.chatroom_uuid,
              userUuid: uuid
            });
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {});
      });
    },
    makeSee({ commit }, chatroom_uuid) {
      //訊息改為已讀
      return new Promise((resolve, reject) => {
        chatApi
          .makeSee(chatroom_uuid)
          .then(res => {
            resolve(res);
            //console.log(res);
            commit('setList', null);
            commit('traggerSeeChannel', chatroom_uuid);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('makeSee api completed');
          });
      });
    },
    getList({ commit }, data) {
      //取得聊天列表
      commit('setLoadList', true);
      return new Promise((resolve, reject) => {
        chatApi
          .getList()
          .then(res => {
            commit('setList', res.data.data.chatrooms);
            commit('setLoadList', false);
            resolve(res);
            //console.log(res);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            //console.log('getList api completed');
          });
      });
    }
  }
};
