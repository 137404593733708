<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container "
    content-class="modal-content"
    id="forgotPasswordModal"
  >
    <div
      class="modal__content uk-modal-body uk-width-1-1 uk-card uk-card-body uk-background-default uk-border-rounded"
      uk-animation-fade
    >
      <button
        class="uk-modal-close-default"
        type="button"
        @click="showModal = false"
        uk-close
      ></button>
      <h3
        class="uk-card-title uk-text-bold uk-text-uppercase uk-flex uk-flex-center"
        style="color: #73d1dd"
      >
        忘記密碼
      </h3>
      <small>請填寫您註冊使用的信箱，我們會向您寄送重設密碼的連結。</small>
      <form @submit.prevent autocomplete="off">
        <div class="uk-margin-top">
          <div class="uk-form-controls uk-position-relative">
            <input
              class="uk-input uk-border-rounded"
              name="email"
              :class="[state.errorMessage.emailError ? 'uk-form-danger' : '']"
              type="mail"
              id="forgotEmail"
              @keyup="emailValidate"
              @paste="emailValidate"
              @change="emailValidate"
              required="required"
              v-model="state.forgotPasswordEmail"
            />
            <label class="uk-form-label uk-position-absolute" for="forgotEmail"
              >E-mail信箱</label
            >
          </div>
          <small
            class="uk-text-danger uk-float-right"
            v-if="state.errorMessage.emailError"
            >注意信箱格式是否錯誤</small
          >
        </div>

        <div class="uk-margin-small-bottom" style="margin-top: 48px">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <button
              class="uk-button forgotBtn uk-button-primary uk-border-pill"
              @click="forgotPassword"
              :disabled="
                state.errorMessage.emailError == false ||
                state.errorMessage.emailError
              "
            >
              發送
            </button>
          </div>
        </div>
        <div class="">
          <div class="uk-form-controls uk-flex uk-flex-center">
            <small
              ><span @click="showModal = false" style="color: #73d1dd"
                >返回登入頁</span
              ></small
            >
          </div>
        </div>
      </form>
    </div>
  </vue-final-modal>
  <AlertModal />
</template>

<script>
import { reactive, ref, inject } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import VALIDATE from '@/lib/validate';
import AlertModal from '@/components/modal/AlertModal.vue';
import axios from 'axios';
export default {
  name: 'ForgotModal',
  components: { AlertModal, VueFinalModal },
  emits: ['openModal', 'open-modal'],

  setup(props, { emit }) {
    const alertMessage = inject('alertMessage');
    const showModal = ref(false);
    const state = reactive({
      errorMessage: {
        emailError: false
      },
      forgotPasswordEmail: ''
    });
    //email檢查
    function emailValidate(event) {
      console.log(event.target.value);
      console.log(VALIDATE.emailValidate(event));
      state.errorMessage.emailError = VALIDATE.emailValidate(event);
    }
    // 忘記密碼
    const forgotPassword = async () => {
      let AlertModal = document.getElementById('alertModal');
      let data = {};
      data.email = state.forgotPasswordEmail;
      showModal.value = false;
      UIkit.modal(AlertModal).show();
      await axios({
        url: '/api/forget_pwd',
        data: data,
        method: 'POST'
      })
        .then(resp => {
          alertMessage.title = '收取驗證信';
          alertMessage.message = '請至信箱收取驗證信';
          setTimeout(() => {
            UIkit.modal(AlertModal).hide();
          }, 2000);

          console.log(resp);
        })
        .catch(err => {
          console.log(err);
          alertMessage.title = '錯誤';
          alertMessage.message = '請聯繫專人為您服務';
          setTimeout(() => {
            UIkit.modal(AlertModal).hide();
          }, 2000);
        });
    };

    const openOtherModal = async modalName => {
      emit('open-modal', modalName);
    };
    const openModal = () => {
      showModal.value = true;
      state.forgotPasswordEmail = '';
      state.errorMessage.emailError = false;
    };

    return {
      state,
      emailValidate,
      forgotPassword,
      openModal,
      openOtherModal,
      showModal
    };
  }
};
</script>
<style lang="scss" scoped>
#forgotPasswordModal .modal__content {
  width: 680px;
  height: 480px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 95vw;
  transition: 0.3s linear;
  h3 {
    margin-top: 96px;
    margin-bottom: 20px;
  }
  form {
    width: 480px;
    button {
      width: 168px;
      height: 48px;
      border-radius: 35px;
    }
  }
}

input {
  &:focus + label {
    transform: translate(-0.5rem, -50%);
    background: #fff;
    top: 0;
  }
  &:valid + label {
    transform: translate(-0.5rem, -50%);
    background: #fff;
    top: 0;
  }
}
label {
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  transition: transform 0.5s ease-in-out;
  padding: 0 2px;
}
small span {
  cursor: pointer;
}
@media (max-width: 560px) {
  #forgotPasswordModal .modal__content {
    width: 95vw;

    input {
      width: 100%;
    }
    form {
      width: 90%;
    }
  }
}
</style>
<style lang="scss">
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uk-button-primary {
  background: #68c0cc;
  color: white;
  &:hover {
    background: #68c0cc;
  }
}
.uk-button-primary:disabled {
  &:hover {
    background: white;
  }
}
</style>
