<template>
  <input
    ref="inputElement"
    placeholder="貼上連結"
    v-model.trim="currentUrl"
    type="text"
    class="tw-rounded tw-border tw-w-[318px] tw-text-base tw-px-4 tw-py-1.5 tw-border-solid tw-border-[#44C3CE] tw-text-[#333333] tw-mr-2"
  />
  <button
    type="button"
    class="tw-flex-1 tw-text-base !tw-text-[#333333] tw-w-[48px] !tw-opacity-100"
    @click="setLink"
  >
    套用
  </button>
</template>
<script>
import { ref, onMounted, nextTick } from 'vue';
import notification from '@/lib/notification';
export default {
  name: '',
  props: ['editor', 'currentUrl'],
  emits: ['closeShowEdit'],
  setup(props, { emit }) {
    const currentUrl = ref(props.editor.getAttributes('link').href);
    const inputElement = ref(null);
    const setLink = () => {
      const reg = /^(?:https?:\/\/)(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/g.test(
        currentUrl.value
      );

      if (currentUrl.value === '') {
        props.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        emit('closeShowEdit');
        return;
      }

      if (currentUrl.value === null || !reg) {
        notification.notificationShow('danger', '請確認是否輸入為網址');
        return;
      }

      props.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: currentUrl.value, 'data-href': currentUrl.value })
        .run();

      emit('closeShowEdit');
    };
    onMounted(() => {
      nextTick(() => {
        inputElement.value.focus();
      });
    });

    return { currentUrl, inputElement, setLink };
  }
};
</script>
<style lang="scss" scoped></style>
