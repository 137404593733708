export default class Path {
  constructor({
    type = '',
    projectId = '',
    taskId = '',
    otherUuid = '',
    order = 0,
    index = 0,
    taskType = ''
  }) {
    const map = {
      project: new Project(),
      briefb: new BriefB(),
      outline: new Outline(),
      draft: new Draft(),
      video: new Video(),
      copyWriting: new CopyWriting(),
      worksUpload: new WorksUpload(),
      caseReport: new CaseReport(),
      projectProgress: new ProjectProgress(),
      profile: new profile()
    };
    this._type = map[type];
    this._data = { projectId, taskId, otherUuid, order, index, taskType };
  }
  get goToPath() {
    return this._type.goToPath(this._data);
  }
}

class profile {
  goToPath(data = '') {
    console.log(data);
    return `/profile`;
  }
}

class Project {
  goToPath(data = '') {
    console.log(data);
    return `/project_progress?id=${data.projectId}&index=${data.index}`;
  }
}

class ProjectProgress {
  goToPath(data = '') {
    console.log(data);
    return `/project_progress?id=${data.projectId}&index=${data.index}`;
  }
}
class BriefB {
  goToPath(data = '') {
    // 特例處理
    return `/briefb/${data.taskId}/${data.taskType}?index=${data.index}`;
  }
}

class Outline {
  goToPath(data = '') {
    return `/outline/${data.taskId}?index=${data.index}`;
  }
}
class Draft {
  goToPath(data = '') {
    // return `/draft/${data.taskId}/draft-details/${data.order - 1}/${
    //   data.otherUuid
    // }?index=${data.index}`;
    return `/draft-type/${data.taskId}?index=${data.index}`;
  }
}
class Video {
  goToPath(data = '') {
    return `/video/${data.taskId}?index=${data.index}`;
  }
}
class CopyWriting {
  goToPath(data = '') {
    return `/copywriting/${data.taskId}/${data.otherUuid}?index=${data.index}`;
  }
}
class WorksUpload {
  goToPath(data = '') {
    return `/works_upload/${data.taskId}?index=${data.index}`;
  }
}
class CaseReport {
  goToPath(data = '') {
    return `/case_report/${data.taskId}/${data.otherUuid}?index=${data.index}`;
  }
}
