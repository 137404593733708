import ChatRoom from '../api/messageBoard';

export default class Chat {
  constructor(data = {}) {
    const map = {
      draft: new Draft(),
      briefb: new BriefB(),
      outline: new Outline(),
      copyWriting: new CopyWriting(),
      video: new Video()
    };
    this._data = data;
    this._api = map[data.type];
  }
  get getMessages() {
    return this._api.getMessages(this._data);
  }
  get sendMessage() {
    return this._api.sendMessage(this._data);
  }
  get updateMessagesProcessed() {
    return this._api.updateMessagesProcessed(this._data);
  }
  get editMessage() {
    return this._api.editMessage(this._data);
  }
  get delMessage() {
    return this._api.delMessage(this._data);
  }
  get getResponse() {
    return this._api.getResponse(this._data);
  }
}

class Draft {
  getMessages(data = { taskId: '', secondaryId: '', columnName: '' }) {
    return ChatRoom.getMessagesFromDraft(data.taskId, data.secondaryId).then(
      res => {
        var result = res.data.data;
        return result;
      }
    );
  }
  sendMessage(
    data = {
      taskId: '',
      secondaryId: '',
      message: '',
      messageId: '',
      images: []
    }
  ) {
    var formatSendData = {
      task_uuid: data.taskId,
      script_uuid: data.secondaryId,
      message: data.message,
      message_id: data?.messageId,
      quote: data?.quote,
      images: data?.images
    };
    return ChatRoom.sendMessageFromDraft(formatSendData);
  }
  updateMessagesProcessed(
    data = { taskId: '', secondaryId: '', messageId: '', status: '' }
  ) {
    return ChatRoom.updateMessagesProcessedFromDraft(
      data.taskId,
      data.messageId,
      data.status
    );
  }
  editMessage(
    data = {
      taskId: '',
      messageId: '',
      message: '',
      images: []
    }
  ) {
    var formatSendData = {
      message: data.message,
      images: data.images
    };
    return ChatRoom.editMessageFromDraft(
      data.taskId,
      data.messageId,
      formatSendData
    );
  }
  delMessage(data = { taskId: '', secondaryId: '', messageId: '' }) {
    return ChatRoom.delMessageFromDraft(data.taskId, data.messageId);
  }
  getResponse(data = { taskId: '', secondaryId: '', messageId: '' }) {
    return ChatRoom.getResponseFromDraft(data.taskId, data.messageId).then(
      res => {
        var result = res.data?.data;
        return result;
      }
    );
  }
}

class BriefB {
  getMessages(data = { taskId: '' }) {
    return ChatRoom.getMessagesFromBriefB(data.taskId).then(res => {
      var result = res.data?.data;
      return result;
    });
  }
  sendMessage(data = { taskId: '', message: '', images: [] }) {
    var formatSendData = {
      task_uuid: data.taskId,
      message: data.message,
      message_id: data?.messageId,
      quote: data?.quote,
      images: data?.images
    };
    return ChatRoom.sendMessageFromBriefB(formatSendData);
  }
  updateMessagesProcessed(data = { taskId: '', messageId: '', status: '' }) {
    return ChatRoom.updateMessagesProcessedFromBriefB(
      data.taskId,
      data.messageId,
      data.status
    );
  }
  editMessage(data = { taskId: '', messageId: '', message: '', images: [] }) {
    var formatSendData = {
      message: data.message,
      images: data.images
    };
    return ChatRoom.editMessageFromBriefB(
      data.taskId,
      data.messageId,
      formatSendData
    );
  }
  delMessage(data = { taskId: '', messageId: '' }) {
    return ChatRoom.delMessageFromBriefB(data.taskId, data.messageId);
  }
  getResponse(data = { taskId: '', messageId: '' }) {
    return ChatRoom.getResponseFromBriefB(data.taskId, data.messageId).then(
      res => {
        var result = res.data?.data;
        return result;
      }
    );
  }
}

class Outline {
  getMessages(data = { taskId: '' }) {
    return ChatRoom.getMessagesFromOutline(data.taskId).then(res => {
      var result = res.data?.data;
      return result;
    });
  }
  sendMessage(data = { taskId: '', message: '', message_id: '', images: [] }) {
    var formatSendData = {
      task_uuid: data.taskId,
      message: data.message,
      message_id: data?.messageId,
      quote: data?.quote,
      images: data?.images
    };
    return ChatRoom.sendMessageFromOutline(formatSendData);
  }
  updateMessagesProcessed(data = { taskId: '', messageId: '', status: '' }) {
    return ChatRoom.updateMessagesProcessedFromOutline(
      data.taskId,
      data.messageId,
      data.status
    );
  }
  editMessage(data = { taskId: '', messageId: '', message: '', images: [] }) {
    var formatSendData = {
      message: data.message,
      images: data.images
    };
    return ChatRoom.editMessageFromOutline(
      data.taskId,
      data.messageId,
      formatSendData
    );
  }
  delMessage(data = { taskId: '', messageId: '' }) {
    return ChatRoom.delMessageFromOutline(data.taskId, data.messageId);
  }
  getResponse(data = { taskId: '', messageId: '' }) {
    return ChatRoom.getResponseFromOutline(data.taskId, data.messageId).then(
      res => {
        var result = res.data?.data;
        return result;
      }
    );
  }
}

class CopyWriting {
  getMessages(data = { taskId: '', platformId: '' }) {
    return ChatRoom.getMessagesFromCopyWriting(
      data.taskId,
      data.platformId
    ).then(res => {
      var result = res.data?.data;
      return result;
    });
  }
  sendMessage(
    data = {
      taskId: '',
      message: '',
      platformId: '',
      messageId: '',
      images: []
    }
  ) {
    var formatSendData = {
      task_uuid: data.taskId,
      platform_uuid: data.platformId,
      message: data.message,
      message_id: data?.messageId,
      quote: data?.quote,
      images: data?.images
    };
    return ChatRoom.sendMessageFromCopyWriting(formatSendData);
  }
  updateMessagesProcessed(
    data = { taskId: '', platformId: '', messageId: '', status: '' }
  ) {
    return ChatRoom.updateMessagesProcessedFromCopyWriting(
      data.taskId,
      data.platformId,
      data.messageId,
      data.status
    );
  }
  editMessage(
    data = {
      taskId: '',
      platformId: '',
      messageId: '',
      message: '',
      images: []
    }
  ) {
    var formatSendData = {
      message: data.message,
      images: data.images
    };
    return ChatRoom.editMessageFromCopyWriting(
      data.taskId,
      data.platformId,
      data.messageId,
      formatSendData
    );
  }
  delMessage(data = { taskId: '', platformId: '', messageId: '' }) {
    return ChatRoom.delMessageFromCopyWriting(
      data.taskId,
      data.platformId,
      data.messageId
    );
  }
  getResponse(data = { taskId: '', platformId: '', messageId: '' }) {
    return ChatRoom.getResponseFromCopyWriting(
      data.taskId,
      data.platformId,
      data.messageId
    ).then(res => {
      var result = res.data?.data;
      return result;
    });
  }
}
class Video {
  getMessages(data = { taskId: '' }) {
    return ChatRoom.getMessagesFromVideo(data.taskId).then(res => {
      var result = res.data?.data;
      return result;
    });
  }
  sendMessage(
    data = {
      taskId: '',
      message: '',
      platformId: '',
      messageId: '',
      images: []
    }
  ) {
    var formatSendData = {
      task_uuid: data.taskId,
      message: data.message,
      message_id: data?.messageId,
      quote: data?.quote,
      images: data?.images
    };
    return ChatRoom.sendMessageFromVideo(formatSendData);
  }
  updateMessagesProcessed(
    data = { taskId: '', platformId: '', messageId: '' }
  ) {
    return ChatRoom.updateMessagesProcessedFromVideo(
      data.taskId,
      data.messageId
    );
  }
  editMessage(
    data = {
      taskId: '',
      messageId: '',
      message: '',
      images: []
    }
  ) {
    var formatSendData = {
      message: data.message,
      images: data.images
    };
    return ChatRoom.editMessageFromVideo(
      data.taskId,
      data.messageId,
      formatSendData
    );
  }
  delMessage(data = { taskId: '', messageId: '' }) {
    return ChatRoom.delMessageFromVideo(data.taskId, data.messageId);
  }
  getResponse(data = { taskId: '', messageId: '' }) {
    return ChatRoom.getResponseFromVideo(data.taskId, data.messageId).then(
      res => {
        var result = res.data?.data;
        return result;
      }
    );
  }
}
