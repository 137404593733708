import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueEasyLightbox from 'vue-easy-lightbox';
import VueDeferredContent from 'vue3-deferred-content';
import useAxios from './api/axios';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import VueResizeObserver from 'vue-resize-observer';

import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';
import './assets/main.scss';
import './assets/base.scss';
import './assets/tailwind.css';

const app = createApp(App);

const baseUrl = process.env.VUE_APP_AXIOS_DEFAULT_URL;

useAxios();
const VueDeferredContentOptions = {
  name: 'lazyContent', // by default: deferred
  rootMargin: '100px', // string
  threshold: 0 // number | number[]
};

app.directive('focus', {
  mounted: el => {
    el.focus();
  }
});

const VueProgressBarOptions = {
  color: '#44c3ce',
  failedColor: '#E96666',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};

import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

loadFonts();

app
  .use(store)
  .use(router)
  .use(ContextMenu)
  .use(VueDeferredContent, VueDeferredContentOptions)
  .use(VueEasyLightbox)
  .use(VueProgressBar, VueProgressBarOptions)
  .use(VueResizeObserver)
  .use(vuetify)
  .provide('baseUrl', baseUrl)
  .mount('#app');

export default baseUrl;
