<template>
  <section class="customerSayArea tw-py-8 md:tw-py-14">
    <div class="tw-homeContainer">
      <h2
        class="tw-text-[#161818] tw-text-[32px] tw-leading-[normal] tw-tracking-[1.28px] tw-mb-8 tw-py-2"
      >
        我們的客戶怎麼說
      </h2>
      <swiper
        :modules="modules"
        :pagination="{ clickable: true }"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }"
        :slides-per-view="1"
        :space-between="2"
        :breakpoints="{
          '992': {
            slidesPerView: 2,
            spaceBetween: 47,
            slidesPerGroup: 2
          }
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(item, index) in data">
          <div
            class="tw-rounded-[48px_48px_48px_4px] tw-border-2 tw-border-solid tw-border-[#4FADCB] tw-py-8 tw-px-10 tw-h-full tw-flex tw-flex-col tw-text-left"
          >
            <div>
              <img
                :src="require(`@/assets/landing_page/customerSay/comma.png`)"
                alt=""
              />
            </div>
            <p
              class="tw-mt-3 tw-mb-4 tw-flex-1 tw-text-[#161818] tw-text-base tw-leading-6 tw-tracking-[1.28px]"
            >
              {{ item.content }}
            </p>
            <div
              class="tw-text-right tw-text-[#616161] tw-text-base tw-not-italic tw-leading-6 tw-tracking-[1.28px]"
            >
              <span
                class="icon-account_circle_white tw-text-[#cacaca] tw-text-3xl"
              ></span>
              {{ item.position }} {{ item.name }}｜ {{ item.industry }}
            </div>
          </div></swiper-slide
        >
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/autoplay';
const modules = [Autoplay, Pagination];
const data = reactive([
  {
    position: '行銷公關',
    name: 'Julia',
    industry: '電商平台',
    content:
      '開始用了AD POST 後，大大提升業配執行的效率，平台的文案比對工具和影音快速審稿很好用，以往需要耗費大量時間去一一核對，尤其是在一次合作幾十位網紅時，除了節省幾十倍的時間也避免人為的失誤，推薦因為業配加班到昏天暗地的行銷人員。'
  },
  {
    position: '行銷企劃',
    name: 'Grace',
    industry: '服飾產業',
    content:
      '之前有試過其他的網紅平台，在茫茫一片的網紅資料庫中還是無法挑選出最適合的人選，後來跟 AD POST 接洽後，AD POST 的行銷團隊很專業且細心，針對所提出的所有需求統整一批專屬推薦的人選，還給予很多執行上的建議，很適合對網紅生態不太了解的新手們。'
  },
  {
    position: '行銷經理',
    name: 'Frank',
    industry: '3C產業',
    content:
      '之前與網紅合作業配時，需要用一堆的信件和LINE群組來回的溝通，資料和訊息的量多到有點混亂，有時也有遺漏的狀況發生，在使用了 AD POST 後，所有的內容都可以在專案裡清楚地呈現，也可以清楚地控管排程進度，這個平台是網紅行銷時的強大助手！'
  },
  {
    position: '品牌企劃',
    name: 'Wilson',
    industry: '家居產業',
    content:
      '已經和 AD POST 合作兩年多，只要填完行銷需求後，後續的一切就可以安心地交由專業執行團隊協助，只需要確認就好，從策略建議、企劃發想、人選媒合、合約等所有環節，如果與網紅溝通有特殊狀況，AD POST 也會從中協助讓專案順利執行，真的讓業配變得很簡單很輕鬆！'
  }
]);
</script>

<style lang="scss" scoped>
.customerSayArea {
  @apply tw-bg-no-repeat tw-bg-[url('~@/assets/landing_page/customerSay/bgComma.png')] tw-bg-[20px_0] md:tw-bg-[84px_0];
}

:deep .swiper-pagination {
  margin-top: 32px;
}

:deep .swiper-pagination-bullet {
  margin: 0 10px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #e5e5e5;
  border-radius: 50%;
  &.swiper-pagination-bullet-active {
    background: #44c3ce;
  }
}

.swiper-slide {
  height: auto;
}
</style>
