<template>
  <div class="notificationPopup shadow-light" v-if="showPopup">
    <!-- 通知列表 -->
    <div
      v-if="!store.state.notification.showInvite"
      class="tw-w-full tw-h-full tw-flex tw-flex-col"
    >
      <div class="title">
        <span>通知</span>
        <small @click="readNotifications">全部已讀</small>
      </div>
      <div class="notificationList" v-if="notificationList.data.length > 0">
        <NotificationListItem
          v-for="message in notificationList.data"
          :key="message"
          :messageData="message"
        />
        <div class="loading" v-if="loading">
          <span uk-spinner="ratio: 1"></span>
        </div>
      </div>
      <div class="emptyNotificationList" v-else>
        <div class="content">
          <img :src="require('@/assets/icon/email.svg')" />
          <span>尚無任何通知</span>
          <small>當有重要訊息時，我們會通知您。</small>
        </div>
      </div>
    </div>
    <!-- 邀請列表 -->
    <div v-else class="tw-w-full tw-h-full tw-flex tw-flex-col">
      <div
        class="title-invite tw-text-[rgb(97,97,97)] tw-border-b-neutral-100 tw-px-4 tw-py-3 tw-border-b-[solid]"
      >
        <div
          @click="backToNotifictionHandler"
          class="tw-absolute tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M11.6773 2.79275C11.7946 2.67536 11.7944 2.48495 11.6767 2.36789L10.9156 1.61107C10.7983 1.49443 10.6087 1.49475 10.4918 1.61177L4.3219 7.78789C4.20485 7.90507 4.20489 8.09493 4.32201 8.21205L10.4979 14.3879C10.615 14.505 10.805 14.505 10.9221 14.3879L11.6779 13.6322C11.795 13.515 11.795 13.3251 11.6779 13.2079L6.68212 8.21216C6.56496 8.095 6.56496 7.90505 6.68212 7.78789L11.6773 2.79275Z"
              fill="#616161"
            />
          </svg>
        </div>
        <span
          >合作邀約&nbsp;&nbsp;({{
            store.state.notification.inviteData.length
          }})</span
        >
        <small></small>
      </div>
      <div class="tw-overflow-auto">
        <NotificationListInviteItem
          v-for="message in store.state.notification.inviteData"
          :key="message"
          :messageData="message"
        ></NotificationListInviteItem>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationListItemContent from '@/components/navbar/notification/NotificationListItemContent';
import NotificationListItem from '@/components/navbar/notification/NotificationListItem';
import NotificationListInviteItem from '@/components/navbar/notification/NotificationListInviteItem';
import Avatar from '@/components/tools/Avatar.vue';
import { ref, watch, inject, provide } from 'vue';
import { useStore } from 'vuex';
import { handlePublishTimeDesc } from '@/lib/time';

export default {
  components: {
    NotificationListItem,
    NotificationListInviteItem,
    NotificationListItemContent,
    Avatar
  },
  setup() {
    const store = useStore();
    const showPopup = ref(false);
    const loading = ref(false);
    const togglePopup = () => (showPopup.value = !showPopup.value);
    const notificationList = inject('notificationList');
    const readNotifications = inject('readNotifications');
    const loadMore = inject('loadMore');
    let notificationListDiv = null;
    watch(showPopup, () => {
      if (showPopup.value) {
        //不讓頁面滾動
        document.querySelector('html').style.overflowY = 'hidden';
        document.querySelector('html').style.paddingRight = '12px';
        document.querySelector('.notification .svg').classList.add('hover');
        setTimeout(() => {
          notificationListDiv = document.querySelector('.notificationList');

          if (notificationListDiv)
            notificationListDiv.addEventListener('scroll', handleScroll);
        }, 300);
        store.state.notification.showInvite = false;
      } else {
        document.querySelector('html').removeAttribute('style');
        document.querySelector('.notification .svg').classList.remove('hover');
      }
    });

    const handleScroll = async () => {
      // 檢查是否滾到底部
      let clientHeight = notificationListDiv.clientHeight;
      let scrollTop = notificationListDiv.scrollTop;
      let scrollHeight = notificationListDiv.scrollHeight;
      if (clientHeight + scrollTop == scrollHeight && scrollHeight > 0) {
        loading.value = true;
        await loadMore();
        loading.value = false;
      }
    };

    const closePopup = () => (showPopup.value = false);

    const backToNotifictionHandler = function () {
      store.state.notification.showInvite = false;
      setTimeout(() => {
        notificationListDiv = document.querySelector('.notificationList');
        if (notificationListDiv)
          notificationListDiv.addEventListener('scroll', handleScroll);
      }, 100);
    };
    provide('closePopup', closePopup);
    return {
      notificationListDiv,
      store,
      handlePublishTimeDesc,
      togglePopup,
      showPopup,
      loading,
      closePopup,
      notificationList,
      readNotifications,
      backToNotifictionHandler
    };
  }
};
</script>
<style lang="scss" scoped>
.notificationPopup {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: $white-color;
  top: 2rem;
  width: 25rem;
  right: 0;
  height: 80vh;
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
  .title {
    display: flex;
    padding: 0.75rem 1rem;
    color: $gray-color;
    border-bottom: solid 1px #f5f5f5;
    justify-content: space-between;
    small {
      align-self: flex-end;
      cursor: pointer;
      &:hover {
        color: $disable-gray-color;
      }
    }
  }

  .title-invite {
    border-bottom: solid 1px #f5f5f5;
  }
  .notificationList {
    overflow: hidden auto;
    .loading {
      padding: 0.5rem 0;
      position: absolute;
      bottom: 1rem;
      width: 40px;
      background: $white-color;
      display: flex;
      height: 40px;
      left: 50%;
      transform: translateX(-50%);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      filter: drop-shadow(4px 4px 19px rgba(0, 0, 0, 0.12));
    }
  }
  .emptyNotificationList {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 25%;
      img {
        width: 60px;
        height: auto;
      }
      span {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.18em;
        color: #cecece;
        margin-top: 2rem;
      }
      small {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: #1d1d1d;
        margin-top: 0.75rem;
      }
    }
  }
  :global(.loading .uk-icon.uk-spinner svg circle) {
    stroke: $brand-color !important;
  }

  .notificationListItem {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1rem;
    position: relative;
    flex-wrap: wrap;
    .avatar {
      flex: 0 0 auto;
      width: 2.25rem;
      height: 2.25rem;
    }
    .message {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;
      margin: 0 0 0 1rem;
      flex: 1;
      .content {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.155em;
        color: #000000;
        text-align: left;
        p {
          margin: 0;
          padding: 0;
        }
        span {
          font-weight: bold;
        }
      }
      .projectInfo {
        display: flex;
        margin: 0.5rem 0;
        span {
          padding: 0.05rem 0.25rem;
          margin-right: 0.25rem;
          border-radius: 8px;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.05em;
          &.name {
            background-color: #dcf2ff;
            color: #233f87;
            max-width: 7rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.partner {
            background: #f3f3f3;
            color: #535353;
            max-width: 6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.type {
            background: #f3f3f3;
            color: #696969;
          }
        }
      }
      .date {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: $gray-color;
      }
    }
    .read {
      .dot {
        width: 8px;
        height: 8px;
        background: $brand-color;
        border-radius: 100%;
      }
    }
    &.read {
      .message {
        .content {
          color: #8f8f8f;
        }
        .projectInfo {
          span {
            &.name {
              background: #e8fffe;
              color: #60959a;
            }
            &.partner {
              background: #e8f7ff;
              color: #5f78b9;
            }
            &.type {
              background: #f4f4f4;
              color: #8a8a8a;
            }
          }
        }
        .date {
          color: #d6d6d6;
        }
      }
      .read {
        .dot {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
