import axios from 'axios';
import router from '@/router/index';
import notification from '@/lib/notification';

export default function useAxios() {
  //axios 設定
  axios.defaults.baseURL = process.env.VUE_APP_AXIOS_DEFAULT_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        switch (error.response.status) {
          // 登入錯誤403
          // 沒有訪問權限
          case 401:
            console.log('沒有訪問權限');
            router.push('/error/project');
            break;
          case 404:
            console.log('你要找的頁面不存在');
            router.push('/404');
            break;
          // 系統邏輯錯誤
          case 422:
            console.log(error.response);
            notification.notificationShow(
              'danger',
              '操作失敗,請重新整理或稍後再試'
            );
            break;
          case 428:
            notification.notificationShow('danger', '未有文案');
          case 500:
            console.log('程式發生問題');
            break;
          default:
            console.log(error.response);
        }
      }
      if (!window.navigator.onLine) {
        alert('網路出了點問題，請重新連線後重整網頁');
        return;
      }
      return Promise.reject(error);
    }
  );
}
