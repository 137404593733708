<template>
  <section class="serviceArea tw-py-8 md:tw-py-14">
    <div class="tw-homeContainer">
      <h2
        class="tw-text-[#161818] tw-text-[32px] tw-leading-[normal] tw-tracking-[1.28px] tw-mb-8 tw-py-2"
      >
        強大平台與服務，讓網紅行銷更簡單更方便！
      </h2>
      <div class="wrap">
        <div
          class="card"
          v-for="(item, index) in serviceData"
          :key="item"
          :data-aos="`${index % 2 === 0 ? 'flip-right' : 'flip-left'}`"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <p
            class="tw-py-1 tw-px-2 tw-text-white tw-text-base tw-rounded-md tw-bg-[#44C3CE] tw-inline-block tw-mb-5"
          >
            {{ item.subTitle }}
          </p>
          <p
            class="tw-mb-3 tw-mt-0 tw-text-black tw-text-2xl tw-not-italic tw-leading-9 tw-tracking-[0.96px]"
          >
            {{ item.title }}
          </p>
          <p
            class="tw-mt-0 tw-text-[rgba(22,24,24,0.90)] tw-text-base tw-not-italic tw-tracking-[1.28px]"
          >
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue';
const serviceData = reactive([
  {
    title: '網紅行銷專案管理平台',
    subTitle: '自行合作業配',
    content:
      '如果您自行洽談網紅行銷，可以使用專為網紅行銷開發的專案管理平台，從業配內容溝通執行、結案報告成效分析，一站式完整順暢的體驗，讓您節省大量的人力與投入！'
  },
  {
    title: '尊爵專案客製服務',
    subTitle: '網紅行銷專業團隊',
    content:
      '從需求目標出發，協助您擬定策略操作，找尋您合適的網紅人選，執行專案過程都由專業團隊協助，支援滿足您所有需求，讓您有效完成每次的網紅行銷！'
  }
]);
</script>

<style lang="scss" scoped>
.serviceArea {
  background: linear-gradient(
    1deg,
    rgba(68, 195, 206, 0.08) -3.13%,
    rgba(68, 195, 206, 0) 90.07%
  );
}
.wrap {
  @apply tw-flex tw-flex-col lg:tw-flex-row tw-pt-2 tw-pb-4 tw-gap-8 lg:tw-gap-[60px] tw-justify-center;
  .card {
    @apply tw-shadow-[1px_1px_25px_0px_rgba(29,120,127,0.20)] tw-rounded-[20px] tw-bg-white tw-py-8 tw-px-10 tw-text-left xl:tw-max-w-[488px];
  }
}
</style>
