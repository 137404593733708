<template>
  <button
    type="button"
    class="m-btn-comment"
    :class="{ disabled: disabled }"
    @click.stop="$emit('emitSendMsg')"
  >
    <span class="uk-icon uk-spinner" role="status" v-if="loading">
      <svg width="24" height="24" viewBox="0 0 30 30">
        <circle
          fill="none"
          stroke="#000"
          cx="15"
          cy="15"
          r="14"
          style="stroke-width: 1.25px"
        ></circle>
      </svg>
    </span>
    <span v-else><slot>留言</slot></span>
  </button>
</template>
<script>
import { reactive, ref } from 'vue';

export default {
  name: '',
  props: ['loading', 'disabled'],
  emits: ['emitSendMsg'],
  setup(props, { emit }) {
    return {};
  }
};
</script>
<style lang="scss" scoped></style>
