import { ref, reactive, provide } from 'vue';
import Chat from '@/class/MessageBoard';

export default function useMessageBoard() {
  const chatState = reactive({
    data: {
      type: '',
      taskId: '',
      platformId: ''
      // secondaryId: '',
    }
  });
  const chatMessage = reactive({
    message: { data: null }
  });

  const quotedValue = ref(null);

  const isOpenCommentDOM = ref(false);

  const _setDefaultState = data => {
    chatState.data = data;
  };

  const getMessage = async () => {
    chatMessage.message.data = await new Chat(chatState.data).getMessages;
  };

  const _getMessage = async () => {
    chatMessage.message.data = await new Chat(chatState.data).getMessages;
    UIkit.offcanvas('#offcanvas-messageBoard').show();
  };

  const sendMessage = (
    message,
    messageId = null,
    quote = null,
    images = []
  ) => {
    let tmpSendData = { ...chatState.data, message };
    if (messageId) {
      tmpSendData.messageId = messageId;
    }
    if (quote) {
      tmpSendData.quote = quote;
    }
    if (images?.length) {
      tmpSendData.images = images;
    }
    return new Chat(tmpSendData).sendMessage;
  };
  const updateMessagesProcessed = (messageId, status) => {
    let tmpSendData = { ...chatState.data, messageId, status };
    return new Chat(tmpSendData).updateMessagesProcessed;
  };
  const editMessage = (message, messageId, images = []) => {
    let tmpSendData = { ...chatState.data, message, messageId, images };
    return new Chat(tmpSendData).editMessage;
  };
  const delMessage = messageId => {
    let tmpSendData = { ...chatState.data, messageId };
    return new Chat(tmpSendData).delMessage;
  };
  const getResponse = messageId => {
    let tmpSendData = { ...chatState.data, messageId };
    return new Chat(tmpSendData).getResponse;
  };

  // public method
  const openChatService = ({ data, name = '' } = {}) => {
    return new Promise(async resolve => {
      _setDefaultState(data, name);
      await _getMessage();

      resolve(true);
      UIkit.offcanvas('#offcanvas-messageBoard').show();
    });
  };
  provide('quotedValue', quotedValue);
  provide('isOpenCommentDOM', isOpenCommentDOM);
  provide('chatState', chatState);
  provide('chatMessage', chatMessage);
  provide('openChatService', openChatService);
  provide('getMessage', getMessage);
  provide('sendMessage', sendMessage);
  provide('updateMessagesProcessed', updateMessagesProcessed);
  provide('editMessage', editMessage);
  provide('delMessage', delMessage);
  provide('getResponse', getResponse);
}
