<template>
  <div class="tw-p-[6px] tw-mr-4 tw-flex">
    <img
      :src="require('@/assets/icon/editor/link-chain.svg')"
      class="tw-mr-1"
      width="24"
      height="24"
    />
    <a
      :href="editor.getAttributes('link').href"
      target="_blank"
      uk-tooltip="title: 另開新視窗; pos: top;offset:20"
    >
      {{ editor.getAttributes('link').href }}</a
    >
  </div>
  <button
    type="button"
    class="p-0"
    uk-tooltip="title: 編輯連結; pos: top;offset:20"
    @click="$emit('showLinkEditModel')"
  >
    <img :src="require('@/assets/icon/editor/link-edit.svg')" />
  </button>
</template>
<script>
import { reactive, ref } from 'vue';
export default {
  name: 'LinkPreview',
  props: ['editor'],
  emits: ['showLinkEditModel'],
  setup(props, { emit }) {
    return {};
  }
};
</script>
<style lang="scss" scoped>
a {
  color: #68cef8;
  text-decoration: underline;
  @apply tw-max-w-[120px] sm:tw-max-w-[200px] md:tw-max-w-[350px];
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
